import React, { useState } from "react";
import Capsule from "../shared/Capsule";
import Crystal from "point_rewards/crystal.png";
import Ray from "point_rewards/rays.png";
import PropTypes from "prop-types";
import "./CrystalRewardCompensateNotify.scss";
import "../shared/useful_css.scss";

const CrystalWithCircle = () => {
  return (
    <div className="circle">
      <img src={Crystal} className="crystal-in-circle" />
    </div>
  )
}

const CrystalWithRay = (courseCount, pointCount, handleConfirm) => {
  return (
    <div className="ray-container">
      <img src={Ray} className="ray" />
      {CrystalWithCircle()}
      {documentContent(courseCount, pointCount, handleConfirm)}
    </div>
  )
}

const documentContent = (courseCount, pointCount, handleConfirm) => {
  const buttonStyle = {
    backgroundColor: "#FFC000",
    color: "#FFF",
    fontSize: '16px',
    padding: '9px 30px',
    display: 'inline',
    width: '180px',
    textAlign: 'center',
    marginTop: '30px',
  }

  return (
    <div className="document-container flex flex-col items-center">
      <div className="text-center">
        <span className="text-dark">您有</span>
        <span className="text-yellow">{courseCount}</span>
        <span className="text-dark">天课程学完未发放水晶，系统已为您补发</span>
        <span className="text-yellow">{pointCount}</span>
        <span className="text-dark">颗水晶！</span>
      </div>
      <Capsule text="确认" style={buttonStyle} onClick={handleConfirm}/>
    </div>
  )
}

const CrystalRewardCompensateNotify = ({ courseCount, pointCount }) => {
  const [showWindow, setShowWindow] = useState(true)
  const buttonStyle = {
    backgroundColor: "#FFC000",
    color: "#FFF",
    fontSize: '16px',
    padding: '9px 30px',
    display: 'inline',
    width: '180px',
    textAlign: 'center',
  }
  const handleConfirm = () => {
    setShowWindow(false)
  }
  return (
    <>
      { 
      showWindow
      ?
      <div className="crystal-reward-compensate-notify">
        <div className="background-mask"></div>
        {CrystalWithRay(courseCount, pointCount, handleConfirm)}
      </div>
      :
      null 
      }
    </>
  )
}

CrystalRewardCompensateNotify.propTypes = {
  courseCount: PropTypes.number.isRequired,
  pointCount: PropTypes.number.isRequired,
}

export default CrystalRewardCompensateNotify