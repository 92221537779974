/* eslint-disable react/prop-types */
import React, { memo, useState, useEffect, useMemo, useCallback } from "react";
import { MyProvider, useSelector } from "../../utils/react-global-state";
import { TabPane, Tabs } from "../../utils/Tabs/Tabs.js";
import SyncPracticeStore from "./SyncPracticeStore.js";
import useCoursePDFCopy from "./components/CoursePDFCopy.jsx";
import useCoursePayment from "./components/CoursePayment.jsx";
import ModalManager from "../../utils/Modal/ModalManage.jsx";
import { request } from "../../utils/request";
import { ToastManager } from "../../utils/Toast.jsx";

import Note from "new_study_unit/Note.png";
import icon_star_0 from "study_package/icon_star_0.png";
import icon_star_1 from "study_package/icon_star_1.png";
import icon_star_2 from "study_package/icon_star_2.png";
import icon_star_3 from "study_package/icon_star_3.png";
import icon_quiz_pass from "icon_quiz_pass.png";
import icon_lock from "study_package/icon_lock_white.png";

import placeholder_note from "sync_training_camp/placeholder_note.png";
import placeholder_play from "sync_training_camp/placeholder_play.png";
import placeholder_print from "sync_training_camp/placeholder_print.png";
import placeholder_rocket from "sync_training_camp/placeholder_rocket.png";
import icon_top from "sync_training_camp/icon_top.png";

import "./SyncPractice.scss";

const ThemeColorCollection = [
  {
    /**
     * 10: "在线测试",
     * 21: "PDF",,
     * 0: "课程讲解",
     * 1: "拓展课程",
     * */
    "0": {
      background: "#ebf8ff",
      font: "#4595e6",
      borderColor: "#00aaff",
      labelBackgroundColor: "#00aaff",
      icon: placeholder_play,
      iconSize: 40,
    },
    "1": {
      background: "#ffeceb",
      font: "#fa817d",
      borderColor: "#ff8880",
      labelBackgroundColor: "#ff8880",
      icon: placeholder_rocket,
      iconSize: 32,
    },
    "10": {
      background: "#fffbe5",
      font: "#8a7500",
      borderColor: "#ffd400",
      labelBackgroundColor: "#ffd400",
      icon: placeholder_note,
      iconSize: 40,
    },
    "21": {
      background: "#e5ffff",
      font: "#4d998f",
      borderColor: "#00e5e5",
      labelBackgroundColor: "#00e5e5",
      icon: placeholder_print,
      iconSize: 45,
    },
  },
];
const ThemeColor = ThemeColorCollection[0];
console.log("ThemeColor: ", ThemeColor);

const SyncPractice = () => {
  const studyPackageUnits = useSelector((state) => state.studyPackageUnits);
  const getUnitStudyUrl = useSelector((state) => state.getUnitStudyUrl);
  const [studyPackageModules, setStudyPackageModules] = useState([]);
  const [preparations, setPreparations] = useState([]);

  useEffect(() => {
    getUnitStudyModule(studyPackageUnits[0].id);
  }, []);

  const getUnitStudyModule = useCallback(
    (unitId) => {
      const toast = ToastManager.showLoading("加载中...");
      request({
        url: getUnitStudyUrl,
        method: "GET",
        params: {
          study_package_unit_id: unitId,
        },
      })
        .then((resp) => {
          console.log("resp.data", resp.data);
          setPreparations(resp.data.preparations);
          setStudyPackageModules(resp.data.study_package_modules);
          toast.cancel();
        })
        .catch((_err) => {
          toast.cancel();
          console.log("请求出错");
        });
    },
    [getUnitStudyUrl]
  );

  console.log("studyPackageModules: ", studyPackageModules);

  const preparationsValue = preparations && preparations.length > 0;
  const studyPackageModulesValue =
    studyPackageModules && studyPackageModules.length > 0;

  const tabClick = (e, activeTabId) => {
    console.log("activeTabId: ", activeTabId);
    getUnitStudyModule(studyPackageUnits[activeTabId].id);
  };
  return (
    (preparationsValue || studyPackageModulesValue) && (
      <div className="comp_sync_training_camp">
        <Tabs styles="tabs" defaultAction={tabClick} defaultActiveIndex={0}>
          {studyPackageUnits.map((study, index) => (
            <TabPane
              key={index}
              tab={
                <div className="name">
                  <img src={study.photo_url} alt="" />
                  <span>{study.name}</span>
                </div>
              }
              activeStyle="active"
              styles={`tab`}
            >
              <div className={`training_camp_container`}>
                <TrainingCampBox
                  preparations={preparations}
                  studyPackageModules={studyPackageModules}
                />
              </div>
            </TabPane>
          ))}
        </Tabs>
      </div>
    )
  );
};

const TrainingCampBox = ({ ...props }) => {
  const { preparations, studyPackageModules } = props;
  // const onPayModalOpen = useCoursePayment();
  // const onCopyModalOpen = useCoursePDFCopy({
  //   pdfUrl: "https://xinya.me/quizzes?id=125781e",
  //   courseTitle: "11~20的数PDF卷"
  // });

  return (
    <React.Fragment>
      <CourseGuideRoute studyPackageModules={studyPackageModules} />
      <PreSchoolPreparation preparations={preparations} />
      {studyPackageModules.map((unit, index) => (
        <MathCourseCollection unit={unit} unitIndex={index} key={index} />
      ))}
      <CurrentUnitEnd />
      <FloatCornerFixed />
      {/* <div onClick={onPayModalOpen}>pay</div>
      <div onClick={onCopyModalOpen}>copy</div> */}
    </React.Fragment>
  );
};

const TitleBar = memo(({ title }) => {
  return (
    <div className="title_bar">
      <span>{title}</span>
    </div>
  );
});

const CourseGuideRoute = memo(({ studyPackageModules }) => {
  const tabItemCollection = [
    {
      bgColor: "#02ca99",
    },
    {
      bgColor: "#3bb6fc",
    },
    {
      bgColor: "#f5bc49",
    },
    {
      bgColor: "#ff99a1",
    },
  ];
  return (
    <div className="course_guide_route_box">
      <div className="course_guide_route clear_scroll_bar">
        {studyPackageModules.map((module, index) => (
          <a
            className="sub_guide_route"
            key={index}
            href={`#anchor${index}`}
            style={{
              background:
                tabItemCollection[index % tabItemCollection.length].bgColor,
            }}
          >
            <div className="guide_title">{module.name}</div>
            <img src={module.photo_url} alt="" />
          </a>
        ))}
      </div>
    </div>
  );
});

const PreSchoolPreparation = memo(({ preparations }) => {
  // const dispatch = useDispatch();
  console.log(preparations);

  const modalStyle = {
    left: 0,
    margin: 0,
    width: "100%",
    maxWidth: "100%",
  };
  const modal = useMemo(
    () =>
      new ModalManager({
        render: (_, modalClose, fakeClose, extra) => {
          document.querySelector("html, body").classList.add("no_scroll");
          const onClose = () => {
            document.querySelector("html, body").classList.remove("no_scroll");
            modalClose();
          };
          return (
            <div className="modal_simple">
              <div className="modal_mask"></div>
              <div className="modal_content" style={modalStyle}>
                <div className="pre_school_video">
                  <video
                    id="pre_video"
                    autoPlay={false}
                    controls="controls"
                    // poster={extra.poster}
                    x5-video-player-type="h5"
                    x5-video-player-fullscreen="false"
                    x5-video-orientation="landscape|portrait"
                  >
                    <source src={extra.video_src} type="video/mp4" />
                    您的浏览器不支持Video标签。
                  </video>
                  <span className="pre_school_video_close" onClick={onClose}>
                    &times;
                  </span>
                </div>
              </div>
            </div>
          );
        },
      }),
    [modalStyle]
  );
  const showVideo = (poster, video_src) => {
    modal.open({ poster: poster, video_src: video_src });
    document.getElementById("pre_video").play();
  };
  return (
    <div>
      {preparations &&
        preparations.map((item, idx) => {
          return (
            <div key={idx} className="pre_school_preparation common_margin">
              <TitleBar title={item.name} />
              <div className="pre_school_box">
                {item.day_studies &&
                  item.day_studies.length > 0 &&
                  item.day_studies.map((study, j) => {
                    return (
                      study.contents &&
                      study.contents.map((content, k) => {
                        return (
                          <div key={k}>
                            {content && content.article_type == 2 && (
                              <div
                                className="knowledge_unscramble"
                                onClick={() =>
                                  showVideo(content.photo_url, content.url)
                                }
                              >
                                <div className="left_box">
                                  <div className="text_title">
                                    {content.name}
                                  </div>
                                  <div className="text_describe">
                                    {content.brief}
                                  </div>
                                </div>
                                <div className="right_box">
                                  <img src={content.photo_url} alt="" />
                                </div>
                              </div>
                            )}

                            {content && !content.article_type && (
                              <a
                                className="knowledge_unscramble pre_knowledge_test"
                                href={content.url}
                              >
                                <div className="left_box">
                                  <div className="text_title">
                                    {content.name}
                                  </div>
                                  <div className="text_describe">
                                    {content.brief}
                                  </div>
                                </div>

                                {"score" in content ? (
                                  <div className="right_box_score">
                                    <img
                                      className="course_pass"
                                      src={icon_quiz_pass}
                                      alt=""
                                    />
                                    <span>{content.score}分</span>
                                  </div>
                                ) : (
                                  <div className="right_box">
                                    <div className="link_url">{`去测评`}</div>
                                  </div>
                                )}
                              </a>
                            )}
                          </div>
                        );
                      })
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
});

const LinkTestGuideSection = memo(({ text, url }) => {
  return (
    <a href={url} className="link_url">
      {text}
    </a>
  );
});

const MathCourseCollection = memo(({ unit, unitIndex }) => {
  const dayStudiesLengthLessOne =
    unit.day_studies && unit.day_studies.length < 2;
  return (
    <div
      className="math_course_collection common_margin"
      id={`anchor${unitIndex}`}
    >
      <TitleBar title={unit.name} />
      {unit.day_studies &&
        unit.day_studies.length > 0 &&
        unit.day_studies.map((study, idx) => (
          <div className="math_course_box" key={idx}>
            <div className="course_container">
              <div className="course_box">
                <div className="left_course_box">
                  {dayStudiesLengthLessOne ? (
                    ""
                  ) : (
                    <div className="sequence_number">
                      <span
                        style={{
                          paddingLeft: idx + 1 >= 10 ? "3px" : "",
                        }}
                      >
                        {idx + 1}
                      </span>
                    </div>
                  )}
                  <div
                    className="course_name"
                    style={{
                      paddingLeft: dayStudiesLengthLessOne ? 0 : null,
                    }}
                  >
                    {study.name}
                  </div>
                </div>
                {study.wrong_quiz_url && (
                  <div className="right_course_box">
                    <LinkTestGuideSection
                      text={`有错题`}
                      url={study.wrong_quiz_url}
                    />
                  </div>
                )}
              </div>
              {study.sub_name && (
                <div
                  className="course_brief"
                  style={{
                    paddingLeft: dayStudiesLengthLessOne ? 0 : null,
                  }}
                >
                  {study.sub_name}
                </div>
              )}
            </div>
            <div
              className="course_slide_section clear_scroll_bar"
              style={{
                paddingLeft: dayStudiesLengthLessOne ? "15px" : null,
              }}
            >
              {study.contents &&
                study.contents.length > 0 &&
                study.contents.map((content, ind) => (
                  <SimpleCourseItem content={content} key={ind} />
                ))}
            </div>
          </div>
        ))}
    </div>
  );
});

const SimpleCourseItem = memo(({ content }) => {
  const iconStars = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];
  const upgradePackage = useSelector((state) => state.upgrade_package);
  // console.log("upgradePackage: ", upgradePackage);
  const onPayModalOpen =
    upgradePackage && upgradePackage.study_package_activity_buy_url
      ? useCoursePayment(upgradePackage)
      : null;
  const {
    background,
    font,
    borderColor,
    labelBackgroundColor,
    icon,
    iconSize,
  } = ThemeColor[content.content_type];
  const STUDYABLE_STAGE = {
    0: "课程讲解",
    1: "拓展课程",
    10: `${
      content.count_down_mins && content.count_down_mins > 0
        ? "在线测试"
        : "在线练习"
    }`,
    21: "PDF",
    22: "在线视频",
  };
  return (
    <a
      href={
        content.lock
          ? "javascript:void(0)"
          : content.studyable_type === "WxArticle"
          ? "javascript:void(0)"
          : content.url
      }
      className="simple_course_box"
      onClick={
        content.lock
          ? onPayModalOpen
          : content.studyable_type === "WxArticle"
          ? useCoursePDFCopy(content.url, content.name)
          : null
      }
      style={{
        backgroundColor: background,
        backgroundImage: `url(${icon})`,
        // backgrountPositon: right bottom,
        backgroundSize: iconSize,
        border: `1px solid ${borderColor}`,
      }}
    >
      {/* lock => true: 未解锁（锁定）， false: 解锁 */}
      {content.lock && (
        <div className="lock_status">
          <img src={icon_lock} alt="" />
        </div>
      )}
      <div className="top_box">
        <div
          className="left_label"
          style={{
            backgroundColor: labelBackgroundColor,
          }}
        >
          {STUDYABLE_STAGE[content.content_type]}
        </div>
        {content.studyable_type !== "WxArticle" &&
          content.studyable_type === "DayStudyExam" &&
          "score" in content && (
            <div
              className="right_status"
              style={{
                color: font,
              }}
            >
              <img className="course_pass" src={icon_quiz_pass} alt="" />
              <span>{content.score}分</span>
            </div>
          )}
        {content.studyable_type !== "WxArticle" &&
          content.studyable_type === "MediaLesson" && (
            <div className="right_status">
              <img className="course_star" src={iconStars[content.rank]} />
            </div>
          )}
      </div>
      <div
        className="center_content"
        style={{
          color: font,
        }}
      >
        <div className="title">{content.name}</div>
        <p>{content.brief}</p>
      </div>
    </a>
  );
});

const CurrentUnitEnd = memo(() => {
  return <div className="current_unit_end">本单元结束</div>;
});

const FloatCornerFixed = memo(() => {
  const wrongQuizUrl = useSelector((state) => state.wrongQuizUrl);
  const handleBackToTop = useCallback(() => {
    $(window).scrollTop(0);
  }, []);
  return (
    <div className="float_corner_fixed">
      <a className="common_icon" href={wrongQuizUrl}>
        <img className="icon_note" src={Note} alt="" />
        <span>错题本</span>
      </a>
      <a
        className="common_icon"
        href="javascript:void(0)"
        onClick={handleBackToTop}
      >
        <img className="icon_top" src={icon_top} alt="" />
        <span>回顶部</span>
      </a>
    </div>
  );
});

const NewProvider = (props) => {
  console.log(props);
  return (
    <MyProvider
      store={SyncPracticeStore.build({
        ...props,
        studyPackageUnits: props.study_package_units,
        studyPackageName: props.study_package_name,
        getUnitStudyUrl: props.get_unit_study_url,
        wrongQuizUrl: props.wrong_quiz_url,
        upgradePackage: props.upgrade_package,
      })}
    >
      <SyncPractice />
    </MyProvider>
  );
};

export default NewProvider;
