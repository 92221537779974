import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import icon_star_0 from "study_package/icon_star_0.png";
import icon_star_1 from "study_package/icon_star_1.png";
import icon_star_2 from "study_package/icon_star_2.png";
import icon_star_3 from "study_package/icon_star_3.png";
import icon_lock_gray from "study_package/icon_lock_gray.png";

import icon_default_boy from "icon_default_boy.png";
import icon_default_girl from "icon_default_girl.png";

import starWhite from "study_package/icon_star_border.png";
import starYellow from "study_package/icon_star_yellow.png";

import UserAgent from "../../utils/UserAgent";
import { onMiniProgram } from "../../utils/helpers";
import { throttle, debounce } from "../../utils/helpers";
import { ToastManager } from "../../utils/Toast";
import { Dialog, DialogManager } from "../../utils/Dialog";
import { request, csrfHeaders } from "../../utils/request";
import {
  SharePosterProxy,
  SharePoster,
} from "../../promotion_activities/SharePoster";
import AudioPlayer, {
  SmallAudioPlayer,
} from "../../utils/audio_player/AudioPlayer";
import { DateCountDown } from "../../utils/formatAudioTime";

import URI from "jsuri";
import QueryString from "query-string";
import { showDialog } from "../../utils/helpers";

import "./Poetry.scss";

// 诗词列表拆分子组件
const OptionItem = ({ lesson, iconStars, poetryTap }) => {
  let optionLessonStatus = "";
  if (lesson.lock == true) {
    optionLessonStatus = (
      <img className="poetry_star lazyload" data-src={icon_lock_gray} alt="" />
    );
  } else {
    if (iconStars[lesson.rank]) {
      optionLessonStatus = (
        <img
          className="poetry_star lazyload"
          data-src={iconStars[lesson.rank]}
          alt=""
        />
      );
    } else {
      optionLessonStatus = <div className="poetry_start">开始</div>;
    }
  }

  return (
    <li>
      <a
        href={"javascript:void(0)"}
        onClick={debounce(poetryTap, 1000)}
        data-url={lesson.lock == true ? "" : lesson.url}
      >
        <div className="poetry_content">
          {optionLessonStatus}
          <p>{lesson.name}</p>
        </div>
      </a>
    </li>
  );
};

// 诗词列表
const PoetryContentList = ({
  poetryMediaLessons,
  name,
  subName,
  iconStars,
  todayQuiz,
  poetryTap,
  reviewMediaLessons,
}) => {
  let awokeReviewstatus = "";
  if (reviewMediaLessons && reviewMediaLessons.length > 0) {
    reviewMediaLessons.forEach((media_lesson) => {
      if (media_lesson.lock == true) {
        awokeReviewstatus = (
          <img className="lazyload" data-src={icon_lock_gray} alt="" />
        );
      } else {
        if (iconStars[media_lesson.rank]) {
          awokeReviewstatus = (
            <img
              className="lazyload"
              data-src={iconStars[media_lesson.rank]}
              alt=""
            />
          );
        } else {
          awokeReviewstatus = <div className="review_start">开始</div>;
        }
      }
    });
  }

  let todayQuizStatus = "";
  if (todayQuiz) {
    if (todayQuiz.lock == true) {
      todayQuizStatus = (
        <img
          className="poetry_star lazyload"
          data-src={icon_lock_gray}
          alt=""
        />
      );
    } else {
      if (iconStars[todayQuiz.rank]) {
        todayQuizStatus = (
          <img
            className="poetry_star lazyload"
            data-src={iconStars[todayQuiz.rank]}
            alt=""
          />
        );
      } else {
        todayQuizStatus = <div className="poetry_start">开始</div>;
      }
    }
  }

  return (
    <div>
      {
        <div className="poetry_content_list">
          <div className="poetry_title">
            <div className="title">
              <span>
                《{name}》{subName}
              </span>
            </div>
            <div className="title_line"></div>
          </div>

          {reviewMediaLessons &&
            reviewMediaLessons.length > 0 &&
            reviewMediaLessons.map((media_lesson) => (
              <a
                key={media_lesson.id}
                className="poetry_review"
                href={"javascript:void(0)"}
                onClick={debounce(poetryTap, 1000)}
                data-url={media_lesson.lock == true ? "" : media_lesson.url}
              >
                {awokeReviewstatus}
                <div className="poetry_review_text">
                  唤醒复习：《{media_lesson.meida_course.name}》
                </div>
              </a>
            ))}

          {poetryMediaLessons.length > 0 && (
            <div className="poetry_lists">
              <ul>
                {poetryMediaLessons.map((lesson) => (
                  <OptionItem
                    key={lesson.id}
                    lesson={lesson}
                    iconStars={iconStars}
                    data-url={lesson.url}
                    poetryTap={poetryTap}
                  />
                ))}
                {todayQuiz && (
                  <li>
                    <a
                      href={"javascript:void(0)"}
                      onClick={debounce(poetryTap, 1000)}
                      data-url={todayQuiz.lock == true ? "" : todayQuiz.url}
                    >
                      <div className="poetry_content">
                        {todayQuizStatus}
                        <p>4.&nbsp;诗词游戏</p>
                      </div>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      }
    </div>
  );
};

// 头图和唤醒复习
const PoetryPeriodHeader = ({ photoUrl, todayQuiz }) => {
  return (
    <div className="poetry_top">
      <div className="head_img">
        <img className="lazyload" data-src={photoUrl} alt="" />
      </div>
      {todayQuiz && todayQuiz.status == true && (
        <div className="already_study"></div>
      )}
    </div>
  );
};

// 学习报告 打卡部分
const ReportCardList = ({ studyReportUrl, cardShareClick }) => {
  return (
    <div className="poetry_study_card">
      <a className="study_report" href={studyReportUrl}>
        学习报告
      </a>
      <a
        className="collect_star"
        href="javascript:void(0)"
        onClick={cardShareClick}
      >
        打卡分享
      </a>
    </div>
  );
};

// 音频列表模块
class UserAudioInfoList extends PureComponent {
  constructor(props) {
    super(props);
    console.log("UserAudioInfoList_props", props);
    this.state = {
      isStared: props.liked,
      starNumed: props.starNumed,
    };
  }

  staredClick = () => {
    request({
      url: this.props.likeUrl,
      method: "POST",
      headers: csrfHeaders,
    }).catch((err) => {
      console.log(err);
    });
    // 点赞 +1
    if (!this.state.isStared) {
      console.log("collect ++ zan");
      this.setState({
        isStared: true,
        starNumed: parseInt(this.state.starNumed) + 1,
      });
    } else {
      // 点赞 -1
      console.log("cancel -- zan");
      this.setState({
        isStared: false,
        starNumed: parseInt(this.state.starNumed) - 1,
      });
    }
  };

  render() {
    let { recorder, index, systemTime } = this.props;
    const { isStared, starNumed } = this.state;

    let audioUserGender;
    if (recorder.baby.photo_url == null) {
      if (recorder.baby.gender == "男") {
        audioUserGender = (
          <img data-src={icon_default_boy} alt="" className="lazyload" />
        );
      } else {
        audioUserGender = (
          <img data-src={icon_default_girl} alt="" className="lazyload" />
        );
      }
    } else {
      audioUserGender = (
        <img data-src={recorder.baby.photo_url} alt="" className="lazyload" />
      );
    }

    return (
      <li>
        <div className="user_info_container">
          <div className="user_info_wrapper">
            <div className="user_avatar">{audioUserGender}</div>
            <div className="text_info">
              <p>
                {recorder.baby.nickname}
                {recorder.baby.grade && <span>（{recorder.baby.grade}）</span>}
              </p>
              <div className="baby_info">
                <span>{DateCountDown(recorder.update_time, systemTime)}</span>
              </div>
            </div>
          </div>

          <div className="user_setting">
            <div className="stared">
              <div
                className={
                  isStared
                    ? "button_wrapper_active_lesson_one"
                    : "button_wrapper_lesson_one"
                }
                onClick={this.staredClick}
              >
                <img
                  style={{
                    marginLeft: isStared ? null : "5px",
                    paddingTop: isStared ? null : "3px",
                  }}
                  src={isStared ? starYellow : starWhite}
                  alt=""
                />
                {starNumed === 0 ? null : (
                  <div className="start_num">{starNumed}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="user_audio_recorder">
          <SmallAudioPlayer
            audioSrc={recorder.entire_record_url}
            audioInfoUrl={recorder.audio_info_url}
            index={index}
          />
        </div>
      </li>
    );
  }
}

// review read、release module
class AudioCommunity extends PureComponent {
  constructor(props) {
    super(props);
    console.log("AudioCommunity_props", props);
    let community = this.props.community;
    let current_baby_audio = community.current_baby_audio;

    this.state = {
      more_baby_recorders: community.baby_audio_recorders,
      page: 2,
      current_every_request_recorder: { length: 10 }, // 初始化请求10条数据
      isStared: current_baby_audio && current_baby_audio.liked,
      likeUrl: current_baby_audio && current_baby_audio.like_url,
      starNumed: current_baby_audio && current_baby_audio.rank,
    };
  }

  // 取消发布之前的弹窗提醒
  PreCancelRelease = () => {
    const buttons = [
      {
        label: "确定取消",
        onClick: () => {
          this.confirmDialog.close();
          this.cancalReleaseAudio();
        },
      },
      {
        label: "不取消",
        onClick: () => this.confirmDialog.close(),
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        取消发布，其他人就听不到
        {this.props.community.current_baby_audio.baby.nickname}读的诗了
      </Dialog>
    );
  };

  // click release audio
  releaseAudio = () => {
    let url = "";
    if (this.state.update_state_url) {
      url = this.state.update_state_url;
    }
    if (this.state.draft_update_state_url) {
      url = this.state.draft_update_state_url;
    }
    let params = {
      state: 1,
    };
    request({
      url: url,
      method: "PUT",
      headers: csrfHeaders,
      data: params,
    })
      .then((res) => {
        const data = res.data;
        this.setState({
          entire_record_url: data.entire_record_url,
          update_time: data.update_time,
          audio_info_url: data.audio_info_url,
          update_state_url: data.update_state_url,
          update_state: data.state,
        });
        location.reload();
        console.log(
          "发布语音: ",
          data,
          "update_state",
          this.state.update_state
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // cancel issue audio
  cancalReleaseAudio = () => {
    let url = "";
    if (this.state.update_state_url) {
      url = this.state.update_state_url;
    }
    if (this.state.draft_update_state_url) {
      url = this.state.draft_update_state_url;
    }
    let params = {
      state: 0,
    };
    request({
      url: url,
      method: "PUT",
      headers: csrfHeaders,
      data: params,
    })
      .then((res) => {
        const data = res.data;
        this.setState({
          draft_entire_record_url: data.entire_record_url,
          draft_update_time: data.update_time,
          draft_audio_info_url: data.audio_info_url,
          draft_update_state_url: data.update_state_url,
          draft_update_state: data.state,
        });
        location.reload();
        console.log(
          "取消发布: ",
          data,
          "draft_update_state ",
          this.state.draft_update_state
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadingMoreAudio = () => {
    this.toast = ToastManager.showLoading("拼命加载中...");
    const url = this.props.community.request_baby_audio_recorder_url;

    let params = {
      input: {
        page: this.state.page,
        count: 10,
      },
    };
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((res) => {
        this.toast.cancel();
        const data = res.data;
        this.setState({
          more_baby_recorders: this.state.more_baby_recorders.concat(
            data.baby_audio_recorders
          ),
          page: this.state.page + 1,
          current_every_request_recorder: res.data.baby_audio_recorders,
        });
      })
      .catch((err) => {
        this.toast.cancel();
        console.log(err);
      });
  };

  // click collect zan and cancel_zan
  staredOneselfClick = () => {
    request({
      url: this.state.likeUrl,
      method: "POST",
      headers: csrfHeaders,
    }).catch((err) => {
      console.log(err);
    });
    if (!this.state.isStared) {
      // 点赞 +1
      console.log("点赞");
      this.setState({
        isStared: true,
        starNumed: parseInt(this.state.starNumed) + 1,
      });
    } else {
      // 点赞 -1
      console.log("取消赞");
      this.setState({
        isStared: false,
        starNumed: parseInt(this.state.starNumed) - 1,
      });
    }
  };

  componentWillMount() {
    let community = this.props.community;
    let current_baby_audio = community.current_baby_audio;

    if (current_baby_audio) {
      let published = current_baby_audio.published;
      let draft = current_baby_audio.draft;

      if (published) {
        this.setState({
          entire_record_url: published.entire_record_url,
          update_time: published.update_time,
          audio_info_url: published.audio_info_url,
          update_state_url: published.update_state_url,
          update_state: published.state,
        });
      }

      if (draft) {
        this.setState({
          draft_entire_record_url: draft.entire_record_url,
          draft_update_time: draft.update_time,
          draft_audio_info_url: draft.audio_info_url,
          draft_update_state_url: draft.update_state_url,
          draft_update_state: draft.state,
        });
      }
    }
  }

  // before issue audio
  reReadIssueAudio(current_baby_audio) {
    let entireRecordUrl;
    if (
      current_baby_audio &&
      current_baby_audio.published &&
      this.state.update_state === 0
    ) {
      entireRecordUrl = this.state.entire_record_url;
    }
    if (
      current_baby_audio &&
      current_baby_audio.draft &&
      this.state.draft_update_state === 0
    ) {
      entireRecordUrl = this.state.draft_entire_record_url;
    }

    return (
      <div className="audio_operate">
        <AudioPlayer
          name={this.props.poetryName}
          babyName={current_baby_audio.baby.nickname}
          entireRecordUrl={entireRecordUrl}
        />
        <div className="operate_section">
          <a
            href="javascript:void(0)"
            onClick={debounce(this.props.poetryTap, 1000)}
            data-url={current_baby_audio.re_record_url}
          >
            重读
          </a>
          <a href="javascript:void(0)" onClick={this.releaseAudio}>
            发布
          </a>
        </div>
      </div>
    );
  }

  // no audio list , need show text describe
  createTextDescribe(text) {
    return (
      <div className="pre_release_remind">
        <p>{text}</p>
      </div>
    );
  }

  // after issue audio (zan and cancel_zan)
  userAudioShow(current_baby_audio, isStared, starNumed, systemTime) {
    let userGender;
    if (current_baby_audio.baby.photo_url == null) {
      if (current_baby_audio.baby.gender == "男") {
        userGender = (
          <img data-src={icon_default_boy} alt="" className="lazyload" />
        );
      } else {
        userGender = (
          <img data-src={icon_default_girl} alt="" className="lazyload" />
        );
      }
    } else {
      userGender = (
        <img
          data-src={current_baby_audio.baby.photo_url}
          alt=""
          className="lazyload"
        />
      );
    }

    let userDateCountDown, entireRecordUrl, audioInfoUrl;
    // current_baby_audio is published mode
    if (
      current_baby_audio &&
      current_baby_audio.published &&
      this.state.update_state === 1
    ) {
      userDateCountDown = (
        <span>{DateCountDown(this.state.update_time, systemTime)}</span>
      );
      entireRecordUrl = this.state.entire_record_url;
      audioInfoUrl = this.state.audio_info_url;
    }
    // current_baby_audio is draft mode
    if (
      current_baby_audio &&
      current_baby_audio.draft &&
      this.state.draft_update_state === 1
    ) {
      userDateCountDown = (
        <span>{DateCountDown(this.state.draft_update_time, systemTime)}</span>
      );
      entireRecordUrl = this.state.draft_entire_record_url;
      audioInfoUrl = this.state.draft_audio_info_url;
    }

    return (
      <li>
        <div className="user_info_container">
          <div className="user_info_wrapper">
            <a
              className="user_avatar"
              href={current_baby_audio.baby.edit_baby_path}
            >
              {userGender}
            </a>
            <div className="text_info">
              <p>
                {current_baby_audio.baby.nickname}
                {current_baby_audio.baby.grade && (
                  <span>（{current_baby_audio.baby.grade}）</span>
                )}
              </p>
              <div className="baby_info">{userDateCountDown}</div>
            </div>
          </div>

          <div className="user_setting">
            <div className="stared">
              <div
                className={
                  isStared
                    ? "button_wrapper_active_lesson_one"
                    : "button_wrapper_lesson_one"
                }
                onClick={this.staredOneselfClick}
              >
                <img
                  style={{
                    marginLeft: isStared ? null : "5px",
                    paddingTop: isStared ? null : "3px",
                  }}
                  src={isStared ? starYellow : starWhite}
                  alt=""
                />
                {starNumed === 0 ? null : (
                  <div className="start_num">{starNumed}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="user_audio_recorder">
          <SmallAudioPlayer
            audioSrc={entireRecordUrl}
            audioInfoUrl={audioInfoUrl}
            index="0"
          />
          <div className="cancel_release_audio">
            <span onClick={this.PreCancelRelease}>取消发布</span>
          </div>
        </div>
      </li>
    );
  }

  render() {
    let { more_baby_recorders, isStared, starNumed } = this.state;
    let { systemTime, community, courseFinishCondition } = this.props;

    console.log("render - update_state: ", this.state.update_state);
    console.log("render - draft_update_state: ", this.state.draft_update_state);

    let current_baby_audio = community.current_baby_audio;

    let textDescribe;
    if (community && !current_baby_audio) {
      if (courseFinishCondition === true) {
        textDescribe = this.createTextDescribe(
          `快完成${community.media_lesson_name}中的跟读录音`
        );
      } else {
        if (more_baby_recorders.length <= 0) {
          textDescribe = this.createTextDescribe(
            `快完成今日学习，成为第一个读诗的人吧`
          );
        } else {
          textDescribe = this.createTextDescribe(
            `快完成今日学习，一起来读诗吧`
          );
        }
      }
    }

    return (
      <div>
        <div className="poetry_title">
          <div className="title">
            <span>大家一起来读诗</span>
          </div>
          <div className="title_line"></div>
        </div>

        {textDescribe}

        {
          <div className="user_audio_list">
            {
              // user person audio published mode
              current_baby_audio &&
                current_baby_audio.published &&
                this.state.update_state === 0 &&
                this.reReadIssueAudio(current_baby_audio)
            }
            {
              // user person audio draft mode
              current_baby_audio &&
                current_baby_audio.draft &&
                this.state.draft_update_state === 0 &&
                this.reReadIssueAudio(current_baby_audio)
            }
            <ul>
              {
                // user person audio list published mode
                current_baby_audio &&
                  current_baby_audio.published &&
                  this.state.update_state === 1 &&
                  this.userAudioShow(
                    current_baby_audio,
                    isStared,
                    starNumed,
                    systemTime
                  )
              }
              {
                // user person audio list draft mode
                current_baby_audio &&
                  current_baby_audio.draft &&
                  this.state.draft_update_state === 1 &&
                  this.userAudioShow(
                    current_baby_audio,
                    isStared,
                    starNumed,
                    systemTime
                  )
              }

              {more_baby_recorders.map((recorder, index) => (
                <UserAudioInfoList
                  key={index}
                  recorder={recorder}
                  index={index + 1}
                  starNumed={recorder.rank}
                  liked={recorder.liked}
                  likeUrl={recorder.like_url}
                  systemTime={systemTime}
                />
              ))}
            </ul>
            {this.state.more_baby_recorders.length >= 10 && (
              <div onClick={this.loadingMoreAudio}>
                {this.state.current_every_request_recorder.length == 10 && (
                  <div className="loading_comment">加载更多</div>
                )}
              </div>
            )}
          </div>
        }
      </div>
    );
  }
}

export default class Poetry extends React.Component {
  constructor(props) {
    super(props);
    console.log("Poetry_props", props);
  }

  poetryTap = (e) => {
    var url = e.currentTarget.getAttribute("data-url");
    console.log(url);
    let searchParams = new URI(url).query();
    let pathname = new URI(url).path();
    let parsedQuery = QueryString.parse(searchParams);

    if (url === "") {
      return;
    } else {
      if (UserAgent.isWechat() || UserAgent.isMiniProgram()) {
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            if (url.includes("/media_lessons/")) {
              let id = pathname.match(/[0-9]*$/)[0];
              let params = Object.assign(parsedQuery, { id: id });
              let paramsString = JSON.stringify(params);
              const url = `/pages/recorder_demo/audioRecorder?params=${paramsString}`;
              wx.miniProgram.navigateTo({ url: url });
            } else {
              onMiniProgram(e, url, {});
            }
          } else {
            window.location.href = url;
          }
        });
      } else if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({ href: url });
      } else {
        window.location.href = url;
      }
      // if (UserAgent.isMiniProgram()) {
      //   if (url.includes('/media_lessons/')) {
      //     let id = pathname.match(/[0-9]*$/)[0]
      //     let params = Object.assign(parsedQuery, { id: id })
      //     let paramsString = JSON.stringify(params)
      //     const url = `/pages/recorder_demo/audioRecorder?params=${paramsString}`
      //     wx.miniProgram.navigateTo({ url: url })
      //   } else {
      //     onMiniProgram(e, url, {})
      //     return
      //   }
      // } else {
      //   window.location.href = url
      //   return
      // }
    }
  };

  // 打卡分享
  cardShareClick = (e) => {
    if (this.props.poster_url) {
      if (!this.sharePosterProxy) {
        this.sharePosterProxy = new SharePosterProxy({
          posterUrl: this.props.poster_url,
          free: "invite_card_collect_star",
          nickName: this.props.baby.nickname,
        });
      }
      this.sharePosterProxy.open();
    }
  };

  render() {
    let {
      review_media_lessons,
      photo_url,
      media_lessons,
      name,
      sub_name,
      study_report,
      study_report_url,
      today_quiz,
      community,
      study_package_system_time,
    } = this.props;

    const icon_stars = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];
    return (
      <div className="comp_poetry">
        <PoetryPeriodHeader
          photoUrl={photo_url}
          iconStars={icon_stars}
          todayQuiz={today_quiz}
          poetryTap={this.poetryTap}
        />
        <PoetryContentList
          reviewMediaLessons={review_media_lessons}
          poetryMediaLessons={media_lessons}
          name={name}
          subName={sub_name}
          iconStars={icon_stars}
          todayQuiz={today_quiz}
          poetryTap={this.poetryTap}
        />
        {study_report === true && (
          <ReportCardList
            studyReportUrl={study_report_url}
            cardShareClick={this.cardShareClick}
          />
        )}

        {community && (
          <AudioCommunity
            community={community}
            poetryName={name}
            systemTime={study_package_system_time}
            courseFinishCondition={study_report}
            poetryTap={this.poetryTap}
          />
        )}
      </div>
    );
  }
}

Poetry.defaultProps = {
  free: "invite_card_collect_star",
};
