/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, {
  memo,
  useCallback,
  useMemo,
  useState,
  createRef,
  useRef,
  useEffect,
} from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../../../utils/request";
import PropTypes from "prop-types";
import Raven from "raven-js";
import RightFloatIcons from "../../..//study_package_activities/RightFloatIcons.jsx";
import { SharePosterProxy } from "../../../promotion_activities/SharePoster";
import WithErrorBoundary from "../../../utils/WithErrorBoundary.jsx";
import { ToastManager } from "../../../utils/Toast";
import WithPlatformEnv from "../../../utils/WithPlatformEnv.jsx";
import UserAgent from "../../../utils/UserAgent";
import { SmallAudioPlayer } from "../../../utils/audio_player/AudioPlayer";
import icon_default_boy from "icon_default_boy.png";
import icon_default_girl from "icon_default_girl.png";
import { defaultFunc, showDialog, debounce } from "../../../utils/helpers";
import XinYaNativeBridge from "@/packs/native_bridges/native_bridge";
import {
  PoetryReading,
  LessonDetailsItem,
  usePoetryDetailInstallApp,
  InstallAppReminder,
} from "./components";
import ListContainer from "../../../utils/ListContainer/ListContainer.jsx";
import { ImgDialog } from "../../../utils/quizzes_practice_template/practiceTemplates";

import iconUpload from "reading/upload.png";
import iconBtnLong from "reading/btn-long.png";
import followRight from "reading/follow_right.png";
import iconBtnShort from "reading/btn-short.png";
import iconSending from "reading/sending.png";
// import iconInfoTitle from "reading/info_title.png";

import iconInfoTitle1 from "reading/info_title_1.png";
import tep_img from "reading/tep_img.png";
import cancle_green from "reading/cancle_green.png";
import iconPlaceholder from "reading/placeholder.png";
import aixin_red from "reading/aixin_red.png";

import aixin_white from "reading/aixin_white.png";
import iconDelete from "btn-fail-selected.png";
import iconPlay from "study_package/icon_player.png";
import iconPause from "study_package/icon_pause.png";
import uploader_img from "../../../../../assets/images/uploader_img.png";
import "./Clocking.scss";

// const BottomFixedButtons = memo(
//   ({
//     studyReportUrl,
//     babyName,
//     posterTemplate,
//     posterCreation,
//     fixedBottomOffset,
//   }) => {
//     const cardShareClick = () => {
//       new SharePosterProxy({
//         posterTemplate,
//         posterCreation,
//         mode: "front_end",
//         free: "poetry_share",
//         nickName: babyName,
//         isNewPoetry: true,
//       }).open();
//     };

//     return (
//       <div className="bottom_fixed" style={{ bottom: fixedBottomOffset || 0 }}>
//         <div className="flex_wrapper">
//           <a
//             className="bt_common study_report"
//             href={`${studyReportUrl}&ref=daily_course`}
//           >
//             学习报告
//           </a>
//           {/* <div className="bt_common poster" onClick={cardShareClick}>
//             打卡海报
//           </div> */}
//         </div>
//       </div>
//     );
//   }
// );

// BottomFixedButtons.propTypes = {
//   posterTemplate: PropTypes.string,
//   posterCreation: PropTypes.string,
//   studyReportUrl: PropTypes.string,
//   babyName: PropTypes.string,
//   fixedBottomOffset: PropTypes.number,
// };

// const ListTitle = memo(({ subTopic }) => {
//   return (
//     <div className="reading_list_title">
//       <p className="title_name">{subTopic}</p>
//     </div>
//   );
// });

// ListTitle.propTypes = {
//   subTopic: PropTypes.string,
// };

const ReviewLessons = memo(
  ({ reviewMediaLesson, onSetRemindInstallApp, reminderInstallAppModal }) => {
    return (
      <ListContainer
        list={reviewMediaLesson}
        listItem={({ itemIndex, ...reviewLesson }) => (
          <LessonDetailsItem
            {...reviewLesson}
            key={itemIndex}
            isReviewLesson
            onSetRemindInstallApp={
              itemIndex === 0 ? onSetRemindInstallApp : defaultFunc
            }
            reminderInstallAppModal={
              itemIndex === 0 ? reminderInstallAppModal : defaultFunc
            }
          />
        )}
      />
    );
  }
);

ReviewLessons.propTypes = {
  reviewMediaLesson: PropTypes.array,
  onSetRemindInstallApp: PropTypes.func,
  reminderInstallAppModal: PropTypes.func,
};

const DayStudyExams = memo(({ dayStudyExams }) => {
  return (
    <ListContainer
      list={dayStudyExams}
      listItem={({ itemIndex, ...exam }) => (
        <LessonDetailsItem {...exam} key={itemIndex} />
      )}
    />
  );
});

DayStudyExams.propTypes = {
  dayStudyExams: PropTypes.array,
};

// const NormalMediaLesson = memo(
//   ({ mediaLessons, onSetRemindInstallApp, reminderInstallAppModal }) => {
//     return (
//       <ListContainer
//         list={mediaLessons}
//         listItem={({ itemIndex, ...lesson }) => (
//           <LessonDetailsItem
//             {...lesson}
//             key={itemIndex}
//             onSetRemindInstallApp={
//               itemIndex === 0 ? onSetRemindInstallApp : defaultFunc
//             }
//             reminderInstallAppModal={
//               itemIndex === 0 ? reminderInstallAppModal : defaultFunc
//             }
//           />
//         )}
//       />
//     );
//   }
// );

// NormalMediaLesson.propTypes = {
//   mediaLessons: PropTypes.array,
//   onSetRemindInstallApp: PropTypes.func,
//   reminderInstallAppModal: PropTypes.func,
//   // isHandbook: PropTypes.bool
// };

const UploadQuizContainer = styled.div`
  width: 100%;
  background: #fff;

  box-shadow: 0px 0px 6px rgba(60, 141, 24, 0.3);
  opacity: 1;
  border-radius: 20px;
  position: relative;
  margin-top: -15px;
  padding-top: 30px;
  padding-bottom: 50px;
  // min-height: inherit;
  // margin-top: ${({ isUploadedFiles }) => (isUploadedFiles ? 62 : 115)}px;
`;

const UserTip = styled.div`
  font-size: 14px;
  letter-spacing: 1px;
  color: #6a5e4e;
  text-align: center;
  position: relative;
  // &::after {
  //   content: "";
  //   background-color: #cdc0a8;
  //   border-radius: 200px;
  //   opacity: 0.5;
  //   width: 173px;
  //   height: 7px;
  //   position: absolute;
  //   top: 63%;
  //   left: 50%;
  //   margin-left: -87px;
  // }
`;

const Tip = styled.div`
  margin: 20px 4px 0px 4px;
  padding: 12px;
  font-size: 14px;
  background-color: #ffffff;
  span {
    color: #ff7200;
  }
`;
const RepeatUploadButton = styled.div`
  display: block;
  width: 75px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #4bc216;
  letter-spacing: 1px;
  color: #4bc216;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  &::after {
    content: "";
    width: 10px;
    height: 12px;
    background-image: url(${followRight});
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-left: 2px;
    position: absolute;
    right: 2px;

    top: 50%;
    margin-top: -5px;
  }
`;
const UploaderWork = styled.div`
  background: #66d167;
  box-shadow: 0px 2px 4px rgba(102, 209, 103, 0.5);
  opacity: 1;
  border-radius: 25px;
  padding: 9px 57px;
  width: max-content;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #ffffff;
  margin: 15px auto 54px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }
`;
const UploadButton = styled.div`
  display: block;
  width: 184px;
  height: 61px;
  line-height: 61px;
  padding-left: 29px;
  background-image: url(${iconBtnShort});
  background-position: 0 0;
  background-size: 100%;
  background-repeat: no-repeat;
  letter-spacing: 1px;
  color: #6a5e4e;
  font-size: 18px;

  font-weight: bold;
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  &::after {
    content: "";
    position: absolute;
    right: 17px;

    background-image: url(${iconUpload});
    background-position: 0 0;
    background-size: 100%;
    width: 42px;
    height: 29px;
    background-repeat: no-repeat;
    top: 50%;
    margin-top: -16px;
  }
`;

const ReadingExerciseButton = styled(UploadButton)`
  display: block;
  width: 184px;
  height: 61px;
  line-height: 61px;
  padding-left: 29px;
  background-image: url(${iconBtnShort});
  &::after {
    width: 36px;
    height: 30px;
    right: 28px;
    top: 52%;
    background-image: url(${iconSending});
  }
`;

const InfoBroadContainer = styled.div`
  margin: 0px 0px 25px 0px;
  padding: 45px 10px 10px 10px;
  background-color: #ffffff;
  // box-shadow: 0px 3px 2px 0px rgba(205, 192, 168, 0.3);
  border-radius: 5px;
  position: relative;
`;

export const InfoBroadTitle = styled.div`
  position: absolute;
  top: 3px;
  left: -4px;
  background-image: url(${iconInfoTitle1});
  background-position: 0 0;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 114px;
  height: 43px;
  letter-spacing: 1px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  // padding-left: 6px;
  text-align: center;
  line-height: 32px;
`;

const StyledPhoto = styled.div`
  width: 104px;
  height: 67px;
  background-color: #ffffff;
  box-shadow: 0px 3px 2px 0px rgba(126, 83, 7, 0.3);
  margin-left: 11px;
  margin-top: 10px;
  position: relative;
  .file {
    width: 100%;
  }
`;
export const Photos = styled.div`
  display: flex;
  flex-wrap: wrap;
  // margin-top: 20px;
  margin-left: -10px;
  margin-bottom: 25px;
`;
const StyledDummyPhoto = styled.div`
  width: 104px;
  height: 67px;
  margin-left: 11px;

  margin-top: 10px;
  background-color: #e8e8e8;
  border: dashed 2px #d1d1d1;
  background-image: url(${iconPlaceholder});
  background-position: center 34%;
  background-size: 31px 22px;
  background-repeat: no-repeat;
  p {
    line-height: 1em;
    font-size: 12px;
    letter-spacing: 1px;
    color: #d2d2d2;
    text-align: center;
    margin-top: 42px;
  }
`;

const FileDelete = styled.img`
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: 0;
  top: 0;
  z-index: 100;
  margin: -7.5px;
`;

const FileWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Photo = ({
  file,
  index,
  setAnswerPhotos,
  isUploaded,
  isReviewed,
}) => {
  const [isOpenImgDialog, setOpenImgDialog] = useState(false);
  if (!file) return null;
  const url = isReviewed ? file : window.URL.createObjectURL(file);
  return (
    <StyledPhoto>
      {!isUploaded && !isReviewed && (
        <FileDelete
          src={cancle_green}
          onClick={() => {
            setAnswerPhotos((files) => {
              files.splice(index, 1, null);
              return [...files];
            });
          }}
        />
      )}
      {isOpenImgDialog && (
        <ImgDialog
          photo_url={url}
          handleImgDialog={() => setOpenImgDialog((pre) => !pre)}
        />
      )}
      <FileWrapper onClick={() => setOpenImgDialog((pre) => !pre)}>
        <img src={url} alt="" className="file" />
      </FileWrapper>
    </StyledPhoto>
  );
};

export const InfoBroad = ({ children }) => {
  return <InfoBroadContainer>{children}</InfoBroadContainer>;
};

const SubmitBtn = styled.span`
  padding: 6px 20px;
  background-color: #49c114;
  border-radius: 18px;
  border-radius: 200px;
  position: relative;
  left: 50%;
  transform: translate(-57%);
  letter-spacing: 1px;
  color: #ffffff;
  display: inline-block;
`;

const UserAnswerInfo = ({
  answerPhotos,
  setAnswerPhotos,
  submitAnswers,
  isUploaded,
  isBeforeDeadline,
  reviewsType,
}) => {
  return (
    <>
      <InfoBroad>
        <InfoBroadTitle>我的回答</InfoBroadTitle>
        <Photos>
          {!!answerPhotos.length &&
            answerPhotos.map((photo, index) => (
              <Photo
                key={index}
                file={photo}
                index={index}
                setAnswerPhotos={setAnswerPhotos}
                isUploaded={isUploaded}
              />
            ))}
          {!isUploaded && (
            <StyledDummyPhoto
              onClick={() => $("#answer_photos").trigger("click")}
            >
              <p>添加照片</p>
            </StyledDummyPhoto>
          )}
        </Photos>
        {!isUploaded && (
          <SubmitBtn onClick={debounce(submitAnswers)}>确认提交</SubmitBtn>
        )}
      </InfoBroad>
      {/* {isUploaded && isBeforeDeadline && (
        <InfoBroad>
          <InfoBroadTitle>老师评语</InfoBroadTitle>
          <StyledUploadTip>
            老师将在作业上传后2个工作日内点评作业，并公布正确答案
          </StyledUploadTip>
        </InfoBroad>
      )} */}
    </>
  );
};

const uploadToQiNiu = (file, token) =>
  new Promise((resolve, reject) => {
    const fd = new FormData();
    fd.append("file", file);
    fd.append("name", "");
    fd.append("token", token);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://upload.qiniup.com", true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
          const obj = JSON.parse(xhr.responseText);
          console.log(obj);
          resolve(obj);
        } else {
          reject(JSON.parse(xhr.responseText).error);
        }
      }
    };
    xhr.onerror = (e) => {
      console.error(e);
      // if (UserAgent.isNativeClient()) {
      //   alert("上传成功！");
      // } else {
      // }
      reject(e);
    };

    xhr.send(fd);
  });

const uploadFile = async ({ file, generateTokenUrl }) => {
  const res = await request({
    url: generateTokenUrl,
    method: "GET",
    headers: csrfHeaders,
    params: {
      type: "day_study_answer",
    },
  });
  const token = res.data.token;
  const photo = await uploadToQiNiu(file, token);
  console.log(photo);
  return { id: photo.id };
};

export const StyledUploadTip = styled.div`
  margin-top: 34px;
  font-size: 14px;
  color: #000000;
  padding-bottom: 20px;
`;

const TeacherContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;
  img {
    width: 46px;
    height: 46px;
    box-shadow: 0px 3px 2px 0px rgba(205, 192, 168, 0.3);
    border-radius: 50%;
  }
  span {
    font-size: 14px;
    letter-spacing: 1px;
    color: #716656;
    margin-left: 10px;
  }
`;

const StyledCommentAudio = styled.div`
  width: 251px;
  height: 48px;
  background-color: #f0f6f0;
  box-shadow: 0px 3px 2px 0px rgba(214, 232, 221, 0.3);
  border-radius: 5px;
  border: solid 1px #d6e8dd;
  margin-top: 10px;
  .audio_player_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .audio_play {
      width: 100%;
      height: 100%;
      .audio_player_container {
        padding: 5px;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        .play {
          background-image: url(${iconPlay});
        }
        .stop,
        .pause {
          background-image: url(${iconPause});
        }
        .play_stop_common {
          background-repeat: no-repeat;
          background-size: 100%;
          height: 100%;
          width: 32px;
          height: 32px;
          position: relative;
        }
        .audio_info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: white;
          .title_info {
            font-size: 14px;
            color: #999999;
            margin-top: -5px;
          }
          .audio_progress {
            margin-top: 2px;
            width: 100%;
            height: 4px;
            background-color: #e3ede6 !important;
            border-radius: 3px;
          }
        }
        .time_progress {
          text-align: center;
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 3px 0 1px 5px;
          color: #999;
          font-size: 14px;
          margin-left: 7px;
          margin-right: 3px;
        }
      }
    }
  }
  .cancel_release_audio {
    text-align: right;
    padding-top: 10px;
    color: #888;
    text-decoration: underline;
  }
`;

export const StyledCommentBroad = styled.div`
  margin-top: 10px;
  .teacher_comment_broad.markdown_content {
    padding-bottom: 0px;
    background-color: #defefe;
    border-radius: 5px;
    // margin-top: 13px;
    padding-top: 15px;
    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 188px;
      margin: 10px auto;
    }
    p {
      font-size: 14px;
      line-height: 1.5em;
      strong {
        color: black;
      }
      img {
        max-width: none;
      }
    }
  }
`;

export const ReviewedBroad = ({
  reviewedPhotos,
  teacherPhoto,
  teacherName,
  commentAudioUrl,
  commentAudioInfo,
  commentText,
  reviewsType,
}) => {
  return (
    <>
      <Photos style={!commentAudioUrl ? { marginBottom: 10 } : {}}>
        {!!reviewedPhotos.length &&
          reviewedPhotos.map((photo, index) => (
            <Photo
              key={index}
              file={photo}
              index={index}
              isReviewed
              isUploaded
            />
          ))}
      </Photos>
      {commentAudioUrl && (
        <TeacherContainer>
          <img src={teacherPhoto} alt="" />
          <span>{teacherName}</span>
        </TeacherContainer>
      )}
      {commentAudioUrl && (
        <StyledCommentAudio>
          <SmallAudioPlayer
            audioSrc={commentAudioUrl}
            audioInfoUrl={commentAudioInfo}
            index={0}
          />
        </StyledCommentAudio>
      )}

      {commentText && (
        <StyledCommentBroad>
          <div className="markdown_content teacher_comment_broad">
            <ReactMarkdown source={commentText} />
          </div>
        </StyledCommentBroad>
      )}
    </>
  );
};

const UploadQuiz = memo(
  ({
    generateTokenUrl,
    dayStudyRecordUrl,
    commentText,
    reviewsType,
    commentAudioUrl,
    commentAudioInfo,
    answers,
    is_first_operate,
    answer,
    isBeforeDeadline,
    courseConsultant,
    submitEndDate,
    submitStartDate,
    start_page,
    end_page,
    quizzes,
    setQuizzes,
  }) => {
    console.log("==================" + submitEndDate);
    const isAnswered = answers?.length > 0;
    const isReviewed = !!commentAudioUrl;
    const reviewedPhotos = useMemo(() => {
      if (isAnswered) {
        if (isReviewed) {
          return answers.map((a) => a.comment_photo_url);
        } else {
          return answers.map((a) => a.answer_photo_url);
        }
      }
      return [];
    }, [answers, isAnswered, isReviewed]);
    const [answerPhotos, setAnswerPhotos] = useState([]);
    const [isUploaded, setUploaded] = useState(false);
    const [imgUrl, setImgUrl] = useState("");
    // const [showImg, setShowImg] = useState(false);
    const inputOnChange = (e) => {
      const newFiles = e.target.files;
      console.log(newFiles);
      setAnswerPhotos((files) => [...files, ...newFiles]);
    };
    useEffect(() => {
      if (UserAgent.isIOS() && XinYaNativeBridge) {
        XinYaNativeBridge.requestPermission("camera", (granted) => {});
      }
    }, []);
    const goImg = (imgUrl) => {
      setImgUrl(imgUrl);
    };
    const cancleImg = () => {
      setImgUrl("");
    };
    const handleClick = (url, quizzes, idx) => {
      const toast = ToastManager.showLoading("加载中...");
      request({
        url,
        method: "POST",
        headers: csrfHeaders,
      })
        .then((e) => {
          quizzes[idx].likes_count = quizzes[idx].is_like
            ? quizzes[idx].likes_count - 1
            : quizzes[idx].likes_count + 1;
          quizzes[idx].is_like = !quizzes[idx].is_like;

          setQuizzes([...quizzes]);
          // window.location.reload();
          toast.cancel();
        })
        .catch((err) => {
          console.log(err);
          toast.cancel();
        });
    };
    const submitAnswers = useCallback(async () => {
      // setUploaded(true);

      // return;
      const toast = ToastManager.showLoading("图片上传中...");
      console.log("图片上传");
      try {
        const ids = await Promise.all(
          answerPhotos
            .filter((p) => !!p)
            .map((f) =>
              uploadFile({
                file: f,
                generateTokenUrl,
              })
            )
        );
        console.log("ids", ids);
        await request({
          url: dayStudyRecordUrl,
          method: "POST",
          headers: csrfHeaders,
          data: {
            day_study_answers: ids.map((r) => ({ answer_photo_id: r.id })),
          },
        });
        window.location.reload();
        toast.cancel();
        setUploaded(true);
      } catch (e) {
        toast.cancel();
        console.log(e);
        const dialogOptions = {
          text: "上传出错，请重试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            submitAnswers();
          },
        };
        showDialog.call(this, dialogOptions);
        Raven.captureException(e);
      }
    }, [answerPhotos, dayStudyRecordUrl, generateTokenUrl]);
    return (
      <UploadQuizContainer
        isUploadedFiles={!!answerPhotos.length || isReviewed}
      >
        {/* 上传图片 */}
        {answerPhotos.length > 0 && (
          <UserAnswerInfo
            answerPhotos={answerPhotos}
            setAnswerPhotos={setAnswerPhotos}
            submitAnswers={submitAnswers}
            isUploaded={isUploaded}
            isBeforeDeadline={isBeforeDeadline}
          />
        )}

        {/* 有回答记录，并且有老师评论语音 || 有回答记录,但是不是规定时间做练习 */}
        {((reviewedPhotos.length > 0 && isReviewed) ||
          (reviewedPhotos.length > 0 && !(false && isBeforeDeadline))) &&
          !answerPhotos?.length > 0 && (
            <InfoBroad>
              <InfoBroadTitle>我的作业</InfoBroadTitle>
              <ReviewedBroad
                reviewedPhotos={reviewedPhotos}
                commentAudioInfo={commentAudioInfo}
                commentAudioUrl={commentAudioUrl}
                commentText={commentText}
                teacherPhoto={courseConsultant?.photo_url}
                teacherName={courseConsultant?.name}
              />
              <RepeatUploadButton
                as="label"
                htmlFor="answer_photos"
                id="file_label2"
              >
                重传作业
              </RepeatUploadButton>
            </InfoBroad>
          )}

        {/* 规定时间做练习，但是还没有老师评论语音，提示用户等待通知 */}
        {/* {!isUploaded &&
          !isReviewed &&
          isBeforeDeadline &&
          reviewedPhotos.length > 0 && (
            <>
              {!(answerPhotos?.length > 0 ? true : false) && (
                <InfoBroad>
                  <InfoBroadTitle>我的回答</InfoBroadTitle>
                  <ReviewedBroad
                    reviewedPhotos={reviewedPhotos}
                    commentAudioUrl={commentAudioUrl}
                    commentText={commentText}
                    teacherPhoto={courseConsultant?.photo_url}
                    teacherName={courseConsultant?.name}
                  />

                  <RepeatUploadButton
                    as="label"
                    htmlFor="answer_photos"
                    id="file_label2"
                  >
                    重传作业
                  </RepeatUploadButton>
                </InfoBroad>
              )}
              <InfoBroad>
                <InfoBroadTitle>老师评语</InfoBroadTitle>
                <StyledUploadTip>
                  老师将在作业上传后2个工作日内点评作业，并公布正确答案
                </StyledUploadTip>
              </InfoBroad>
            </>
          )} */}

        {/* 不是规定时间做练习，老师评论语音显示答案解析 */}
        {((isUploaded && !(false && isBeforeDeadline)) ||
          isReviewed ||
          (!isBeforeDeadline && isAnswered)) &&
          answer &&
          !answerPhotos?.length > 0 && (
            <InfoBroad>
              <InfoBroadTitle>答案解析</InfoBroadTitle>
              <StyledCommentBroad>
                <div
                  style={{
                    borderRadius: 10,
                    border: "1px solid #66d167",
                    overflow: "hidden",
                  }}
                >
                  <div className="markdown_content teacher_comment_broad">
                    {answer && <ReactMarkdown source={answer} />}
                  </div>
                  {/* {answer && <img style={{ width: "100%" }} src={answer}></img>} */}
                </div>
              </StyledCommentBroad>
            </InfoBroad>
          )}
        {((reviewedPhotos.length > 0 && isReviewed) ||
          (reviewedPhotos.length > 0 && !isBeforeDeadline)) &&
          quizzes.length > 0 &&
          !answerPhotos?.length > 0 && (
            <InfoBroad>
              <InfoBroadTitle>作业广场</InfoBroadTitle>

              <div>
                <div className="work-house">
                  {quizzes &&
                    quizzes.map((item, idx) => {
                      return (
                        <div key={"item" + idx} className="work-house-item">
                          <div className="work-house-item-top">
                            <img
                              src={item.homework_url}
                              onClick={() => {
                                goImg(item.homework_url);
                              }}
                            ></img>
                            <div
                              className="work-house-item-top-like"
                              onClick={() => {
                                handleClick(item.like_url, quizzes, idx);
                              }}
                            >
                              <img
                                className="work-house-item-top-like-icon"
                                src={item.is_like ? aixin_red : aixin_white}
                              ></img>
                              <div className="work-house-item-top-like-cout">
                                {item.likes_count}
                              </div>
                            </div>
                          </div>
                          <div className="work-house-item-bottom">
                            <div className="work-house-item-bottom-left">
                              <img
                                src={
                                  item.baby_avatar_url
                                    ? item.baby_avatar_url
                                    : item.gender == "男"
                                    ? icon_default_boy
                                    : icon_default_girl
                                }
                                className="work-house-item-bottom-left-photo"
                              ></img>

                              <div className="work-house-item-bottom-left-name">
                                {item.nickname}
                              </div>
                            </div>

                            <div className="work-house-item-bottom-right">
                              {item.grade}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </InfoBroad>
          )}
        {!answerPhotos.length && is_first_operate && (
          <>
            <div className="tep tep-one">
              <div className="tep_img">
                <img src={tep_img}></img>
                <div className="tep_tit">01</div>
              </div>
              <div className="tep_title">
                {"请完成练习册"}
                <span style={{ color: "#FFB11A" }}>
                  {start_page && end_page
                    ? `第${start_page}页 ~ 第${end_page}页`
                    : start_page
                    ? `第${start_page}页`
                    : ""}
                </span>
              </div>
            </div>
            <div className="tep tep-two">
              <div className="tep_img">
                <img src={tep_img}></img>
                <div className="tep_tit">02</div>
              </div>
              <div className="tep_title">拍照上传打卡</div>
            </div>
            <div className="tep tep-there">
              <div className="tep_img">
                <img src={tep_img}></img>
                <div className="tep_tit">03</div>
              </div>
              <div className="tep_title">核对答案，查看其它小朋友作业</div>
            </div>
            <div className="complent_work">我已经完成纸质版练习</div>
            <UploaderWork as="label" htmlFor="answer_photos" id="file_label">
              <span>上传作业</span>
              <img src={uploader_img} className="uploader_img"></img>
            </UploaderWork>
          </>
        )}

        {!answerPhotos.length && !isAnswered && (
          <>
            {/* <UserTip>我已经完成纸质版练习</UserTip>
            <UploadButton as="label" htmlFor="answer_photos" id="file_label">
              上传作业
            </UploadButton> */}
            {/* <div className="complent_work">我已经完成纸质版练习</div>
            <UploaderWork as="label" htmlFor="answer_photos" id="file_label">
              <span>上传作业</span>
              <img src={uploader_img} className="uploader_img"></img>
            </UploaderWork> */}
            {/* <Tip>
              在<span>{submitEndDate?.replace?.(/-/g, ".")} 23:59</span>
              前上传作业，可以获得老师的批改和点评。
            </Tip>

            <Tip>如果没有及时上传作业，仅能查看答案解析哦。</Tip> */}
          </>
        )}

        <input
          type="file"
          id="answer_photos"
          accept="image/jpeg,image/jpg,image/png"
          style={{ display: "none" }}
          onChange={inputOnChange}
          multiple
        />
        {imgUrl && (
          <div
            onClick={() => {
              cancleImg();
            }}
            className="look-img"
          >
            <img src={imgUrl} style={{ width: "100%", margin: "auto" }}></img>
          </div>
        )}
      </UploadQuizContainer>
    );
  }
);

// const ReadingExercise = ({ todayQuizUrl }) => {
//   return (
//     <UploadQuizContainer>
//       <UserTip>我已经读完了这篇文章</UserTip>
//       <ReadingExerciseButton as="a" href={todayQuizUrl}>
//         阅读闯关
//       </ReadingExerciseButton>
//     </UploadQuizContainer>
//   );
// };

const Reading = memo((props) => {
  console.log("Reading_props", props);
  const {
    sub_name: subName,
    // community,
    // name: poetryName,
    // system_time: systemTime,
    study_report: courseFinishCondition,
    review_media_lessons: reviewMediaLesson,
    // media_lessons: mediaLessons,
    photo_url: photoUrl,
    study_report_url: studyReportUrl,
    poster_photo_template_url: posterTemplate,
    poster_creation: posterCreation,
    baby,
    module_review: isUnitReview,
    // poetry_articles: poetryReadings,
    day_study_exams: dayStudyExams,
    author,
    today_quiz: todayQuiz,
    platformEnv: { isNativeClient },
    study_type: studyType,
    media_course_name: mediaCourseName,
    sub_topic: subTopic,
    generate_token_url: generateTokenUrl,
    day_study_record_url: dayStudyRecordUrl,
    comment_text: commentText,
    reviews_type: reviewsType,
    comment_audio_url: commentAudioUrl,
    answers,
    answer,
    reminder_teacher_comment: isBeforeDeadline,
    course_consultant: courseConsultant,
    submit_end_date: submitEndDate,
    submit_start_date: submitStartDate,
    comment_audio_info_url: commentAudioInfo,
    baby_homework_url: getQuizzesUrl,
    // handbook
  } = props;
  // const getQuizzesUrl = "345";
  // mediaLessons.forEach((l, index) => {
  //   l.name = `${index + 1}. ${l.name}`;
  // });
  const [quizzes, setQuizzes] = useState([]);
  const [isNoContent, setIsNoContent] = useState(false);
  const quizzesContainerRef = createRef();

  const page = useRef(1);
  // const poetryGameLessonItem = useMemo(
  //   () =>
  //     !isUnitReview && todayQuiz
  //       ? [
  //           {
  //             url: todayQuiz.url,
  //             name: `${mediaLessons.length + 1}. 随堂练习`,
  //             rank: todayQuiz.rank,
  //             lock: todayQuiz.lock,
  //             isReviewLesson: false,
  //           },
  //         ]
  //       : [],
  //   [isUnitReview, mediaLessons.length, todayQuiz]
  // );

  // const allLessonItems = useMemo(
  //   () => [
  //     ...reviewMediaLesson,
  //     // ...mediaLessons,
  //     // ...poetryGameLessonItem,
  //     ...dayStudyExams,
  //   ],
  //   [dayStudyExams, reviewMediaLesson]
  // );
  const handleBackCourse = () => {
    window.location.href = props?.study_package_details_url;
  };
  const renderLessonItem = useCallback(
    ({ itemIndex, ...lesson }) => (
      <LessonDetailsItem
        {...lesson}
        key={itemIndex}
        usePoetryDetailInstallApp={usePoetryDetailInstallApp.bind(
          null,
          !isNativeClient && itemIndex === 0
        )}
      />
    ),
    [isNativeClient]
  );
  const doGetQuizzesRequest = useCallback((getQuizzesUrl, currentPage) => {
    return request({
      url: getQuizzesUrl,
      method: "GET",
      headers: csrfHeaders,
      params: {
        day_study_id: props.day_study_id,
        study_package_activity_id: props.study_package_activity_id,
        // per: 20,
        page: currentPage || 1,
      },
    });
  }, []);
  const onScrollHandle = useCallback(
    (event) => {
      var scrollHeight = Math.max(
        document.documentElement.scrollHeight,
        document.body.scrollHeight
      );
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var clientHeight =
        window.innerHeight ||
        Math.min(
          document.documentElement.clientHeight,
          document.body.clientHeight
        );
      // const clientHeight = document.body.clientHeight;
      // const scrollHeight = document.body.scrollHeight;
      // const scrollTop = document.body.scrollTop;
      // const isBottom = clientHeight + scrollTop === scrollHeight;
      // console.log(
      //   clientHeight,
      //   scrollTop,
      //   scrollHeight,
      //   "onScrollHandle-------",
      //   isBottom,
      //   event,
      //   document
      // );
      if (
        page.current !== 1 &&
        clientHeight + scrollTop >= scrollHeight &&
        !isNoContent
      ) {
        // if (isBottom) {
        console.log("isBottom");
        const toast = ToastManager.showLoading("数据加载中...");
        doGetQuizzesRequest(getQuizzesUrl, page.current)
          .then((res) => {
            const againQuizzes = res.data.data;
            if (againQuizzes.length > 0) {
              // page.current++;

              setQuizzes((quizzes) => [...quizzes, ...againQuizzes]);
            }
            setIsNoContent(true);
            toast.cancel();
          })
          .catch((err) => {
            console.log(err);
            toast.cancel();
          });
      }
    },
    [doGetQuizzesRequest, getQuizzesUrl]
  );
  // useEffect(() => {
  //   console.log("--------------", quizzesContainerRef);
  //   // 设置触底请求
  //   if (quizzesContainerRef.current) {
  //     quizzesContainerRef.current.addEventListener("scroll", onScrollHandle);
  //   }
  // }, [onScrollHandle, quizzesContainerRef]);
  useEffect(() => {
    document.addEventListener("scroll", onScrollHandle);
    if (getQuizzesUrl) {
      const toast = ToastManager.showLoading("数据加载中...");
      doGetQuizzesRequest(getQuizzesUrl, page.current)
        .then((res) => {
          const quizzes = res.data.data;
          console.log(res.data);
          if (quizzes.length > 0) {
            page.current++;
            setQuizzes((preQuizzes) => [...preQuizzes, ...quizzes]);
            // setQuizCount(res.data.quiz_count);
          } else {
            setIsNoContent(true);
          }
          toast.cancel();
        })
        .catch((err) => {
          alert("获取数据出错");
          console.log(err);
          toast.cancel();
        });
    }
  }, [getQuizzesUrl, onScrollHandle]);
  return (
    <div
      className="comp_reading_detail"
      ref={quizzesContainerRef}
      id="correct_quizzes_container"
      // style={{ paddingBottom: !isNativeClient ? 70 + 54 : 54 }}
    >
      {/* 已学过的标志 */}
      {/* {courseFinishCondition && <div className="has_learned_icon"></div>} */}

      <div className="lesson_banner">
        <img src={photoUrl} alt="" />
        {/* <ListTitle subTopic={subTopic} /> */}
      </div>

      {/* {studyType === 0 && (
        <div className="poetry_details_list">
          <div className="study_list">
            <ListContainer list={allLessonItems} listItem={renderLessonItem} />
          </div>
        </div>
      )} */}

      <UploadQuiz
        generateTokenUrl={generateTokenUrl}
        dayStudyRecordUrl={dayStudyRecordUrl}
        commentText={commentText}
        reviewsType={reviewsType}
        commentAudioUrl={commentAudioUrl}
        answer={answer}
        answers={answers}
        is_first_operate={props.is_first_operate}
        isBeforeDeadline={isBeforeDeadline}
        courseConsultant={courseConsultant}
        submitEndDate={submitEndDate}
        submitStartDate={submitStartDate}
        commentAudioInfo={commentAudioInfo}
        start_page={props.start_page}
        end_page={props.end_page}
        quizzes={quizzes}
        setQuizzes={setQuizzes}
      />

      {/* {studyType === 2 && <ReadingExercise todayQuizUrl={todayQuiz?.url} />} */}

      {/* {courseFinishCondition && (
        <BottomFixedButtons
          studyReportUrl={studyReportUrl}
          posterTemplate={posterTemplate}
          posterCreation={posterCreation}
          babyName={baby.nickname}
          fixedBottomOffset={!isNativeClient ? 60 : 0}
        />
      )} */}
      <RightFloatIcons
        isShowBack
        onBackClick={handleBackCourse}
        // isShowSetting={this.props.trial_unlock_mode ? false : true}
        // onSetting={this.handleBackToTop}
        // isShowScrollTop
        // onScrollTop={this.handleBackToTop}
        // bottomPosition={80}
      />
    </div>
  );
});

Reading.displayName = "Reading";

Reading.propTypes = {
  sub_name: PropTypes.string,
  community: PropTypes.object,
  name: PropTypes.string,
  system_time: PropTypes.string,
  study_report: PropTypes.bool,
  review_media_lessons: PropTypes.array,
  // media_lessons: PropTypes.array,
  photo_url: PropTypes.string,
  study_report_url: PropTypes.string,
  poster_photo_template_url: PropTypes.string,
  poster_creation: PropTypes.string,
  baby: PropTypes.object,
  module_review: PropTypes.bool,
  author: PropTypes.string,
  poetry_articles: PropTypes.array,
  is_first_operate: PropTypes.bool,
  today_quiz: PropTypes.object,
  onSetRemindInstallApp: PropTypes.func,
  reminderInstallAppModal: PropTypes.func,
  // handbook: PropTypes.bool,
  day_study_exams: PropTypes.array,
  platformEnv: PropTypes.object,
  study_type: PropTypes.number,
  media_course_name: PropTypes.string,
  sub_topic: PropTypes.string,
};

export default WithPlatformEnv(WithErrorBoundary(Reading));
