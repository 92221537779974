import { ApplicationStore } from "../../utils/react-global-state";
class SyncPracticeStore extends ApplicationStore {
  constructor(props) {
    super(props);
    this.defaultStates = {};
  }

  actionCreators = () => ({});

  reducer = (states, action) => {};
}

export default new SyncPracticeStore().init();
