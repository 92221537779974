import React from "react";
import { request, csrfHeaders } from "../utils/request";
import UserAgent from "../utils/UserAgent";
import Picker from "better-picker";
import iconBoyUser from "icon_default_boy.png";
import iconGirlUser from "icon_default_girl.png";
import "./CourseUserSetting.scss";

import { StudyPackageCommentModule } from "./UnitModeStudyPackage";

const date_hour_data = [
  { text: "不提醒", value: -1 },
  { text: "00点", value: 0 },
  { text: "01点", value: 1 },
  { text: "02点", value: 2 },
  { text: "03点", value: 3 },
  { text: "04点", value: 4 },
  { text: "05点", value: 5 },
  { text: "06点", value: 6 },
  { text: "07点", value: 7 },
  { text: "08点", value: 8 },
  { text: "09点", value: 9 },
  { text: "10点", value: 10 },
  { text: "11点", value: 11 },
  { text: "12点", value: 12 },
  { text: "13点", value: 13 },
  { text: "14点", value: 14 },
  { text: "15点", value: 15 },
  { text: "16点", value: 16 },
  { text: "17点", value: 17 },
  { text: "18点", value: 18 },
  { text: "19点", value: 19 },
  { text: "20点", value: 20 },
  { text: "21点", value: 21 },
  { text: "22点", value: 22 },
  { text: "23点", value: 23 },
];

const UserSettings = (props) => {
  console.log("UserSettings");
  console.log(props);
  const setRemainderPicker = (dom) => {
    const nameEl = dom;
    const picker = new Picker({
      data: [date_hour_data],
      selectedIndex: [props.reminder_time_hour + 1],
      title: "",
    });
    const config_reminder_time_url = props.config_reminder_time_url;
    const reminder_time_hour = props.reminder_timeHour;
    picker.on("picker.select", function (selectedVal, selectedIndex) {
      nameEl.innerText = date_hour_data[selectedIndex[0]].text;

      if (selectedIndex[0] - 1 != reminder_time_hour) {
        let params = {
          reminder_hour: selectedIndex[0] - 1,
        };
        var url = config_reminder_time_url;
        request({
          url: url,
          method: "POST",
          headers: csrfHeaders,
          data: params,
        })
          .then((resp) => {
            console.log("正确返回");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });

    if (props.reminder_time_hour >= -1) {
      const time_setup_click = document.getElementById("time_setup_click");

      if (time_setup_click) {
        time_setup_click.addEventListener("click", function () {
          picker.show();
          if (UserAgent.isNativeClient()) {
            XinYaNativeBridge.requestAuthorization({
              authType: "notification",
            });
          }
        });
      }
    }
  };
  return (
    <CourseUserSettings
      baby={props.baby}
      mediaLessonsCount={props.media_lessons_count}
      totalStudiedMediaLessonsCount={props.total_studied_media_lessons_count}
      // checkin={this.props.checkin}
      // studyReportUrl={this.props.last_study_report_url}
      // checkinDays={this.props.checkin_days}
      noteUrl={props.note_url}
      bonusType={props.bonus_type}
      bonus={props.bonus}
      isIosReview={props.is_ios_review}
      shareUrl={props.share_url}
      reminderTimeHour={props.reminder_time_hour}
      commentState={props.comment_state}
      commentUrl={props.comment_url}
      commentPromotionBrief={props.comment_promotion_brief}
      // configReminderTimeUrl={this.props.config_reminder_time_url}
      setRemainderPicker={setRemainderPicker}
      sno={props.sno}
      create_recommend_request_url={props.create_recommend_request_url}
      comment_target={props.comment_target}
      current_user_comment={props.current_user_comment}
      bought_phase={props.bought_phase}
      promotion={props.promotion}
      rate={props.rate}
    />
  );
};

const CourseUserSettings = (props) => (
  <div className="comp_course_user_settings">
    <div className="info_container">
      <a className="user_baby_avatar" href={props.baby.edit_baby_path}>
        <img
          className="lazyload"
          data-src={
            props.baby.photo_url ||
            (props.baby.gender === "男" ? iconBoyUser : iconGirlUser)
          }
          alt=""
        />
      </a>
      <div className="study_package_info">
        <p className="name_number">
          <span>{props.baby.nickname}</span>
          <span>（学号：{props.sno}）</span>
        </p>
        <div className="progress_bar">
          <div
            className="progress"
            style={{
              width: `${
                (props.totalStudiedMediaLessonsCount /
                  props.mediaLessonsCount) *
                100
              }%`,
            }}
          ></div>
          <div className="text_progress">{`已学课时（${props.totalStudiedMediaLessonsCount}/${props.mediaLessonsCount}）`}</div>
        </div>
      </div>
    </div>
    <CourseActivityEntrance
      noteUrl={props.noteUrl}
      bonus={props.bonus}
      isIosReview={props.isIosReview}
      shareUrl={props.shareUrl}
      commentState={props.commentState}
      commentUrl={props.commentUrl}
      commentPromotionBrief={props.commentPromotionBrief}
      setRemainderPicker={props.setRemainderPicker}
      reminderTimeHour={props.reminderTimeHour}
      create_recommend_request_url={props.create_recommend_request_url}
      comment_target={props.comment_target}
      current_user_comment={props.current_user_comment}
      bought_phase={props.bought_phase}
      promotion={props.promotion}
      rate={props.rate}
    />
  </div>
);

class CourseActivityEntrance extends React.Component {
  state = {
    writeEvaluateDialogState: false,
    createRecommendRequestUrl: this.props.create_recommend_request_url,
  };
  componentDidMount() {
    const pickerDom = document.getElementById("reminder_picker");
    this.props.setRemainderPicker(pickerDom);
  }

  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });
  };

  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };

  // 请求接口数据成功之后, 链接置空
  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };

  render() {
    const bonusType = this.props.bonusType === 0 ? "现金" : "奖学金";
    return (
      <React.Fragment>
        <div className="course_activity_entrance">
          <ul>
            <li className="icon_user_guide">
              <a href={this.props.noteUrl + "?hidden"}>
                <p>添加老师微信</p>
                <span>查看使用须知</span>
              </a>
            </li>
            <li className="icon_remind" id="time_setup_click">
              <a href="javascript:void(0)">
                <p>设置上课提醒时间</p>
                {this.props.reminderTimeHour >= -1 ? (
                  <div className="remind_time_set">
                    <span id="reminder_picker">
                      {this.props.reminderTimeHour === -1
                        ? "不提醒"
                        : `当前${this.props.reminderTimeHour}点`}
                    </span>
                  </div>
                ) : (
                  <span>暂无法设置</span>
                )}
              </a>
            </li>
            {this.state.createRecommendRequestUrl && !this.props.isIosReview && (
              <li
                className="icon_comment"
                onClick={this.getWriteEvaluateStatus}
              >
                <a href="javascript: void(0)">
                  <p>写评价</p>
                  <span>{this.props.commentPromotionBrief}</span>
                </a>
              </li>
            )}
            {this.props.isIosReview === false && (
              <li className="icon_invite">
                <a href={this.props.shareUrl}>
                  <p>
                    {this.props.bonus == 0
                      ? "分享给好友"
                      : `邀请领${bonusType}`}
                  </p>
                  {this.props.bonus == 0 ? (
                    ""
                  ) : (
                    <span>{`每邀请一位新用户报名且不退款，奖励${this.props.bonus}元${bonusType}`}</span>
                  )}
                </a>
              </li>
            )}
          </ul>
        </div>
        {this.state.writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={
              this.props.create_recommend_request_url
            }
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment}
            bought_phase={this.props.bought_phase}
            promotion={this.props.promotion}
            rate={this.props.rate}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}
      </React.Fragment>
    );
  }
}

export default UserSettings;
