/* eslint-disable react/prop-types */
import React, { memo, useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import Picker from "better-picker";
import { request, csrfHeaders } from "../utils/request";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import { ToastManager } from "../utils/Toast.jsx";
import UserAgent from "../utils/UserAgent";
import { getCurrentDateNextSevenDays } from "../utils/formatAudioTime";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
import classNames from "classnames";
// import icon_active from "personalized_schedule/icon_active.png";
import checkbox_active from "personalized_schedule/icon_active_blue.png";
import checkbox_normal from "personalized_schedule/icon_normal.png";
import icon_play_pen from "personalized_schedule/icon_pen.png";
import icon_cancle_upper from "../../../assets/images/icon_cancle_upper.png";

import "./Setting.scss";
import RenderButton from "../day_studies/courses/common/RenderButtom";

const DateHourData = [
  { text: "不提醒", value: -1, times: "不提醒" },
  { text: "00 : 00", value: 0, times: "0" },
  { text: "01 : 00", value: 1, times: "1" },
  { text: "02 : 00", value: 2, times: "2" },
  { text: "03 : 00", value: 3, times: "3" },
  { text: "04 : 00", value: 4, times: "4" },
  { text: "05 : 00", value: 5, times: "5" },
  { text: "06 : 00", value: 6, times: "6" },
  { text: "07 : 00", value: 7, times: "7" },
  { text: "08 : 00", value: 8, times: "8" },
  { text: "09 : 00", value: 9, times: "9" },
  { text: "10 : 00", value: 10, times: "10" },
  { text: "11 : 00", value: 11, times: "11" },
  { text: "12 : 00", value: 12, times: "12" },
  { text: "13 : 00", value: 13, times: "13" },
  { text: "14 : 00", value: 14, times: "14" },
  { text: "15 : 00", value: 15, times: "15" },
  { text: "16 : 00", value: 16, times: "16" },
  { text: "17 : 00", value: 17, times: "17" },
  { text: "18 : 00", value: 18, times: "18" },
  { text: "19 : 00", value: 19, times: "19" },
  { text: "20 : 00", value: 20, times: "20" },
  { text: "21 : 00", value: 21, times: "21" },
  { text: "22 : 00", value: 22, times: "22" },
  { text: "23 : 00", value: 23, times: "23" },
];
const weeklyDays = [
  { id: 1, week_name: "周一" },
  { id: 2, week_name: "周二" },
  { id: 3, week_name: "周三" },
  { id: 4, week_name: "周四" },
  { id: 5, week_name: "周五" },
  { id: 6, week_name: "周六" },
  { id: 7, week_name: "周日" },
];

const NUMBER = ["日", "一", "二", "三", "四", "五", "六"];

const CourseFrequencyContianer = styled.div`
  padding-bottom: 60px;
  background: #fff;
  min-height: 100vh;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;

const WelcomeContainer = styled.div`
  margin: 30% 15px 0;
  .welcome_head_box {
    display: inline-flex;
    align-items: center;
    position: relative;
    .head_info {
      position: relative;
      height: 56px;
      width: 56px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        z-index: 3;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -25px;
        margin-top: -25px;
      }
      &::before {
        content: "";
        width: 56px;
        height: 56px;
        background: #b4f3f7;
        position: absolute;
        border-radius: 50%;
        z-index: 2;
      }
    }
    .head_title_text {
      height: 40px;
      background: #9be8ff;
      line-height: 40px;
      padding: 0 15px 0 30px;
      border-radius: 50px;
      margin-left: -25px;
    }
  }
  p {
    padding-bottom: 15px;
    font-weight: 700;
    font-size: 16px;
  }
`;
const ButtonContainer = styled.div`
  position: fixed;
  width: calc(100% - 20px);
  bottom: 20px;
  margin: 0 auto;
  left: 10px;
  z-index: 5;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: calc(500px - 20px);
    left: 50%;
    margin-left: -240px;
  }
`;
const ButtonBox = styled.div`
  display: flex;
`;
const ButtonContent = styled.div`
  height: 45px;
  width: 50%;
  line-height: 45px;
  text-align: center;
  // background: #17de26;
  color: #fff;
  border-radius: 50px;
  position: relative;
  // flex: 1;
  margin: 0 auto;
  background: linear-gradient(0deg, #5bc2f9 0%, #aae2ff 100%);
  box-shadow: 0px 4px 0px #39ace9;
  .buttom-icon {
    position: absolute;
    left: 18px;
    top: 4px;
    line-height: 3px;
    .buttom-icon1 {
      display: inline-block;
      width: 7px;
      height: 3px;
      background: #ffffff;
      opacity: 1;
      border-radius: 2px;
      margin-right: 2px;
    }
    .buttom-icon2 {
      display: inline-block;
      width: 15px;
      height: 3px;
      background: #ffffff;
      opacity: 1;
      border-radius: 2px;
    }
  }
  &.half_width_button {
    flex: inherit;
    width: 60%;
  }
  &.pre_button {
    border: 1px solid #9be8ff;
    background: #fff;
    color: #87c0dc;
    box-shadow: 0 10px 20px 0 rgba(135, 192, 220, 0.3);
    margin-right: 15px;
  }
  &.required {
    background: #ddd;
    box-shadow: 0 10px 20px 0px rgba(221, 221, 221, 0.3);
  }
`;

const SubTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 10px 45px;
  .sub_content {
    text-align: left;
    padding-left: 6px;
    .write_day {
      color: #ff5c2a;
    }
    .alert {
      font-size: 12px;
      color: #7e7878;
    }
  }
`;

const CcContainer = styled.div`
  position: relative;
  display: flex;
  margin: 30px 15px;
  .cc_head_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .img_info_box {
      width: 68px;
      height: 68px;
      background: #9be8ff;
      position: relative;
      border-radius: 50%;
      .cc_photo {
        width: 60px;
        height: 60px;
        position: absolute;
        left: 50%;
        margin-left: -30px;
        top: 50%;
        margin-top: -30px;
        border-radius: 50%;
      }
    }
    .cc_title {
      font-size: 12px;
      white-space: nowrap;
      margin-top: -22px;
      text-align: center;
      background: #9be8ff;
      border-radius: 50px;
      padding: 0 5px;
      height: 25px;
      line-height: 25px;
      position: relative;
      min-width: 85px;
    }
  }
  .right_message {
    margin-left: 8px;
    width: 100%;
    .message {
      background-color: #f0f0f0;
      border-radius: 6px;
      padding: 6px 10px 6px 12px;
      position: relative;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      min-height: 60px;
      p {
        font-size: 13px;
        padding-bottom: 10px;
        span {
          font-weight: 700;
          color: #ff5c2a;
        }
        &.last_p {
          padding-bottom: 0;
        }
      }
      .left_arrow {
        position: absolute;
        top: 20px;
        left: -18px;
        &:before {
          content: "";
          position: absolute;
          top: 0px;
          left: 2px;
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-right: 10px solid #f0f0f0;
          z-index: 1;
        }
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-right: 10px solid #ebe8e8;
        }
      }
    }
  }
`;

const ChooseWeeklyDaySectionContainer = styled.div`
  .choose_weekly_day_box {
    padding: 60px 0;
    .choose_weekly_days {
      display: flex;
    }
  }
  .choose_alert_time {
    padding: 30px 0 60px;
    .alert_time {
      text-align: center;
      .current_time_box {
        position: relative;
        display: inline-flex;
        .current_time {
          display: inline-flex;
          align-items: center;
          font-size: 20px;
          border-bottom: 1px dashed #9be8ff;
          span {
            font-size: 20px;
          }
        }
        i {
          background: url(${icon_play_pen}) no-repeat right center / 18px;
          width: 30px;
          height: 30px;
          display: block;
          margin-left: 7px;
          position: absolute;
          right: -25px;
          bottom: -1px;
        }
      }
    }
  }
  .divide_line {
    width: 80%;
    height: 1px;
    border: 0.5px solid #ddd;
    margin: 30px auto 0;
  }
`;

const ChooseWeeklyDayList = styled.div`
  height: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-left: 10px;
  &:last-child {
    margin-right: 10px;
  }
  img {
    min-width: 24px;
    width: 24px;
    min-height: 24px;
    height: 24px;
    display: block;
  }
  .week_day_text {
    padding-top: 5px;
  }
`;
const TimeItem = styled.div`
  margin-right: 7px;
  background: #ffffff;
  font-size: 32px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 45px;
  color: #ff7911;
  // letter-spacing: 250px;
  border-radius: 4px;
  padding: 0px 4px;
`;

const ButtonFactory = ({ buttonArr }) => {
  return (
    <ButtonContainer>
      <ButtonBox>
        {buttonArr.map((item, index) => (
          <ButtonContent
            key={index}
            className={`${item.extendedClass ?? ""}`}
            onClick={item.action}
          >
            {item.text}
            {!item.extendedClass && (
              <div className="buttom-icon">
                <span className="buttom-icon1"></span>
                <span className="buttom-icon2"></span>
              </div>
            )}
          </ButtonContent>
        ))}
      </ButtonBox>
    </ButtonContainer>
  );
};

const Welcome = memo(({ start, courseConsultant, baby }) => {
  return (
    <WelcomeContainer>
      <div className="welcome_head_box">
        <div className="head_info">
          <img src={courseConsultant?.photo_url} alt="" />
        </div>
        <div className="head_title_text">{courseConsultant?.name}</div>
      </div>
      <>
        <p>
          Hi，很高兴见到你！我是{baby.nickname}
          的专属课程老师，学习过程中遇到任何问题都可以联系我。
        </p>
        <p>
          为了更好的学习效果，请你根据{baby.nickname}的时间，定制{baby.nickname}
          专属的学习计划安排。
        </p>
      </>
      <ButtonFactory buttonArr={[{ action: start, text: "开始" }]} />
    </WelcomeContainer>
  );
});

// 一周几天？什么时间提醒
const ChooseWeeklyDaySection = ({
  prev,
  submit,
  getWeeklyDaySection,
  count,
  reminderTimeUrl,
  config,
  // setMyReminderHour,
  reminderHour,
  courseConsultant,
  trial,
}) => {
  const extra_days_week = config?.days_in_week;
  count = count ? count : 4;
  const week = new Array(7).fill(false);
  // 构建当前一周
  const nextSevenDays = getCurrentDateNextSevenDays(new Date());
  const nextSevenDayArr = [];
  for (let i = 0; i < nextSevenDays.length; i++) {
    const singleDay = new Date(nextSevenDays[i]).getDay();
    nextSevenDayArr.push({
      id: singleDay == 0 ? 7 : singleDay,
      week_name: `周${NUMBER[singleDay]}`,
      date: nextSevenDays[i],
    });
  }

  const lastWeeklyDays = trial ? nextSevenDayArr : weeklyDays;
  // console.log("lastWeeklyDays", lastWeeklyDays);

  // 双层遍历，寻找两个数组中相同的id，如果相等设为true
  if (extra_days_week) {
    week.forEach((element, index) => {
      extra_days_week.forEach((ele, idx) => {
        if (element == ele) {
          week[index] = true;
        }
      });
    });
  }
  const renderTime = (time) => {
    if (time == "-1" || time == "不提醒") {
      return <div className="no-reminder">不提醒</div>;
    }
    let timep = Number(time) < 10 ? "0" + time : time;
    let times = timep.split("");

    let timeC = times.map((t, i) => {
      return <TimeItem key={i + "=" + t}>{t}</TimeItem>;
    });
    console.log(times, "--------", timep, "---", timeC);
    return (
      <div className="render-time">
        {timeC}
        <div style={{ marginRight: 7 }}>:</div> <TimeItem>0</TimeItem>
        <TimeItem>0</TimeItem>
      </div>
    );
  };
  const [chooseWeeklyInfo, setChooseWeeklyInfo] = useState(() => {
    return (
      config?.days_in_week?.reduce((acc, cur) => {
        acc[cur - 1] = true;
        return acc;
      }, week) || week
    );
  });
  const [myTimes, setMytimes] = useState(reminderHour);
  const [myReminderHour, setMyReminderHour] = useState(
    renderTime(String(reminderHour))
  );
  const [flag, setFlag] = useState(false);
  const [activeCount, setActiveCount] = useState(0);

  const handleWeekDayList = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    setFlag(!flag);
    setChooseWeeklyInfo((pre) => {
      pre[id] = !pre[id];
      return pre;
    });
  };

  const reminderTimeHour = reminderHour || 0;
  const configReminderTimeUrl = reminderTimeUrl;

  const setRemainderPicker = useCallback(
    (dom) => {
      const picker = new Picker({
        data: [DateHourData],
        selectedIndex: [reminderTimeHour + 1],
        title: "",
      });
      picker.on("picker.select", function (selectedVal, selectedIndex) {
        setMyReminderHour(
          renderTime(String(DateHourData[selectedIndex[0]].times))
        );
        setMytimes(selectedIndex[0] - 1);
        // if (selectedIndex[0] - 1 != reminderTimeHour) {
        // const params = {
        //   reminder_hour: selectedIndex[0] - 1,
        // };
        //   const url = configReminderTimeUrl;
        //   request({
        //     url: url,
        //     method: "POST",
        //     headers: csrfHeaders,
        //     data: params,
        //   })
        //     .then((resp) => {
        //       // setTimeout
        //       // setMyReminderHour(DateHourData[selectedIndex[0]].times);
        //       console.log("正确返回");
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //     });
        // }
      });

      if (reminderTimeHour >= -1) {
        const time_setup_click = document.getElementById("time_setup_click");

        if (time_setup_click) {
          time_setup_click.addEventListener("click", function () {
            picker.show();
            if (UserAgent.isNativeClient()) {
              XinYaNativeBridge.requestAuthorization({
                authType: "notification",
              });
            }
          });
        }
      }
    },
    [configReminderTimeUrl, reminderTimeHour]
  );
  useEffect(() => {
    const pickerDom = document.getElementById("reminder_picker");
    setRemainderPicker(pickerDom);
  }, [setRemainderPicker]);

  useEffect(() => {
    const new_arr = [];
    chooseWeeklyInfo.filter((item, index) => item && new_arr.push(index));
    console.log("new_arr: ", new_arr);
    setActiveCount(new_arr);
    getWeeklyDaySection(new_arr);
  }, [chooseWeeklyInfo, getWeeklyDaySection, flag]);

  return (
    <ChooseWeeklyDaySectionContainer>
      {/* <TitleDescribe><Title>学习提醒设置</Title></TitleDescribe> */}
      {/* <div className="choose_weekly_day_box">
        <SubTitle>
          <i>1、</i>
          <div className="sub_content">
            请选择每周学习日期（
            <span className="write_day">{`${count}天`}</span>）
          </div>
        </SubTitle>
        <div className="choose_weekly_days">
          {lastWeeklyDays.map((week, index) => (
            <ChooseWeeklyDayList
              key={`${week.id}`}
              data-id={week.id}
              onClick={handleWeekDayList}
            >
              <img
                src={
                  chooseWeeklyInfo[week.id] ? checkbox_active : checkbox_normal
                }
                alt=""
              />
              <div className="week_day_text">{week.week_name}</div>
              {trial && (
                <div>
                  {`${new Date(week.date).getMonth() + 1}/${new Date(
                    week.date
                  ).getDate()}`}
                </div>
              )}
            </ChooseWeeklyDayList>
          ))}
        </div>
      </div> */}
      <div
        className="choose_alert_time choose_alert_time2"
        style={{ padding: "70px 0px 20px" }}
      >
        <SubTitle>
          {/* <i>2、</i> */}
          <div className="sub_content">
            <div>请选择学习当天的提醒时间</div>
            <div className="alert">（将通过微信公众号消息提醒）</div>
          </div>
        </SubTitle>
        <div className="alert_time" id="time_setup_click">
          <div className="current_time_box">
            <div className="current_time" id="reminder_picker">
              {
                myReminderHour
                // reminderTimeHour === -1
                //   ? "不提醒"
                //   : renderTime(String(reminderTimeHour))
                // `${reminderTimeHour} : 00`
              }
            </div>
            {/* <i></i> */}
          </div>
        </div>
      </div>
      {/* <div className="divide_line"></div> */}
      {/* <CourseConsultant courseConsultant={courseConsultant} flag={true} /> */}
      <RenderButton
        text="确定"
        answer={true}
        handleClick={() => {
          submit(myTimes);
        }}
      ></RenderButton>
    </ChooseWeeklyDaySectionContainer>
  );
};
export default (props) => {
  console.log("props: ", props);

  const {
    baby,
    config_reminder_time_url,
    config_freq_url,
    total_study_time_in_minutes,
    trial,
    config,
    reminder_hour,
    configged,
    course_consultant,
    redirect_url,
  } = props;
  const firstModule = (
    // eslint-disable-next-line no-use-before-define
    <Welcome start={start} courseConsultant={course_consultant} baby={baby} />
  );
  const [nextQuestion, setNextQuestion] = useState(0);
  const [frenquencyArr, setFrenquencyArr] = useState(firstModule);

  const refCount = useRef();
  const refRequestStudy = useRef();
  const refRequestWeek = useRef();

  const start = useCallback(() => {
    setNextQuestion(refCount.current);
  }, []);
  const next = useCallback(() => {
    setNextQuestion(refCount.current);
  }, []);

  const prev = useCallback(() => {
    setNextQuestion(refCount.current - 2);
  }, []);

  const submit = useCallback((time) => {
    const studyInfo = refRequestStudy.current;
    const weekInfo = refRequestWeek.current || [];
    console.log("studyInfo_weekInfo: ", studyInfo, weekInfo);
    // if (weekInfo.length > studyInfo?.days_per_week) {
    //   alert(`只能选择${studyInfo.days_per_week}天上课时间`);
    // } else if (weekInfo.length < studyInfo?.days_per_week) {
    //   alert(`请选择${studyInfo.days_per_week}天上课时间`);
    // } else {
    const toast = ToastManager.showLoading("上传中...");
    const url = config_freq_url;
    request({
      url: config_reminder_time_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        reminder_hour: time,
        // ...refRequestStudy.current,
        // days_in_week: [...refRequestWeek.current].map((item) =>
        //   parseInt(item)
        // ),
      },
    })
      .then((resp) => {
        console.log("resp: ", resp);
        const redirect_url = resp?.data?.redirect_url;
        if (redirect_url) {
          window.location.href = redirect_url;
          return;
        } else if (props.redirect_url) {
          window.location.href = props.redirect_url;
          return;
        }
        toast.cancel();
      })
      .catch((err) => {
        console.log(err);
        toast.cancel();
      });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWeeklyDaySection = (weekly_arr) => {
    refRequestWeek.current = weekly_arr;
    return weekly_arr;
  };

  const count = refRequestStudy.current?.days_per_week;

  const frenquencyCollection = [
    // {
    //   // 页面一仅在用户首次该课程设置的时候有弹出
    //   module: !configged ? (
    //     <Welcome
    //       start={start}
    //       courseConsultant={course_consultant}
    //       baby={baby}
    //     />
    //   ) : (
    //     ""
    //   ),
    // },
    // {
    //   module: (
    //     <ChooseStudySection
    //       nextQuestion={nextQuestion}
    //       next={next}
    //       getStudySectionData={getStudySectionData}
    //       totalStudyMinutesTime={total_study_time_in_minutes}
    //       config={config}
    //       courseConsultant={course_consultant}
    //       trial={trial}
    //     />
    //   ),
    // },
    {
      module: (
        <ChooseWeeklyDaySection
          prev={prev}
          submit={submit}
          getWeeklyDaySection={getWeeklyDaySection}
          count={count}
          reminderTimeUrl={config_reminder_time_url}
          config={config}
          // setMyReminderHour={setMyReminderHour}
          reminderHour={reminder_hour}
          courseConsultant={course_consultant}
          trial={trial}
        />
      ),
    },
  ].filter((item) => item.module != "");
  const collectLength = frenquencyCollection.length;

  useEffect(() => {
    refCount.current = nextQuestion + 1;
    setFrenquencyArr(frenquencyCollection[nextQuestion]?.module);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextQuestion]);

  const percent = Math.ceil((100 / collectLength) * (nextQuestion + 1));

  const useUpdateScheduleModal = () => {
    return new ModalManager({
      render: (_, modalClose) => {
        const onClose = () => {
          modalClose();
        };
        return (
          <AdjustScheduleTimes
            onModalClose={onClose}
            courseConsultant={course_consultant}
            redirect_url={redirect_url}
          />
        );
      },
    });
  };

  useEffect(() => {
    const updateScheduleModal = useUpdateScheduleModal();
    if (configged) {
      updateScheduleModal.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CourseFrequencyContianer>
      {/* <ProgressBar progressPercent={percent} /> */}
      {frenquencyArr}
    </CourseFrequencyContianer>
  );
};

const AdjustScheduleTimes = ({
  onModalClose,
  courseConsultant,
  redirect_url,
}) => {
  return (
    <div className="guide_adjust_schedule">
      <div className="dialog_qrcode_body">
        {/* <div className="btn-close" onClick={() => onModalClose()}>
          &times;
        </div> */}
        <p>您本月已调整过，紧急情况需要调整，</p>
        <p>请联系您的专属课程老师为您调整。</p>
        <img
          src={courseConsultant.qrcode_url}
          className="lazyload"
          data-sizes="auto"
        />
      </div>
      <div className="cancle">
        <img
          src={icon_cancle_upper}
          onClick={() => {
            window.location.href = redirect_url;
          }}
        ></img>
      </div>
    </div>
  );
};
