/* eslint-disable lines-between-class-members */
import { ApplicationStore } from "../../../utils/react-global-state";
class PoetryListStore extends ApplicationStore {
  constructor(props) {
    super(props);
    this.defaultStates = {};
  }
  actionCreators = () => ({});

  reducer = (states, action) => {};
}

export default new PoetryListStore().init();
