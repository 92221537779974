/* eslint-disable lines-between-class-members */
/* eslint-disable react/prop-types */
import React from "react";
import { request, csrfHeaders } from "../utils/request";
import { formatDate } from "../utils/formatAudioTime";

const ThemeColor = [
  {
    background: "#ffdfd9",
    font: "#99594d",
    titleBorder: "#ff6a4d",
    shadow: "128,74,64,0.5",
  },
  {
    background: "#fff9d9",
    font: "#998c4d",
    titleBorder: "#ccbb66",
    shadow: "128,117,64,0.5",
  },
  {
    background: "#d9ffe5",
    font: "#4d9966",
    titleBorder: "#3dcc6d",
    shadow: "64,128,85,0.5",
  },
  {
    background: "#d9f2ff",
    font: "#4d8099",
    titleBorder: "#4dc3ff",
    shadow: "64,106,128,0.5",
  },
  {
    background: "#d9d9ff",
    font: "#4d4d99",
    titleBorder: "#8080ff",
    shadow: "64,64,128,0.5",
  },
  {
    background: "#ecd9ff",
    font: "#734d99",
    titleBorder: "#bf80ff",
    shadow: "96,64,128,0.5",
  },
  {
    background: "#ffd9df",
    font: "#994d59",
    titleBorder: "#ff8095",
    shadow: "128,64,74,0.5",
  },
  {
    background: "#d9fff9",
    font: "#4d998c",
    titleBorder: "#45e6cb",
    shadow: "64,128,117,0.5",
  },
  {
    background: "#e5ffd9",
    font: "#66994d",
    titleBorder: "#74d941",
    shadow: "85,128,64,0.5",
  },
  {
    background: "#ffecd9",
    font: "#99734d",
    titleBorder: "#ffa64d",
    shadow: "128,96,64,0.5",
  },
];

export const withUnitStudySubscription = (UnitModeStudyPackage) => {
  class WithSubscription extends React.Component {
    constructor(props) {
      console.log("withUnitStudySubscription props: ", props);
      super(props);
      this.defaultActiveUnitId = 0;
      // this.state = {
      //   readingData: "",
      // };
    }

    getDefaultActiveUnit = () => {
      const {
        study_mode,
        study_package_start_time,
        study_package_end_time,
        study_package_activities,
        // study_package_units,
        study_package_system_time,
        current_study_date,
        current_study_day,
      } = this.props;
      const study_package_units =
        study_mode == "customized" ||
        study_mode == "customized_v2" ||
        study_mode == "topic"
          ? study_package_activities
          : this.props.study_package_units;
      const currentStudyDate = new Date(study_package_system_time);
      const skippedStudyDate = new Date(current_study_date);

      const skippedStudyDay = current_study_day;
      const unitStartDay = (unit) => unit.start_day;
      const unitEndDay = (unit) => unit.end_day;

      const unitStartDate = (unit) => new Date(unit.start_date);
      const unitEndDate = (unit) => new Date(unit.end_date);
      const packageEndTime = new Date(study_package_end_time);
      const packageStartTime = new Date(study_package_start_time);
      let anchor = 0;
      let skippedStudyDateUnitIndex = 0;

      const withinUnitDay = (day, unit) =>
        day - unitStartDay(unit) >= 0 && unitEndDay(unit) - day >= 0;

      const withinUnitTime = (date, unit) =>
        date - unitStartDate(unit) >= 0 && unitEndDate(unit) - date >= 0;
      study_package_units.some((unit, index) => {
        // · 当前进度时间 >= 模块开始时间 && 当前进度时间 <= 模块结束时间
        // · 当前进度天数 >= 模块开始天数 && 当前进度天数 <= 模块结束天数

        // TODO: 注，个性化/定制化课表要和后端商量好用哪个参数条件定位, 尤其是定制化课表
        const flag = this.props.is_user_study_package
          ? withinUnitDay(skippedStudyDay, unit)
          : withinUnitTime(skippedStudyDate, unit);
        // console.log("flag: ", flag);

        if (flag) {
          skippedStudyDateUnitIndex = parseInt(index);
          // console.log("skippedStudyDateUnitIndex: ", skippedStudyDateUnitIndex);
          return true;
        }
      });
      for (const [index, unit] of Object.entries(study_package_units)) {
        // · 当前系统时间 >= 模块开始时间 && 当前系统时间 <= 模块结束时间
        if (withinUnitTime(currentStudyDate, unit)) {
          if (skippedStudyDateUnitIndex < index) {
            // console.log("anchor111: ", anchor);
            anchor = skippedStudyDateUnitIndex;
          } else {
            // console.log("anchor222: ", anchor);
            anchor = parseInt(index);
          }
        } else {
          anchor = skippedStudyDateUnitIndex;
          // console.log("anchor333: ", anchor);
        }
        // // 周末休息
        // if ((currentStudyDate - unitEndDate(unit)) > 0 && (currentStudyDate - unitStartDate(study_package_units[
        //   (parseInt(index) + 1) < study_package_units.length - 1
        //     ? (parseInt(index) + 1)
        //     : study_package_units.length - 1
        // ])) < 0) {
        //   anchor = parseInt(index)
        //   break
        // }
      }

      if (currentStudyDate - packageStartTime <= 0) {
        console.log("系统时间 < 课程包开始时间");
        anchor = 0;
      }

      if (currentStudyDate - packageEndTime >= 0) {
        console.log("系统时间 >= 课程包开始时间");
        anchor = skippedStudyDateUnitIndex;
      }
      console.log("study_package_units_anchor: ", anchor);
      // console.log("study_package_units: ", study_package_units);
      this.defaultActiveUnitId = study_package_units[anchor].id;
      return {
        index: anchor,
        item: study_package_units[anchor],
      };
    };
    // 学前体验模块
    getHandBookDayToStudy = (dayStudies) => {
      if (
        dayStudies.some((dayStudy) => dayStudy.current_progress_date === true)
      ) {
        return;
      }
      let dayStudyStates = [];
      let nextIdx = 0;

      // 取出最后一个state是pass的情况
      dayStudyStates = [...dayStudies].map((study) => study.state);
      // console.log("dayStudyStates: ", dayStudyStates);

      // for (let i = 0; i < dayStudyStates.length; i++) {
      //   if (dayStudyStates[i] == "pass") {
      //     nextIdx = (i < (dayStudyStates.length - 1)) ? (i + 1) : dayStudyStates.length - 1
      //   }
      // }
      const lastPassIndex = dayStudyStates.lastIndexOf("pass");
      nextIdx =
        lastPassIndex < dayStudyStates.length - 1
          ? lastPassIndex + 1
          : dayStudyStates.length - 1;
      // console.log("lastPassIndex: ", lastPassIndex, "nextIdx: ", nextIdx);
      return nextIdx;
    };

    getTheDayNeedToStudy = (dayStudy, index, dayStudies) => {
      // console.log("dayStudy_index_dayStudies: ", dayStudy, index, dayStudies);
      if (
        dayStudies.some((dayStudy) => dayStudy.current_progress_date === true)
      ) {
        return;
      }

      let date = "";
      let day = "";

      // 后一天锁定 当天通过且没有锁定 则定位到当天
      if (
        index + 1 < dayStudies.length &&
        dayStudy.state == "pass" &&
        dayStudies[index + 1].day_lock &&
        !dayStudy.day_lock
      ) {
        date = dayStudy.date;
        day = dayStudy.day;
        // console.log("1111--111", day);
        return this.props.is_user_study_package ? day : date;
      }

      // 前一天通过且 当天未做 则定位到当天
      if (
        index - 1 >= 0 &&
        dayStudy.state == "blank" &&
        dayStudies[index - 1].state == "pass" &&
        !dayStudy.day_lock
      ) {
        date = dayStudy.date;
        day = dayStudy.day;
        console.log("2222--222", day, date);
        return this.props.is_user_study_package ? day : date;
      }

      // 后端给的current_study_date对应锁定，则需要定位到之前通过且未锁定的那天
      // const currentDateLockedIndex = dayStudies.findIndex((day) => {
      //   return (day.date === this.props.current_study_date && day.day_lock)
      // })
      // if (currentDateLockedIndex !== -1) {
      //   const copyDayStudies = [...dayStudies]
      //   copyDayStudies.splice(0, currentDateLockedIndex).reverse().some((day) => {
      //     if (!day.day_lock && day.state == 'pass') {
      //       date = day.date
      //       return true
      //     }
      //   })
      //   if (date) {
      //     console.log('currentDateLockedIndex', currentDateLockedIndex, date)
      //     return date
      //   }
      // }
      const result = this.props.is_user_study_package
        ? this.props.current_study_day
        : this.props.current_study_date;
      // console.log("3333--333", result);
      return result;
    };

    setDayStudyMediaCourses = (dayStudy) => {
      if (
        !("media_lessons" in dayStudy) ||
        dayStudy.media_lessons.length == 0
      ) {
        dayStudy.media_courses = [];
        return dayStudy;
      }
      let mediaCourseIds = [];
      const mediaCourses = [];
      dayStudy.media_lessons.forEach((mediaLesson) => {
        mediaCourses.push({
          id: mediaLesson.media_course_id,
          name: mediaLesson.media_course_name,
          photoUrl: mediaLesson.media_course_photo_url || "",
          mediaLesson: mediaLesson,
        });
        if (!mediaCourseIds.includes(mediaLesson.media_course_id)) {
          mediaCourseIds.push(mediaLesson.media_course_id);
        }
      });

      mediaCourseIds = mediaCourseIds.map((courseId) => {
        const mediaLessons = [];
        let courseName = "";
        let photoUrl = "";
        mediaCourses.forEach((course) => {
          if (course.id == courseId) {
            courseName = course.name;
            photoUrl = course.photoUrl;
            mediaLessons.push(course.mediaLesson);
          }
        });
        return {
          id: courseId,
          name: courseName,
          photo_url: photoUrl,
          media_lessons: mediaLessons,
        };
      });
      dayStudy.media_courses = mediaCourseIds;
    };

    collectRestHolidays = (happyDays = [], dayStudy, dayStudyIndex) => {
      // console.log("这个值是未经处理过的原始dayStudy: ", dayStudy);
      if (!("state" in dayStudy)) {
        happyDays.push({
          restDate: dayStudy.date,
          indexOfDayStudy: dayStudyIndex,
        });
      }
    };

    groupRestHolidays = (happyDays, dayStudies) => {
      const restDays = {};
      let idx = 0;
      let length = 1;
      // 这个white循环是在 grouping 连续的放假 dayStudy
      while (idx < happyDays.length) {
        if (
          idx + 1 < happyDays.length &&
          happyDays[idx].indexOfDayStudy + 1 ==
            happyDays[idx + 1].indexOfDayStudy
        ) {
          length += 1;
        } else {
          restDays[idx + "spliceFlagIndex-" + length] =
            happyDays[idx].indexOfDayStudy - length + 1;
          length = 1;
        }
        idx = idx + 1;
      }

      console.log("restDays: ", restDays);
      // 记录之前切除的节假日长度
      let lastSpliceLength = 0;

      // 把 grouping 好的放假日期放到原 dayStudies 中
      // 首先在原 dayStudies 中切除对应的放假日期
      // 然后把grouping 好的放假日期放到原 dayStudies 中
      // 这里需要注意 切除的对应放假日期 和 grouping 好的放假日期 对应的index要一致
      for (const [key, value] of Object.entries(restDays)) {
        const spliceLength = key.split("-")[1];
        // console.log(parseInt(value) - lastSpliceLength)
        const longRestDays = dayStudies.splice(
          parseInt(value) - lastSpliceLength,
          parseInt(spliceLength)
        );
        if (longRestDays.length) {
          // console.log("longRestDays", longRestDays);
          const newLongRestDays = {};
          if (longRestDays.length == 1) {
            newLongRestDays.start_rest_day = longRestDays[0].date;
            newLongRestDays.end_rest_day = longRestDays[0].date;

            if (longRestDays[0].week) {
              newLongRestDays.week = longRestDays[0].week;
              newLongRestDays.week_start = longRestDays[0].week_start;
              newLongRestDays.week_end = longRestDays[0].week_end;
            }
          } else {
            newLongRestDays.start_rest_day = longRestDays[0].date;
            newLongRestDays.end_rest_day =
              longRestDays[longRestDays.length - 1].date;
            if (longRestDays[0].week) {
              newLongRestDays.week = longRestDays[0].week;
              newLongRestDays.week_start = longRestDays[0].week_start;
              newLongRestDays.week_end =
                longRestDays[longRestDays.length - 1].week_end;
            }
          }

          newLongRestDays.day_lock =
            parseInt(value) - 1 - lastSpliceLength >= 0
              ? dayStudies[parseInt(value) - 1 - lastSpliceLength].day_lock
              : false;

          dayStudies.splice(
            parseInt(value) - lastSpliceLength,
            0,
            newLongRestDays
          );
          console.log("381_dayStudies", [...dayStudies]);

          lastSpliceLength = lastSpliceLength + (spliceLength - 1);
        }
      }
      return [...dayStudies];
    };

    setCurrentStudyState = (currentStudyDate, dayStudy) => {
      // console.log("currentStudyDate_dayStudy: ", currentStudyDate, dayStudy);
      if (currentStudyDate) {
        const isCurrentProgressDate = ({
          currentDate,
          theDateStart,
          theDateEnd,
        }) => {
          // console.log('isCurrentProgressDate', currentDate, theDateStart, theDateEnd)
          let inNotRestDate = false;
          let inRestDate = false;
          if (theDateStart && !theDateEnd) {
            // console.log(
            //   "401 currentDate, theDateStart: ",
            //   currentDate,
            //   theDateStart
            // );
            inNotRestDate = this.props.is_user_study_package
              ? currentDate === theDateStart
              : formatDate(currentDate) === formatDate(theDateStart);
            // console.log(
            //   "inNotRestDate, inRestDate: ",
            //   inNotRestDate,
            //   inRestDate
            // );
            // console.log("==414==: ", inNotRestDate || inRestDate);
            return inNotRestDate || inRestDate;
          }
          if (theDateStart && theDateEnd) {
            // console.log(408, theDateStart, theDateEnd);
            inRestDate = this.props.is_user_study_package
              ? theDateStart <= currentDate && currentDate <= theDateEnd
              : new Date(theDateStart) <= new Date(currentDate) &&
                new Date(currentDate) <= new Date(theDateEnd);
            return inNotRestDate || inRestDate;
          }
        };

        const getTheDate = (day) => {
          // console.log("getTheDate_day: ", day);
          let theDateStart = "";
          let theDateEnd = "";
          if (this.props.is_user_study_package) {
            // console.log("if");
            if ("day" in day) {
              // console.log("if-day: ", day);
              // console.log("if-theDateStart: ", theDateStart);
              theDateStart = day.day;
            }
            // if ("start_rest_day" in day) {
            //   theDateStart = day.start_rest_day;
            // }
            // if ("end_rest_day" in day) {
            //   theDateEnd = day.end_rest_day;
            // }
          } else {
            // console.log("else");
            if ("date" in day) {
              theDateStart = day.date;
            }
            if ("start_rest_day" in day) {
              theDateStart = day.start_rest_day;
            }
            if ("end_rest_day" in day) {
              theDateEnd = day.end_rest_day;
            }
          }
          // console.log("451 currentStudyDate, theDateStart, theDateEnd", {
          //   currentStudyDate,
          //   theDateStart,
          //   theDateEnd,
          // });
          return {
            currentDate: currentStudyDate,
            theDateStart,
            theDateEnd,
          };
        };

        // console.log("447-----", dayStudy);

        // console.log("442", getTheDate(dayStudy));
        const current_progress_date = isCurrentProgressDate({
          ...getTheDate(dayStudy),
        });
        // console.log("464---: ", { ...getTheDate(dayStudy) });
        // console.log("466_current_progress_date: ", current_progress_date);
        dayStudy.current_progress_date = current_progress_date;
      } else {
        dayStudy.current_progress_date = false;
      }
    };

    dealDayStudies = (day_studies, unit_id) => {
      // console.log("483_day_studies: ", day_studies);
      const happyDays = [];
      day_studies = day_studies.map((dayStudy, index) => {
        this.collectRestHolidays(happyDays, dayStudy, index);
        this.setDayStudyMediaCourses(dayStudy);
        return dayStudy;
      });

      if (happyDays.length) {
        day_studies = this.groupRestHolidays(happyDays, day_studies);
      }

      day_studies.forEach((dayStudy, index) => {
        const todayDate =
          formatDate(this.props.study_package_system_time) ===
          formatDate(dayStudy.date);
        dayStudy.today_date = todayDate;

        // 如果不是默认定位到的单元 则不去设置学习锚点
        // console.log(492, this.defaultActiveUnitId, unit_id);
        if (this.defaultActiveUnitId == unit_id) {
          if (dayStudy.handbook) {
            const currentStudyIndex = this.getHandBookDayToStudy(day_studies);
            if (currentStudyIndex !== undefined) {
              day_studies[currentStudyIndex].current_progress_date = true;
            }
          } else {
            const currentStudyDate = this.getTheDayNeedToStudy(
              dayStudy,
              index,
              day_studies
            );
            // console.log("505_currentStudyDate: ", currentStudyDate);
            this.setCurrentStudyState(currentStudyDate, dayStudy);
          }
        }
      });
      // console.log("521_day_studies: ", day_studies);
      return day_studies;
    };

    getUnitStudy = (unitStudyUrl, unitId) => {
      console.log("506_unitStudyUrl, unitId: ", unitStudyUrl, unitId);
      const params = {};
      if (
        this.props.study_mode !== "customized" &&
        this.props.study_mode !== "customized_v2" &&
        this.props.study_mode !== "topic"
      ) {
        params.study_package_unit_id = unitId;
      }
      console.log("params: ", params);
      return request({
        url: unitStudyUrl,
        method: "GET",
        headers: csrfHeaders,
        params: params,
      }).then(({ data }) => {
        if (
          this.props.study_mode == "customized" ||
          this.props.study_mode == "customized_v2" ||
          this.props.study_mode == "topic"
        ) {
          const {
            study_package_modules,
            handbook,
            batch_ecoupons_end_time,
            ecoupons_study_required,
            ecoupons,
            upgrade_package,
            upgrade_package_required,
            questionnaire_url,
            trial_expired,
            target_id,
            target_type,
            trial_unlock_mode,
            study_state,
            study_url,
            discount_get,
            old_discount_get,
            get_discount_url,
            study_package_activity_details_url,
          } = data;

          study_package_modules.forEach((module, index) => {
            return handbook
              ? module.day_studies.sort((a, b) => a.day - b.day)
              : module.day_studies;
          });
          const arr = [];
          let day_studies = [];
          // 因为嵌套了好几层，所以遍历数据的时候，要平铺传递才行，不然每次都会执行一遍子项数据
          study_package_modules.forEach((module, index) => {
            arr.push(module.day_studies);
          });
          day_studies = arr.flat(Infinity); // 数据平铺
          this.dealDayStudies(day_studies, unitId);
          // console.log(548, study_package_modules);
          return {
            batch_ecoupons_end_time,
            ecoupons_study_required,
            ecoupons,
            study_package_modules,
            study_state,
            study_url,
            handbook,
            upgrade_package,
            upgrade_package_required,
            questionnaire_url,
            trial_expired,
            target_id,
            target_type,
            trial_unlock_mode,
            discount_get,
            old_discount_get,
            get_discount_url,
            study_package_activity_details_url,
          };
        } else {
          const {
            day_studies,
            study_package_unit_id,
            main_photo_url,
            description,
            ecoupons_study_required,
            ecoupons,
            upgrade_package_required,
            batch_ecoupons_end_time,
            handbook,
          } = data;
          const new_day_studies = this.dealDayStudies(day_studies, unitId);
          console.log("588_new_day_studies: ", new_day_studies);
          return {
            day_studies: new_day_studies,
            // day_studies,
            study_package_unit_id,
            main_photo_url,
            description,
            ecoupons_study_required,
            ecoupons,
            upgrade_package_required,
            batch_ecoupons_end_time,
            handbook,
          };
        }
      });
    };

    groupSameWeekDayStudy = (day_studies) => {
      const dayStudiesObj = day_studies.reduce((acc, dayStudy) => {
        acc[dayStudy.week] = acc[dayStudy.week] || [];
        acc[dayStudy.week].push(dayStudy);
        return acc;
      }, {});
      return Object.values(dayStudiesObj);
    };

    // 针对的是语文类课表
    getChineseUnitStudy = (unitStudyUrl, unitId) => {
      return request({
        url: unitStudyUrl,
        method: "GET",
        headers: csrfHeaders,
      }).then(({ data }) => {
        // console.log("getChineseUnitStudy_request_data: ", data);
        // eslint-disable-next-line no-unused-expressions
        data?.study_package_modules?.forEach((module) => {
          let { day_studies } = module;
          const happyDays = [];
          day_studies = day_studies.map((dayStudy, index) => {
            this.collectRestHolidays(happyDays, dayStudy, index);
            return dayStudy;
          });

          if (happyDays.length) {
            day_studies = this.groupRestHolidays(happyDays, day_studies);
          }

          day_studies.forEach((dayStudy, index) => {
            const todayDate =
              formatDate(this.props.study_package_system_time) ===
              formatDate(dayStudy.date);
            dayStudy.today_date = todayDate;

            // 如果不是默认定位到的单元 则不去设置学习锚点
            if (this.defaultActiveUnitId == unitId) {
              if (dayStudy.handbook) {
                const currentStudyIndex = this.getHandBookDayToStudy(
                  day_studies
                );
                // console.log('currentStudyIndex: ', currentStudyIndex);
                if (currentStudyIndex !== undefined) {
                  day_studies[currentStudyIndex].current_progress_date = true;
                }
              } else {
                const currentStudyDate = this.getTheDayNeedToStudy(
                  dayStudy,
                  index,
                  day_studies
                );
                this.setCurrentStudyState(currentStudyDate, dayStudy);
                // current_progress_date true false
              }
            }
          });
          if (this.props.sub_course_type === "reading") {
            day_studies = this.groupSameWeekDayStudy(day_studies);
          }

          module.day_studies = day_studies;
        });
        return data;
      });
    };
    getChineseTopicUnitStudy = (unitStudyUrl, unitId) => {
      return request({
        url: unitStudyUrl,
        method: "GET",
        headers: csrfHeaders,
      }).then(({ data }) => {
        console.log("data is => ", data)
        // console.log("getChineseUnitStudy_request_data: ", data);
        // eslint-disable-next-line no-unused-expressions
        data?.study_package_modules?.forEach((modules) => {
          modules.study_topics.map((module) => {
            let { day_studies } = module;
            const happyDays = [];
            day_studies = day_studies.map((dayStudy, index) => {
              this.collectRestHolidays(happyDays, dayStudy, index);
              return dayStudy;
            });

            if (happyDays.length) {
              day_studies = this.groupRestHolidays(happyDays, day_studies);
            }

            day_studies.forEach((dayStudy, index) => {
              const todayDate =
                formatDate(this.props.study_package_system_time) ===
                formatDate(dayStudy.date);
              dayStudy.today_date = todayDate;

              // 如果不是默认定位到的单元 则不去设置学习锚点
              if (this.defaultActiveUnitId == unitId) {
                if (dayStudy.handbook) {
                  const currentStudyIndex = this.getHandBookDayToStudy(
                    day_studies
                  );
                  // console.log('currentStudyIndex: ', currentStudyIndex);
                  if (currentStudyIndex !== undefined) {
                    day_studies[currentStudyIndex].current_progress_date = true;
                  }
                } else {
                  const currentStudyDate = this.getTheDayNeedToStudy(
                    dayStudy,
                    index,
                    day_studies
                  );
                  this.setCurrentStudyState(currentStudyDate, dayStudy);
                  // current_progress_date true false
                }
              }
            });
            if (this.props.sub_course_type === "reading") {
              day_studies = this.groupSameWeekDayStudy(day_studies);
            }

            module.day_studies = day_studies;
          });
        });
        return data;
      });
    };

    render() {
      return (
        <UnitModeStudyPackage
          {...this.props}
          getUnitStudy={
            this.props.study_mode === "term_review" ||
            this.props.study_mode === "peotry_v2" ||
            this.props.study_mode === "reading_inclass"
              ? this.getChineseUnitStudy
              : this.props.study_mode == "reading_v2"
              ? this.getChineseTopicUnitStudy
              : this.getUnitStudy
          }
          // readingData={this.state.readingData}
          getDefaultActiveUnit={this.getDefaultActiveUnit}
          themeColor={ThemeColor}
        />
      );
    }
  }

  WithSubscription.displayName = "WithSubscription(UnitModeStudyPackage)";
  return WithSubscription;
};
