// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/share_tips_2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp-sharehint-dialog .share-inpage{background:hsla(0,0%,100%,.95);position:fixed;top:0;left:0;right:0;bottom:0;display:block;z-index:9999999}.comp-sharehint-dialog .share-inpage .share-notice-container{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 0;background-size:80%;padding:200px 10% 0;color:#333;position:absolute;left:0;right:0;top:10px;text-align:center;font-size:20px}.comp-sharehint-dialog .share-inpage .share-notice-container span{display:block;padding-top:20px}", ""]);
// Exports
module.exports = exports;
