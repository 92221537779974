import React from "react";
import "./OldDataAnalysis.scss";
import icon_back from "../../../assets/images/c4_old_data/old_data_back_bottom.png";
const studyStatus = [
  { status: "未学习", next: "新课学习", color: "#333333" },
  { status: "未掌握", next: "再学一遍", color: "#FF6868" },
  { status: "用时久", next: "做巩固练习", color: "#FFA000" },
  { status: "已掌握", next: "跳过学习", color: "#7BA6EF" },
  { status: "熟练掌握", next: "跳过学习", color: "#55C656" },
];
export default class OldDataAnalysis extends React.Component {
  constructor(props) {
    super(props);
    console.log("==========", props);
    this.state = {};
  }
  render() {
    const { media_lessons } = this.props;
    return (
      <div className="old-data-back">
        <div className="back-white">
          <div className="old-data-decs">
            <div className="old-data-decs-to">亲爱的爸爸妈妈、小朋友：</div>
            <span className="old-data-decs-cont">
              {`  欢迎来到《${
                this.props.course_name || "系列课程"
              }》的学习，根据小朋友之前的学习情况，将生成以下自适应学习课表：`}
            </span>
          </div>

          {media_lessons && media_lessons.length > 0 ? (
            <div>
              <table>
                <thead>
                  <tr>
                    <td className="item-class">课时</td>
                    <td className="item-status head-add-border ">学习状态</td>
                    <td className="item-next head-add-border ">
                      课表自适应调整
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {media_lessons.map((i, dex) => {
                    // let d =
                    //   0 < Number(dex) && Number(dex) < 5 ? Number(dex) : 0;

                    return (
                      <tr
                        style={{
                          backgroundColor: i.grasp_state ? "#FFFBF3" : "#fff",
                        }}
                        key={"tr" + dex}
                      >
                        <td>{`${i.name}`}</td>
                        <td
                          className="body-add-border"
                          style={{ color: studyStatus[i.grasp_state].color }}
                        >
                          {studyStatus[i.grasp_state].status}
                        </td>
                        <td
                          className="body-add-border"
                          style={{ color: studyStatus[i.grasp_state].color }}
                        >
                          {studyStatus[i.grasp_state].next}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}
        </div>
        <img src={icon_back} className="icon-back-bottom"></img>
        <div
          className="old-data-bottom"
          onClick={() => {
            window.location.href = this.props.study_url;
          }}
        >
          <div className="new-go">进入课表学习</div>
        </div>
      </div>
    );
  }
}
