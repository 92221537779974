import React, { useCallback } from "react";
import { request, csrfHeaders } from "../utils/request";
import UserAgent from "../utils/UserAgent";
import Picker from "better-picker";
// import { request, csrfHeaders } from "../utils/request";
import { GA } from "../utils/Env.js";
import { clipboard, promptPageBack, showDialog } from "../utils/helpers.js";
import { ToastManager } from "../utils/Toast";
// import { } from "../promotion_activities"
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import iconBoyUser from "icon_default_boy.png";
import iconGirlUser from "icon_default_girl.png";
import queryString from "query-string";
import ParentGuide from "./poetries/v2/components/ParentGuide";
import "./StudySetting.scss";

import { StudyPackageCommentModule } from "./UnitModeStudyPackage";

const date_hour_data = [
  { text: "不提醒", value: -1 },
  { text: "00点", value: 0 },
  { text: "01点", value: 1 },
  { text: "02点", value: 2 },
  { text: "03点", value: 3 },
  { text: "04点", value: 4 },
  { text: "05点", value: 5 },
  { text: "06点", value: 6 },
  { text: "07点", value: 7 },
  { text: "08点", value: 8 },
  { text: "09点", value: 9 },
  { text: "10点", value: 10 },
  { text: "11点", value: 11 },
  { text: "12点", value: 12 },
  { text: "13点", value: 13 },
  { text: "14点", value: 14 },
  { text: "15点", value: 15 },
  { text: "16点", value: 16 },
  { text: "17点", value: 17 },
  { text: "18点", value: 18 },
  { text: "19点", value: 19 },
  { text: "20点", value: 20 },
  { text: "21点", value: 21 },
  { text: "22点", value: 22 },
  { text: "23点", value: 23 },
];

const setRemainderPicker = (url, reminder_time_hour) => {
  console.log(reminder_time_hour, "66666666666");
  // const nameEl = dom;
  const picker = new Picker({
    data: [date_hour_data],
    selectedIndex: [reminder_time_hour + 1],
    title: "",
  });

  // const config_reminder_time_url = props.config_reminder_time_url;
  // const reminder_time_hour = props.reminder_timeHour;
  picker.on("picker.select", function (selectedVal, selectedIndex) {
    // nameEl.innerText = date_hour_data[selectedIndex[0]].text;
    console.log(selectedIndex, "66666666666");
    // if (selectedIndex[0] - 1 != reminder_time_hour) {
    let params = {
      reminder_hour: selectedIndex[0] - 1,
    };
    // var url = config_reminder_time_url;
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((resp) => {
        window.location.reload();
        console.log("正确返回");
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  });
  picker.show();
  // if (props.reminder_time_hour >= -1) {
  //   const time_setup_click = document.getElementById("time_setup_click");

  //   if (time_setup_click) {
  //     time_setup_click.addEventListener("click", function () {
  //       picker.show();
  //       if (UserAgent.isNativeClient()) {
  //         XinYaNativeBridge.requestAuthorization({
  //           authType: "notification",
  //         });
  //       }
  //     });
  //   }
  // }
};

class StudySetting extends React.Component {
  constructor(props) {
    console.log("-----------", props);
    super(props);
    this.state = {
      writeEvaluateDialogState: false,
      get_shopping_group_data_url: "",
    };
  }

  /**
   * 点击分享
   */
  onInvitationIconClick = (e) => {
    const {
      // get_shopping_group_data_url,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      study_package_activity_id,
    } = this.props;
    // const { get_shopping_group_data_url } = this.state;
    // console.log(e, "------44444444444-----", get_shopping_group_data_url);
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: posterCreation,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(
        ({
          bonus,
          had_joined_groupon,
          remaining_shopping_group_count,
          avatar_location,
        }) => {
          new SharePosterProxy({
            // posterUrl: this.props.poster_url,
            // posterImgSrc: this.props.poster_img_src,
            avatar_location,
            posterTemplate,
            posterCreation,
            mode: "front_end",
            free:
              had_joined_groupon && remaining_shopping_group_count > 0
                ? "in_or_open_shopping_group"
                : "none_shopping_group",
            bonus: bonus || 100,
            sale_mode: "shopping_group",
            remaining_shopping_group_count,
          })
            .open({
              isShowToast: false,
            })
            .then(() => {
              toast.cancel();
            });
        }
      )
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });

    GA("shortcut", "reportPoster", study_package_activity_id);
  };

  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });
    GA("shortcut", "reportComment", this.props.study_package_activity_id);
  };

  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState(
      {
        writeEvaluateDialogState: false,
      },
      () => {
        window.location.reload();
      }
    );
  };

  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };

  componentDidMount() {
    // this.props
  }
  render() {
    // const StudySetting = (props) => {
    // console.log("UserSettings");
    // console.log(props);

    const calcAlreadyStudyDayPercent = (
      studied_course_day_count,
      course_day_count
    ) => {
      // const { studied_course_day_count, course_day_count } = study_package_activity;
      const widthPercent =
        (studied_course_day_count / course_day_count) * 100 + "%";
      return widthPercent;
    };
    return (
      <div>
        {/* 头部 */}
        <div className="header-connext">
          <img
            src={
              this.props.baby.photo_url ||
              (this.props.baby.gender === "男" ? iconBoyUser : iconGirlUser)
            }
            className="header-left"
          >
            {/* <image src=""></image> */}
          </img>
          <div className="header-right">
            <div className="header-right-top">
              <div>{this.props.baby.nickname}</div>
              <div className="header-right-top-desc">
                {"(学号：" + this.props.baby.sno + ")"}
              </div>
            </div>
            <div className="header-right-bottom">
              <div className="course_study_progress">
                <div className="left_progress">
                  <div className="progress_bar">
                    <div
                      className="progress"
                      style={{
                        // width: "50%",
                        width: calcAlreadyStudyDayPercent(
                          this.props.baby.total_studied_media_lessons_count,
                          this.props.baby.media_lessons_count
                        ),
                      }}
                    ></div>
                  </div>
                  <div className="header-right-top-desc">
                    {"已学课时（" +
                      this.props.baby.total_studied_media_lessons_count +
                      "/" +
                      this.props.baby.media_lessons_count +
                      "）"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 二维码 */}
        <div className=" setting-actions teacher-wechat">
          {/* <div className="teacher-wechat-top"></div> */}
          <img
            src={this.props.cc_info.cc_qrcode_url}
            className="teacher-wechat-top"
          ></img>
          <div className="teacher-wechat-bottom">
            {this.props.cc_info.description}
          </div>
        </div>
        {/* 设置 */}
        <div className=" setting-actions class-setting">
          {this.props.settings.length
            ? this.props.settings.map((item, index) => {
                return item.position_type == 2 ? (
                  <div
                    className="setting-actions-item"
                    key={"b" + index}
                    onClick={() => {
                      if (item.pick_type == 1) {
                        let url = window.location.origin + item.url;
                        window.location.href = url;
                      } else {
                        setRemainderPicker(
                          item.url,
                          this.props.reminder_time_hour
                        );
                        // console.log(item.title);
                      }
                    }}
                  >
                    <img
                      src={item.icon}
                      className="setting-actions-item-left"
                    ></img>
                    <div className="setting-actions-item-right">
                      <div className="setting-actions-item-right-connext">
                        <div className="setting-actions-item-right-title">
                          {item.title}
                        </div>
                        <div className="setting-actions-item-right-desc">
                          {item.title == "上课提醒设置"
                            ? item.reminder_hour < 0
                              ? item.title_description + "不提醒"
                              : item.reminder_hour < 10
                              ? item.title_description +
                                "0" +
                                item.reminder_hour +
                                ":00"
                              : item.title_description +
                                item.reminder_hour +
                                ":00"
                            : item.title_description}
                        </div>
                      </div>
                      <div className="setting-actions-item-right-icon"></div>
                    </div>
                  </div>
                ) : (
                  ""
                );
              })
            : ""}
        </div>
        {/* 活动 */}
        <div className="setting-actions">
          {this.props.settings.length
            ? this.props.settings.map((item, index) => {
                return item.position_type == 3 &&
                  (item.title != "评价领卷" ||
                    this.props.comment_target.is_display) ? (
                  <div
                    className="setting-actions-item"
                    key={"a" + index}
                    data-url={item.url}
                    onClick={(e) => {
                      let that = this;
                      if (item.title == "评价领卷") {
                        this.setState(
                          { createRecommendRequestUrl: item.url },
                          that.getWriteEvaluateStatus()
                        );
                      } else {
                        that.onInvitationIconClick(e);
                        // console.log("++++++++++", item);
                        // this.setState(
                        //   { get_shopping_group_data_url: item.url },
                        //   that.onInvitationIconClick(e)
                        // );
                        // this.onInvitationIconClick()
                        // console.log(item.title);
                      }
                    }}
                  >
                    <img
                      src={item.icon}
                      className="setting-actions-item-left"
                    ></img>
                    <div className="setting-actions-item-right">
                      <div className="setting-actions-item-right-connext">
                        <div className="setting-actions-item-right-title">
                          {item.title}
                        </div>
                        <div className="setting-actions-item-right-desc">
                          {item.title_description}
                        </div>
                      </div>
                      <div className="setting-actions-item-right-icon"></div>
                    </div>
                  </div>
                ) : (
                  ""
                );
              })
            : ""}
        </div>
        {!!this.state.writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={this.state.createRecommendRequestUrl}
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment || ""}
            bought_phase={this.props.bought_phase || ""}
            promotion={this.props.promotion || ""}
            rate={this.props.rate || ""}
            name={this.props.study_package_name || ""}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}
      </div>
    );
  }
}
export default StudySetting;
