import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import ModalManage from "../../../utils/Modal/ModalManage.jsx";
import ModalMask from "../../../utils/Modal/ModalMask.jsx";
import { clipboard } from "../../../utils/helpers.js";

const CoursePDFCopyModal = ({ onModalClose, pdfUrl, courseTitle }) => {
  const copyPdfUrl = useCallback(() => {
    clipboard(window, document, navigator).copy(pdfUrl);
  }, [pdfUrl]);
  return (
    <div className="pdf_copy_container">
      <div className="modal_close" onClick={onModalClose}></div>
      <div className="modal_content">
        <p className="course_title">{courseTitle}</p>
        <p className="course_pdf_url">{pdfUrl}</p>
        <p className="tip">建议复制网址，通过电脑浏览器进行打印</p>
        <div className="btn_group">
          <a className="preview" href={pdfUrl}>
            预览内容
          </a>
          <div className="copy_link" onClick={copyPdfUrl}>
            复制网址
          </div>
        </div>
      </div>
    </div>
  );
};

CoursePDFCopyModal.propTypes = {
  onModalClose: PropTypes.func,
  pdfUrl: PropTypes.string,
  courseTitle: PropTypes.string,
};

const useCoursePDFCopy = (pdfUrl, courseTitle) => {
  // console.log("pdfUrl: ", pdfUrl);
  // console.log("courseTitle: ", courseTitle);
  const copyModal = useMemo(
    () =>
      new ModalManage({
        render: (_, onModalClose) => {
          return (
            <ModalMask>
              <CoursePDFCopyModal
                onModalClose={onModalClose}
                pdfUrl={pdfUrl}
                courseTitle={courseTitle}
              />
            </ModalMask>
          );
        },
      }),
    [courseTitle, pdfUrl]
  );
  return copyModal.open;
};

export default useCoursePDFCopy;
