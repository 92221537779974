import React from "react";
import {
  SharePosterProxy,
  SharePoster,
} from "../../promotion_activities/SharePoster";
import { onNavigate } from "../../utils/helpers";

import "./PoetryReportCard.scss";

export default class PoetryReportCard extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  studyReportUrl = (e) => {
    onNavigate(e, this.props.studyReportUrl);
    // window.location.href = studyReportUrl
  };

  cardCollectClick = () => {
    if (this.props.posterUrl) {
      if (!this.sharePosterProxy) {
        this.sharePosterProxy = new SharePosterProxy({
          posterUrl: this.props.posterUrl,
          free: "invite_collect_star",
          nickName: this.props.nickName,
          ruleUrl: this.props.ruleUrl,
        });
      }
      this.sharePosterProxy.open();
    }
  };

  render() {
    const { studyReport } = this.props;
    return !studyReport ? (
      ""
    ) : (
      <div className="poetry_bottom">
        <a
          className="study_report"
          href="javascript:void(0)"
          onClick={this.studyReportUrl}
        >
          学习报告
        </a>
        <a
          className="collect_star"
          href="javascript:void(0)"
          onClick={this.cardCollectClick}
        >
          打卡分享
        </a>
      </div>
    );
  }
}

PoetryReportCard.defaultProps = {
  free: "invite_collect_star",
};
