/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import UserAgent from "../utils/UserAgent";
import QueryString from "query-string";

import { request, csrfHeaders } from "../utils/request";
import { formatAudioTime } from "../utils/formatAudioTime";
import { reloadPage, onMiniProgram, convertURLToJSON } from "../utils/helpers";
import { Dialog, DialogManager } from "../utils/Dialog";
import Raven from "raven-js";
import { GA } from "../utils/Env";

import ShareHintDialogProxy from "../shared/ShareHintDialog";
import trial_ad from "study_package/trial_ad.png";
import icon_lock_gray from "study_package/icon_lock_gray.png";

import "./TrialPackage.scss";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";

class TrialPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lesson_times: [],
      shared: this.props.shared,
    };
    this.getAllLessonTimeInfo(this.props.media_lessons);
  }

  // 完善用户信息·弹框引导
  addUserInfoDialog = (register_url, err_code, user_id) => {
    const buttons = [
      {
        label: "确定",
        onClick: () => {
          this.confirmDialog.close();
          if (err_code == "err_no_baby") {
            GA("Register", "skipPhone", user_id);
          }
          window.location.href = register_url;
        },
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        为了给您提供更有针对性的服务，请先完善用户信息。
      </Dialog>
    );
  };

  inviteListenClick = (e) => {
    const media_lesson_url = e.currentTarget.getAttribute(
      "data-media-lesson-url"
    );
    const day_study_url = e.currentTarget.getAttribute("data-url");
    const i = e.currentTarget.getAttribute("data-index");

    console.log(53, media_lesson_url);
    console.log(54, day_study_url);

    // if (i >= 3 && !this.state.shared) {
    //   new ShareHintDialogProxy(
    //     "分享本页面到朋友圈或是好友<br><span>解锁剩余全部课程</span>"
    //   ).open();
    //   return;
    // }
    if (!this.props.bought) {
      // 这里的请求针对的是没有购买过课程的用户
      const order_url = this.props.trial_order_url;
      const params = {
        source_study_package_activity_id: this.props
          .source_study_package_activity_id,
      };
      request({
        url: order_url,
        headers: csrfHeaders,
        method: "POST",
        data: params,
      })
        .then((resp) => {
          console.log("resp data: ", resp.data);
          const data = resp.data;
          const redirect_url = resp.data.redirect_url;
          if (
            redirect_url &&
            (data.err_code == "err_no_baby" ||
              data.err_code == "err_unbound_mobile")
          ) {
            // 注册 信息不全, 需跳转到注册信息页·完善信息
            this.addUserInfoDialog(redirect_url, data.err_code, data.user_id);
          } else {
            if (media_lesson_url) {
              this.handleEnterCourse(i, media_lesson_url);
            } else if (day_study_url) {
              this.handleTodayQuiz(day_study_url);
            }
          }
        })
        .catch((err) => {
          Raven.captureException(err);
        });
    } else {
      if (media_lesson_url) {
        this.handleEnterCourse(i, media_lesson_url);
      } else if (day_study_url) {
        this.handleTodayQuiz(day_study_url);
      }
    }
    // // to filter for miniProgram
    // let mini_program_url = e.currentTarget.getAttribute("data-url")
    // let params = {}
    // onMiniProgram(e, mini_program_url, params)
  };

  handleEnterCourse = (i, media_lesson_url) => {
    console.log("index: ", i + "," + "media_lesson_url: ", media_lesson_url);
    // if (i < 3 || this.state.shared) {
    if (UserAgent.isMiniProgram()) {
      onMiniProgram(e, media_lesson_url, {});
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: media_lesson_url });
    } else {
      window.location.href = media_lesson_url;
    }
    // }
  };

  handleTodayQuiz = (day_study_url) => {
    if (UserAgent.isMiniProgram()) {
      const params = {};
      onMiniProgram(e, day_study_url, params);
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: day_study_url });
    } else {
      window.location.href = day_study_url;
    }
  };

  getAllLessonTimeInfo(media_lessons) {
    for (var i = 0; i < media_lessons.length; i++) {
      const url = media_lessons[i].audio_info_url;
      const quiz_count = media_lessons[i].resource_quiz_count * 10;
      this.getAudioResult(url, quiz_count);
    }
  }

  getAudioResult(infourl, quiz_count) {
    request({
      url: infourl,
      method: "get",
    })
      .then((resp) => {
        const totalTime = parseInt(resp.data.format.duration);

        const showTime = formatAudioTime(totalTime + quiz_count);
        const lesson_time = { url: infourl, time: showTime };
        var lesson_times = this.state.lesson_times;
        lesson_times.push(lesson_time);

        this.setState({
          lesson_times: lesson_times,
        });
      })
      .catch((err) => {
        Raven.captureException(err);
      });
  }

  componentDidMount() {
    const trial_share_url = this.props.trial_shared_url;
    const share_image_url = this.props.share_image_url;

    const share_url = window.location.href;
    const share_title = this.props.share_title;
    const share_description = this.props.share_description;

    wx.ready(function () {
      function on_share_success() {
        // request({
        //   url: trial_share_url,
        //   headers: csrfHeaders,
        //   method: "POST"
        // })
        //   .then(resp => {
        //     reloadPage();
        //   })
        //   .catch(err => {
        //     Raven.captureException(err);
        //   });
      }
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    if (UserAgent.isMiniProgram()) {
      const urlString = convertURLToJSON(share_url);
      wx.miniProgram.postMessage({
        data: { title: share_title, link: urlString, imgUrl: share_image_url },
      });
    }
  }

  render() {
    const { media_lessons, is_spell } = this.props;

    return (
      <div>
        <div className="trail_package_head">
          <img src={this.props.main_photo_url} />
        </div>
        <div className="trial_course_box">
          <div className="trial_course_list">
            {media_lessons.map((media_lesson, i) => {
              const lesson_time = this.state.lesson_times.find(
                (lesson_time) => {
                  if (lesson_time.url == media_lesson.audio_info_url) {
                    return lesson_time;
                  }
                }
              );
              return (
                <a
                  // href={ ((i < 3) || this.props.shared) ? media_lesson.url : 'javascript:void(0)'}
                  href="javascript:void(0)"
                  data-index={i}
                  data-media-lesson-url={media_lesson.url}
                  key={media_lesson.id}
                  onClick={this.inviteListenClick}
                >
                  <div className="trial_course">
                    {/* {i < 3 || this.state.shared ? (
                      ""
                    ) : (
                      <img className="course_share_lock" src={icon_lock_gray} />
                    )} */}
                    <div className="knowledge_content">
                      <h2
                        style={{
                          paddingBottom: media_lesson.objective ? null : "5px",
                        }}
                      >
                        {media_lesson.media_course_name}：{media_lesson.name}
                      </h2>
                      {media_lesson.objective && (
                        <p>{media_lesson.objective}</p>
                      )}
                      <div className="course_subscrible_practice">
                        <div className="go_class">去上课</div>
                      </div>
                      <div className="course_time_difficulty">
                        <i>{lesson_time ? lesson_time.time : ""}</i>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}

            <a
              href="javascript:void(0)"
              data-url={this.props.today_quiz_url}
              onClick={this.inviteListenClick}
            >
              <div className="trial_course trial_exam">
                <div className="knowledge_content">
                  <h2>{is_spell ? "练一练" : "随堂测试"}</h2>
                  <div className="course_subscrible_practice">
                    <div className="go_class">去练习</div>
                  </div>
                  <div className="course_amount_time">
                    <i>{this.props.quizzes_count}道题</i>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
        {!this.props.mini_program_review && !this.props.is_ios_review ? (
          <div className="trail_package_head">
            <a href={this.props.complete_study_package_url}>
              <img src={trial_ad} />
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default class TrialPackage extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    return (
      <div className="comp-trial-package">
        <TrialPlan
          media_lessons={this.props.media_lessons}
          quizzes_count={this.props.quizzes_count}
          today_quiz_url={this.props.today_quiz_url}
          shared={this.props.shared}
          complete_study_package_url={this.props.complete_study_package_url}
          trial_shared_url={this.props.trial_shared_url}
          share_image_url={this.props.share_image_url}
          share_title={this.props.share_title}
          share_description={this.props.share_description}
          trial_order_url={this.props.trial_order_url}
          source_study_package_activity_id={
            this.props.source_study_package_activity_id
          }
          bought={this.props.bought}
          main_photo_url={this.props.main_photo_url}
          mini_program_review={this.props.mini_program_review}
          is_ios_review={this.props.is_ios_review}
        />
      </div>
    );
  }
}

TrialPackage.propTypes = {
  media_lessons: PropTypes.array,
};
