/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import WithErrorBoundary from "../utils/WithErrorBoundary.jsx";
import { ToastManager } from "../utils/Toast.jsx";
import { request, csrfHeaders } from "../utils/request";
import WrongBook from "./poetries/v2/components/WrongBook";
import styled from "styled-components";

const StyledWrongBook = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  max-height: 100vh;
  .comp_wrong_book {
    margin-top: 0;
  }
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;

const QuestionBook = ({ ...props }) => {
  console.log("QuestionBook_props: ", props);
  const {
    get_wrong_quiz_sets_url,
    get_revisal_quiz_sets_url,
    get_math_wrong_quiz_sets_url,
    get_chinese_wrong_quiz_sets_url,
    study_package_url,
    course_type,
  } = props;
  const [unitData, setUnitData] = useState(null);

  const getWrongQuizRequest = (requestUrl) => {
    if (requestUrl) {
      setUnitData(null);
      const toast = ToastManager.showLoading("加载中...");
      request({
        url: requestUrl,
        method: "GET",
        headers: csrfHeaders,
      })
        .then(({ data }) => {
          console.log("getWrongQuizRequest_data: ", data);
          setUnitData(data);
          toast.cancel();
        })
        .catch((err) => {
          setUnitData(null);
          toast.cancel();
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getWrongQuizRequest(
      props.course_type == "chinese"
        ? get_chinese_wrong_quiz_sets_url
        : props.course_type == "math"
        ? get_math_wrong_quiz_sets_url
        : get_revisal_quiz_sets_url
    );
    // getWrongQuizRequest(get_wrong_quiz_sets_url);
  }, []);

  return (
    <>
      <StyledWrongBook id="_scroll_list">
        {unitData ? (
          <WrongBook
            unitData={unitData}
            get_wrong_quiz_sets_url={get_wrong_quiz_sets_url}
            get_revisal_quiz_sets_url={get_revisal_quiz_sets_url}
            study_package_url={study_package_url}
            isShowFloat={true}
            {...props}
          />
        ) : (
          ""
        )}
      </StyledWrongBook>
    </>
  );
};

export default WithErrorBoundary(QuestionBook);
