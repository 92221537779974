/* eslint-disable react/prop-types */
import React, { memo, useState, useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import Picker from "better-picker";
import { request, csrfHeaders } from "../utils/request";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import { ToastManager } from "../utils/Toast.jsx";
import UserAgent from "../utils/UserAgent";
import { getCurrentDateNextSevenDays } from "../utils/formatAudioTime";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
import classNames from "classnames";

import checkbox_active from "personalized_schedule/icon_active.png";
import checkbox_normal from "personalized_schedule/icon_normal.png";
import icon_play_pen from "personalized_schedule/icon_pen.png";

import "./Setting.scss";

const DateHourData = [
  { text: "不提醒", value: -1 },
  { text: "00 : 00", value: 0 },
  { text: "01 : 00", value: 1 },
  { text: "02 : 00", value: 2 },
  { text: "03 : 00", value: 3 },
  { text: "04 : 00", value: 4 },
  { text: "05 : 00", value: 5 },
  { text: "06 : 00", value: 6 },
  { text: "07 : 00", value: 7 },
  { text: "08 : 00", value: 8 },
  { text: "09 : 00", value: 9 },
  { text: "10 : 00", value: 10 },
  { text: "11 : 00", value: 11 },
  { text: "12 : 00", value: 12 },
  { text: "13 : 00", value: 13 },
  { text: "14 : 00", value: 14 },
  { text: "15 : 00", value: 15 },
  { text: "16 : 00", value: 16 },
  { text: "17 : 00", value: 17 },
  { text: "18 : 00", value: 18 },
  { text: "19 : 00", value: 19 },
  { text: "20 : 00", value: 20 },
  { text: "21 : 00", value: 21 },
  { text: "22 : 00", value: 22 },
  { text: "23 : 00", value: 23 },
];
const weeklyDays = [
  { id: 1, week_name: "周一" },
  { id: 2, week_name: "周二" },
  { id: 3, week_name: "周三" },
  { id: 4, week_name: "周四" },
  { id: 5, week_name: "周五" },
  { id: 6, week_name: "周六" },
  { id: 7, week_name: "周日" },
];
const durationLists = [
  { id: 1, time: "15" },
  { id: 2, time: "30" },
  // { id: 3, time: "45" },
  // { id: 4, time: "60" },
  // { id: 5, time: "90" },
  // { id: 6, time: "120" },
];
const NUMBER = ["日", "一", "二", "三", "四", "五", "六"];

const CourseFrequencyContianer = styled.div`
  padding-bottom: 60px;
  background: #fff;
  min-height: 100vh;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;
const ExclusivePlanText = styled.div`
  font-size: 20px;
  text-align: center;
  font-weight: 700;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #17de26;
`;
const TitleDescribe = styled.div`
  text-align: center;
  margin-top: 20px;
`;
const ProgressBarContainer = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  height: 6px;
  background-color: #dcdcdc;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2);
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 6px;
    background-color: #17de26;
  }
`;
const WelcomeContainer = styled.div`
  margin: 30% 15px 0;
  .welcome_head_box {
    display: inline-flex;
    align-items: center;
    position: relative;
    .head_info {
      position: relative;
      height: 56px;
      width: 56px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        z-index: 3;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -25px;
        margin-top: -25px;
      }
      &::before {
        content: "";
        width: 56px;
        height: 56px;
        background: #b4f3f7;
        position: absolute;
        border-radius: 50%;
        z-index: 2;
      }
    }
    .head_title_text {
      height: 40px;
      background: #9be8ff;
      line-height: 40px;
      padding: 0 15px 0 30px;
      border-radius: 50px;
      margin-left: -25px;
    }
  }
  p {
    padding-bottom: 15px;
    font-weight: 700;
    font-size: 16px;
  }
`;
const ButtonContainer = styled.div`
  position: fixed;
  width: calc(100% - 20px);
  bottom: 20px;
  margin: 0 auto;
  left: 10px;
  z-index: 5;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: calc(500px - 20px);
    left: 50%;
    margin-left: -240px;
  }
`;
const ButtonBox = styled.div`
  display: flex;
`;
const ButtonContent = styled.div`
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: #17de26;
  color: #fff;
  border-radius: 50px;
  flex: 1;
  margin: 0 auto;
  box-shadow: 0 10px 20px 0 rgba(73, 193, 20, 0.3);
  &.half_width_button {
    flex: inherit;
    width: 60%;
  }
  &.pre_button {
    border: 1px solid #9be8ff;
    background: #fff;
    color: #87c0dc;
    box-shadow: 0 10px 20px 0 rgba(135, 192, 220, 0.3);
    margin-right: 15px;
  }
  &.required {
    background: #ddd;
    box-shadow: 0 10px 20px 0px rgba(221, 221, 221, 0.3);
  }
`;

const SubTitle = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 10px 15px;
  .sub_content {
    text-align: left;
    padding-left: 6px;
    .write_day {
      color: #ff5c2a;
    }
    .alert {
      font-size: 12px;
      color: #7e7878;
    }
  }
`;
const ChooseStudySectionContainer = styled.div`
  .choose_study_durtaion {
    margin: 10px 0 20px;
    .duration_lists_box {
      display: flex;
      width: 100%;
      margin-bottom: 15px;
    }
    .duration_brief {
      display: flex;
      color: #9f9fa0;
      margin: 0 15px;
      font-size: 13px;
      i {
        padding-top: 2px;
      }
      span {
        padding-left: 8px;
      }
    }
  }
  .choose_study_time {
    .choose_time_lists {
      display: flex;
      width: 100%;
      align-items: center;
      padding-right: 10px;
    }
    .study_time_scope {
      font-size: 13px;
      text-align: center;
      padding-top: 20px;
      span {
        color: #ff5c2a;
        font-weight: 700;
      }
    }
    .study_done_week {
      display: inline-flex;
      width: 100%;
      .done_week {
        position: relative;
        text-align: center;
        margin: 0 auto;
        z-index: 1;
        font-size: 13px;
        &::after {
          content: "";
          position: absolute;
          bottom: 2px;
          width: 100%;
          height: 6px;
          border-radius: 50px;
          background-color: #9be8ff;
          left: 0;
          z-index: -1;
        }
        span {
          font-weight: 700;
          color: #ff5c2a;
        }
      }
    }
  }
`;

const CcContainer = styled.div`
  position: relative;
  display: flex;
  margin: 30px 15px;
  .cc_head_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .img_info_box {
      width: 68px;
      height: 68px;
      background: #9be8ff;
      position: relative;
      border-radius: 50%;
      .cc_photo {
        width: 60px;
        height: 60px;
        position: absolute;
        left: 50%;
        margin-left: -30px;
        top: 50%;
        margin-top: -30px;
        border-radius: 50%;
      }
    }
    .cc_title {
      font-size: 12px;
      white-space: nowrap;
      margin-top: -22px;
      text-align: center;
      background: #9be8ff;
      border-radius: 50px;
      padding: 0 5px;
      height: 25px;
      line-height: 25px;
      position: relative;
      min-width: 85px;
    }
  }
  .right_message {
    margin-left: 8px;
    width: 100%;
    .message {
      background-color: #f0f0f0;
      border-radius: 6px;
      padding: 6px 10px 6px 12px;
      position: relative;
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1);
      min-height: 60px;
      p {
        font-size: 13px;
        padding-bottom: 10px;
        span {
          font-weight: 700;
          color: #ff5c2a;
        }
        &.last_p {
          padding-bottom: 0;
        }
      }
      .left_arrow {
        position: absolute;
        top: 20px;
        left: -18px;
        &:before {
          content: "";
          position: absolute;
          top: 0px;
          left: 2px;
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-right: 10px solid #f0f0f0;
          z-index: 1;
        }
        &:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border: 8px solid transparent;
          border-right: 10px solid #ebe8e8;
        }
      }
    }
  }
`;

const DurationList = styled.div`
  border: 1px solid #bbe7fb;
  ${({ highlight }) =>
    highlight
      ? {
          backgroundColor: "#17de26",
          color: "#fff",
          border: "1px solid #17de26",
        }
      : {}}
  border-radius: 50px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  flex: 1;
  min-width: 110px;
  margin-left: 10px;
  box-shadow: 0 10px 5px -5px rgba(187, 231, 251, 0.3);
  &:last-child {
    margin-right: 10px;
  }
`;
const ChooseTimeList = styled.div`
  border: 1px solid #bbe7fb;
  ${({ highlight }) =>
    highlight
      ? {
          backgroundColor: "#17de26",
          color: "#fff",
          border: "1px solid #17de26",
        }
      : {}}
  ${({ invalid }) =>
    invalid
      ? {
          background: "#ddd",
          border: "1px solid #c6c6c6",
          color: "#fff",
          cursor: "not-allowed",
        }
      : {}}
  border-radius: 50px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  flex: 1;
  margin-left: 10px;
  box-shadow: 0 1px 5px 0px rgba(113, 180, 210, 0.3);
  &:last-child {
    margin-right: 10px;
  }
`;

const ChooseWeeklyDaySectionContainer = styled.div`
  .choose_weekly_day_box {
    padding: 0 0 60px;
    .choose_weekly_days {
      display: flex;
    }
  }
  .choose_alert_time {
    padding: 30px 0 60px;
    .alert_time {
      text-align: center;
      .current_time_box {
        position: relative;
        display: inline-flex;
        .current_time {
          display: inline-flex;
          align-items: center;
          font-size: 20px;
          border-bottom: 1px dashed #9be8ff;
          span {
            font-size: 20px;
          }
        }
        i {
          background: url(${icon_play_pen}) no-repeat right center / 18px;
          width: 30px;
          height: 30px;
          display: block;
          margin-left: 7px;
          position: absolute;
          right: -25px;
          bottom: -1px;
        }
      }
    }
  }
  .divide_line {
    width: 80%;
    height: 1px;
    border: 0.5px solid #ddd;
    margin: 30px auto 0;
  }
`;

const ChooseWeeklyDayList = styled.div`
  height: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-left: 10px;
  &:last-child {
    margin-right: 10px;
  }
  img {
    min-width: 24px;
    width: 24px;
    min-height: 24px;
    height: 24px;
    display: block;
  }
  .week_day_text {
    padding-top: 5px;
  }
`;

const ButtonFactory = ({ buttonArr }) => {
  return (
    <ButtonContainer>
      <ButtonBox>
        {buttonArr.map((item, index) => (
          <ButtonContent
            key={index}
            className={`${item.extendedClass ?? ""}`}
            onClick={item.action}
          >
            {item.text}
          </ButtonContent>
        ))}
      </ButtonBox>
    </ButtonContainer>
  );
};
const ProgressBar = memo(({ progressPercent }) => {
  return (
    <ProgressBarContainer>
      <div className="progress" style={{ width: `${progressPercent}%` }}></div>
    </ProgressBarContainer>
  );
});
const Welcome = memo(({ start, courseConsultant, baby }) => {
  return (
    <WelcomeContainer>
      <div className="welcome_head_box">
        <div className="head_info">
          <img src={courseConsultant?.photo_url} alt="" />
        </div>
        <div className="head_title_text">{courseConsultant?.name}</div>
      </div>
      <>
        <p>
          Hi，很高兴见到你！我是{baby.nickname}
          的专属课程老师，学习过程中遇到任何问题都可以联系我。
        </p>
        <p>
          为了更好的学习效果，请你根据{baby.nickname}的时间，定制{baby.nickname}
          专属的学习计划安排。
        </p>
      </>
      <ButtonFactory buttonArr={[{ action: start, text: "开始" }]} />
    </WelcomeContainer>
  );
});

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const ChooseStudySection = memo(
  ({
    nextQuestion,
    next,
    getStudySectionData,
    totalStudyMinutesTime,
    config,
    courseConsultant,
    trial,
  }) => {
    // console.log("nextQuestion: ", nextQuestion);
    const [minutesPerTime, setMinutesPerTime] = useState(
      config?.minutes_per_time || 0
    );
    const [validCount, setValidCount] = useState([]);
    const [dayPerWeek, setDayPerWeek] = useState(config?.days_per_week || -1);
    const [studyWeekTime, setStudyWeekTime] = useState("-");

    const prevDayPerWeek = usePrevious(dayPerWeek);
    const handleDurationList = useCallback((e) => {
      const time = e.currentTarget.getAttribute("data-time");
      setMinutesPerTime(time);
      setDayPerWeek(-1);
      setStudyWeekTime("-");
    }, []);
    const handleTimeList = useCallback((e) => {
      const number = e.currentTarget.getAttribute("data-number");
      setDayPerWeek(number);
    }, []);

    useEffect(() => {
      const leftTime = ~~(30 / minutesPerTime);
      const rightTime = ~~(120 / minutesPerTime);
      const arr = [];
      for (let i = leftTime; i <= rightTime; i++) {
        arr.push(i);
        if (arr[i] == 0) {
          arr.shift();
        }
      }
      setValidCount(arr);
      if (
        minutesPerTime > 0 &&
        dayPerWeek >= 0 &&
        dayPerWeek != prevDayPerWeek
      ) {
        const lastStudyWeekTime = Math.ceil(
          totalStudyMinutesTime / (dayPerWeek * minutesPerTime)
        );
        setStudyWeekTime(lastStudyWeekTime);
        getStudySectionData(minutesPerTime, dayPerWeek);
        console.log(
          "minutesPerTime, dayPerWeek, lastStudyWeekTime: ",
          minutesPerTime,
          dayPerWeek,
          lastStudyWeekTime
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      getStudySectionData,
      minutesPerTime,
      dayPerWeek,
      minutesPerTime,
      prevDayPerWeek,
      nextQuestion,
    ]);
    const copy = [...durationLists];
    const splitedList = [];
    while (copy.length) {
      const sliced = copy.splice(0, 3);
      splitedList.push({
        value: sliced,
      });
    }

    const operateValidCount = (number) => {
      for (let i = 0; i < validCount.length; i++) {
        const ele = validCount[i];
        if (ele == number) {
          return false;
        }
      }
      return true;
    };
    const required = minutesPerTime < 0 || dayPerWeek < 0;
    return (
      <ChooseStudySectionContainer>
        <TitleDescribe>
          <ExclusivePlanText>定制专属学习计划安排</ExclusivePlanText>
          <Title>学习时间设置</Title>
        </TitleDescribe>
        <div className="choose_study_durtaion">
          <SubTitle>
            <i>1/4</i>
            <div className="sub_content">请选择每次学习时长</div>
          </SubTitle>
          {splitedList.map((list, index) => (
            <div className="duration_lists_box" key={index}>
              {list.value.map((item, idx) => (
                <DurationList
                  key={idx}
                  data-id={item.id}
                  data-time={item.time}
                  onClick={handleDurationList}
                  highlight={item.time == minutesPerTime}
                  style={{ flex: "initial" }}
                >
                  {item.time >= 60
                    ? `${item.time / 60}小时`
                    : `${item.time}分钟`}
                </DurationList>
              ))}
            </div>
          ))}
          {minutesPerTime >= 60 && (
            <div className="duration_brief">
              <i>* </i>
              <span>
                {`连续上课${
                  minutesPerTime / 60
                }小时强度较大，系统将会为孩子拆成一天${
                  minutesPerTime / 30
                }次课，每次30分钟。`}
              </span>
            </div>
          )}
        </div>
        <div className="choose_study_time">
          <SubTitle>
            <i>2/4</i>
            <div className="sub_content">请选择每周学习天数</div>
          </SubTitle>
          <div className="choose_time_lists">
            {[1, 2, 3, 4, 5, 6, 7].map((number, index) => (
              <ChooseTimeList
                key={index}
                data-number={number}
                onClick={
                  operateValidCount(number) == true ? null : handleTimeList
                }
                highlight={number == dayPerWeek}
                invalid={operateValidCount(number)}
              >
                {number}
              </ChooseTimeList>
            ))}
            天
          </div>
          <div className="study_time_scope">
            每周最少学习<span>30分钟</span>，最多<span>2小时</span>
          </div>
          {!trial && (
            <div className="study_done_week">
              <div className="done_week">
                根据设置，大约需要
                <span> {studyWeekTime} </span>周学完
              </div>
            </div>
          )}
          <CourseConsultant courseConsultant={courseConsultant} />
          <ButtonFactory
            buttonArr={[
              {
                action: required ? null : next,
                text: "下一步",
                extendedClass: classNames("half_width_button", {
                  required: required,
                }),
              },
            ]}
          />
        </div>
      </ChooseStudySectionContainer>
    );
  }
);

const CourseConsultant = memo(({ courseConsultant, flag }) => {
  const { name, photo_url } = courseConsultant;
  return (
    <CcContainer>
      <div className="cc_head_info">
        <div className="img_info_box">
          <img src={photo_url} alt="" className="cc_photo" />
        </div>
        <div className="cc_title">{name}</div>
      </div>
      <div className="right_message">
        <div className="message">
          {flag ? (
            <p className="last_p">
              本设置根据需求，<span>每个月</span>可调整1次。
              <br />
              <br />
              如有问题，请随时联系我。
            </p>
          ) : (
            <>
              <p>
                <span>短时</span>+<span>多次</span>的学习效果最佳哦~
              </p>
              <p className="last_p">
                建议以下两种上课方案之一：
                <br />
                1. 每次学习<span>15</span>分钟，每周
                <span>5</span>天
                <br />
                2. 每次学习<span>30</span>分钟，每周<span>3</span>天
              </p>
            </>
          )}
          <div className="left_arrow"></div>
        </div>
      </div>
    </CcContainer>
  );
});

const ChooseWeeklyDaySection = ({
  prev,
  submit,
  getWeeklyDaySection,
  count,
  reminderTimeUrl,
  config,
  reminderHour,
  courseConsultant,
  trial,
}) => {
  const extra_days_week = config?.days_in_week;

  const week = new Array(7).fill(false);
  // 构建当前一周
  const nextSevenDays = getCurrentDateNextSevenDays(new Date());
  const nextSevenDayArr = [];
  for (let i = 0; i < nextSevenDays.length; i++) {
    const singleDay = new Date(nextSevenDays[i]).getDay();
    nextSevenDayArr.push({
      id: singleDay == 0 ? 7 : singleDay,
      week_name: `周${NUMBER[singleDay]}`,
      date: nextSevenDays[i],
    });
  }

  const lastWeeklyDays = trial ? nextSevenDayArr : weeklyDays;
  // console.log("lastWeeklyDays", lastWeeklyDays);

  // 双层遍历，寻找两个数组中相同的id，如果相等设为true
  if (extra_days_week) {
    week.forEach((element, index) => {
      extra_days_week.forEach((ele, idx) => {
        if (element == ele) {
          week[index] = true;
        }
      });
    });
  }

  const [chooseWeeklyInfo, setChooseWeeklyInfo] = useState(() => {
    return (
      config?.days_in_week?.reduce((acc, cur) => {
        acc[cur - 1] = true;
        return acc;
      }, week) || week
    );
  });
  const [flag, setFlag] = useState(false);
  const [activeCount, setActiveCount] = useState(0);

  const handleWeekDayList = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    setFlag(!flag);
    setChooseWeeklyInfo((pre) => {
      pre[id] = !pre[id];
      return pre;
    });
  };

  const reminderTimeHour = reminderHour || 18;
  const configReminderTimeUrl = reminderTimeUrl;

  const setRemainderPicker = useCallback(
    (dom) => {
      const picker = new Picker({
        data: [DateHourData],
        selectedIndex: [reminderTimeHour + 1],
        title: "",
      });
      picker.on("picker.select", function (selectedVal, selectedIndex) {
        dom.innerText = DateHourData[selectedIndex[0]].text;

        if (selectedIndex[0] - 1 != reminderTimeHour) {
          const params = {
            reminder_hour: selectedIndex[0] - 1,
          };
          const url = configReminderTimeUrl;
          request({
            url: url,
            method: "POST",
            headers: csrfHeaders,
            data: params,
          })
            .then((resp) => {
              console.log("正确返回");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });

      if (reminderTimeHour >= -1) {
        const time_setup_click = document.getElementById("time_setup_click");

        if (time_setup_click) {
          time_setup_click.addEventListener("click", function () {
            picker.show();
            if (UserAgent.isNativeClient()) {
              XinYaNativeBridge.requestAuthorization({
                authType: "notification",
              });
            }
          });
        }
      }
    },
    [configReminderTimeUrl, reminderTimeHour]
  );
  useEffect(() => {
    const pickerDom = document.getElementById("reminder_picker");
    setRemainderPicker(pickerDom);
  }, [setRemainderPicker]);

  useEffect(() => {
    const new_arr = [];
    chooseWeeklyInfo.filter((item, index) => item && new_arr.push(index));
    console.log("new_arr: ", new_arr);
    setActiveCount(new_arr);
    getWeeklyDaySection(new_arr);
  }, [chooseWeeklyInfo, getWeeklyDaySection, flag]);

  return (
    <ChooseWeeklyDaySectionContainer>
      <TitleDescribe>
        <Title>学习提醒设置</Title>
      </TitleDescribe>
      <div className="choose_weekly_day_box">
        <SubTitle>
          <i>3/4</i>
          <div className="sub_content">
            请选择每周学习日期（
            <span className="write_day">{`${count}天`}</span>）
          </div>
        </SubTitle>
        <div className="choose_weekly_days">
          {lastWeeklyDays.map((week, index) => (
            <ChooseWeeklyDayList
              key={`${week.id}`}
              data-id={week.id}
              onClick={handleWeekDayList}
            >
              <img
                src={
                  chooseWeeklyInfo[week.id] ? checkbox_active : checkbox_normal
                }
                alt=""
              />
              <div className="week_day_text">{week.week_name}</div>
              {trial && (
                <div>
                  {`${new Date(week.date).getMonth() + 1}/${new Date(
                    week.date
                  ).getDate()}`}
                </div>
              )}
            </ChooseWeeklyDayList>
          ))}
        </div>
      </div>
      <div className="choose_alert_time">
        <SubTitle>
          <i>4/4</i>
          <div className="sub_content">
            请选择学习当天的提醒时间
            <span className="alert">（将通过微信公众号消息提醒）</span>
          </div>
        </SubTitle>
        <div className="alert_time" id="time_setup_click">
          <div className="current_time_box">
            <div className="current_time" id="reminder_picker">
              {reminderTimeHour === -1 ? "不提醒" : `${reminderTimeHour} : 00`}
            </div>
            <i></i>
          </div>
        </div>
      </div>
      {/* <div className="divide_line"></div> */}
      <CourseConsultant courseConsultant={courseConsultant} flag={true} />
      <ButtonFactory
        buttonArr={[
          { action: prev, text: "上一步", extendedClass: "pre_button" },
          {
            action: submit,
            text: "确认",
            extendedClass: classNames({
              required: activeCount.length == 0 || activeCount.length != count,
            }),
          },
        ]}
      />
    </ChooseWeeklyDaySectionContainer>
  );
};
export default (props) => {
  console.log("props_new_setting: ", props);

  const {
    baby,
    config_reminder_time_url,
    config_freq_url,
    total_study_time_in_minutes,
    trial,
    config,
    reminder_hour,
    configged,
    course_consultant,
  } = props;
  const firstModule = (
    // eslint-disable-next-line no-use-before-define
    <Welcome start={start} courseConsultant={course_consultant} baby={baby} />
  );
  const [nextQuestion, setNextQuestion] = useState(0);
  const [frenquencyArr, setFrenquencyArr] = useState(firstModule);

  const refCount = useRef();
  const refRequestStudy = useRef();
  const refRequestWeek = useRef();

  const start = useCallback(() => {
    setNextQuestion(refCount.current);
  }, []);
  const next = useCallback(() => {
    setNextQuestion(refCount.current);
  }, []);

  const prev = useCallback(() => {
    setNextQuestion(refCount.current - 2);
  }, []);

  const submit = useCallback(() => {
    const studyInfo = refRequestStudy.current;
    const weekInfo = refRequestWeek.current || [];
    console.log("studyInfo_weekInfo: ", studyInfo, weekInfo);
    if (weekInfo.length > studyInfo?.days_per_week) {
      alert(`只能选择${studyInfo.days_per_week}天上课时间`);
    } else if (weekInfo.length < studyInfo?.days_per_week) {
      alert(`请选择${studyInfo.days_per_week}天上课时间`);
    } else {
      const toast = ToastManager.showLoading("个性化课表生成中...");
      const url = config_freq_url;
      request({
        url: url,
        method: "POST",
        headers: csrfHeaders,
        data: {
          ...refRequestStudy.current,
          days_in_week: [...refRequestWeek.current].map((item) =>
            parseInt(item)
          ),
        },
      })
        .then((resp) => {
          console.log("resp: ", resp);
          const redirect_url = resp?.data?.redirect_url;
          if (redirect_url) {
            window.location.href = redirect_url;
            return;
          }
          toast.cancel();
        })
        .catch((err) => {
          console.log(err);
          toast.cancel();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStudySectionData = (minutesPerTime, dayPerWeek) => {
    const arr_study = {
      minutes_per_time: minutesPerTime,
      days_per_week: dayPerWeek,
    };
    refRequestStudy.current = arr_study;
    return arr_study;
  };
  const getWeeklyDaySection = (weekly_arr) => {
    refRequestWeek.current = weekly_arr;
    return weekly_arr;
  };

  const count = refRequestStudy.current?.days_per_week;

  const frenquencyCollection = [
    // {
    //   // 页面一仅在用户首次该课程设置的时候有弹出
    //   module: !configged ? (
    //     <Welcome
    //       start={start}
    //       courseConsultant={course_consultant}
    //       baby={baby}
    //     />
    //   ) : (
    //     ""
    //   ),
    // },
    {
      module: (
        <ChooseStudySection
          nextQuestion={nextQuestion}
          next={next}
          getStudySectionData={getStudySectionData}
          totalStudyMinutesTime={total_study_time_in_minutes}
          config={config}
          courseConsultant={course_consultant}
          trial={trial}
        />
      ),
    },
    {
      module: (
        <ChooseWeeklyDaySection
          prev={prev}
          submit={submit}
          getWeeklyDaySection={getWeeklyDaySection}
          count={count}
          reminderTimeUrl={config_reminder_time_url}
          config={config}
          reminderHour={reminder_hour}
          courseConsultant={course_consultant}
          trial={trial}
        />
      ),
    },
  ].filter((item) => item.module != "");
  const collectLength = frenquencyCollection.length;

  useEffect(() => {
    refCount.current = nextQuestion + 1;
    setFrenquencyArr(frenquencyCollection[nextQuestion]?.module);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextQuestion]);

  const percent = Math.ceil((100 / collectLength) * (nextQuestion + 1));

  const useUpdateScheduleModal = () => {
    return new ModalManager({
      render: (_, modalClose) => {
        const onClose = () => {
          modalClose();
        };
        return (
          <AdjustScheduleTimes
            onModalClose={onClose}
            courseConsultant={course_consultant}
          />
        );
      },
    });
  };

  useEffect(() => {
    const updateScheduleModal = useUpdateScheduleModal();
    if (configged) {
      updateScheduleModal.open();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CourseFrequencyContianer>
      <ProgressBar progressPercent={percent} />
      {frenquencyArr}
    </CourseFrequencyContianer>
  );
};

const AdjustScheduleTimes = ({ onModalClose, courseConsultant }) => {
  return (
    <div className="guide_adjust_schedule">
      <div className="dialog_qrcode_body">
        {/* <div className="btn-close" onClick={() => onModalClose()}>
          &times;
        </div> */}
        <p>您本月已调整过，紧急情况需要调整，</p>
        <p>请联系您的专属课程老师为您调整。</p>
        <img
          src={courseConsultant.qrcode_url}
          className="lazyload"
          data-sizes="auto"
        />
      </div>
    </div>
  );
};
