import Picker from "better-picker";
import { request, csrfHeaders } from "../request";
import UserAgent from "../UserAgent";

const date_hour_data = [
  { text: "不提醒", value: -1 },
  { text: "00点", value: 0 },
  { text: "01点", value: 1 },
  { text: "02点", value: 2 },
  { text: "03点", value: 3 },
  { text: "04点", value: 4 },
  { text: "05点", value: 5 },
  { text: "06点", value: 6 },
  { text: "07点", value: 7 },
  { text: "08点", value: 8 },
  { text: "09点", value: 9 },
  { text: "10点", value: 10 },
  { text: "11点", value: 11 },
  { text: "12点", value: 12 },
  { text: "13点", value: 13 },
  { text: "14点", value: 14 },
  { text: "15点", value: 15 },
  { text: "16点", value: 16 },
  { text: "17点", value: 17 },
  { text: "18点", value: 18 },
  { text: "19点", value: 19 },
  { text: "20点", value: 20 },
  { text: "21点", value: 21 },
  { text: "22点", value: 22 },
  { text: "23点", value: 23 },
];

/*
usage: settingReminder({ 
  remindTime: reminder_time_hour, // 已经设置的提醒时间
  postUrl: config_reminder_time_url,  // 提交的url
  targetEleId: 'time_setup_click'  //  挂载的容器
})
 */
export const settingReminder = (obj) => {
  let reminder_time_hour = obj.remindTime;
  let config_reminder_time_url = obj.postUrl;
  let time_setup_click = obj.targetEleId;

  if (!config_reminder_time_url || !time_setup_click) {
    throw new Error("Error in passed element for Picker Reminder");
  }

  // 提醒设置
  const picker = new Picker({
    data: [date_hour_data],
    selectedIndex: [reminder_time_hour + 1],
    title: "",
  });
  picker.on("picker.select", function (selectedVal, selectedIndex) {
    console.log(selectedIndex - 1);
    // nameEl.innerText = date_hour_data[selectedIndex[0]].text;

    console.log(
      "古诗词提醒时间选择：",
      selectedIndex - 1,
      "后台数据返回的值：",
      reminder_time_hour
    );
    if (selectedIndex - 1 != reminder_time_hour) {
      let params = {
        reminder_hour: selectedIndex[0] - 1,
      };
      console.log("params: ", params["reminder_hour"]);
      var url = config_reminder_time_url;
      request({
        url: url,
        method: "POST",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {
          // const data = resp.data
          console.log("正确返回");
        })
        .catch((err) => {
          console.log("错误返回");
          console.log(err);
        });
    }
  });

  picker.on("picker.change", function (index, selectedIndex) {
    // console.log(index);
    // console.log(selectedIndex - 1);
  });

  picker.on("picker.valuechange", function (selectedVal, selectedIndex) {
    // console.log(selectedVal);
    // console.log(selectedIndex - 1);
  });

  // 值不为 -1 的情况下，需注意 reminder_time_hour == 0 为假值的情况判断
  if (reminder_time_hour >= -1) {
    const click = document.getElementById(time_setup_click);
    click.addEventListener("click", function () {
      let pickerContainer = document.getElementsByClassName("picker")[0];
      pickerContainer.style.cssText = "z-index:1000000";
      picker.show();

      if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.requestAuthorization({ authType: "notification" });
      }
    });
  }
};
