import React from "react";
import PropTypes from "prop-types";

import ReactMarkdown from "react-markdown";

import "./StudyPackageHandouts.scss";
import icon_lock_gray from "study_package/icon_lock_gray.png";

export default class StudyPackageHandouts extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  componentDidMount() {
    const share_url = this.props.share_url;
    const share_image_url = this.props.share_image_url;
    const date = this.props.date;

    const title = date + "课程讲义";
    const desc = date + "小朋友练习成绩及课程知识点讲义";

    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: title,
        link: share_url,
        desc: desc,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  }

  render() {
    const media_lessons = this.props.media_lessons;
    const score_stars = ["", "🌟", "🌟🌟", "🌟🌟🌟"];

    return (
      <div className="comp-study-package-handouts">
        {this.props.day_study == true ? (
          <div className="score_amount">
            {this.props.date}有{this.props.finished_user_count}
            人完成学习，其中获得全三星的有{this.props.great_score_user_count}
            人。
          </div>
        ) : (
          ""
        )}
        {this.props.day_study == true ? (
          <div>
            {media_lessons.map((media_lesson, idx) => (
              <div className="handouts_list" key={idx}>
                <div className="handouts_name">
                  <p>
                    {media_lesson.media_course_name}：
                    {media_lesson.media_lesson_name}
                  </p>
                </div>
                <div className="handouts_desc_content">
                  <div className="handouts_amount_total">
                    所有孩子练习成绩统计：
                    <br />
                    {media_lesson.ranks.map((rank, i) => (
                      <span key={i}>
                        {score_stars[rank.rank]}
                        {rank.quiz_rank_count}人{i < 2 ? "、" : ""}
                      </span>
                    ))}
                  </div>
                  {media_lesson.current_baby ? (
                    <div className="handouts_user_amount">
                      <div className="handouts_baby_record">
                        {media_lesson.current_baby.quiz_rank ? (
                          <p>
                            {this.props.baby_nickname}练习成绩：
                            {score_stars[media_lesson.current_baby.quiz_rank]}
                          </p>
                        ) : (
                          <div>{this.props.baby_nickname}还未做练习</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="user_no_finsh">
                      {this.props.baby_nickname}还未进行学习
                    </div>
                  )}
                  <h3>知识点讲解：</h3>
                  <ReactMarkdown source={media_lesson.conclusion} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="handouts_no_finish">
            {this.props.baby_nickname}休息一下，当前没有课程学习
          </div>
        )}

        <div className="handouts_days_switch">
          <a href={this.props.prev_day_url} className="btn pre_day">
            前一天
          </a>
          {this.props.next_day_url ? (
            <a href={this.props.next_day_url} className="btn next_day">
              后一天
            </a>
          ) : (
            <a href="javascript:void(0)" className="next_day_lock">
              <img src={icon_lock_gray} />
            </a>
          )}
        </div>
      </div>
    );
  }
}

StudyPackageHandouts.propTypes = {
  media_lessons: PropTypes.array,
};
