import React, { memo, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import QueryString from "query-string";
import URI from "jsuri";
import { SharePosterProxy } from "../../../promotion_activities/SharePoster";
import WithErrorBoundary from "../../../utils/WithErrorBoundary.jsx";
import WithPlatformEnv from "../../../utils/WithPlatformEnv.jsx";

import { onMiniProgram, defaultFunc } from "../../../utils/helpers";
import {
  AudioCommunity,
  PoetryReading,
  LessonDetailsItem,
  usePoetryDetailInstallApp,
  InstallAppReminder,
} from "./components";
import ListContainer from "../../../utils/ListContainer/ListContainer.jsx";

import "./Poetry.scss";

const PoetryArticles = memo(({ isUnitReview, poetryReadings }) => {
  return (
    <React.Fragment>
      {poetryReadings && poetryReadings.length > 0 && (
        <div
          className={`section_title_common ${
            isUnitReview ? "unit_readings" : "reading_recommendations"
          }`}
        ></div>
      )}
      <div className="poetry_articles_container">
        <ListContainer
          list={poetryReadings}
          listItem={({ itemIndex, ...reading }) => (
            <PoetryReading
              key={itemIndex}
              isUnitReview={isUnitReview || poetryReadings.length > 1}
              {...reading}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
});

PoetryArticles.propTypes = {
  isUnitReview: PropTypes.bool,
  poetryReadings: PropTypes.array,
};

const AudiosTogether = memo(
  ({ community, poetryName, systemTime, courseFinishCondition, poetryTap }) => {
    return (
      <React.Fragment>
        <div className="section_title_common learning_together_learn"></div>
        <div className="learning_together">
          {community && (
            <AudioCommunity
              community={community}
              poetryName={poetryName}
              systemTime={systemTime}
              courseFinishCondition={courseFinishCondition}
              poetryTap={poetryTap}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
);

AudiosTogether.propTypes = {
  community: PropTypes.object,
  poetryName: PropTypes.string,
  systemTime: PropTypes.string,
  courseFinishCondition: PropTypes.bool,
  poetryTap: PropTypes.func,
};

const BottomFixedButtons = memo(
  ({
    studyReportUrl,
    babyName,
    posterTemplate,
    posterCreation,
    fixedBottomOffset,
  }) => {
    const cardShareClick = () => {
      new SharePosterProxy({
        posterTemplate,
        posterCreation,
        mode: "front_end",
        free: "poetry_share",
        nickName: babyName,
        isNewPoetry: true,
      }).open();
    };

    return (
      <div className="bottom_fixed" style={{ bottom: fixedBottomOffset || 0 }}>
        <div className="flex_wrapper">
          <a
            className="bt_common study_report"
            href={`${studyReportUrl}&ref=daily_course`}
          >
            学习报告
          </a>
          {posterTemplate && (
            <div className="bt_common poster" onClick={cardShareClick}>
              打卡海报
            </div>
          )}
        </div>
      </div>
    );
  }
);

BottomFixedButtons.propTypes = {
  posterTemplate: PropTypes.string,
  posterCreation: PropTypes.string,
  studyReportUrl: PropTypes.string,
  babyName: PropTypes.string,
  fixedBottomOffset: PropTypes.number,
};

const ListTitle = memo(
  ({
    isUnitReview,
    subName,
    poetryName,
    author,
    studyType,
    mediaCourseName,
  }) => {
    if (isUnitReview) {
      return (
        <div className="unit_review_title">
          <p className="title_name">{poetryName}</p>
        </div>
      );
    }
    if (studyType === 1) {
      return (
        <div className="list_title">
          <p className="title_name margin_set">{mediaCourseName}</p>
          <div className="wrapper">
            <div className="poetry_index">{subName}</div>
            <div className="poetry_sub">{poetryName}</div>
          </div>
        </div>
      );
    }
    return (
      <div className="list_title">
        <p className="title_name">{poetryName}</p>
        <p className="author">{author}</p>
        <div className="poetry_index">{subName}</div>
      </div>
    );
  }
);

ListTitle.propTypes = {
  isUnitReview: PropTypes.bool,
  subName: PropTypes.string,
  poetryName: PropTypes.string,
  author: PropTypes.string,
  studyType: PropTypes.number,
  mediaCourseName: PropTypes.string,
};

const ReviewLessons = memo(
  ({ reviewMediaLesson, onSetRemindInstallApp, reminderInstallAppModal }) => {
    return (
      <ListContainer
        list={reviewMediaLesson}
        listItem={({ itemIndex, ...reviewLesson }) => (
          <LessonDetailsItem
            {...reviewLesson}
            key={itemIndex}
            isReviewLesson
            onSetRemindInstallApp={
              itemIndex === 0 ? onSetRemindInstallApp : defaultFunc
            }
            reminderInstallAppModal={
              itemIndex === 0 ? reminderInstallAppModal : defaultFunc
            }
          />
        )}
      />
    );
  }
);

ReviewLessons.propTypes = {
  reviewMediaLesson: PropTypes.array,
  onSetRemindInstallApp: PropTypes.func,
  reminderInstallAppModal: PropTypes.func,
};

const DayStudyExams = memo(({ dayStudyExams }) => {
  return (
    <ListContainer
      list={dayStudyExams}
      listItem={({ itemIndex, ...exam }) => (
        <LessonDetailsItem {...exam} key={itemIndex} />
      )}
    />
  );
});

DayStudyExams.propTypes = {
  dayStudyExams: PropTypes.array,
};

const NormalMediaLesson = memo(
  ({ mediaLessons, onSetRemindInstallApp, reminderInstallAppModal }) => {
    return (
      <ListContainer
        list={mediaLessons}
        listItem={({ itemIndex, ...lesson }) => (
          <LessonDetailsItem
            {...lesson}
            key={itemIndex}
            onSetRemindInstallApp={
              itemIndex === 0 ? onSetRemindInstallApp : defaultFunc
            }
            reminderInstallAppModal={
              itemIndex === 0 ? reminderInstallAppModal : defaultFunc
            }
          />
        )}
      />
    );
  }
);

NormalMediaLesson.propTypes = {
  mediaLessons: PropTypes.array,
  onSetRemindInstallApp: PropTypes.func,
  reminderInstallAppModal: PropTypes.func,
  // isHandbook: PropTypes.bool
};

const Poetry = memo((props) => {
  console.log("Poetry_props", props);
  const {
    sub_name: subName,
    community,
    name: poetryName,
    system_time: systemTime,
    study_report: courseFinishCondition,
    review_media_lessons: reviewMediaLesson,
    media_lessons: mediaLessons,
    photo_url: photoUrl,
    study_report_url: studyReportUrl,
    poster_photo_template_url: posterTemplate,
    poster_creation: posterCreation,
    baby,
    module_review: isUnitReview,
    poetry_articles: poetryReadings,
    day_study_exams: dayStudyExams,
    author,
    today_quiz: todayQuiz,
    platformEnv: { isNativeClient, isWechat, isMiniProgram },
    study_type: studyType,
    media_course_name: mediaCourseName,
    // handbook
  } = props;

  const poetryTap = useCallback(
    (e) => {
      var url = e.currentTarget.getAttribute("data-url");
      console.log(url);
      const searchParams = new URI(url).query();
      const pathname = new URI(url).path();
      const parsedQuery = QueryString.parse(searchParams);

      if (url) {
        if (isWechat || isMiniProgram) {
          wx.miniProgram.getEnv((res) => {
            if (res.miniprogram) {
              if (url.includes("/media_lessons/")) {
                const id = pathname.match(/[0-9]*$/)[0];
                const params = Object.assign(parsedQuery, { id: id });
                const paramsString = JSON.stringify(params);
                const url = `/pages/recorder_demo/audioRecorder?params=${paramsString}`;
                wx.miniProgram.navigateTo({ url: url });
              } else {
                onMiniProgram(e, url, {});
              }
            } else {
              window.location.href = url;
            }
          });
        } else if (isNativeClient) {
          // eslint-disable-next-line no-undef
          XinYaNativeBridge.navigate({ href: url });
        } else {
          window.location.href = url;
        }
      }
    },
    [isMiniProgram, isNativeClient, isWechat]
  );

  const poetryGameLessonItem = useMemo(
    () =>
      !isUnitReview && todayQuiz
        ? [
            {
              url: todayQuiz.url,
              name: `${mediaLessons.length + 1}. 诗词游戏`,
              rank: todayQuiz.rank,
              lock: todayQuiz.lock,
              isReviewLesson: false,
            },
          ]
        : [],
    [isUnitReview, mediaLessons.length, todayQuiz]
  );

  const allLessonItems = useMemo(
    () => [
      ...reviewMediaLesson,
      ...mediaLessons,
      ...poetryGameLessonItem,
      ...dayStudyExams,
    ],
    [dayStudyExams, mediaLessons, poetryGameLessonItem, reviewMediaLesson]
  );

  const renderLessonItem = useCallback(
    ({ itemIndex, ...lesson }) => (
      <LessonDetailsItem
        {...lesson}
        key={itemIndex}
        usePoetryDetailInstallApp={usePoetryDetailInstallApp.bind(
          null,
          !isNativeClient && itemIndex === 0
        )}
      />
    ),
    [isNativeClient]
  );

  return (
    <div
      className="comp_poetry_detail"
      style={{ paddingBottom: !isNativeClient ? 70 + 54 : 54 }}
    >
      {courseFinishCondition && <div className="has_learned_icon"></div>}

      <div className="lesson_banner">
        <img src={photoUrl} alt="" />
      </div>

      <div className="poetry_details_list">
        <ListTitle
          isUnitReview={isUnitReview}
          subName={subName}
          poetryName={poetryName}
          author={author}
          studyType={studyType}
          mediaCourseName={mediaCourseName}
        />
        <div className="study_list">
          <ListContainer list={allLessonItems} listItem={renderLessonItem} />
        </div>
      </div>

      <PoetryArticles
        poetryReadings={poetryReadings}
        isUnitReview={isUnitReview}
      />

      {!isUnitReview && (
        <AudiosTogether
          community={community}
          poetryName={mediaCourseName || poetryName}
          systemTime={systemTime}
          courseFinishCondition={courseFinishCondition}
          poetryTap={poetryTap}
        />
      )}
      {courseFinishCondition && (
        <BottomFixedButtons
          studyReportUrl={studyReportUrl}
          posterTemplate={posterTemplate}
          posterCreation={posterCreation}
          babyName={baby.nickname}
          fixedBottomOffset={!isNativeClient ? 60 : 0}
        />
      )}
      {!isNativeClient && <InstallAppReminder />}
    </div>
  );
});

Poetry.displayName = "Poetry";

Poetry.propTypes = {
  sub_name: PropTypes.string,
  community: PropTypes.object,
  name: PropTypes.string,
  system_time: PropTypes.string,
  study_report: PropTypes.bool,
  review_media_lessons: PropTypes.array,
  media_lessons: PropTypes.array,
  photo_url: PropTypes.string,
  study_report_url: PropTypes.string,
  poster_photo_template_url: PropTypes.string,
  poster_creation: PropTypes.string,
  baby: PropTypes.object,
  module_review: PropTypes.bool,
  author: PropTypes.string,
  poetry_articles: PropTypes.array,
  today_quiz: PropTypes.object,
  onSetRemindInstallApp: PropTypes.func,
  reminderInstallAppModal: PropTypes.func,
  // handbook: PropTypes.bool,
  day_study_exams: PropTypes.array,
  platformEnv: PropTypes.object,
  study_type: PropTypes.number,
  media_course_name: PropTypes.string,
};

export default WithPlatformEnv(WithErrorBoundary(Poetry));
