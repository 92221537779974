/* eslint-disable react/prop-types */
import React, { Component } from "react";
import PropTypes from "prop-types";
import AudioPlayer from "../../utils/audio_player/AudioPlayer";
import { request, csrfHeaders } from "../../utils/request";
import { formatAudioTime } from "../../utils/formatAudioTime";
import UserAgent from "../../utils/UserAgent";
import { onMiniProgram } from "../../utils/helpers";
import Raven from "raven-js";

import home_logo_footer from "home_logo_footer.png";
import icon_menu from "study_package/icon_list.png";
import icon_play_course from "study_package/icon_card_play.png";
import "./PoetryCard.scss";

export default class PoetryCard extends Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      ShowSolarTermsList: false,
      // totalTime: 0.0,
      formattedTime: "00:00",
    };
  }

  // 关闭列表弹框事件
  handleBtnClose = () => {
    this.setState({
      ShowSolarTermsList: false,
    });
  };

  // “更多”列表icon事件
  handleShowListDate = () => {
    this.setState({
      ShowSolarTermsList: true,
    });

    const gerAudioInfos = this.props.poetry_cards.map((poetry_card) => {
      return this.getAudioInfo.bind(this, poetry_card.audio_url);
    });
    Promise.all(gerAudioInfos.map((request) => request()))
      .then((reses) => {
        // console.log("27",reses)
        const totalTime = reses.map((resp) => resp.data.format.duration);
        // console.log("44",totalTime)
        this.getTime(totalTime);
      })
      .catch((err) => {
        Raven.captureException(err);
      });
  };

  // 获取语音的播放时长
  getAudioInfo(poetryAudioUrl) {
    const url = poetryAudioUrl + "?avinfo";
    return request({
      url: url,
      method: "get",
    });
  }

  getTime(totalTime) {
    this.setState({
      formattedTime: totalTime,
    });
  }

  // 列表中单个卡片事件
  handleAloneSolarTerm = (e) => {
    const redirect_url = e.currentTarget.getAttribute("data-redirect-url");
    if (UserAgent.isMiniProgram()) {
      onMiniProgram(e, redirect_url, {});
      return;
    }
    window.location.href = redirect_url;
  };

  render() {
    const { poetry_cards, photo_url, audio_url, group_name, name } = this.props;
    const { formattedTime } = this.state;
    return (
      <div className="comp_solar_terms">
        <HeadTop handleShowListDate={this.handleShowListDate} />
        <SolarTermsExplain
          solarTermPhoto={photo_url}
          solarTerms={group_name}
          name={name}
          entireRecordUrl={audio_url}
        />
        {this.state.ShowSolarTermsList && (
          <SolarTermsList
            handleBtnClose={this.handleBtnClose}
            handleAloneSolarTerm={this.handleAloneSolarTerm}
            solarTermsItems={poetry_cards}
            formattedTime={formattedTime}
          />
        )}
      </div>
    );
  }
}

const HeadTop = ({ handleShowListDate }) => {
  return (
    <div className="head_top">
      <div className="xinya_logo_head">
        <img
          className="lazyload"
          data-src={home_logo_footer}
          data-sizes="auto"
        />
        <span>心芽学堂</span>
      </div>
      <div className="icon_more" onClick={handleShowListDate}>
        <img className="lazyload" data-src={icon_menu} data-sizes="auto" />
      </div>
    </div>
  );
};

const SolarTermsExplain = ({
  solarTermPhoto,
  solarTerms,
  name,
  entireRecordUrl,
}) => {
  const SolarTermPhotoShow = function () {
    return (
      <div className="solar_trems_photo">
        <img src={solarTermPhoto} />
      </div>
    );
  };

  return (
    <div className="solar_terms_wrapper">
      <div className="terms_module_box">
        <SolarTermPhotoShow />
        <div className="solar_terms_audio">
          <AudioPlayer
            solarTerms={solarTerms}
            name={name}
            entireRecordUrl={entireRecordUrl}
          />
        </div>
      </div>
    </div>
  );
};

const SolarTermsList = ({
  handleBtnClose,
  handleAloneSolarTerm,
  solarTermsItems,
  formattedTime,
}) => {
  return (
    <div className="solar_terms_list">
      <div className="terms_audio_container">
        <div className="overlay"></div>
        <div className="terms_audio_box">
          <div className="dialog_title">列表</div>
          <div className="close_btn_right" onClick={handleBtnClose}>
            &times;
          </div>
          <div className="list_data">
            <ul>
              {solarTermsItems.map((item, index) => (
                <SolarTermsItem
                  key={item.id}
                  item={item}
                  handleAloneSolarTerm={handleAloneSolarTerm}
                  formattedTime={formatAudioTime(formattedTime[index])}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const SolarTermsItem = ({ item, handleAloneSolarTerm, formattedTime }) => {
  return (
    <li onClick={handleAloneSolarTerm} data-redirect-url={item.redirect_url}>
      <div className="terms_data">
        <div className="terms_left">
          <img
            className="lazyload"
            data-src={`${item.photo_url}?imageView2/1/w/180/h/180`}
            data-sizes="auto"
          />
          <div className="terms_title">
            <div className="title">{item.name}</div>
            <p>{formattedTime}</p>
          </div>
        </div>
        <div className="terms_right">
          <img
            className="lazyload"
            data-src={icon_play_course}
            data-sizes="auto"
          />
        </div>
      </div>
    </li>
  );
};

PoetryCard.propTypes = {
  poetry_cards: PropTypes.array,
};
