/* eslint-disable react/prop-types */
/* eslint-disable lines-between-class-members */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Raven from "raven-js";
import { ToastManager } from "../../utils/Toast";
import { request, csrfHeaders } from "../../utils/request";
import { Dialog, DialogManager } from "../../utils/Dialog";
import {
  SharePosterProxy,
  SharePoster,
} from "../../promotion_activities/SharePoster";
import { formatDate } from "../../utils/formatAudioTime";
import StudyProgressCircle from "../../utils/study_progress_circle/StudyProgressCircle";
import "./PoetryList.scss";
import star from "icon_evaluate.png";
import { settingReminder } from "../../utils/user_setting_reminder/reminder";
import ExternlReadingTitle from "study_package/title_gsfd.png";
import note from "study_package/note.jpg";
import UserAgent from "../../utils/UserAgent";
import { onMiniProgram } from "../../utils/helpers";
import { StudyPackageCommentModule } from "../../study_packages/UnitModeStudyPackage";
import RewardCommodityList from "../../point_reward/RewardCommodityList";

// 头部 星星排行榜和邀请奖励
class StarsAndBonus extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="stars_and_bonus">
        <a className="stars_ranking" href={this.props.onStarRank}>
          <div className="star">{this.props.star}</div>
          <div className="rank_text">排行榜</div>
        </a>
        {/* <a className="invitation_bonus" onClick={this.props.getBonus}> */}
        <a className="invitation_bonus" href={this.props.invitationRewardsUrl}>
          邀请有奖
        </a>
      </div>
    );
  }
}

// 古诗词课程滑动列表
class PoetryCoursesList extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { poetryCoursesList, poetryClick, onPoetryNoteClick } = this.props;
    // 开始学习
    const NeedLearn = ({ poetryCourse }) => (
      <div className="start_learn">
        <div className="text">
          {poetryCourse["is_day_study_exam"] ? "开始挑战" : "开始学习"}
        </div>
      </div>
    );
    //锁定
    const Locked = ({ poetryCourse }) => (
      <div className="locked_mask">
        <div className="lock_location"></div>
      </div>
    );
    // 开始复习
    const Learned = ({ poetryCourse }) => (
      <div className="basic_mask">
        <div className="stars_cover">
          <div className="learn_to_stars">
            <div className="star_to_get">{poetryCourse.study_rank}</div>
            <div className="action">学习</div>
          </div>
          <div className="share_to_stars">
            <div className="star_to_get">{poetryCourse.checkin_rank}</div>
            <div className="action">打卡</div>
          </div>
        </div>
        <div className="learn_again">
          <div className="text">
            {poetryCourse["is_day_study_exam"] ? "再次挑战" : "开始复习"}
          </div>
        </div>
      </div>
    );
    const StateCoverStrategy = {
      state0: Locked,
      state1: NeedLearn,
      state2: Learned,
    };
    const StateCover = ({ poetryCourse }) => {
      return (
        <div className="poetry_course_content_mask">
          {
            StateCoverStrategy[`state${poetryCourse["study_state"]}`]({
              poetryCourse,
            })
            // StateCoverStrategy[`state0`]({ poetryCourse })
          }
        </div>
      );
    };
    return (
      <div className="poetry_course_list" id="poetry_course_list">
        {poetryCoursesList.map((poetryCourse, index) => (
          <PoetryCourseItem
            poetryCourse={poetryCourse}
            index={index}
            key={index}
            poetryClick={poetryClick}
            render={(poetryCourse) => (
              <StateCover poetryCourse={poetryCourse} />
            )}
          />
        ))}
      </div>
    );
  }
}

// 单个古诗词课程
class PoetryCourseItem extends React.Component {
  constructor(props) {
    super(props);
  }

  displsyCourseHeader = (chinese_date, today, usageGuide) => {
    let showUsageGuide = ({ usageGuide }) => (
      <div className="poetry_course_date">
        <div
          className={
            usageGuide === "heightlight" ? "ready_attention" : "date_text"
          }
        >
          课前准备
        </div>
      </div>
    );

    let notToday = ({ chinese_date }) => (
      <div className="poetry_course_date">
        <div className="date_text">{chinese_date}</div>
      </div>
    );

    let showToday = () => (
      <div className="poetry_course_date">
        <div className="today_attention">
          <div className="paddingLeft"></div>
          今日
          <div className="paddingRight"></div>
        </div>
      </div>
    );

    if (usageGuide) {
      return showUsageGuide({ usageGuide });
    }
    if (today) {
      return showToday();
    }
    if (chinese_date) {
      return notToday({ chinese_date });
    }
  };

  onPoetryClick = (e) => {
    this.props.poetryClick(
      e,
      this.props.poetryCourse.study_state,
      this.props.poetryCourse.click_url
    );
  };

  render() {
    const { poetryCourse, index, render } = this.props;
    return (
      <div
        className="poetry_course_item_wrapper"
        id="poetry_course_item_wrapper"
        onClick={this.onPoetryClick}
      >
        {this.displsyCourseHeader(
          poetryCourse.chinese_date,
          poetryCourse.today,
          poetryCourse.usageGuide
        )}
        <div className="poetry_course_item">
          <div className="poetry_course_content">
            {render(poetryCourse)}
            <div className="poetry_name">
              {poetryCourse["is_day_study_exam"]
                ? "诗词小擂台"
                : poetryCourse.name}
            </div>
            {poetryCourse["is_day_study_exam"] ? (
              <div className="poetry_sub_name">
                {poetryCourse.sub_name || "练习"}
              </div>
            ) : (
              <div className="poetry_sub_name">{poetryCourse.sub_name}</div>
            )}
            <div className="img_wrapper">
              <img
                id="img"
                className="img_cover lazyload"
                data-src={poetryCourse.photo_url}
              ></img>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// 单个泛读包
const ExternalPackItem = ({
  externalPoetry,
  render,
  index,
  onItemClick,
  systemTime,
  startTime,
}) => {
  return (
    <div className="external_pack_item" onClick={onItemClick.bind(null, index)}>
      <div className="img_wrapper">
        <img data-src={externalPoetry.photo_url} className="lazyload" />
        {render(formatDate(systemTime) < formatDate(startTime))}
      </div>
      <div className="peotry_info">
        <div className="peotry_title">{externalPoetry.name}</div>
        <div className="author_dynasty">{externalPoetry.author}</div>
      </div>
    </div>
  );
};

// 泛读展示块
class PoetryExternalPack extends PureComponent {
  constructor(props) {
    super(props);
  }
  
  render() {
    const {
      externalPoetries,
      externalPoetryItemClick,
      totalExternalPoetries,
      onAllClick,
      systemTime,
      endTime,
      startTime,
    } = this.props;
    // 未解锁泛读包样式
    const LockedExternalPackSyle = () => (
      <div className="lock_mask">
        <div className="lock_top"></div>
        {/* <div className="lock_bottom">
          <p>{starsToDeblock}</p>
          <img src={star} />
        </div> */}
      </div>
    );

    return (
      <div className="poetry_external_pack">
        <div className="external_pack_title">
          <img data-src={ExternlReadingTitle} className="lazyload" />
          <div className="divider"></div>
          <div className="all" onClick={onAllClick}>
            {" "}
            全部({totalExternalPoetries}) »
          </div>
        </div>
        <div className="external_pack_wrapper">
          {externalPoetries.map((externalPoetry, index) => (
            <ExternalPackItem
              externalPoetry={externalPoetry}
              systemTime={systemTime}
              endTime={endTime}
              startTime={startTime}
              render={(isLocked) => isLocked && <LockedExternalPackSyle />}
              key={index}
              index={index}
              onItemClick={externalPoetryItemClick}
            />
          ))}
        </div>
      </div>
    );
  }
}

//底部 用户模块
class UserBar extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      babayName,
      babyNumber,
      commentState,
      commentUrl,
      noteUrl,
      createRecommendRequestUrl,
      getWriteEvaluateStatus,
    } = this.props;
    return (
      <div className="user_bar">
        <div className="baby_info">
          <span className="baby_name">{babayName}</span>
          <span className="baby_number">学号：{babyNumber}</span>
        </div>
        <div className="user_actions">
          {
            // (commentState === 'uncommented' || commentState === 'commented') &&
            // <a className="pass_comment common_style" href={commentUrl}>写评价</a>
          }
          {createRecommendRequestUrl && (
            <a
              className="pass_comment common_style"
              href="javascript:void(0)"
              onClick={() => getWriteEvaluateStatus()}
            >
              写评价
            </a>
          )}
          <a
            className="use_guaide common_style"
            href={noteUrl}
            style={{ display: "none" }}
          >
            使用说明
          </a>
          <div className="set_alert common_style" id="time_setup_click">
            设置提醒
          </div>
        </div>
      </div>
    );
  }
}

export default class PoetryList extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);

    this.state = {
      poetriesData: {
        system_Time: props.system_time.substr(0, 10),
        // 'system_Time': '2018-08-27',
        poetry_courses: [],
      },
      studyCircleScale: 1,
      createRecommendRequestUrl: props.create_recommend_request_url,
      writeEvaluateDialogState: false,
      showChooseReward: this.props.should_show_commodity_list,
    };

    this.operatePoetriesData = this.managePoetriesData();
    this.requestPoetryCourse = this.getPoetryCourse(
      this.props.poetries_study_package_url
    );

    // 全局变量
    this.isTodayFounded = false;
    this.isgettingPoetryCourseData = false;
  }

  poetryCourseItemClick = (e, studyState, url) => {
    if (studyState === 0) {
      return;
    }
    this.userAgentUrl(e, url);
  };

  findActiveIndex = () => {
    for (let i = 0; i < this.props.exchange_commodities.commodities.length; i++) {
      if (this.props.exchange_commodities.selected === this.props.exchange_commodities.commodities[i].commodity.id) {
        return i
      }
    }
    return -1
  }

  hideChooseReward = () => {
    this.setState({
      showChooseReward: false,
    })
  }

  // mediator 集中处理PoetriesData
  managePoetriesData() {
    let operations = {
      // 增加中文日期格式
      addChineseDate: function (newData) {
        newData.forEach((lesson) => {
          if (!("date" in lesson)) {
            return;
          }
          let month = lesson["date"].substr(5, 2);
          let day = lesson["date"].substr(8, 2);
          let monthArr = month.split("");
          let dayArr = day.split("");
          if (monthArr[0] == 0) {
            month = monthArr[1];
          }
          if (dayArr[0] == 0) {
            day = dayArr[1];
          }
          lesson["chinese_date"] = `${month}月${day}日`;
        });
        let poetriesData = {
          system_Time: this.state.poetriesData.system_Time,
          poetry_courses: [
            ...this.state.poetriesData["poetry_courses"].concat(newData),
          ],
        };
        this.setState({
          poetriesData: poetriesData,
        });
      },

      // 寻找今日学习，并返回
      findTodayLearning: function () {
        return new Promise((resolve, reject) => {
          let olderData = {
            system_Time: this.state.poetriesData.system_Time,
            poetry_courses: [...this.state.poetriesData["poetry_courses"]],
          };

          let toScrollCourse = this.state.poetriesData["poetry_courses"].find(
            (poetryCourse, index) => {
              if (
                poetryCourse.date === this.state.poetriesData["system_Time"]
              ) {
                // if (poetryCourse.study_state === 1) {
                olderData["poetry_courses"][index]["today"] = true;
                this.setState({
                  poetriesData: olderData,
                });
                poetryCourse["today"] = true;
                this.isTodayFounded = true;
                poetryCourse["index"] = index;
                // console.log("poetryCourse 返回的是当天的数据")
                return poetryCourse;
              }
            }
          );
          resolve(toScrollCourse);
        });
      },
      showUserGuide: function (newData) {
        for (let index = 0; index < newData.length; index++) {
          if (index == 0) {
            let systemTime = this.state.poetriesData["system_Time"];
            if (new Date(systemTime) < new Date(newData[1].date)) {
              newData[0]["usageGuide"] = "heightlight";
            } else {
              newData[0]["usageGuide"] = "normal";
            }
            this.setState({
              poetriesData: {
                // 'system_Time': props.system_time.substr(0, 10),
                system_Time: systemTime,
                poetry_courses: [...newData],
              },
            });
            return;
          }
        }
      },
    };
    var reciveMessage = function () {
      var message = Array.prototype.shift.call(arguments);
      return operations[message].apply(this, arguments);
    };

    return reciveMessage;
  }

  // 获取古诗词课程
  getPoetryCourse(url) {
    let params = {
      per: 80,
      page: 1,
    };
    let requestData = () => {
      this.toast = ToastManager.showLoading("数据加载中...");
      return new Promise((resolve, reject) => {
        request({
          url: url,
          method: "GET",
          headers: csrfHeaders,
          params: params,
        })
          .then((data) => {
            if (data.data["day_study_reocrds"].length === 0) {
              resolve(null);
            } else {
              params.page++;
              resolve({ data, page: params.page });
            }
          })
          .catch((err) => reject(new Error(err)));
      });
    };
    return requestData;
  }

  // 获取古诗词课程数据
  getPoetryCourseData() {
    this.isgettingPoetryCourseData = true;
    return this.requestPoetryCourse
      .call(this)
      .then((res) => {
        setTimeout(() => {
          this.isgettingPoetryCourseData = false;
        }, 500);
        if (!res) {
          this.toast.cancel();
          return;
        }
        this.page = res.page;
        this.operatePoetriesData(
          "addChineseDate",
          res.data.data["day_study_reocrds"]
        );
        this.operatePoetriesData(
          "showUserGuide",
          res.data.data["day_study_reocrds"]
        );
        // 是否有今日lesson
        if (!this.isTodayFounded) {
          this.operatePoetriesData("findTodayLearning").then(
            (toScrollCourse) => {
              // this.operatePoetriesData('usageGuide')
              if (toScrollCourse) {
                let scrollEle = document.getElementById("poetry_course_list");
                let scrollStandard = document.getElementById(
                  "poetry_course_item_wrapper"
                );
                this.scrollingTodayLearning.call(
                  this,
                  toScrollCourse,
                  scrollEle,
                  scrollStandard.offsetWidth
                );
              }
            }
          );
        }
        this.toast.cancel();
      })
      .catch((err) => {
        this.isgettingPoetryCourseData = false;
        this.toast.cancel();
        console.log(err);
        Raven.captureException(err);
      });
  }

  // 设置滑动
  scrollingTodayLearning(toScrollCourse, scrollEle, scrollStandard) {
    // let toScrollCourse = this.findTodayLearning.call(this)
    let realClientWidth = document.body.clientWidth;
    // 如果横屏并且屏幕宽度大于1024（也就是大设备横屏）
    if (
      document.body.clientWidth > document.body.clientHeight &&
      document.body.clientWidth >= 1024
    ) {
      realClientWidth = 500;
    }
    setTimeout(() => {
      scrollEle.scrollLeft =
        toScrollCourse && "index" in toScrollCourse
          ? scrollStandard * (parseInt(toScrollCourse.index) + 0.5) -
            Math.floor(realClientWidth / 2) //保证了各种设备宽度下都能居中显示
          : 0;
    }, 300);
  }

  // 监听滑动
  onScrollPoetriesListening(scrollEle, scrollStandard) {
    let scroll = function () {
      let scrollEle = document.getElementById("poetry_course_list");
      return {
        left: scrollEle.scrollLeft,
      };
    };
    // let onScroll =
    document.getElementById("poetry_course_list").onscroll = (function (
      self,
      scroll
    ) {
      let sLeft = scroll().left;

      return function () {
        let onLeft = null;
        // 向右滑动
        // console.log(sLeft ,scroll().left)
        if (sLeft < scroll().left) {
          onLeft = true;
        }
        if (sLeft != scroll().left) {
          sLeft = scroll().left;
        }
        let totalLength =
          parseInt(
            scrollStandard.offsetWidth *
              self.state.poetriesData["poetry_courses"].length
          ) +
          7 * 2; // 最后两个marginleft和right是7，可以设置额外的poetry_course宽度，不在poetriesData内
        // console.log((parseInt(scrollEle.scrollLeft) + scrollEle.offsetWidth))
        // console.log(totalLength)
        if (
          (parseInt(scrollEle.scrollLeft) + scrollEle.offsetWidth ==
            totalLength ||
            parseInt(scrollEle.scrollLeft) + scrollEle.offsetWidth + 1 ==
              totalLength ||
            parseInt(scrollEle.scrollLeft) + scrollEle.offsetWidth + 2 ==
              totalLength ||
            parseInt(scrollEle.scrollLeft) +
              scrollEle.offsetWidth +
              self.page ==
              totalLength) &&
          onLeft
        ) {
          console.log("到底了");
          // 获取古诗词课程数据
          if (!self.isgettingPoetryCourseData) {
            self.getPoetryCourseData.call(self);
          }
        }
      };
    })(this, scroll);
  }

  // 邀请领现金
  // getBonus = () => {
  //   if (this.props.poster_url) {
  //     this.sharePosterProxy = new SharePosterProxy({
  //       posterUrl: this.props.poster_url,
  //       posterImgSrc: this.props.poster_img_src,
  //       free: "invite_draw_cash_or_share_friend",
  //       bonus: this.props.bonus,
  //     })
  //     this.sharePosterProxy.open()
  //     let container = document.getElementsByClassName('comp_poetry_list')[0]
  //     let width = container.offsetWidth
  //     let poster = document.getElementsByClassName('comp-share-poster')[0]
  //     if (poster) {
  //       poster.style.cssText = `width:${width + 2}px; position:absolute; left:50%;transform:translateX(-50%)`
  //     }
  //   }
  // }

  componentDidMount() {
    const { reminder_time_hour, config_reminder_time_url } = this.props;
    // 获取古诗词课程数据
    this.getPoetryCourseData.call(this).then(() => {
      let scrollEle = document.getElementById("poetry_course_list");
      let scrollStandard = document.getElementById(
        "poetry_course_item_wrapper"
      );
      this.onScrollPoetriesListening.call(this, scrollEle, scrollStandard);
    });
    settingReminder({
      remindTime: reminder_time_hour,
      postUrl: config_reminder_time_url,
      targetEleId: "time_setup_click",
    });
  }

  onAllClick = (e) => {
    let poetry_reading_url = this.props.poetry_reading_url;
    this.userAgentUrl(e, poetry_reading_url);
  };

  onPoetryNoteClick = (e) => {
    let note_url = this.props.note_url;
    this.userAgentUrl(e, note_url);
  };

  onExternalPoetryClick = (index) => {
    let clickedItem = this.props.articles[index];

    let poetry_article_url = clickedItem.poetry_article_url;
    if (
      formatDate(this.props.system_time) <
      formatDate(this.props.study_package_start_time)
    ) {
      // if (clickedItem.lock) {
      const buttons = [
        {
          label: "关闭",
          onClick: () => this.confirmDialog.close(),
        },
        // {
        //   label: "集星规则",
        //   onClick: () => {
        //     this.confirmDialog.close()
        //     let rule_url = this.props.rule_url
        //     this.userAgentUrl(event, rule_url)
        //   }
        // }
      ];
      this.confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          <p>等待课程开课后解锁</p>
        </Dialog>
      );
      let buttonOne = document.getElementsByClassName("xinyaui-dialog__btn")[0];
      buttonOne.style.cssText = "color:black;";
      // } else {
      //   this.userAgentUrl(event, poetry_article_url)
      // }
    } else {
      this.userAgentUrl(event, poetry_article_url);
    }
  };

  userAgentUrl = (e, url) => {
    console.log("url: ", url);
    e.preventDefault();
    if (UserAgent.isMiniProgram()) {
      onMiniProgram(e, url, {});
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: url });
    } else {
      window.location.href = url;
    }
  };

  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });
  };

  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };

  // 请求接口数据成功之后, 链接置空
  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };

  render() {
    const {
      baby_name,
      sno,
      studied_course_day_count,
      studied_media_courses_count,
      course_day_count,
      media_courses_count,
      comment_state,
      comment_url,
      study_package_rank,
      note_url,
      rank_url,
      articles_count,
      articles,
      bonus,
      study_package_end_time,
      study_package_start_time,
      system_time,
      invitation_rewards_url,
    } = this.props;
    const {
      poetriesData,
      studyCircleScale,
      createRecommendRequestUrl,
      writeEvaluateDialogState,
    } = this.state;
    return (
      <div className="comp_poetry_list">
        { this.state.showChooseReward &&
          this.props.exchange_commodities.commodities &&
        <RewardCommodityList
          list={this.props.exchange_commodities.commodities}
          selectRewardPath={this.props.select_reward_commodity_path}
          studyPackageActivityId={this.props.study_package_activity_id}
          activeIndex={this.findActiveIndex()}
          commoditiesPath={this.props.commodities_path}
          confirmExchangeOption={this.hideChooseReward}
          maxRewardPoints={this.props.max_reward_points}
          studyReportUrl={this.props.study_report_url}
        />
      }
        <StarsAndBonus
          star={study_package_rank}
          // getBonus={this.getBonus}
          invitationRewardsUrl={invitation_rewards_url}
          onStarRank={rank_url}
          bonus={bonus}
        />
        <div className="study_progress_container">
          <StudyProgressCircle
            studiedCourseDayCount={studied_course_day_count}
            courseDayCount={course_day_count}
            totalStudiedMediaLessonsCount={studied_media_courses_count}
            mediaLessonsCount={media_courses_count}
            leftCircleName={"已学天数"}
            rightCircleName={"已学古诗"}
          />
        </div>
        <PoetryCoursesList
          poetryCoursesList={poetriesData["poetry_courses"]}
          poetryClick={this.poetryCourseItemClick}
          onPoetryNoteClick={this.onPoetryNoteClick}
        />
        <PoetryExternalPack
          externalPoetries={articles}
          totalExternalPoetries={articles_count}
          externalPoetryItemClick={this.onExternalPoetryClick}
          onAllClick={this.onAllClick}
          endTime={study_package_end_time}
          startTime={study_package_start_time}
          systemTime={system_time}
        />
        <UserBar
          babayName={baby_name}
          babyNumber={sno}
          commentState={comment_state}
          commentUrl={comment_url}
          noteUrl={note_url}
          createRecommendRequestUrl={createRecommendRequestUrl}
          getWriteEvaluateStatus={this.getWriteEvaluateStatus}
        />
        {writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={createRecommendRequestUrl}
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment}
            bought_phase={this.props.bought_phase}
            promotion={this.props.promotion}
            rate={this.props.rate}
            name={this.props.study_package_name}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}
      </div>
    );
  }
}
