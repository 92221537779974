/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React, { memo, useCallback, useMemo, useState, useEffect } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../../../utils/request";
import PropTypes from "prop-types";
import Raven from "raven-js";
import UploadExerciseContent from "../../../reading_files/reading_details/UploadExerciseContent";
import { SharePosterProxy } from "../../../promotion_activities/SharePoster";
import WithErrorBoundary from "../../../utils/WithErrorBoundary.jsx";
import { ToastManager } from "../../../utils/Toast";
import WithPlatformEnv from "../../../utils/WithPlatformEnv.jsx";
import UserAgent from "../../../utils/UserAgent";
import XinYaNativeBridge from "@/packs/native_bridges/native_bridge";
import { SmallAudioPlayer } from "../../../utils/audio_player/AudioPlayer";
// import { onNavigate, clipboard } from "../../../../utils/helpers";
import {
  defaultFunc,
  showDialog,
  debounce,
  clipboard,
} from "../../../utils/helpers";
import {
  PoetryReading,
  LessonDetailsItem,
  usePoetryDetailInstallApp,
  InstallAppReminder,
} from "./components";
import { PdfPracticeNoteCopy } from "./components/ReadingList.jsx";
import ListContainer from "../../../utils/ListContainer/ListContainer.jsx";
import { ImgDialog } from "../../../utils/quizzes_practice_template/practiceTemplates";
import down_back_img from "reading/down_back_img.png";
import iconUpload from "reading/upload.png";
import iconBtnLong from "reading/btn-long.png";
import followRight from "reading/follow_right.png";
import iconBtnShort from "reading/btn-short.png";
import iconSending from "reading/sending.png";
import iconInfoTitle from "reading/info_title.png";
import iconPlaceholder from "reading/placeholder.png";
import iconDelete from "btn-fail-selected.png";
import iconPlay from "study_package/icon_player.png";
import iconPause from "study_package/icon_pause.png";
import btn_option_error from "../../../../../assets/images/btn_option_error.png";
import "./Reading.scss";
import FullScreenCrystalReward from "../../../point_reward/FullScreenCrystalReward";
import FinishWholeDayStudyIcon from "point_rewards/finish-current-day-reward-5.png";
import StudyOnTimeIcon from "point_rewards/study-ontime-reward-5.png";
import Crystal from "point_rewards/crystal.png";

const PoetryArticles = memo(({ isUnitReview, poetryReadings }) => {
  return (
    <React.Fragment>
      {poetryReadings && poetryReadings.length > 0 && (
        <div
          className={`section_title_common ${
            isUnitReview ? "unit_readings" : "reading_recommendations"
          }`}
        ></div>
      )}
      <div className="poetry_articles_container">
        <ListContainer
          list={poetryReadings}
          listItem={({ itemIndex, ...reading }) => (
            <PoetryReading
              key={itemIndex}
              isUnitReview={isUnitReview || poetryReadings.length > 1}
              {...reading}
            />
          )}
        />
      </div>
    </React.Fragment>
  );
});

PoetryArticles.propTypes = {
  isUnitReview: PropTypes.bool,
  poetryReadings: PropTypes.array,
};

const BottomFixedButtons = memo(
  ({
    studyReportUrl,
    babyName,
    posterTemplate,
    posterCreation,
    fixedBottomOffset,
  }) => {
    const cardShareClick = () => {
      new SharePosterProxy({
        posterTemplate,
        posterCreation,
        mode: "front_end",
        free: "poetry_share",
        nickName: babyName,
        isNewPoetry: true,
      }).open();
    };

    return (
      <div className="bottom_fixed" style={{ bottom: fixedBottomOffset || 0 }}>
        <div className="flex_wrapper">
          <a
            className="bt_common study_report"
            href={`${studyReportUrl}&ref=daily_course`}
          >
            学习报告
          </a>
          {/* <div className="bt_common poster" onClick={cardShareClick}>
            打卡海报
          </div> */}
        </div>
      </div>
    );
  }
);

BottomFixedButtons.propTypes = {
  posterTemplate: PropTypes.string,
  posterCreation: PropTypes.string,
  studyReportUrl: PropTypes.string,
  babyName: PropTypes.string,
  fixedBottomOffset: PropTypes.number,
};

const ListTitle = memo(({ subTopic }) => {
  return (
    <div className="reading_list_title">
      <p className="title_name">{subTopic}</p>
    </div>
  );
});

ListTitle.propTypes = {
  subTopic: PropTypes.string,
};

const ReviewLessons = memo(
  ({ reviewMediaLesson, onSetRemindInstallApp, reminderInstallAppModal }) => {
    return (
      <ListContainer
        list={reviewMediaLesson}
        listItem={({ itemIndex, ...reviewLesson }) => (
          <LessonDetailsItem
            {...reviewLesson}
            key={itemIndex}
            isReviewLesson
            onSetRemindInstallApp={
              itemIndex === 0 ? onSetRemindInstallApp : defaultFunc
            }
            reminderInstallAppModal={
              itemIndex === 0 ? reminderInstallAppModal : defaultFunc
            }
          />
        )}
      />
    );
  }
);

ReviewLessons.propTypes = {
  reviewMediaLesson: PropTypes.array,
  onSetRemindInstallApp: PropTypes.func,
  reminderInstallAppModal: PropTypes.func,
};

const DayStudyExams = memo(({ dayStudyExams }) => {
  return (
    <ListContainer
      list={dayStudyExams}
      listItem={({ itemIndex, ...exam }) => (
        <LessonDetailsItem {...exam} key={itemIndex} />
      )}
    />
  );
});

DayStudyExams.propTypes = {
  dayStudyExams: PropTypes.array,
};

const NormalMediaLesson = memo(
  ({ mediaLessons, onSetRemindInstallApp, reminderInstallAppModal }) => {
    return (
      <ListContainer
        list={mediaLessons}
        listItem={({ itemIndex, ...lesson }) => (
          <LessonDetailsItem
            {...lesson}
            key={itemIndex}
            onSetRemindInstallApp={
              itemIndex === 0 ? onSetRemindInstallApp : defaultFunc
            }
            reminderInstallAppModal={
              itemIndex === 0 ? reminderInstallAppModal : defaultFunc
            }
          />
        )}
      />
    );
  }
);

NormalMediaLesson.propTypes = {
  mediaLessons: PropTypes.array,
  onSetRemindInstallApp: PropTypes.func,
  reminderInstallAppModal: PropTypes.func,
  // isHandbook: PropTypes.bool
};

const UploadQuizContainer = styled.div`
  width: 100%;
  margin-top: ${({ isUploadedFiles }) => (isUploadedFiles ? 62 : 115)}px;
`;

const UserTip = styled.div`
  font-size: 14px;
  letter-spacing: 1px;
  color: #6a5e4e;
  text-align: center;
  position: relative;
  &::after {
    content: "";
    background-color: #cdc0a8;
    border-radius: 200px;
    opacity: 0.5;
    width: 173px;
    height: 7px;
    position: absolute;
    top: 63%;
    left: 50%;
    margin-left: -87px;
  }
`;

const Tip = styled.div`
  margin: 20px 4px 0px 4px;
  padding: 12px;
  font-size: 14px;
  background-color: #ffffff;
  span {
    color: #ff7200;
  }
`;
const RepeatUploadButton = styled.div`
  display: block;
  width: 75px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #4bc216;
  letter-spacing: 1px;
  color: #4bc216;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  &::after {
    content: "";
    width: 10px;
    height: 12px;
    background-image: url(${followRight});
    background-position: 0 0;
    background-size: 100%;
    background-repeat: no-repeat;
    padding-left: 2px;
    position: absolute;
    right: 2px;

    top: 50%;
    margin-top: -5px;
  }
`;

const UploadButton = styled.div`
  display: block;
  width: 184px;
  height: 61px;
  line-height: 61px;
  padding-left: 29px;
  background-image: url(${iconBtnShort});
  background-position: 0 0;
  background-size: 100%;
  background-repeat: no-repeat;
  letter-spacing: 1px;
  color: #6a5e4e;
  font-size: 18px;

  font-weight: bold;
  position: relative;
  margin: 0 auto;
  margin-top: 20px;
  &::after {
    content: "";
    position: absolute;
    right: 17px;

    background-image: url(${iconUpload});
    background-position: 0 0;
    background-size: 100%;
    width: 42px;
    height: 29px;
    background-repeat: no-repeat;
    top: 50%;
    margin-top: -16px;
  }
`;

const ReadingExerciseButton = styled(UploadButton)`
  display: block;
  width: 184px;
  height: 61px;
  line-height: 61px;
  padding-left: 29px;
  background-image: url(${iconBtnShort});
  &::after {
    width: 36px;
    height: 30px;
    right: 28px;
    top: 52%;
    background-image: url(${iconSending});
  }
`;

const InfoBroadContainer = styled.div`
  margin: 20px 0px 15px 0px;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 2px 0px rgba(205, 192, 168, 0.3);
  border-radius: 5px;
  position: relative;
`;

export const InfoBroadTitle = styled.div`
  position: absolute;
  top: 3px;
  left: -4px;
  background-image: url(${iconInfoTitle});
  background-position: 0 0;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 109px;
  height: 31px;
  letter-spacing: 1px;
  color: #ffffff;
  font-size: 14px;
  padding-left: 6px;
  line-height: 29px;
`;

const StyledPhoto = styled.div`
  width: 104px;
  height: 67px;
  background-color: #ffffff;
  box-shadow: 0px 3px 2px 0px rgba(126, 83, 7, 0.3);
  margin-left: 11px;
  margin-top: 10px;
  position: relative;
  .file {
    width: 100%;
  }
`;
export const Photos = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: -10px;
  margin-bottom: 25px;
`;
const StyledDummyPhoto = styled.div`
  width: 104px;
  height: 67px;
  margin-left: 11px;

  margin-top: 10px;
  background-color: #e8e8e8;
  border: dashed 2px #d1d1d1;
  background-image: url(${iconPlaceholder});
  background-position: center 34%;
  background-size: 31px 22px;
  background-repeat: no-repeat;
  p {
    line-height: 1em;
    font-size: 12px;
    letter-spacing: 1px;
    color: #d2d2d2;
    text-align: center;
    margin-top: 42px;
  }
`;

const FileDelete = styled.img`
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: 0;
  top: 0;
  z-index: 100;
  margin: -7.5px;
`;

const FileWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Photo = ({
  file,
  index,
  setAnswerPhotos,
  isUploaded,
  isReviewed,
}) => {
  const [isOpenImgDialog, setOpenImgDialog] = useState(false);
  if (!file) return null;
  const url = isReviewed ? file : window.URL.createObjectURL(file);
  return (
    <StyledPhoto>
      {!isUploaded && !isReviewed && (
        <FileDelete
          src={iconDelete}
          onClick={() => {
            setAnswerPhotos((files) => {
              files.splice(index, 1, null);
              return [...files];
            });
          }}
        />
      )}
      {isOpenImgDialog && (
        <ImgDialog
          photo_url={url}
          handleImgDialog={() => setOpenImgDialog((pre) => !pre)}
        />
      )}
      <FileWrapper onClick={() => setOpenImgDialog((pre) => !pre)}>
        <img src={url} alt="" className="file" />
      </FileWrapper>
    </StyledPhoto>
  );
};

export const InfoBroad = ({ children }) => {
  return <InfoBroadContainer>{children}</InfoBroadContainer>;
};

const SubmitBtn = styled.span`
  padding: 6px 20px;
  background-color: #49c114;
  border-radius: 18px;
  border-radius: 200px;
  position: relative;
  left: 50%;
  transform: translate(-57%);
  letter-spacing: 1px;
  color: #ffffff;
  display: inline-block;
`;

const UserAnswerInfo = ({
  answerPhotos,
  setAnswerPhotos,
  submitAnswers,
  isUploaded,
  isBeforeDeadline,
  reviewsType,
  without_comment,
}) => {
  return (
    <>
      <InfoBroad>
        <InfoBroadTitle>我的回答</InfoBroadTitle>
        <Photos>
          {!!answerPhotos.length &&
            answerPhotos.map((photo, index) => (
              <Photo
                key={index}
                file={photo}
                index={index}
                setAnswerPhotos={setAnswerPhotos}
                isUploaded={isUploaded}
              />
            ))}
          {!isUploaded && (
            <StyledDummyPhoto
              onClick={() => $("#answer_photos").trigger("click")}
            >
              <p>添加照片</p>
            </StyledDummyPhoto>
          )}
        </Photos>
        {!isUploaded && (
          <SubmitBtn onClick={debounce(submitAnswers)}>确认提交</SubmitBtn>
        )}
      </InfoBroad>
      {!without_comment && isUploaded && isBeforeDeadline && (
        <InfoBroad>
          <InfoBroadTitle>老师评语</InfoBroadTitle>
          <StyledUploadTip>
            老师将在作业上传后2个工作日内点评作业。
          </StyledUploadTip>
        </InfoBroad>
      )}
    </>
  );
};

const uploadToQiNiu = (file, token) =>
  new Promise((resolve, reject) => {
    const fd = new FormData();
    fd.append("file", file);
    fd.append("name", "");
    fd.append("token", token);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://upload.qiniup.com", true);
    xhr.onreadystatechange = () => {
      if (xhr.readyState == 4) {
        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
          const obj = JSON.parse(xhr.responseText);
          console.log(obj);
          resolve(obj);
        } else {
          reject(JSON.parse(xhr.responseText).error);
        }
      }
    };
    xhr.onerror = (e) => {
      console.error(e);
      // if (UserAgent.isNativeClient()) {
      //   alert("上传成功！");
      // } else {
      // }
      reject(e);
    };

    xhr.send(fd);
  });

const uploadFile = async ({ file, generateTokenUrl }) => {
  const res = await request({
    url: generateTokenUrl,
    method: "GET",
    headers: csrfHeaders,
    params: {
      type: "day_study_answer",
    },
  });
  const token = res.data.token;
  const photo = await uploadToQiNiu(file, token);
  console.log(photo);
  return { id: photo.id };
};

export const StyledUploadTip = styled.div`
  margin-top: 34px;
  font-size: 14px;
  color: #000000;
  padding-bottom: 20px;
`;

const TeacherContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;
  img {
    width: 46px;
    height: 46px;
    box-shadow: 0px 3px 2px 0px rgba(205, 192, 168, 0.3);
    border-radius: 50%;
  }
  span {
    font-size: 14px;
    letter-spacing: 1px;
    color: #716656;
    margin-left: 10px;
  }
`;

const StyledCommentAudio = styled.div`
  width: 251px;
  height: 48px;
  background-color: #f0f6f0;
  box-shadow: 0px 3px 2px 0px rgba(214, 232, 221, 0.3);
  border-radius: 5px;
  border: solid 1px #d6e8dd;
  margin-top: 10px;
  .audio_player_wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .audio_play {
      width: 100%;
      height: 100%;
      .audio_player_container {
        padding: 5px;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        .play {
          background-image: url(${iconPlay});
        }
        .stop,
        .pause {
          background-image: url(${iconPause});
        }
        .play_stop_common {
          background-repeat: no-repeat;
          background-size: 100%;
          height: 100%;
          width: 32px;
          height: 32px;
          position: relative;
        }
        .audio_info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: white;
          .title_info {
            font-size: 14px;
            color: #999999;
            margin-top: -5px;
          }
          .audio_progress {
            margin-top: 2px;
            width: 100%;
            height: 4px;
            background-color: #e3ede6 !important;
            border-radius: 3px;
          }
        }
        .time_progress {
          text-align: center;
          color: white;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 3px 0 1px 5px;
          color: #999;
          font-size: 14px;
          margin-left: 7px;
          margin-right: 3px;
        }
      }
    }
  }
  .cancel_release_audio {
    text-align: right;
    padding-top: 10px;
    color: #888;
    text-decoration: underline;
  }
`;

export const StyledCommentBroad = styled.div`
  margin-top: 16px;
  .teacher_comment_broad.markdown_content {
    padding-bottom: 0px;
    background-color: #defefe;
    border-radius: 5px;
    margin-top: 13px;
    padding-top: 15px;
    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 188px;
      margin: 10px auto;
    }
    p {
      font-size: 14px;
      line-height: 1.5em;
      strong {
        color: black;
      }
      img {
        max-width: none;
      }
    }
  }
`;

export const ReviewedBroad = ({
  reviewedPhotos,
  teacherPhoto,
  teacherName,
  commentAudioUrl,
  commentAudioInfo,
  commentText,
  reviewsType,
}) => {
  return (
    <>
      <Photos style={!commentAudioUrl ? { marginBottom: 10 } : {}}>
        {!!reviewedPhotos.length &&
          reviewedPhotos.map((photo, index) => (
            <Photo
              key={index}
              file={photo}
              index={index}
              isReviewed
              isUploaded
            />
          ))}
      </Photos>
      {commentAudioUrl && (
        <TeacherContainer>
          <img src={teacherPhoto} alt="" />
          <span>{teacherName}</span>
        </TeacherContainer>
      )}
      {commentAudioUrl && (
        <StyledCommentAudio>
          <SmallAudioPlayer
            audioSrc={commentAudioUrl}
            audioInfoUrl={commentAudioInfo}
            index={0}
          />
        </StyledCommentAudio>
      )}

      {commentText && (
        <StyledCommentBroad>
          <div className="markdown_content teacher_comment_broad">
            <ReactMarkdown source={commentText} />
          </div>
        </StyledCommentBroad>
      )}
    </>
  );
};

const UploadQuiz = memo(
  ({
    generateTokenUrl,
    dayStudyRecordUrl,
    commentText,
    reviewsType,
    commentAudioUrl,
    commentAudioInfo,
    answers,
    answer,
    isBeforeDeadline,
    courseConsultant,
    submitEndDate,
    submitRewardEndDate,
    hasPointReward,
    submitStartDate,
    setShowSubmitOfflineTaskReward,
    setShowStudyOnTimeReward,
    setShouldShowSubmitOfflineTaskReward,
    isUploaded,
    rewardExpired,
    handlePointReward,
    without_comment,
  }) => {
    console.log("==================" + submitEndDate);
    const isAnswered = answers?.length > 0;
    const isReviewed = !!commentAudioUrl;
    const reviewedPhotos = useMemo(() => {
      if (isAnswered) {
        if (isReviewed) {
          return answers.map((a) => a.comment_photo_url);
        } else {
          return answers.map((a) => a.answer_photo_url);
        }
      }
      return [];
    }, [answers, isAnswered, isReviewed]);
    const [answerPhotos, setAnswerPhotos] = useState([]);
    useEffect(() => {
      if (UserAgent.isIOS() && XinYaNativeBridge) {
        XinYaNativeBridge.requestPermission("camera", (granted) => {});
      }
    }, []);
    const inputOnChange = (e) => {
      const newFiles = e.target.files;
      console.log(newFiles);
      setAnswerPhotos((files) => [...files, ...newFiles]);
    };

    const timeInspect = (time) => {
      return (
        <span style={{ color: "#FF973C" }}>
          {time?.replace?.(/-/g, ".")} 23:59
        </span>
      );
    };

    const submitAnswers = useCallback(async () => {
      // setUploaded(true);

      // return;
      const toast = ToastManager.showLoading("图片上传中...");
      console.log("图片上传");
      try {
        const ids = await Promise.all(
          answerPhotos
            .filter((p) => !!p)
            .map((f) =>
              uploadFile({
                file: f,
                generateTokenUrl,
              })
            )
        );
        console.log("ids", ids);
        const res = await request({
          url: dayStudyRecordUrl,
          method: "POST",
          headers: csrfHeaders,
          data: {
            day_study_answers: ids.map((r) => ({ answer_photo_id: r.id })),
          },
        });
        toast.cancel();
        handlePointReward(res);
      } catch (e) {
        console.log(e, "================ 出错啦！");
        toast.cancel();
        const dialogOptions = {
          text: "上传出错，请重试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            submitAnswers();
          },
        };
        showDialog.call(this, dialogOptions);
        Raven.captureException(e);
      }
    }, [answerPhotos, dayStudyRecordUrl, generateTokenUrl]);
    return (
      <UploadQuizContainer
        isUploadedFiles={!!answerPhotos.length || isReviewed}
      >
        {/* 上传图片 */}
        {answerPhotos.length > 0 && (
          <UserAnswerInfo
            answerPhotos={answerPhotos}
            setAnswerPhotos={setAnswerPhotos}
            submitAnswers={submitAnswers}
            isUploaded={isUploaded}
            isBeforeDeadline={isBeforeDeadline}
            without_comment={without_comment}
          />
        )}

        {/* 有回答记录，并且有老师评论语音 || 有回答记录,但是不是规定时间做练习 */}
        {((reviewedPhotos.length > 0 && isReviewed) ||
          (reviewedPhotos.length > 0 && !isBeforeDeadline)) && (
          <InfoBroad>
            <InfoBroadTitle>我的回答</InfoBroadTitle>
            <ReviewedBroad
              reviewedPhotos={reviewedPhotos}
              commentAudioInfo={commentAudioInfo}
              commentAudioUrl={commentAudioUrl}
              commentText={commentText}
              teacherPhoto={courseConsultant?.photo_url}
              teacherName={courseConsultant?.name}
            />
          </InfoBroad>
        )}

        {/* 规定时间做练习，但是还没有老师评论语音，提示用户等待通知 */}
        {!isUploaded &&
          !isReviewed &&
          isBeforeDeadline &&
          reviewedPhotos.length > 0 && (
            <>
              {!(answerPhotos?.length > 0 ? true : false) && (
                <InfoBroad>
                  <InfoBroadTitle>我的回答</InfoBroadTitle>
                  <ReviewedBroad
                    reviewedPhotos={reviewedPhotos}
                    commentAudioUrl={commentAudioUrl}
                    commentText={commentText}
                    teacherPhoto={courseConsultant?.photo_url}
                    teacherName={courseConsultant?.name}
                  />

                  <RepeatUploadButton
                    as="label"
                    htmlFor="answer_photos"
                    id="file_label2"
                  >
                    重传作业
                  </RepeatUploadButton>
                </InfoBroad>
              )}
              {!without_comment &&
                <InfoBroad>
                  <InfoBroadTitle>老师评语</InfoBroadTitle>
                  <StyledUploadTip>
                    老师将在作业上传后2个工作日内点评作业。
                  </StyledUploadTip>
                </InfoBroad>
              }
            </>
          )}

        {/* 不是规定时间做练习，老师评论语音显示答案解析 */}
        {((isUploaded && !(false && isBeforeDeadline)) ||
          isReviewed ||
          (!(false && isBeforeDeadline) && isAnswered)) && (
          <InfoBroad>
            <InfoBroadTitle>答案解析</InfoBroadTitle>
            <StyledCommentBroad style={{ marginTop: 33 }}>
              <div className="markdown_content teacher_comment_broad">
                {answer && <ReactMarkdown source={answer} />}
              </div>
            </StyledCommentBroad>
          </InfoBroad>
        )}

        {!answerPhotos.length && !isAnswered && (
          <>
            <UserTip>我已经完成纸质版练习</UserTip>
            <UploadButton as="label" htmlFor="answer_photos" id="file_label">
              上传作业
            </UploadButton>
            {hasPointReward && !rewardExpired && (
              <Tip
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div>{timeInspect(submitRewardEndDate)}前上传，可额外获得5</div>
                <img src={Crystal} style={{ width: "20px" }} />
                <div>奖励。</div>
              </Tip>
            )}

            {without_comment ? <Tip>上传后，即可查看答案解析。</Tip>
              :
              <Tip>
                {timeInspect(submitEndDate)}
                前，都可以获得老师的点评。超出上传时间，仅能查看答案解析哦。
              </Tip>
            }
          </>
        )}

        <input
          type="file"
          id="answer_photos"
          accept="image/jpeg,image/jpg,image/png"
          style={{ display: "none" }}
          onChange={inputOnChange}
          multiple
        />
      </UploadQuizContainer>
    );
  }
);

const ReadingExercise = ({ todayQuizUrl }) => {
  return (
    <UploadQuizContainer>
      <UserTip>我已经读完了这篇文章</UserTip>
      <ReadingExerciseButton as="a" href={todayQuizUrl}>
        阅读闯关
      </ReadingExerciseButton>
    </UploadQuizContainer>
  );
};

const Reading = memo((props) => {
  console.log("Reading_props", props);
  const {
    sub_name: subName,
    // community,
    // name: poetryName,
    // system_time: systemTime,
    study_report: courseFinishCondition,
    review_media_lessons: reviewMediaLesson,
    media_lessons: mediaLessons,
    photo_url: photoUrl,
    study_report_url: studyReportUrl,
    poster_photo_template_url: posterTemplate,
    poster_creation: posterCreation,
    baby,
    module_review: isUnitReview,
    // poetry_articles: poetryReadings,
    day_study_exams: dayStudyExams,
    author,
    today_quiz: todayQuiz,
    platformEnv: { isNativeClient },
    study_type: studyType,
    media_course_name: mediaCourseName,
    sub_topic: subTopic,
    generate_token_url: generateTokenUrl,
    day_study_record_url: dayStudyRecordUrl,
    comment_text: commentText,
    reviews_type: reviewsType,
    comment_audio_url: commentAudioUrl,
    answers,
    answer,
    is_first_view,
    document: trial_period_notice,
    reminder_teacher_comment: isBeforeDeadline,
    course_consultant: courseConsultant,
    submit_end_date: submitEndDate,
    has_point_reward: hasPointReward,
    submit_reward_end_date: submitRewardEndDate,
    submit_start_date: submitStartDate,
    comment_audio_info_url: commentAudioInfo,
    ...others
    // handbook
  } = props;

  const [showSubmitOfflineTaskReward, setShowSubmitOfflineTaskReward] =
    useState(false);
  const [
    shouldShowSubmitOfflineTaskReward,
    setShouldShowSubmitOfflineTaskReward,
  ] = useState(false);
  const [totalPoint, setTotalPoint] = useState(props.baby_point);
  const [showStudyOnTimeReward, setShowStudyOnTimeReward] = useState(false);
  const [isUploaded, setUploaded] = useState(false);

  mediaLessons.forEach((l, index) => {
    l.name = `${index + 1}. ${l.name}`;
  });
  const [pdfPracticeUrlCopy, setPdfPracticeUrlCopy] = useState(false);
  const [showDown, setShowDown] = useState(false);
  const poetryGameLessonItem = useMemo(
    () =>
      !isUnitReview && todayQuiz
        ? [
            {
              url: todayQuiz.url,
              name: `${mediaLessons.length + 1}. 随堂练习`,
              rank: todayQuiz.rank,
              lock: todayQuiz.lock,
              isReviewLesson: false,
            },
          ]
        : [],
    [isUnitReview, mediaLessons.length, todayQuiz]
  );

  const allLessonItems = useMemo(
    () => [
      ...reviewMediaLesson,
      ...mediaLessons,
      ...poetryGameLessonItem,
      ...dayStudyExams,
    ],
    [dayStudyExams, mediaLessons, poetryGameLessonItem, reviewMediaLesson]
  );
  const handlePdfPractice = (boolState) => {
    setPdfPracticeUrlCopy(boolState);
  };
  const handleCopyUrl = (e) => {
    // const parentIndex = e.currentTarget.getAttribute("data-index");
    // console.log('parentIndex: ', parentIndex);
    // let currentClickUrl = "";
    let currentClickUrl = trial_period_notice.download_url;
    // parentIndex == -1
    //   ? this.props.unit_pdf_url
    //   : this.props.media_lessons[parentIndex].media_lesson_pdf_url;

    // console.log("parentIndex: ", parentIndex);
    // console.log("currentClickUrl: ", currentClickUrl);

    clipboard(window, document, navigator).copy(currentClickUrl, true);
  };
  const renderLessonItem = useCallback(
    ({ itemIndex, ...lesson }) => (
      <LessonDetailsItem
        {...lesson}
        key={itemIndex}
        // baby={baby}
        setShowDown={is_first_view && trial_period_notice ? setShowDown : ""}
        usePoetryDetailInstallApp={usePoetryDetailInstallApp.bind(
          null,
          !isNativeClient && itemIndex === 0
        )}
      />
    ),
    [isNativeClient]
  );

  const handleStudyOnTimeConfirmClick = () => {
    setShowStudyOnTimeReward(false);
    if (shouldShowSubmitOfflineTaskReward) {
      setTimeout(() => {
        setShowSubmitOfflineTaskReward(true);
        setTotalPoint(totalPoint + props.finish_whole_day_study_point);
      }, 300);
    } else {
      window.location.reload();
    }
  };

  const handleFinishAllDayStudyConfirmClick = () => {
    setShowSubmitOfflineTaskReward(false);
    window.location.reload();
  };

  // 处理按时学习/完成整天学习 水晶奖励
  const handlePointReward = (res) => {
    console.log("res data is: ", res)
    res = res.data ? res.data : res;
    if (res.data.show_study_on_time_reward) {
      setShowStudyOnTimeReward(true);
      setShouldShowSubmitOfflineTaskReward(
        res.data.show_finish_whole_day_study
      );
    } else if (res.data.show_finish_whole_day_study) {
      setShowSubmitOfflineTaskReward(true);
    } else {
      setUploaded(true);
      window.location.reload();
    }
  };

  const uploadOfflineExerciseComponent = () => {
    if (props.offline_exercises && props.offline_exercises.length > 0) {
      let neverUploaded = true;
      if (props.answers && props.answers.length > 0) {
        neverUploaded = false;
      }
      const {
        answers,
        upload_end_date: uploadEndDate,
        upload_expired: uploadExpired,
        day_study_record_url: dayStudyRecordUrl,
        submit_reward_end_date: submitRewardEndDate,
        without_comment,
        ...others
      } = props;
      return (
        <div style={{ marginTop: "70px" }}>
          <UploadExerciseContent
            neverUploaded={neverUploaded}
            answers={answers}
            templates={props.offline_exercises}
            hasPointReward={hasPointReward}
            submitRewardEndDate={submitRewardEndDate ?? ""}
            uploadEndDate={uploadEndDate ?? ""}
            uploadExpired={uploadExpired ?? true}
            generateTokenUrl={generateTokenUrl}
            dayStudyRecordUrl={dayStudyRecordUrl}
            others={others}
            handleShowReward={handlePointReward}
            buttonColor={"#49C114"}
            without_comment={without_comment}
          />
        </div>
      );
    } else {
      return (
        <UploadQuiz
          isUploaded={isUploaded}
          setUploaded={setUploaded}
          handlePointReward={handlePointReward}
          generateTokenUrl={generateTokenUrl}
          dayStudyRecordUrl={dayStudyRecordUrl}
          commentText={commentText}
          reviewsType={reviewsType}
          commentAudioUrl={commentAudioUrl}
          answer={answer}
          answers={answers}
          isBeforeDeadline={isBeforeDeadline}
          courseConsultant={courseConsultant}
          submitEndDate={submitEndDate}
          submitRewardEndDate={submitRewardEndDate}
          hasPointReward={hasPointReward}
          submitStartDate={submitStartDate}
          commentAudioInfo={commentAudioInfo}
          setShowSubmitOfflineTaskReward={setShowSubmitOfflineTaskReward}
          setShouldShowSubmitOfflineTaskReward={
            setShouldShowSubmitOfflineTaskReward
          }
          setShowStudyOnTimeReward={setShowStudyOnTimeReward}
          rewardExpired={props.reward_expired}
          without_comment={props.without_comment}
        />
      );
    }
  };

  return (
    <div
      className="comp_reading_detail"
      style={{ paddingBottom: !isNativeClient ? 70 + 54 : 54 }}
    >
      {courseFinishCondition && <div className="has_learned_icon"></div>}
      {showSubmitOfflineTaskReward && props.should_reward ? (
        <FullScreenCrystalReward
          point={props.finish_whole_day_study_point}
          totalPoint={totalPoint}
          textUrl={FinishWholeDayStudyIcon}
          handleClick={handleFinishAllDayStudyConfirmClick}
        />
      ) : null}

      {showStudyOnTimeReward && props.should_reward ? (
        <FullScreenCrystalReward
          point={props.study_on_time_reward_point}
          totalPoint={totalPoint}
          textUrl={StudyOnTimeIcon}
          handleClick={handleStudyOnTimeConfirmClick}
        />
      ) : null}

      <div className="lesson_banner">
        <img src={photoUrl} alt="" />
        <ListTitle subTopic={subTopic} />
      </div>

      {studyType === 0 && (
        <div className="poetry_details_list">
          <div className="study_list">
            <ListContainer
              list={allLessonItems}
              listItem={renderLessonItem}
              // baby={baby}
            />
          </div>
        </div>
      )}
      {studyType === 1 && uploadOfflineExerciseComponent()}
      {studyType === 2 && <ReadingExercise todayQuizUrl={todayQuiz?.url} />}

      {courseFinishCondition && (
        <BottomFixedButtons
          studyReportUrl={studyReportUrl}
          posterTemplate={posterTemplate}
          posterCreation={posterCreation}
          babyName={baby.nickname}
          fixedBottomOffset={!isNativeClient ? 60 : 0}
        />
      )}
      {!isNativeClient && <InstallAppReminder />}
      {trial_period_notice && showDown && (
        <div className="down_pdf">
          <div className="down_pdf_connect">
            <img src={down_back_img}></img>
            <div className="down_pdf_connect_header">
              <div className="down_pdf_connect_header_title">温馨提示</div>
              <img
                src={btn_option_error}
                className="down_pdf_connect_header_img"
                onClick={() => {
                  setShowDown(false);
                  props.media_lessons.map((item, idx) => {
                    if (item.name && item.name.indexOf("热身准备") !== -1) {
                      window.location.href = item.url;
                    }
                  });
                  // request({
                  //   url: "",
                  //   method: "POST",
                  //   params: "",
                  // }).then(() => {
                  //   setShowDown(false);
                  //   props.media_lessons.map((item, idx) => {
                  //     if (item.name == "热身准备") {
                  //       window.location.href = item.url;
                  //     }
                  //   });
                  // });
                }}
              ></img>
            </div>
            <div className="down_pdf_connect_body">
              为了更好的体验，请下载、 打印
              <span style={{ color: "#FF791F" }}>电子教材</span>配合学习
              (正式课会寄送7本教材)。
            </div>

            <div
              className="down_pdf_connect_down"
              onClick={() => {
                handlePdfPractice(true);
              }}
            >
              下载
            </div>
            <div
              className="down_pdf_connect_look"
              onClick={() => {
                setShowDown(false);
                window.location.href =
                  "https://upload.fireflybaby.cn/file/656feb418549068c280d7bff6cfd0a10.pdf";
              }}
            >
              预览
            </div>
          </div>
        </div>
      )}
      {pdfPracticeUrlCopy && (
        <PdfPracticeNoteCopy
          // media_lessons={media_lessons}
          handlePdfPractice={handlePdfPractice}
          copyUrl={handleCopyUrl}
          unitPdfUrl={trial_period_notice.download_url}
          unitName={trial_period_notice.name}
          // activityCode={study_package_activity_code}
        />
      )}
    </div>
  );
});

Reading.displayName = "Reading";

Reading.propTypes = {
  sub_name: PropTypes.string,
  community: PropTypes.object,
  name: PropTypes.string,
  system_time: PropTypes.string,
  study_report: PropTypes.bool,
  review_media_lessons: PropTypes.array,
  media_lessons: PropTypes.array,
  photo_url: PropTypes.string,
  study_report_url: PropTypes.string,
  poster_photo_template_url: PropTypes.string,
  poster_creation: PropTypes.string,
  baby: PropTypes.object,
  module_review: PropTypes.bool,
  author: PropTypes.string,
  poetry_articles: PropTypes.array,
  today_quiz: PropTypes.object,
  onSetRemindInstallApp: PropTypes.func,
  reminderInstallAppModal: PropTypes.func,
  // handbook: PropTypes.bool,
  day_study_exams: PropTypes.array,
  platformEnv: PropTypes.object,
  study_type: PropTypes.number,
  media_course_name: PropTypes.string,
  sub_topic: PropTypes.string,
};

export default WithPlatformEnv(WithErrorBoundary(Reading));
