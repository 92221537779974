// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/c4/icon_fill_tips.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".tabsIndex{background:#fff;display:-webkit-flex;display:flex;font-size:14px;font-family:PingFang SC;font-weight:700;line-height:20px;width:100%;max-width:500px;position:fixed;top:0;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;-webkit-transform:translateZ(2px);transform:translateZ(2px);z-index:9;color:#333}.tabsIndex .tabs_wrapper_index{background:#fff9e7;padding:7px 15px;margin:8px 0 8px 20px;border-radius:17px}.tabsIndex .active{background:#ff973c;font-size:18px;line-height:25px;color:#fff;padding:4px 15px}.reading-new .comp_wrong_book{margin-top:60px!important}.reading-new .container-tip{font-size:16px;font-family:PingFang SC;font-weight:400;color:#fff;position:fixed;left:0;top:0;width:100%;height:100%;z-index:88}.reading-new .container-tip .container-tip-canvas{position:absolute;top:0;left:0}.reading-new .container-tip .container-tip-content,.reading-new .container-tip .container-tip-content-bg{width:280px;height:249px;position:absolute;top:50px}.reading-new .container-tip .container-tip-content-bg{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0/100% 100% no-repeat;-webkit-transform:rotateY(180deg);transform:rotateY(180deg)}.reading-new .container-tip .tip-content-wrapper{position:absolute;bottom:0;left:0;right:0}.reading-new .container-tip .tip-content{margin-left:25px;font-size:17px}.reading-new .container-tip .tip-button{background:#ff973c;margin:13px auto 9px;color:#fff;width:150px;height:40px;text-align:center;line-height:40px;font-size:17px;border-radius:30px}@media screen and (orientation:landscape)and (min-width:500px){.container .reading-new .comp_activity_left_icons .float_icons{right:calc(50% - 235px)!important}}@media screen and (min-width:500px){.container .reading-new .comp_activity_left_icons .float_icons{right:calc(50% - 235px)!important}}", ""]);
// Exports
module.exports = exports;
