import React from "react";
import PropTypes from "prop-types";

import ComponentProxy from "../core/ComponentProxy";

import "./ShareHintDialog.scss";

class ShareHintDialog extends React.Component {
  render() {
    return (
      <div className="comp-sharehint-dialog">
        <div className="share-inpage" onClick={this.props.onClose}>
          <div
            className="share-notice-container"
            dangerouslySetInnerHTML={{ __html: this.props.msg }}
          ></div>
        </div>
      </div>
    );
  }
}

ShareHintDialog.propTypes = {
  msg: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

ShareHintDialog.defaultProps = {
  msg: "点击右上角分享给朋友",
};

export default class ShareHintDialogProxy extends ComponentProxy {
  constructor(msg) {
    super();

    this.msg = msg;
  }

  createComponent() {
    return <ShareHintDialog msg={this.msg} onClose={() => this.cancel()} />;
  }
}
