// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/study_package/icon_note_course.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../assets/images/study_package/icon_star_yellow.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".poetry_bottom{text-align:center;padding:15px 0;position:fixed;bottom:0;width:100%;background:#faf8ed;box-shadow:0 -1px 1px rgba(0,0,0,.1)}.poetry_bottom .collect_star,.poetry_bottom .study_report{display:inline-block;padding:5px 5px 5px 30px;width:130px;border-radius:50px;color:#fff;position:relative;font-size:16px;background:#00d3dc}.poetry_bottom .collect_star:before,.poetry_bottom .study_report:before{content:\"\";position:absolute;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:14px;left:20px;background-position:1px 4px;width:28px;height:32px}.poetry_bottom .collect_star{background:#50bffb;margin-left:10%}.poetry_bottom .collect_star:before{width:28px;height:32px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:16px;left:15px;background-position:1px 4px;width:32px;height:30px}", ""]);
// Exports
module.exports = exports;
