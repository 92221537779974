import React from "react";
import styled from "styled-components";
import { Tabs, TabPane } from "../utils/Tabs/Tabs";
import ReadingIndex from "./reading_index/ReadingIndex";
import ReadingWord from "./reading_word/ReadingWord";
import RightFloatIcons from "../study_package_activities/RightFloatIcons.jsx";
import "./Index.scss";
import { ToastManager } from "../utils/Toast";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import { request, csrfHeaders } from "../utils/request";
import WrongBook from "../study_packages/poetries/v2/components/WrongBook";
// import { getDate } from "date-fns";
// import CourseWrongs from "../study_packages/CourseWrongs";
import { StudyPackageCommentModule } from "@/components/study_packages/UnitModeStudyPackage";
import icon_crystal from "point_rewards/crystal.png";
import CommodityList from "@/components/commodities/Index";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    console.log("index props is: ", props);
    this.state = {
      createRecommendRequestUrl: props.create_recommend_request_url,
      activeIndex: props.jump_to_wrong_quiz_sets ? 99 : 0,
      tabData: [
        ...props.study_package_units.map((t, ind) => {
          return {
            name: t.name,
            requestUrl: t.get_unit_study_url,
            id: t.id,
            comP: (data, props) => {
              return (
                <ReadingIndex
                  {...props}
                  getDataUrl={t.get_unit_study_url}
                  getDataId={t.id}
                  getData={this.props.getUnitStudy}
                ></ReadingIndex>
              );
            },
          };
        }),
      ],
      readingData: null,
      showCommoditiesTip: false,
      tipContentLeft: 0,
      commodities: null,
    };
    if (this.props.study_mode_version !== 4 && this.props.study_mode_version !== 5) {
      this.state.tabData.push({
        name: "好词好句",
        requestUrl: props.materials_url,
        id: "good",
        // requestUrl: peops.get_unit_study_url,
        comP: (data, props) => {
          return (
            <ReadingWord
              {...props}
              getDataUrl={props.materials_url}
              getData={this.getData3}
            ></ReadingWord>
          );
        },
      });
    }
    this.state.tabData.push({
      name: "错题本",
      requestUrl:
      props.course_type == "chinese"
      ? props.get_chinese_wrong_quiz_sets_url
      : props.course_type == "math"
      ? props.get_math_wrong_quiz_sets_url
      : props.get_revisal_quiz_sets_url,
      id: "Wrong",
      // requestUrl: peops.get_unit_study_url,
      comP: (data, props) => {
        return <WrongBook {...props} unitData={data}></WrongBook>;
        // return <CourseWrongs {...props}></CourseWrongs>;
      },
    })

    if (props.commodities_url) {
      this.state.tabData.push({
        name: "水晶商城",
        requestUrl: props.commodities_url,
        id: "commodities",
        comP: (data, props) => {
          if (data) {
            return <CommodityList {...data} url={props.commodities_url} containerStyle={{ paddingTop: 70, background: 'white' }} headerStyle={{ background: '#fff9e7' }} isInStudyPackage={true} />;
          } else {
            return "";
          }
        },
      })
      this.state.showCommoditiesTip = !props.point_market_reminded;
    }

    // if (this.props.jump_to_wrong_quiz_sets) {
    //   this.getDate2(
    //     props.course_type == "chinese"
    //       ? props.get_chinese_wrong_quiz_sets_url
    //       : props.course_type == "math"
    //       ? props.get_math_wrong_quiz_sets_url
    //       : props.get_revisal_quiz_sets_url
    //   );
    // }
  }
  getData(url, id) {
    return this.props.getUnitStudy(url, id);
  }
  getData3(url) {
    return request({
      url,
      method: "GET",
      headers: csrfHeaders,
    });
  }
  getData2(url) {
    const toast = ToastManager.showLoading();
    request({
      url,
      method: "GET",
      headers: csrfHeaders,
    }).then((res) => {
      console.log(res, "22222222222");
      this.setState({ readingDataWrong: { ...res.data } });
      toast.cancel();
    });
  }
  // setReadingData(data) {
  //   this.setState(
  //     {
  //       readingData: data,
  //     },
  //     () => {
  //       console.log(this);
  //     }
  //   );
  // }
  componentDidMount() {
    if (this.props.jump_to_wrong_quiz_sets)
      $(`.tabs_wrapper_index [data-index=2]`).trigger("click");

    let nodes = document.querySelectorAll('[data-tab-id="commodities"]');
    if (nodes.length > 0) {
      let node = nodes[0];
      let nodeLeft = node.offsetLeft + node.parentElement.offsetLeft;
      let nodeWidth = node.offsetWidth;

      if (this.tipCanvas) {
        let width = window.outerWidth;;
        let height = window.outerHeight;
        this.tipCanvas.width = width;
        this.tipCanvas.height = height;
        let ctx = this.tipCanvas.getContext('2d');
        ctx.rect(0, 0, width, height);
        ctx.ellipse(nodeLeft + nodeWidth / 2, 25, nodeWidth / 2 + 10, 18, 0, 0, 2 * Math.PI);
        ctx.fillStyle = 'rgba(0, 0, 0, 0.8)';
        ctx.fill('evenodd');
      }

      this.setState({ tipContentLeft: nodeLeft - 140 });
    }
  }
  handleBackCourse() {
    $(`.tabs_wrapper_index [data-index=0]`).trigger("click");
  }
  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });
  };

  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };

  // 请求接口数据成功之后, 链接置空
  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };

  handleWrongBookClick = () => {
    $(`.tabs_wrapper_index [data-index=2]`).trigger("click");
  };
  onInvitationIconClick = () => {
    const {
      get_shopping_group_data_url,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      study_package_activity_id,
    } = this.props;
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: get_shopping_group_data_url,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(
        ({
          bonus,
          had_joined_groupon,
          remaining_shopping_group_count,
          avatar_location,
        }) => {
          new SharePosterProxy({
            // posterUrl: this.props.poster_url,
            // posterImgSrc: this.props.poster_img_src,
            avatar_location,
            posterTemplate,
            posterCreation,
            mode: "front_end",
            free:
              had_joined_groupon && remaining_shopping_group_count > 0
                ? "in_or_open_shopping_group"
                : "none_shopping_group",
            bonus: bonus,
            sale_mode: "shopping_group",
            remaining_shopping_group_count,
          })
            .open({
              isShowToast: false,
            })
            .then(() => {
              toast.cancel();
              // GA("shortcut", "packagePoster", study_package_activity_id);
            });
        }
      )
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });
  };
  onStudyReportIconClick = () => {
    window.location.href = `${this.props.study_report_url}&ref=shortcut_course`;
  };

  onCommoditiesTipClick = () => {
    this.setState({ showCommoditiesTip: false });
    request({
      url: this.props.point_market_reminded_url,
      method: "POST",
      headers: csrfHeaders,
    })
  };

  tabClick(e) {
    let index = Number(e.target.getAttribute("data-index"));
    this.setState({ activeIndex: Number(index) });
    let tabItem = this.state.tabData[Number(index)];
    if (tabItem.id == "Wrong") {
      this.getData2(tabItem.requestUrl);
    } else if (tabItem.id === 'commodities') {
      const toast = ToastManager.showLoading();
      request({
        url: this.props.commodities_url,
        method: "GET",
        headers: csrfHeaders,
      }).then((res) => {
        console.log('data', res.data);
        this.setState({ commodities: res.data });
        toast.cancel();
      });
    }
    // if (this.state.tabData[index].id) {
    //   this.getData(
    //     this.state.tabData[index].requestUrl,
    //     this.state.tabData[index].id
    //   );
    // } else {
    //   this.getData2(
    //     this.state.tabData[index].requestUrl
    //     // this.state.tabData[index].id
    //   );
    // }
    // this.setState(
    //   {
    //     activeIndex: Number(index),
    //   },
    //   () => {
    //     if (this.state.tabData[index].id) {
    //       this.getData(
    //         this.state.tabData[index].requestUrl,
    //         this.state.tabData[index].id
    //       );
    //     } else {
    //       this.getData2(
    //         this.state.tabData[index].requestUrl
    //         // this.state.tabData[index].id
    //       );
    //     }
    //   }
    // );
    // console.log(e.currentTarget.value, "---------", e.target.value);
    // let { activeIndex } = this.state;
    // this.setState({
    //   activeIndex:activeIndex?
    // })
  }
  render() {
    return (
      <div className="reading-new">
        <Tabs
          styles="tabsIndex"
          defaultAction={this.tabClick.bind(this)}
          defaultActiveIndex={this.state.activeIndex}
        >
          {this.state.tabData.map((t, i) => {
            return (
              <TabPane
                tab={<div data-index={i}>{t.name}</div>}
                key={t.name + "a"}
                styles="tabs_wrapper_index"
                activeStyle="active"
                tabId={t.id}
              >
                {t.id === 'commodities' ? t.comP(this.state.commodities, this.props) : (
                  (t.id !== "Wrong" && !this.state.readingData) ||
                  (t.id == "Wrong" && this.state.readingDataWrong)
                    ? t.comP(this.state.readingDataWrong, this.props)
                    : ""
                )}
              </TabPane>
            );
          })}
        </Tabs>
        {this.state.activeIndex == 0 || this.state.activeIndex == 1 ? (
          <RightFloatIcons
            isShowMakeComment={
              this.props.comment_state === "uncommented" &&
              !!this.state.createRecommendRequestUrl
            }
            onMakeCommentClick={this.getWriteEvaluateStatus}
            isShowRedMoney={!!this.props.bonus}
            onRedMoneyClick={this.onInvitationIconClick}
            isShowReport={!!this.props.study_report_url}
            onReportClick={this.onStudyReportIconClick}
            isShowWrongBook
            onWongBookClick={this.handleWrongBookClick}
            // isShowSetting={this.props.trial_unlock_mode ? false : true}
            // onSetting={this.onSettingIconClick}
            bottomPosition={this.props.trial_unlock_mode ? 170 : 80}
            // isShowScrollTop
            // onScrollTop={this.handleBackToTop}
            // isShowBack={this.state.activeTabId == 1}
            // onBackClick={this.handleBackCourse}
          />
        ) : (
          <RightFloatIcons
            isShowBack
            onBackClick={this.handleBackCourse.bind(this)}
            // isShowSetting={this.props.trial_unlock_mode ? false : true}
            // onSetting={this.handleBackToTop}
            // isShowScrollTop
            // onScrollTop={this.handleBackToTop}
            bottomPosition={80}
          />
        )}
        {/* <TabPane></TabPane> */}
        {this.state.writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={this.state.createRecommendRequestUrl}
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment}
            bought_phase={this.props.bought_phase}
            promotion={this.props.promotion}
            rate={this.props.rate}
            name={this.props.study_package_name}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}

        {this.props.study_mode_version === 5 && this.state.showCommoditiesTip &&
          <div className="container-tip">
            <canvas className="container-tip-canvas" ref={r => { this.tipCanvas = r; }}></canvas>
            <div className="container-tip-content-bg" style={{ left: this.state.tipContentLeft }}>
            </div>
            <div className="container-tip-content" style={{ left: this.state.tipContentLeft }}>
              <div className="tip-content-wrapper">
                <div className="tip-content">
                  <div style={{ display: 'flex', alignItems: 'center' }}>上课得水晶<img src={icon_crystal} style={{ width: 18, height: 18 }} />，换奖品！</div>
                  <div style={{ marginTop: 5 }}>按时上课可收获更多水晶哦～</div>
                </div>
                <div className="tip-button" onClick={this.onCommoditiesTipClick}>我知道了</div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
