import React from "react";
import icon_badge from "study_package/leitai.png";
import dialog_star_0 from "study_package/dialog_star_0.png";
import dialog_star_1 from "study_package/dialog_star_1.png";
import dialog_star_2 from "study_package/dialog_star_2.png";
import dialog_star_3 from "study_package/dialog_star_3.png";

import UserAgent from "../../utils/UserAgent";
import { onMiniProgram } from "../../utils/helpers";
import ReportCard from "../../utils/bottom_report_card/PoetryReportCard";
import "./PoetryArena.scss";

export default class PoetryArena extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  onPoetryArean = (e) => {
    if (UserAgent.isMiniProgram()) {
      // sessionStorage.setItem('arenaWindowHref', window.location.href)
      onMiniProgram(e, e.currentTarget.href, {});
    }
  };

  render() {
    let {
      rank,
      url,
      study_report,
      study_report_url,
      baby,
      poster_url,
      rule_url,
    } = this.props;
    const arena_stars = [
      dialog_star_0,
      dialog_star_1,
      dialog_star_2,
      dialog_star_3,
    ];

    return (
      <div className="comp_poetry_arena">
        <div className="arena_pic">
          <img className="lazyload" data-src={icon_badge} alt="" />
        </div>
        <div className="star_and_battle">
          {"rank" in this.props ? (
            <div>
              <img className="lazyload" data-src={arena_stars[rank]} alt="" />
              <a
                className="once_again_battle"
                href={url}
                onClick={this.onPoetryArean}
              >
                再次挑战
              </a>
            </div>
          ) : (
            <a
              className="start_battle"
              href={url}
              style={{ marginTop: "75px" }}
              onClick={this.onPoetryArean}
            >
              开始挑战
            </a>
          )}
        </div>
        {study_report == true && (
          <ReportCard
            studyReport={study_report}
            studyReportUrl={study_report_url}
            posterUrl={poster_url}
            nickName={baby.nickname}
            ruleUrl={rule_url}
          />
        )}
      </div>
    );
  }
}
