/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useState } from "react";
import { MyProvider, useSelector } from "../../../utils/react-global-state";
import { ToastManager } from "../../../utils/Toast";
import { request, csrfHeaders } from "../../../utils/request.js";
import PoetryListStore from "./PoetryListStore.js";
import { Tabs, TabPane } from "../../../utils/Tabs/Tabs.js";
import { withUnitStudySubscription } from "../../withUnitStudySubscription.jsx";
import * as Components from "./components";
import { GA, GA_new } from "../../../utils/Env";
import Index from "../../../reading_files/Index";
import RewardCommodityList from "../../../point_reward/RewardCommodityList";
// import ReadingReport from "../../../reading_files/reading_report/ReadingReport";
// import ReadingSpecial from "../../../reading_files/reading_special/ReadingSpecial";
import "./PoetryList.scss";
// import CourseWrongs from "../../CourseWrongs";
import WrongBook from "./components/WrongBook";

const setTabLocalData = (key, value) => {
  window.sessionStorage && window.sessionStorage.setItem(key, value);
};

const getTabLocalData = (key) => {
  const val = window.sessionStorage && window.sessionStorage.getItem(key);
  return val;
};

const SliderMenu = memo((props) => {
  console.log("SliderMenu_props:Poetr ", props);
  const { renderTabPaneContent, isSpell, isSanZiJing, getUnitStudy } = props;
  const [unitData, setUnitData] = useState(null);
  const [activeTabId, setActiveTabId] = useState(props.activeTabId);

  const shareTitle = useSelector((state) => state.shareTitle);
  const shareUrl = useSelector((state) => state.shareUrl);
  const shareDescription = useSelector((state) => state.shareDescription);
  const shareImageUrl = useSelector((state) => state.shareImageUrl);

  const parentGuideUrl = useSelector((state) => state.parentGuideUrl);
  const studyPackageUnits = useSelector((state) => state.studyPackageUnits);
  const getPoetryArticlesUrl = useSelector(
    (state) => state.getPoetryArticlesUrl
  );
  const myAudiosUrl = useSelector((state) => state.myAudiosUrl);
  const isReading = useSelector((state) => state.isReading);

  const TOP_TABS = [
    {
      name: "家长须知",
      Comp: Components.ParentGuide,
      requestUrl: parentGuideUrl,
    },
    ...(studyPackageUnits || []).map((unit) => ({
      id: unit.id,
      name: unit.name,
      Comp: isReading ? Components.ReadingList : Components.SimplePoetryList,
      requestUrl: unit.get_unit_study_url,
    })),
  ];

  if (isSanZiJing) {
    TOP_TABS.push({
      name: isSanZiJing ? "我的作品" : "我的音频",
      Comp: Components.MyAudios,
      requestUrl: myAudiosUrl,
    });
  } else if (isSpell) {
    TOP_TABS.push(
      {
        name: isSpell ? "百家姓" : isSanZiJing ? "全文诵读" : "泛读诗",
        Comp: Components.ExtensiveReading,
        requestUrl: getPoetryArticlesUrl,
      },
      {
        name: isSanZiJing ? "我的作品" : "我的音频",
        Comp: Components.MyAudios,
        requestUrl: myAudiosUrl,
      }
    );
  }
  if (isReading) {
    TOP_TABS.push({
      name: "错题本",
      Comp: WrongBook,
      requestUrl:
        props.course_type == "chinese"
          ? props.get_chinese_wrong_quiz_sets_url
          : props.course_type == "math"
          ? props.get_math_wrong_quiz_sets_url
          : props.get_revisal_quiz_sets_url,
    });
  }
  const UNITS = TOP_TABS.filter((item) => (item != null ? item : ""));

  console.log("UNITS: ", UNITS);

  const setPackageModuleStudies = (params) => {
    // 针对的是课程模块的数据请求
    setUnitData(null);
    const toast = ToastManager.showLoading("加载中");
    const { requestUrl, id } = params;
    return getUnitStudy(requestUrl, id)
      .then((res) => {
        console.log("getChineseDayStudies_res111111111111111: ", res);
        toast.cancel();
        setUnitData(res);
      })
      .catch((err) => {
        setUnitData(null);
        toast.cancel();
        console.log(err);
      });
  };

  const commonSenseModuleStudies = (requestUrl) => {
    // 针对的是通识模块的数据请求
    if (requestUrl) {
      setUnitData(null);
      const toast = ToastManager.showLoading("加载中...");
      request({
        url: requestUrl,
        method: "GET",
        headers: csrfHeaders,
      })
        .then(({ data }) => {
          console.log(
            "getChineseCommonSenseModule_data11111111111111111: ",
            data
          );
          setUnitData(data);
          toast.cancel();
        })
        .catch((err) => {
          setUnitData(null);
          toast.cancel();
          console.log(err);
        });
    }
  };
  // const getWrongBook = (requestUrl) => {
  //   // 针对的是通识模块的数据请求
  //   if (requestUrl) {
  //     setUnitData(null);
  //     const toast = ToastManager.showLoading("加载中...");
  //     request({
  //       url: requestUrl,
  //       method: "GET",
  //       headers: csrfHeaders,
  //     })
  //       .then(({ data }) => {
  //         console.log(
  //           "getChineseCommonSenseModule_data11111111111111111: ",
  //           data
  //         );
  //         setUnitData(data);
  //         toast.cancel();
  //       })
  //       .catch((err) => {
  //         setUnitData(null);
  //         toast.cancel();
  //         console.log(err);
  //       });
  //   }
  // };

  const tabClick = (e, activeTabId) => {
    setActiveTabId(activeTabId);
    activeTabId = activeTabId >= UNITS.length ? 0 : activeTabId;
    // tab_select=UNITS[0].name=="家长须知"?["tab1", "tab2"]
    let tabs =
      UNITS[activeTabId]?.handbook ||
      UNITS[activeTabId]?.name == "家长须知" ||
      UNITS[activeTabId]?.name == "学前体验"
        ? "parent_guide"
        : UNITS[activeTabId]?.name == "错题本"
        ? "wrong_quiz"
        : UNITS[0]?.name == "家长须知" ||
          UNITS[0]?.name == "学前体验" ||
          UNITS[0]?.handbook
        ? "tab" + activeTabId
        : "tab" + (1 + activeTabId);

    GA(
      props.sub_course_type == "poetry"
        ? "study_package_details"
        : props.sub_course_type == "reading"
        ? "study_package_details"
        : "study_package_details",
      tabs,
      props.study_package_activity_id
    );
    GA_new(
      props.sub_course_type == "poetry"
        ? "study_package_details"
        : props.sub_course_type == "reading"
        ? "study_package_details"
        : "study_package_details",
      tabs,
      props.study_package_activity_id,
      props.target_type,
      props.target_id
    );
    if (UNITS[activeTabId]?.name == "错题本") {
      console.log(activeTabId, "错题本==================");
    } else {
      console.log(activeTabId, "setTabLocalData==================");
      setTabLocalData(
        "poetryActiveTabIndex_" + props.study_package_activity_id,
        activeTabId
      );
    }

    const { requestUrl, id } = UNITS[activeTabId || 0];
    if (id) {
      setPackageModuleStudies(UNITS[activeTabId]);
    } else {
      commonSenseModuleStudies(requestUrl);
    }
  };

  useEffect(() => {
    // 默认的首屏unit
    if (props.jump_to_wrong_quiz_sets) {
      tabClick(null, UNITS.length - 1);
    } else {
      tabClick(null, activeTabId);
    }

    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: shareTitle,
        link: shareUrl,
        desc: shareDescription,
        imgUrl: shareImageUrl,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs
      defaultAction={tabClick}
      styles="head_menu_ul clear_scroll_bar reading_head_menu_ul"
      defaultActiveIndex={Number(
        props.jump_to_wrong_quiz_sets ? UNITS.length - 1 : activeTabId
      )}
      animatedTabTitle={true}
    >
      {UNITS.map((unit, index) => (
        <TabPane
          tab={<div className="unit_pane_name">{unit.name}</div>}
          styles="head_menu_li"
          activeStyle={`tab_light ${isReading ? "reading" : ""}`}
          key={index}
        >
          {unitData
            ? renderTabPaneContent({
                UnitComp: unit.Comp,
                unitName: unit.name,
                unitData,
                requestUrl: unit.requestUrl,
                isShowFloat: unit.name == "错题本" && isReading,
                limitTop: true,
              })
            : ""}
        </TabPane>
      ))}
    </Tabs>
  );
});

SliderMenu.displayName = "SliderMenu";

const CourseListSection = memo(({ UnitComp, ...restProps }) => {
  return (
    <div className="course_list_section_container">
      <UnitComp {...restProps} />
    </div>
  );
});

CourseListSection.displayName = "CourseListSection";

const PoetryList = memo((props) => {
  console.log("PoetryList props: ", props);
  const {
    getDefaultActiveUnit,
    study_package_system_time,
    study_package_start_time,
    handbook_has_studied,
    study_package_units,
    is_spell,
    sub_course_type,
    getUnitStudy,
  } = props;

  const { index, item } = getDefaultActiveUnit();
  let lastUsedActiveTabId;
  if (
    new Date(study_package_system_time) < new Date(study_package_start_time)
  ) {
    // 系统时间 < 正价课开始时间（还没到正价课课程包开始时间）
    if (handbook_has_studied) {
      // 学过体验课, 定位到“体验课”
      lastUsedActiveTabId = 1;
    } else {
      // 没学过体验课, 定位到“家长须知”
      lastUsedActiveTabId = 0;
    }
  } else {
    console.log("==1111111111=============================", index);
    lastUsedActiveTabId = index + 1;
    console.log(
      "==2222222222=============================",
      lastUsedActiveTabId
    );
  }

  const poetryActiveTabIndex = getTabLocalData(
    "poetryActiveTabIndex_" + props.study_package_activity_id
  );
  if (
    poetryActiveTabIndex &&
    (study_package_units && study_package_units.length + 1) ==
      poetryActiveTabIndex
  ) {
    console.log(
      poetryActiveTabIndex,
      "poetryActiveTabIndex000study_package_units",
      study_package_units
    );
    lastUsedActiveTabId = poetryActiveTabIndex;
  }

  console.log("getDefaultActiveUnit: ", getDefaultActiveUnit());
  console.log("lastUsedActiveTabId: ", lastUsedActiveTabId);
  return (
    <div
      className={
        props.study_mode == "reading_v2"
          ? ""
          : "comp_new_poetry_list clear_scroll_bar"
      }
      id="_scroll_list"
    >
      {props.study_mode == "reading_v2" ? (
        <Index {...props}></Index>
      ) : (
        <SliderMenu
          isSanZiJing={sub_course_type === "sanzijing"}
          isSpell={is_spell}
          target_type={props.target_type}
          target_id={props.target_id}
          activeTabId={lastUsedActiveTabId}
          sub_course_type={props.sub_course_type}
          study_package_activity_id={props.study_package_activity_id}
          course_type={props.course_type}
          get_chinese_wrong_quiz_sets_url={
            props.get_chinese_wrong_quiz_sets_url
          }
          get_math_wrong_quiz_sets_url={props.get_math_wrong_quiz_sets_url}
          get_revisal_quiz_sets_url={props.get_revisal_quiz_sets_url}
          renderTabPaneContent={(extraProps) => (
            <CourseListSection {...extraProps} {...props} />
          )}
          jump_to_wrong_quiz_sets={props.jump_to_wrong_quiz_sets}
          getUnitStudy={getUnitStudy}
        />
      )}
      {/* <ReadingReport></ReadingReport> */}
      {/* <ReadingSpecial></ReadingSpecial> */}
      {/* <ReadingDetails></ReadingDetails> */}
      {/* <Index {...props}></Index> */}
      {/* <SliderMenu
        isSanZiJing={sub_course_type === "sanzijing"}
        isSpell={is_spell}
        target_type={props.target_type}
        target_id={props.target_id}
        activeTabId={lastUsedActiveTabId}
        sub_course_type={props.sub_course_type}
        study_package_activity_id={props.study_package_activity_id}
        renderTabPaneContent={(extraProps) => (
          <CourseListSection {...extraProps} {...props} />
        )}
        getUnitStudy={getUnitStudy}
      /> */}
    </div>
  );
});

PoetryList.displayName = "PoetryList";

const ChooseRewardContainer = (props) => {
  const [showChooseReward, setShowChooseReward] = useState(
    props.should_show_commodity_list
  );

  const findActiveIndex = () => {
    for (let i = 0; i < props.exchange_commodities.commodities.length; i++) {
      if (
        props.exchange_commodities.selected ===
        props.exchange_commodities.commodities[i].commodity.id
      ) {
        return i;
      }
    }
    return -1;
  };

  const hideChooseReward = () => {
    setShowChooseReward(false);
  };

  const chooseLater = () => {
    setShowChooseReward(false);
    ToastManager.showToastText("下次进入课表可重新选择哦", 2000);
  };

  if (showChooseReward && props.exchange_commodities.commodities.length > 0) {
    return (
      <RewardCommodityList
        list={props.exchange_commodities.commodities}
        selectRewardPath={props.select_reward_commodity_path}
        studyPackageActivityId={props.study_package_activity_id}
        activeIndex={findActiveIndex()}
        commoditiesPath={props.commodities_path}
        confirmExchangeOption={hideChooseReward}
        chooseLater={chooseLater}
        maxRewardPoints={props.max_reward_points}
        studyReportUrl={props.study_report_url}
        isDemoClass={props.is_demo_class}
      />
    );
  } else {
    return "";
  }
};

const NewProvider = (props) => {
  console.log("PoetryList NewProvider: ", props);
  const WithUnitStudySubscription = withUnitStudySubscription(PoetryList);

  return (
    <MyProvider
      store={PoetryListStore.build({
        ...props,
        parentGuideUrl: props.parent_guide_url,
        studyPackageUnits: props.study_package_units || [],
        getPoetryArticlesUrl: props.get_poetry_articles_url,
        myAudiosUrl: props.my_audios_url,
        shareTitle: props.share_title,
        shareDescription: props.share_description,
        shareUrl: props.share_url,
        shareImageUrl: props.share_image_url,
        isReading: props.sub_course_type === "reading",
      })}
    >
      <ChooseRewardContainer {...props} />
      <WithUnitStudySubscription {...props} />
    </MyProvider>
  );
};

NewProvider.displayName = "PoetryListProvider";

export default NewProvider;
