/* eslint-disable react/prop-types */
import React, { useState } from "react";
import styled from "styled-components";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import ModalMask from "../utils/Modal/ModalMask";
import Raven from "raven-js";

import checkbox_normal from "study_package/checkbox_normal.png";
import checkbox_active from "study_package/checkbox_active.png";
import foot_background from "study_package/background.png";
import icon_tips from "study_package/icon_tips.png";

const SelectScheduleContainer = styled.div`
  height: 100vh;
  background: #d2e6f0 url(${foot_background}) no-repeat bottom center / contain;
  padding-top: 10vh;
`;
const ScheduleGuideBox = styled.div`
  background: #fff;
  width: 94%;
  margin: 0 auto 0;
  border-radius: 8px;
  box-shadow: 0px 1px 0px 2px rgba(199, 218, 228, 0.2);
  padding: 15px 15px;
`;
const Title = styled.div`
  text-align: center;
  margin-bottom: 30px;
  p {
    padding: 0;
    margin: 0;
    font-weight: 700;
  }
  a {
    color: #5b9ce3;
    font-size: 13px;
    text-decoration: underline;
  }
`;
const OptionItemsBox = styled.div``;
const OptionItem = styled.div`
  ${({ highlight }) =>
    highlight
      ? {
          backgroundColor: "#f4feee",
          border: ".5px solid #6cbe3b",
        }
      : {
          border: ".5px solid #dedede",
        }}
  padding: 10px 10px 10px 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_box {
    .course_mode_text {
      font-weight: 700;
      ${({ highlight }) =>
        highlight && {
          color: "#6cbe3b",
        }}
    }
    p {
      padding: 0;
      margin: 0;
      font-size: 12px;
      color: #777;
      ${({ highlight }) =>
        highlight && {
          color: "#6cbe3b",
        }}
    }
  }
  .right_box {
    display: flex;
    img {
      min-width: 38px;
      width: 38px;
      height: 38px;
    }
  }
`;
const Button = styled.div`
  font-size: 18px;
  border-radius: 50px;
  background-color: #ddd;
  color: #fff;
  line-height: 50px;
  height: 50px;
  text-align: center;
  margin: 40px 0 10px;
  ${({ buttonActive }) =>
    buttonActive && {
      backgroundColor: "#6cbe3b",
    }}
`;
const NoteWarn = styled.div`
  text-align: center;
  font-size: 12px;
  color: #e86d74;
  padding: 5px 0;
`;

const OptionIndexs = styled.div`
  color: #666;
  font-size: 12px;
  text-align: center;
  padding-top: 15px;
`;

const ActionDialog = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  width: 86%;
  margin-left: -43%;
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
  padding: 20px 15px;
  .content {
    padding-bottom: 30px;
    img {
      min-width: 64px;
      width: 64px;
      height: 64px;
      margin-bottom: 5px;
    }
  }
  .dialog_buttons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    .confirm,
    .close {
      flex: 1;
      border-radius: 50px;
      height: 40px;
      line-height: 40px;
    }
    .confirm {
      background: #6cbe3b;
      color: #fff;
    }
    .close {
      background: #fff;
      color: #6cbe3b;
      border: 0.5px solid #6cbe3b;
      margin-left: 15px;
    }
  }
`;

let answers = {};
let mode_id;
let is_show_legacy;
const SelectSchedule = ({ ...props }) => {
  const select_collections = [
    {
      id: 0,
      title: `选择适合${props?.baby?.nickname}的上课模式`,
      note_url: "https://mp.weixin.qq.com/s/57VuUIHgXbocsDDQFQPAlw",
      options: [
        {
          mode_id: 1,
          class_mode: "正常上课模式",
          brief: "按每天20分钟左右排课，适合全新学习",
          type: "number",
        },
        {
          mode_id: 2,
          class_mode: "复习模式",
          brief: "按知识点排课，适合有针对性的复习",
          type: "number",
        },
      ],
      note_warn: [
        { note: "选择上课模式之后会立即开课" },
        { note: "暂时不想开课的家长可等到想开课时再选择" },
      ],
    },
    {
      id: 1,
      title: "是否在新课表里显示之前的学习数据",
      options: [
        {
          is_show_legacy: true,
          class_mode: "显示",
          brief: "之前的学习数据会在新课表里显示",
          type: "boolean",
        },
        {
          is_show_legacy: false,
          class_mode: "不显示",
          brief: "完全全新的课表",
          type: "boolean",
        },
      ],
    },
  ];

  const schedule_arr = props.has_legacy_data
    ? select_collections
    : [select_collections.shift()];

  const [currentQuiz, setCurrentQuiz] = useState(schedule_arr[0]);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(1);
  const [optionIndex, setOptionIndex] = useState(-1);
  const [buttonActive, setButtonActive] = useState(false);
  const [optionType, setOptionType] = useState("");

  const handleOptionClick = (e) => {
    const index = e.currentTarget.getAttribute("data-index");
    const type = e.currentTarget.getAttribute("data-type");
    setOptionIndex(index);
    setOptionType(type);
    setButtonActive(true);
  };

  const handleButtonClick = (e) => {
    if (!buttonActive) {
      alert("选项不能为空");
      return;
    }
    dialogSecondConfirm().open();
  };

  const dialogSecondConfirm = () => {
    return new ModalManager({
      render: (_, modalClose) => {
        const onClose = () => {
          modalClose();
        };
        const confirmOk = () => {
          setNextQuiz();
          modalClose();
        };
        return (
          <ModalMask>
            <ActionDialogBox confirmOk={confirmOk} onConfirm={onClose} />
          </ModalMask>
        );
      },
    });
  };

  const ActionDialogBox = ({ confirmOk, onConfirm }) => {
    return (
      <ActionDialog>
        <div className="content">
          <img src={icon_tips} alt="" />
          <p>上课模式选择确定之后</p>
          <p>无法再更改</p>
        </div>
        <div className="dialog_buttons">
          <div className="confirm" onClick={confirmOk}>
            确定
          </div>
          <div className="close" onClick={onConfirm}>
            重新选择
          </div>
        </div>
      </ActionDialog>
    );
  };

  const lastAjaxSubmit = () => {
    const text = props.has_legacy_data ? "数据迁移中..." : "提交中...";
    const toast = ToastManager.showLoading(text);
    request({
      url: props.request_url,
      method: "POST",
      headers: csrfHeaders,
      data: answers,
    })
      .then((resp) => {
        toast.cancel();
        const url = resp.data?.url;
        window.location.href = url;
      })
      .catch((err) => {
        toast.cancel();
        Raven.captureException(err);
        alert(err);
      });
  };

  const setAnswers = (quiz) => {
    if (optionType == "number") {
      mode_id = quiz.options[optionIndex].mode_id;
    }
    if (optionType == "boolean") {
      is_show_legacy = quiz.options[optionIndex].is_show_legacy;
    }

    answers = props.has_legacy_data
      ? {
          mode_id,
          is_show_legacy,
        }
      : {
          mode_id,
        };
    console.log("answers", answers);
  };

  const setNextQuiz = () => {
    const quiz = currentQuiz;
    const index = schedule_arr.findIndex((element) => element.id == quiz.id);
    const nextQuiz = schedule_arr[index + 1];
    // console.log("(上一题)已提交的索引，题目: ", index + 1, quiz);
    // console.log("当前题目的索引，题目: ", index + 1, nextQuiz);
    setAnswers(quiz);

    if (isLastQuiz()) {
      if (!buttonActive) {
        alert("选项不能为空");
        return;
      }
      lastAjaxSubmit();
      return;
    }
    setCurrentQuizIndex(index + 2);
    setCurrentQuiz(nextQuiz);
    setOptionIndex(-1);
    setButtonActive(false);
  };

  const isLastQuiz = () => {
    const lastQuiz = schedule_arr[schedule_arr.length - 1];
    return lastQuiz && lastQuiz.id == currentQuiz.id;
  };
  return (
    <SelectScheduleContainer>
      <ScheduleGuideBox>
        <Title>
          <p>{currentQuiz.title}</p>
          {currentQuiz.note_url && (
            <a href={currentQuiz.note_url}>两种上课模式详细介绍 »</a>
          )}
        </Title>
        <OptionItemsBox>
          {currentQuiz.options.map((option, index) => (
            <OptionItemSection
              key={index}
              handleOptionClick={handleOptionClick}
              option={option}
              index={index}
              optionIndex={optionIndex}
            />
          ))}
        </OptionItemsBox>
        <Button onClick={handleButtonClick} buttonActive={buttonActive}>
          确定
        </Button>
        {currentQuiz.note_warn && (
          <NoteWarn>
            {currentQuiz.note_warn.map((item, index) => (
              <div className="note" key={index}>
                {item.note}
              </div>
            ))}
          </NoteWarn>
        )}
      </ScheduleGuideBox>
      <OptionIndexs>{`${currentQuizIndex}/${schedule_arr.length}`}</OptionIndexs>
    </SelectScheduleContainer>
  );
};

const OptionItemSection = ({ ...props }) => {
  const { handleOptionClick, option, index, optionIndex } = props;
  return (
    <OptionItem
      data-index={index}
      data-type={option.type}
      onClick={handleOptionClick}
      highlight={index == optionIndex}
    >
      <div className="left_box">
        <div className="course_mode_text">{option.class_mode}</div>
        <p>{option.brief}</p>
      </div>
      <div className="right_box">
        <img src={index == optionIndex ? checkbox_active : checkbox_normal} />
      </div>
    </OptionItem>
  );
};

export default SelectSchedule;
