import React from "react";
import ReactMarkdown from "react-markdown";
import "./PoetryRule.scss";
import "../../utils/ReactMarkDown.scss";

export default class PoetryRule extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }
  render() {
    return (
      <div className="comp_poetry_rule">
        <div className="collect_star_rule">集星规则</div>
        <div className="rule_content markdown_content">
          <ReactMarkdown source={this.props.rank_rule} />
        </div>
      </div>
    );
  }
}
