/* eslint-disable no-sequences */
/* eslint-disable react/prop-types */
import React, { memo, useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  MyProvider,
  CreateStore,
  useSelector,
} from "../../../utils/react-global-state";
import { media } from "../../../utils/helpers";
import { AudioCommunity } from "./components";
import { useEnv } from "../../../utils/WithPlatformEnv.jsx";
import { SharePosterProxy } from "../../../promotion_activities/SharePoster";
import ListContainer from "../../../utils/ListContainer/ListContainer";
import icon_star_0 from "study_package/icon_star_0.png";
import icon_star_1 from "study_package/icon_star_1.png";
import icon_star_2 from "study_package/icon_star_2.png";
import icon_star_3 from "study_package/icon_star_3.png";
import icon_1 from "pinyin/icon_1.png";
import icon_2 from "pinyin/icon_2.png";
import icon_3 from "pinyin/icon_3.png";
import icon_4 from "pinyin/icon_4.png";
import icon_5 from "pinyin/icon_5.png";
import icon_book from "pinyin/icon_book.png";
import icon_test from "pinyin/icon_test.png";
import arrow_right from "icon_thin_blank_right.png";
import sanzijingHeaderBottom from "sanzijing_header_bottom.png";
import sanzijingHeaderTop from "sanzijing_header_top.png";
import duyuanwen from "duyuanwen.png";
import huanxin from "huanxin.png";
import lianyilian from "lianyilian.png";
import peiyuedu from "peiyuedu.png";
import qiaojiyi from "qiaojiyi.png";
import shihanzi from "shihanzi.png";
import tuoshiye from "tuoshiye.png";
import zhiwenyi from "zhiwenyi.png";

import "./Spell.scss";

const backgroundColorCollection = [
  {
    background: "#fc6876",
  },
  {
    background: "#fe8753",
  },
  {
    background: "#a1db2c",
  },
  {
    background: "#ffc002",
  },
  {
    background: "#57c5e9",
  },
];

const backgroundTestColor = [
  {
    background: "#915bf4",
  },
  {
    background: "#3ae1c7",
  },
];

const ICON_STARS = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];
const IconIllustrations = [icon_1, icon_2, icon_3, icon_4, icon_5];
const IconBookTest = [icon_book, icon_test];
const mapSanZiJingLessonIcon = (lessonName) => {
  if (lessonName === "读原文") {
    return duyuanwen;
  }
  if (lessonName === "唤醒") {
    return huanxin;
  }
  if (lessonName === "练一练") {
    return lianyilian;
  }
  if (lessonName === "配乐读") {
    return peiyuedu;
  }
  if (lessonName === "巧记忆") {
    return qiaojiyi;
  }
  if (lessonName === "识汉字") {
    return shihanzi;
  }
  if (lessonName === "拓视野") {
    return tuoshiye;
  }
  if (lessonName === "知文意") {
    return zhiwenyi;
  }
};

const AudiosTogether = memo(() => {
  const community = useSelector((state) => state.community);
  const systemTime = useSelector((state) => state.systemTime);
  const isCourseFinished = useSelector((state) => state.isCourseFinished);
  const poetryName = useSelector((state) => state.name);
  const mediaCourseName = useSelector((state) => state.mediaCourseName);
  const {
    platformEnv: { isNativeClient },
  } = useEnv();
  const isSanZiJing = useSelector((state) => state.isSanZiJing);

  const poetryTap = useCallback(
    (e) => {
      const url = e.currentTarget.getAttribute("data-url");

      if (url) {
        if (isNativeClient) {
          // eslint-disable-next-line no-undef
          XinYaNativeBridge.navigate({ href: url });
        } else {
          window.location.href = url;
        }
      }
    },
    [isNativeClient]
  );
  return (
    <React.Fragment>
      <div className="section_title_common audios">
        <p>作品墙</p>
      </div>
      <div className="learning_together">
        {community && (
          <AudioCommunity
            community={community}
            poetryName={mediaCourseName || poetryName}
            systemTime={systemTime}
            courseFinishCondition={isCourseFinished}
            poetryTap={poetryTap}
            isSanZiJing={isSanZiJing}
          />
        )}
      </div>
    </React.Fragment>
  );
});

const LessonScoreStatus = React.memo(({ lessonRank }) => {
  if (typeof lessonRank === "number") {
    return (
      <img
        className="poetry_star lazyload"
        data-src={ICON_STARS[lessonRank]}
        alt=""
      />
    );
  }
  return (
    <img className="poetry_start lazyload" data-src={arrow_right} alt="" />
  );
});

const SingleCollection = React.memo(({ item, index, render }) => {
  const isSanZiJing = useSelector((state) => state.isSanZiJing);
  return (
    <a href={item.url} className="lesson_item">
      <img
        className="illustration"
        src={item.photo_url}
        alt=""
        style={isSanZiJing ? { width: 32 } : {}}
      />
      <div className="text">{item.name}</div>

      {render()}
    </a>
  );
});

const StyledSanZiJingListContent = styled.div`
  box-shadow: 0px 4px 11px 1px rgba(155, 155, 155, 0.5);
  border-radius: 32px 32px 22px 22px !important;
  overflow: hidden;
  padding: 0 30px;
  background: white;
  border-radius: 0px 0px 25px 25px;
  padding-bottom: 12px;
  padding-top: 30px;
`;

const StyledSanZiJingListHeader = styled.div`
  position: relative;
  height: 50px;
  text-align: center;
  top: 23px;
  .header_bottom {
    left: 0;
    position: absolute;
    z-index: -1;
    width: 108%;
    margin-left: calc(-4%);

    ${media.aboveTablet`top:-23px;`};
    ${media.aboveDesktop`top:-18px;`};
    ${media.miniPhone`top: 5px;`};
  }
  .header_top {
    left: 0;
    top: 45%;
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
    ${media.aboveTablet` top:19%;`};
    ${media.miniPhone` top:36%;`};
  }
  .title {
    left: 0;
    font-size: 22px;
    letter-spacing: 1px;
    color: #4d3b34;
    font-weight: bold;
    position: absolute;
    width: 100%;
    top: 45%;
    transform: translateY(-50%);
    ${media.aboveTablet`top:19%;`};
    ${media.miniPhone`top:36%;`};
  }
`;

const SanZiJingList = () => {
  const dealMediaLessons = useSelector((state) => state.dealMediaLessons);
  const name = useSelector((state) => state.name);
  const namePhotoUrl = useSelector((state) => state.namePhotoUrl);
  return (
    <>
      <StyledSanZiJingListHeader>
        <img src={sanzijingHeaderBottom} alt="" className="header_bottom" />
        <img src={sanzijingHeaderTop} alt="" className="header_top" />
        <div className="title"> {!namePhotoUrl && name ? name : ""}</div>
      </StyledSanZiJingListHeader>
      <StyledSanZiJingListContent>
        <ListContainer
          list={dealMediaLessons}
          listItem={({ itemIndex, ...item }) => (
            <SingleCollection
              key={itemIndex}
              item={item}
              index={itemIndex}
              render={() => <LessonScoreStatus lessonRank={item.rank} />}
            />
          )}
        />
      </StyledSanZiJingListContent>
      <AudiosTogether />
    </>
  );
};

const SpellList = () => {
  const namePhotoUrl = useSelector((state) => state.namePhotoUrl);
  const dealMediaLessons = useSelector((state) => state.dealMediaLessons);
  const name = useSelector((state) => state.name);
  return (
    <>
      <div
        className="list_header"
        style={namePhotoUrl && { backgroundImage: `url(${namePhotoUrl})` }}
      >
        {!namePhotoUrl && name ? name : ""}
      </div>

      <div className="list_content">
        <ListContainer
          list={dealMediaLessons}
          listItem={({ itemIndex, ...item }) => (
            <SingleCollection
              key={itemIndex}
              item={item}
              index={itemIndex}
              render={() => <LessonScoreStatus lessonRank={item.rank} />}
            />
          )}
        />
      </div>
    </>
  );
};

const StyledButtonItem = styled.div`
  width: 140px;
  height: 36px;
  border-radius: 20px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  line-height: 36px;
  display: block;
`;

const StyledStudyReportButton = styled(StyledButtonItem)`
  background-color: #50bffb;
`;

const StyledCheckInPosterButton = styled(StyledButtonItem)`
  background-color: #00d3dc;
  margin-left: 12px;
`;

const CheckInPoster = () => {
  const babyName = useSelector((state) => state.babyName);
  const posterTemplate = useSelector((state) => state.posterTemplate);
  const posterCreation = useSelector((state) => state.posterCreation);
  const cardShareClick = () => {
    new SharePosterProxy({
      posterTemplate,
      posterCreation,
      mode: "front_end",
      free: "poetry_share",
      nickName: babyName,
      isNewPoetry: true,
    }).open();
  };
  return (
    <StyledCheckInPosterButton onClick={cardShareClick}>
      打卡海报
    </StyledCheckInPosterButton>
  );
};

const StudyReportButton = () => {
  const studyReportUrl = useSelector((state) => state.studyReportUrl);

  return (
    <StyledStudyReportButton as="a" href={`${studyReportUrl}&ref=daily_course`}>
      学习报告
    </StyledStudyReportButton>
  );
};

const StyledBottomButtonGroup = styled.div`
  position: fixed;
  z-index: 500;
  bottom: 20px;
  margin-left: -20px;
  width: 100%;
  text-align: center;
  color: #fff;
  max-width: 500px;
  .flex_wrapper {
    width: 100%;
    height: 100%;

    justify-content: center;
    align-items: center;
    display: flex;
  }
`;

const BottomButtonGroup = () => {
  const isSanZiJing = useSelector((state) => state.isSanZiJing);
  const posterTemplate = useSelector((state) => state.posterTemplate);
  return (
    <StyledBottomButtonGroup>
      <div className="flex_wrapper">
        <StudyReportButton />
        {isSanZiJing && posterTemplate && <CheckInPoster />}
      </div>
    </StyledBottomButtonGroup>
  );
};

const Spell = memo(() => {
  const photoUrl = useSelector((state) => state.photoUrl);

  const isSpell = useSelector((state) => state.isSpell);
  const isSanZiJing = useSelector((state) => state.isSanZiJing);

  const studyReport = useSelector((state) => state.studyReport);
  const studyReportUrl = useSelector((state) => state.studyReportUrl);
  return (
    <div
      className="pinyin_schedule_detail"
      style={{ backgroundColor: isSanZiJing ? "#ffffff" : "" }}
    >
      <div className="details_banner">
        <img src={photoUrl} />
      </div>
      <div
        className={`schedule_detail_list ${
          studyReport && studyReportUrl ? "report_detail_style" : ""
        }`}
      >
        {isSpell && <SpellList />}
        {isSanZiJing && <SanZiJingList />}
      </div>

      {studyReport && studyReportUrl && <BottomButtonGroup />}
    </div>
  );
});

const SimilarSpellCourseListProvider = (props) => {
  console.log(props);
  const {
    baby,
    community,
    system_time: systemTime,
    study_report: isCourseFinished,
    media_lessons: mediaLessons,
    today_quiz: todayQuiz,
    study_report: studyReport,
    study_report_url: studyReportUrl,
    review_media_lessons: reviewMediaLessons,
    day_study_exams: dayStudyExams,
    photo_url: photoUrl,
    name_photo_url: namePhotoUrl,
    media_course_name: mediaCourseName,
    name,
    sub_course_type,
    isSpell = sub_course_type === "spell",
    isSanZiJing = sub_course_type === "sanzijing",
    poster_photo_template_url,
    poster_creation,
  } = props;

  if (mediaLessons && mediaLessons.length > 0) {
    mediaLessons.forEach((lesson, index) => {
      lesson.type = "media_lesson";
      lesson.photo_url = IconIllustrations[index];
      lesson.bg_color = backgroundColorCollection[index].background;
      if (isSanZiJing) {
        lesson.photo_url = mapSanZiJingLessonIcon(lesson.name);
      }
    });
  }

  if (dayStudyExams && dayStudyExams.length > 0) {
    dayStudyExams.forEach((exam, index) => {
      exam.type = "day_study_exam";
      exam.photo_url = IconBookTest[1];
      exam.bg_color = backgroundTestColor[1].background;
      if (isSanZiJing) {
        exam.photo_url = mapSanZiJingLessonIcon("练一练");
      }
    });
  }

  if (reviewMediaLessons && reviewMediaLessons.length > 0) {
    reviewMediaLessons.forEach((review_lesson) => {
      review_lesson.type = "review_media_lesson";
      review_lesson.photo_url = IconBookTest[0];
      review_lesson.bg_color = backgroundTestColor[0].background;
      if (isSanZiJing) {
        review_lesson.photo_url = mapSanZiJingLessonIcon("唤醒");
      }
    });
  }

  const dealMediaLessons = useMemo(() => {
    if (isSanZiJing) {
      return [...reviewMediaLessons, ...mediaLessons, ...dayStudyExams];
    } else {
      return [...mediaLessons, ...reviewMediaLessons, ...dayStudyExams];
    }
  }, [dayStudyExams, isSanZiJing, mediaLessons, reviewMediaLessons]);

  if (todayQuiz) {
    todayQuiz.name = "练一练";
    todayQuiz.type = "today_quiz";
    todayQuiz.photo_url = IconIllustrations[IconIllustrations.length - 1];
    todayQuiz.bg_color =
      backgroundColorCollection[
        backgroundColorCollection.length - 1
      ].background;
    if (isSanZiJing) {
      todayQuiz.photo_url = mapSanZiJingLessonIcon(todayQuiz.name);
    }
    dealMediaLessons.push(todayQuiz);
  }

  return (
    <MyProvider
      store={
        new CreateStore({
          babyName: baby?.nickname,
          isCourseFinished,
          community,
          systemTime,
          mediaLessons,
          todayQuiz,
          studyReport,
          studyReportUrl,
          reviewMediaLessons,
          dayStudyExams,
          photoUrl,
          namePhotoUrl,
          name,
          isSpell,
          isSanZiJing,
          dealMediaLessons,
          mediaCourseName,
          posterTemplate: poster_photo_template_url,
          posterCreation: poster_creation,
        })
      }
    >
      <Spell />
    </MyProvider>
  );
};

export default SimilarSpellCourseListProvider;
