/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import DayModeStudyPackage from "./DayModeStudyPackage";
import ExperienceStudyPackage from "./ExperienceStudyPackage";
import UnitModeStudyPackage from "./UnitModeStudyPackage";
import MultiUnitModeStudyPackage from "./MultiUnitModeStudyPackage";
import CustomizedUnitModeStudyPackage from "./CustomizedUnitModeStudyPackage";
import CustomizedUnitModeStudyPackages from "./CustomizedUnitModeStudyPackages"; // 定制版V2
import CourseListStudy from "./CourseListStudy"; // 定制版V2
import CustomizedUnitClockStudy from "./CustomizedUnitClockStudy"; //时钟定制
import { withUnitStudySubscription } from "./withUnitStudySubscription.jsx";
import PracticeCourses from "../poetry_articles/PracticeCourses";
import RewardCommodityList from "../point_reward/RewardCommodityList";
import CrystalRewardCompensateNotify from "../point_reward/CrystalRewardCompensateNotify";
import TremReview from "./term_review/TremReview";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";

class ChooseRewardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showRewardPopup: props.should_show_commodity_list,
      selectedRewardCommodity: props.exchange_commodities.selected,
      currentActiveIndex: null,
    };

    this.couldShowChooseReward = props.has_config_commodity_data;
    if (this.couldShowChooseReward) {
      this.state.currentActiveIndex = this.findActiveIndex();
    }
  }

  hideChooseReward = (res) => {
    if (res) {
      this.setState({ selectedRewardCommodity: res.data.data.reward_commodity });
      const index = this.findActiveIndex(res.data.data.reward_commodity.id);
      this.setState({ currentActiveIndex: index });
    }
    this.setState({ showRewardPopup: false });
  };

  chooseLater = () => {
    let props = this.props;
    this.setState({ showRewardPopup: false });
    if (props.show_choose_next_time_reminder) {
      ToastManager.showToastText("下次进入课表可重新选择哦", 2000);
      request({
        url: props.update_choose_next_time_reminder_state_path,
        method: "post",
        headers: csrfHeaders,
        data: {
          kind: props.choose_next_time_reminder_kind,
          target_type: props.choose_next_time_reminder_type,
          target_id: props.choose_next_time_reminder_id,
        },
      });
    }
  };

  findActiveIndex() {
    let props = this.props;
    for (let i = 0; i < props.exchange_commodities.commodities.length; i++) {
      if (
        this.state.selectedRewardCommodity ===
        props.exchange_commodities.commodities[i].commodity.id
      ) {
        return i;
      }
    }
    return -1;
  }

  handleHeaderContentClick = (item) => {
    let props = this.props;
    if (item.title === "完课奖励") {
      if (!this.couldShowChooseReward) {
        window.location.href = item.url;
        return;
      }
      if (!this.state.selectedRewardCommodity || !props.last_study_report_path) {
        this.setState({ showRewardPopup: true });
        return;
      }
      window.location.href = props.last_study_report_path;
    } else {
      window.location.href = item.url;
    }
  };

  render() {
    let props = this.props;
    return (
      <>
        {this.state.selectedRewardCommodity && props.point_compensated && (
          <CrystalRewardCompensateNotify
            courseCount={props.learned_course_count}
            pointCount={props.point_compensated}
          />
        )}
        {this.couldShowChooseReward && this.state.showRewardPopup &&
          <RewardCommodityList
            list={props.exchange_commodities.commodities}
            chooseLater={this.chooseLater}
            selectRewardPath={props.select_reward_commodity_path}
            studyPackageActivityId={props.study_package_activity_id}
            activeIndex={this.state.currentActiveIndex}
            commoditiesPath={props.commodities_path}
            confirmExchangeOption={this.hideChooseReward}
            maxRewardPoints={props.max_reward_points}
            studyReportUrl={props.study_report_url}
            isDemoClass={props.is_demo_class}
          />
        }
      </>
    );
  }
}

const StudyPackage = (props) => {
  console.log("StudyPackage___________props", props);

  const chooseRewardContainerRef = useRef();
  const handleHeaderContentClick = (item) => {
    if (chooseRewardContainerRef.current) {
      chooseRewardContainerRef.current.handleHeaderContentClick(item);
    }
  };

  const PageComponent = () => {
    if (props.study_mode === "unit") {
      StudyPackage.displayName = "ExperienceStudyPackage";
      return <ExperienceStudyPackage {...props} />;
    }
    if (props.study_mode === "day") {
      StudyPackage.displayName = "DayModeStudyPackage";
      return <DayModeStudyPackage {...props} />;
    }
    if (props.study_mode === "new_unit") {
      if (props.is_user_study_package) {
        StudyPackage.displayName = "MultiStudyPackage";
        const WithMultiUnitStudySubscription = withUnitStudySubscription(
          MultiUnitModeStudyPackage
        );
        return <WithMultiUnitStudySubscription {...props} />;
      } else {
        StudyPackage.displayName = "StudyPackage";
        const WithUnitStudySubscription =
          withUnitStudySubscription(UnitModeStudyPackage);
        return <WithUnitStudySubscription {...props} />;
      }
    }
    if (props.study_mode === "customized") {
      StudyPackage.displayName = "CustomizedStudyPackage";
      const WithCustomizedStudySubscription = withUnitStudySubscription(
        CustomizedUnitModeStudyPackage
      );
      return <WithCustomizedStudySubscription {...props} />;
    }
    if (props.study_mode === "customized_v2") {
      StudyPackage.displayName = "CustomizedStudyPackages";
      const WithCustomizedStudySubscription = withUnitStudySubscription(
        CustomizedUnitModeStudyPackages
      );
      return <WithCustomizedStudySubscription {...props} />;
    }
    if (props.study_mode === "topic") {
      StudyPackage.displayName = "ClockCustomizedStudyPackage";
      const WithCustomizedStudySubscription = withUnitStudySubscription(
        CustomizedUnitClockStudy
      );
      return <WithCustomizedStudySubscription {...props} />;
    }
    if (props.study_mode === "reading_inclass") {
      StudyPackage.displayName = "PracticeCourses";
      const WithCustomizedStudySubscription =
        withUnitStudySubscription(PracticeCourses);
      return <WithCustomizedStudySubscription {...props} />;
    }
    if (props.study_mode === "intelligent") {
      StudyPackage.displayName = "CourseListStudy";
      const WithCustomizedStudySubscription =
        withUnitStudySubscription(CourseListStudy);
      return (
        <WithCustomizedStudySubscription
          {...props}
          handleHeaderContentClick={handleHeaderContentClick}
        />
      );
    }
    if (props.study_mode === "term_review") {
      StudyPackage.displayName = "TremReview";
      const WithCustomizedStudySubscription =
        withUnitStudySubscription(TremReview);
      return <WithCustomizedStudySubscription {...props} />;
    }
    return null;
  };

  return (
    <>
      <ChooseRewardContainer ref={chooseRewardContainerRef} {...props} />
      {PageComponent()}
    </>
  );
};

export default StudyPackage;
