import React from "react";
import PropTypes from "prop-types";

import Picker from "better-picker";
import { throttle } from "../utils/helpers";
import $ from "jquery";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import { Dialog, DialogManager } from "../utils/Dialog";
import {
  formatDate,
  formatAudioTime,
  formatDotDate,
} from "../utils/formatAudioTime";
import { SharePoster } from "../promotion_activities/SharePoster";
import CountDownTime from "../utils/CountDown";
import { onMiniProgram } from "../utils/helpers";
import Calendar from "../utils/Calendar";
import UserAgent from "../utils/UserAgent";
import icon_star_0 from "study_package/icon_star_0.png";
import icon_star_1 from "study_package/icon_star_1.png";
import icon_star_2 from "study_package/icon_star_2.png";
import icon_star_3 from "study_package/icon_star_3.png";
import icon_lock_gray from "study_package/icon_lock_gray.png";
import placeholder_404_end from "study_package/placeholder_404_end.png";
import icon_package_finish from "study_package/icon_package_finish.png";
import icon_package_today from "study_package/icon_package_today.png";
import icon_package_rest from "study_package/icon_package_rest.png";
import icon_package_unfinish from "study_package/icon_package_unfinish.png";
import icon_package_placeholder from "study_package/icon_package_placeholder.png";
import icon_quick_menu from "study_package/icon_quick_menu.png";
import icon_current_progress from "tips_progress.png";

import icon_quiz_pass from "icon_quiz_pass.png";
import "./StudyPackageUserInfo.scss";
import icon_reward from "study_package/icon_package_reward.png";
import setting from "study_package/icon_package_setting.jpg";
import icon_default_boy from "icon_default_boy.png";
import icon_default_girl from "icon_default_girl.png";
import "./ExperienceStudyPackage.scss";

const DISPLAY_ADSORPTION_EFFECT = 60;
const date_hour_data = [
  { text: "不提醒", value: -1 },
  { text: "00点", value: 0 },
  { text: "01点", value: 1 },
  { text: "02点", value: 2 },
  { text: "03点", value: 3 },
  { text: "04点", value: 4 },
  { text: "05点", value: 5 },
  { text: "06点", value: 6 },
  { text: "07点", value: 7 },
  { text: "08点", value: 8 },
  { text: "09点", value: 9 },
  { text: "10点", value: 10 },
  { text: "11点", value: 11 },
  { text: "12点", value: 12 },
  { text: "13点", value: 13 },
  { text: "14点", value: 14 },
  { text: "15点", value: 15 },
  { text: "16点", value: 16 },
  { text: "17点", value: 17 },
  { text: "18点", value: 18 },
  { text: "19点", value: 19 },
  { text: "20点", value: 20 },
  { text: "21点", value: 21 },
  { text: "22点", value: 22 },
  { text: "23点", value: 23 },
];
class UserInfo extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const {
      is_ios_review,
      share_url,
      media_lessons_count,
      total_studied_media_lessons_count,
      photo_url,
      edit_baby_path,
      nickname,
      sno,
      bonus,
      is_experience,
      gender,
    } = this.props;

    return (
      <div className="comp_study_package_user_info">
        <div className="info_container">
          <a className="user_baby_avatar" href={edit_baby_path}>
            <img
              className="lazyload"
              data-src={
                photo_url ||
                (gender === "男" ? icon_default_boy : icon_default_girl)
              }
              alt=""
            />
          </a>
          <div className="study_package_info">
            <p className="name_number">
              <span>{nickname}</span>
              <span>（学号：{sno}）</span>
            </p>
            {!is_experience && (
              <div className="progress_bar">
                <div
                  className="progress"
                  style={{
                    width: `${
                      (total_studied_media_lessons_count /
                        media_lessons_count) *
                      100
                    }%`,
                  }}
                ></div>
                <div className="text_progress">
                  {`已学课时(${total_studied_media_lessons_count}/${media_lessons_count})`}
                </div>
              </div>
            )}
          </div>
          {!is_ios_review && bonus != 0 && (
            <div className="lucky_money">
              <a href={share_url}>
                <img data-src={icon_reward} alt="" className="lazyload" />
                <span>现金红包</span>
              </a>
            </div>
          )}
          <div
            className="setting"
            onClick={this.props.handleShowList}
            style={{ flex: !is_experience ? "0.9" : "none" }}
          >
            <a href="javascript:void(0)">
              <img data-src={setting} alt="" className="lazyload" />
              <span>更多</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

class StudyPackageUnitSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchor: 0,
      new_study_package_units: [...props.study_package_units],
    };
  }

  setAnchor = () => {
    const {
      study_package_start_time,
      study_package_end_time,
      study_package_units,
      current_study_date,
      is_experience,
      study_package_system_time,
    } = this.props;
    let anchor = 0;
    let new_study_package_units = study_package_units;
    const setAppendBoxWidth = () => {
      const containerWidth = this.sliderUnits.offsetWidth;
      return containerWidth - 160;
    };
    return new Promise((resolve, reject) => {
      const currentStudyDate = new Date(
        is_experience ? study_package_system_time : current_study_date
      );
      console.log(currentStudyDate);
      const unitStartDate = (unit) => new Date(unit.start_date);
      const unitEndDate = (unit) => new Date(unit.end_date);
      const packageEndTime = new Date(study_package_end_time);
      const packageStartTime = new Date(study_package_start_time);

      for (const [index, unit] of Object.entries(study_package_units)) {
        if (
          currentStudyDate - unitStartDate(unit) >= 0 &&
          unitEndDate(unit) - currentStudyDate >= 0
        ) {
          anchor = parseInt(index);
          break;
        }
        // 周末休息
        if (
          currentStudyDate - unitEndDate(unit) > 0 &&
          currentStudyDate -
            unitStartDate(
              study_package_units[
                parseInt(index) + 1 < study_package_units.length - 1
                  ? parseInt(index) + 1
                  : study_package_units.length - 1
              ]
            ) <
            0
        ) {
          anchor = parseInt(index);
          break;
        }
      }

      if (currentStudyDate - packageStartTime <= 0) {
        anchor = 0;
      }

      if (currentStudyDate - packageEndTime >= 0) {
        anchor = study_package_units.length - 1;
      }
      new_study_package_units.push({
        width: setAppendBoxWidth(),
        id: null,
      });
      if (
        new_study_package_units.length >
        this.state.new_study_package_units.length
      ) {
        this.setState(() => ({
          anchor: anchor,
          new_study_package_units: new_study_package_units,
        }));
      }
      resolve(anchor);
      console.log(anchor);
    });
  };

  setScroll = (day_studies) => {
    if (!this.props.is_experience) {
      day_studies &&
        day_studies.forEach((day_study) => {
          let current_progress_date =
            formatDate(this.props.current_study_date) ===
            formatDate(day_study.date);
          if (current_progress_date) {
            $("#href_current").trigger("click");
          }
        });
    }
    if (this.props.is_experience) {
      day_studies &&
        day_studies.forEach((day_study) => {
          let today_date =
            formatDate(this.props.study_package_system_time) ===
            formatDate(day_study.date);
          if (today_date) {
            if (window.location.href.includes("#today")) {
              return;
            }
            $("#href_today").trigger("click");
          }
        });
    }
  };
  getUnitStudy = (e) => {
    if (this.requesting) return;

    const unitId =
      (e && e.currentTarget.getAttribute("data-unitid")) ||
      this.props.study_package_units[this.state.anchor].id;
    const getAnchor = this.props.study_package_units.findIndex(
      (unit) => unit.id == unitId
    );

    if (unitId === null) return;
    if (e && this.state.anchor == getAnchor) return;

    this.scrollLeft(getAnchor, true);
    const requestUnitStudyData = () => {
      this.requesting = true;
      return request({
        url: this.props.get_unit_study_url,
        method: "GET",
        headers: csrfHeaders,
        params: {
          study_package_unit_id: unitId,
        },
      })
        .then((data) => {
          // this.scrollLeft(getAnchor, true)
          // console.log(data.data.day_studies)
          const day_studies = data.data.day_studies;
          return new Promise((resolve, reject) => {
            this.props
              .dayStudies(day_studies)
              .then(() => {
                if (e) {
                  console.log("dataEnterAnimation");
                  return this.props.dataEnterAnimation();
                }
              })
              .then(() => {
                this.setScroll(day_studies);
                this.requesting = false;
                this.toast.cancel();
                resolve();
              })
              .catch((err) => {
                this.setScroll(day_studies);
                console.log(err);
                this.requesting = false;
                this.toast.cancel();
                reject(err);
              });
          });
        })
        .catch((err) => {
          this.requesting = false;
          this.toast.cancel();
          console.log(err);
        });
    };

    // 点击触发渐出动画
    this.toast = ToastManager.showLoading("数据加载中...");
    if (e) {
      this.props
        .dataLeaveAnimation()
        .then(requestUnitStudyData)
        .catch((err) => console.log(err));
    } else {
      requestUnitStudyData();
    }
  };
  componentDidMount() {
    this.setAnchor().then((anchor) => {
      this.scrollLeft(anchor);
      this.getUnitStudy();
    });
  }
  scrollLeft = (anchor, force) => {
    if (anchor != this.state.anchor || force) {
      this.setState(() => ({
        anchor: anchor,
      }));
    }
    if (this.sliderUnits) {
      $("#comp_study_package_unit_slider").animate(
        { scrollLeft: 160 * anchor },
        500
      );
    }
  };
  render() {
    const { new_study_package_units, anchor } = this.state;
    const { is_experience, is_parent_class } = this.props;
    return (
      <div
        ref={(container) => (this.sliderUnits = container)}
        className="comp_study_package_unit_slider"
        id="comp_study_package_unit_slider"
        style={{ display: is_parent_class ? "none" : null }}
      >
        {new_study_package_units.map((unit, index) => {
          return (
            <div
              className={`unit_container  ${
                index == anchor ? "active" : "inactive"
              }`}
              key={index}
              style={{
                visibility: !unit.name ? "hidden" : "visible",
                width: !unit.name ? unit.width : "",
                justifyContent: is_experience && "center",
                padding: is_experience && "0 0 0 20px",
              }}
              onClick={this.getUnitStudy}
              data-unitid={unit.id}
            >
              <div
                className="unit_name"
                style={{ paddingBottom: !is_experience && "" }}
              >
                {unit.name}
              </div>
              {!(unit.media_courses_count == 0) && (
                <div className="unit_progress_bar">
                  <p className="text_progress">
                    <span>{unit.media_courses_count}知识点</span>
                    <span>
                      已学课时(
                      {`${unit.studied_media_lessons_count}/${unit.media_lessons_count}`}
                      )
                    </span>
                  </p>

                  <div className="progress_bar">
                    <div
                      className="unit_progress"
                      style={{
                        width: `${
                          (unit.studied_media_lessons_count /
                            unit.media_lessons_count) *
                          100
                        }%`,
                      }}
                    ></div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
const CourseActivityEntrance = ({
  checkin,
  studyReportUrl,
  checkinDays,
  bonus,
  isIosReview,
  shareUrl,
  reminderTimeHour,
  commentState,
  commentUrl,
  handleHideDialog,
  isStyleDialog,
  isExperience,
}) => {
  return (
    <div
      className="study_time_accumulate"
      style={{ display: isStyleDialog ? "block" : "none" }}
    >
      <div className="course_activity_entrance">
        <div className="setting_text">更多</div>
        <div className="icon_closed" onClick={handleHideDialog}>
          &times;
        </div>
        <ul>
          {bonus != 0 && isIosReview === false && (
            <li className="icon_invite">
              <a href={shareUrl}>
                <p>赢奖学金</p>
                <span>{bonus}元/新用户</span>
              </a>
            </li>
          )}
          {checkin && (
            <li className="icon_card">
              <a href={studyReportUrl}>
                <p>打卡活动</p>
                <span>已打卡{checkinDays}天</span>
              </a>
            </li>
          )}

          {!isExperience &&
            (commentState === "uncommented" ||
              commentState === "commented") && (
              <li className="icon_comment">
                <a href={commentUrl}>
                  <p>写评价</p>
                  <span>赢畅听卡</span>
                </a>
              </li>
            )}

          <li className="icon_remind" id="time_setup_click">
            <a href="javascript:void(0)">
              <p>上课提醒</p>
              {reminderTimeHour >= -1 ? (
                <div className="remind_time_set">
                  <span id="name">
                    {reminderTimeHour === -1
                      ? "不提醒"
                      : `${reminderTimeHour}点`}
                  </span>
                </div>
              ) : (
                <span>暂无法设置</span>
              )}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const DayAndStudyReportEntrance = ({
  day,
  todayQuizStatus,
  studyReportUrl,
  study_package_system_time,
  day_study,
  current_study_date,
  isExperience,
  study_report,
  is_parent_class,
}) => {
  let current_progress_date =
    formatDate(current_study_date) === formatDate(day_study.date);

  /* usage like:
   * state: "fail" (过去·未完成)
   * state: "blank" (将来·未完成)
   *
   * state: "blank" (系统时间 >= 当前时间) 今天
   * state: "不存在" (系统时间 === 当前时间) 今天
   *
   * state: "pass" 完成
   * state "字段不存在" 休息
   */

  // 课程完成状态显示 (优先以 "今" 天为主)
  let dateStatusIcon = "";
  formatDate(study_package_system_time) === formatDate(day_study.date)
    ? (dateStatusIcon = <img src={icon_package_today} alt="" />)
    : day_study.state === "pass"
    ? (dateStatusIcon = <img src={icon_package_finish} alt="" />)
    : day_study.state === "blank"
    ? new Date(study_package_system_time) >=
      new Date(day_study.day_study_start_time)
      ? (dateStatusIcon = <img src={icon_package_today} alt="" />)
      : (dateStatusIcon = <img src={icon_package_unfinish} alt="" />)
    : day_study.state === "fail"
    ? (dateStatusIcon = <img src={icon_package_unfinish} alt="" />)
    : // 当day_study state 值不存在的情况下
    formatDate(study_package_system_time) === formatDate(day_study.date)
    ? (dateStatusIcon = <img src={icon_package_today} alt="" />)
    : (dateStatusIcon = <img src={icon_package_rest} alt="" />);
  return (
    <div
      className="day_and_study_report_entrance"
      id={current_progress_date ? "current_progress" : null}
    >
      {
        <div className="status_left">
          {
            // 日期状态 icon
            dateStatusIcon
          }
          <div className="day_or_date">
            {day && <div className="correspond_course">第{day}天</div>}
            {!is_parent_class && (
              <div className="date">
                {day_study.day_study_start_time
                  ? formatDotDate(day_study.day_study_start_time)
                  : formatDotDate(day_study.date)}
              </div>
            )}
          </div>
        </div>
      }

      {study_report && (
        <div className="button_study_report">
          <a href={studyReportUrl}>学习报告</a>
        </div>
      )}
      {!is_parent_class && !isExperience && current_progress_date && (
        <div className="icon_current_progress">
          <img src={icon_current_progress} alt="" />
        </div>
      )}
    </div>
  );
};

const AwakeCourseForeverUnlock = ({
  day_study_exam,
  day_lock,
  day_study_start_time,
  now_date,
  icon_stars,
}) => {
  // 唤醒 · 课程永久解锁
  return (
    <a href={day_lock == true ? "javascript:void(0)" : day_study_exam.url}>
      <div className="calendar_examine">
        <img
          className="course_star"
          src={
            day_lock == true ? icon_lock_gray : icon_stars[day_study_exam.rank]
          }
        />
        {day_lock == false &&
          (day_study_exam.score >= 0 ? (
            <div className="score">
              <i>{day_study_exam.score}</i>分
            </div>
          ) : (
            <div className="go_practice">去练习</div>
          ))}

        <div className="knowledge_content">
          <h2 style={{ color: day_lock == true && "#999" }}>
            {day_study_exam.name}
          </h2>
        </div>

        <div className="course_subscrible_practice">
          <div className="course_amount_time">
            {<i>{day_study_exam.quizzes_count}道题</i>}
            {day_study_exam.count_down_mins && (
              <span>限时：{day_study_exam.count_down_mins}分钟</span>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

const AwakeCourseEverydayUnlock = ({
  day_study_exam,
  day_study_start_time,
  now_date,
}) => {
  // 唤醒 · 课程每日解锁
  return (
    <a
      href={
        day_study_start_time < now_date
          ? day_study_exam.url
          : "javascript:void(0)"
      }
    >
      <div className="calendar_examine">
        {day_study_start_time < now_date ? (
          day_study_exam.score >= 0 ? (
            <div className="score">
              <i>{day_study_exam.score}</i>分
            </div>
          ) : (
            day_study_start_time <= now_date && (
              <div className="go_practice">去练习</div>
            )
          )
        ) : (
          <img className="course_star" src={icon_lock_gray} />
        )}
        <div className="knowledge_content">
          <h2 style={{ color: day_study_start_time >= now_date && "#999" }}>
            {day_study_exam.name}
          </h2>
        </div>

        <div className="course_subscrible_practice">
          <div className="course_amount_time">
            {<i>{day_study_exam.quizzes_count}道题</i>}
            {day_study_exam.count_down_mins && (
              <span>限时：{day_study_exam.count_down_mins}分钟</span>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

const HolidayRestPrompt = ({ isExperience }) => {
  return (
    <div className="course_box">
      <div className="calendar_course_list">
        <div className="holiday_rest_container">
          <img
            style={{ minHeight: 94 }}
            src={icon_package_placeholder}
            alt=""
          />
          {isExperience ? (
            <div className="rest_text">
              <p>今天休息</p>
              <span>可自行安排收听其他的经验分享~</span>
            </div>
          ) : (
            <div className="rest_text">
              <p>今天休息</p>
              <span>补课或复习之前的内容吧~</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const NoneExperiencesPrompt = ({ isExperience }) => {
  return (
    <div className="calendar_course_list">
      <div className="holiday_rest_container">
        <img style={{ minHeight: 94 }} src={icon_package_placeholder} alt="" />
        {isExperience ? (
          <div className="rest_text">
            <p>今日休息，可自行安排收听其他的经验分享</p>
          </div>
        ) : (
          <div className="rest_text">
            <p>今日休息，家长可自行安排复习前面的知识点</p>
          </div>
        )}
      </div>
    </div>
  );
};

// 经验主题周
const ExperienceTopic = ({ experiences, onAlert }) => {
  let experienceStateText;
  return experiences.map((experience) => {
    if (experience.state == 0) {
      experienceStateText = "嘉宾分享";
    } else if (experience.state == 1) {
      experienceStateText = "去收听";
    } else {
      experienceStateText = "嘉宾分享";
    }

    return (
      <a
        key={experience.id}
        href="javascript:void(0)"
        data-url={experience.url}
        data-state={experience.state}
        onClick={(e) => onAlert(e, true)}
      >
        <div className="calendar_course" style={{ minHeight: "70px" }}>
          <div className="go_class">{experienceStateText}</div>
          <div className="knowledge_content">
            <h2>{experience.name}</h2>
            <div>
              <p>嘉宾：{experience.user_name}</p>
            </div>
          </div>
        </div>
      </a>
    );
  });
};

const CourseEverydayTotalUnlock = ({
  media_lesson,
  day_lock,
  day_study_start_time,
  now_date,
  icon_stars,
  lesson_time,
  is_parent_class,
}) => {
  let courseEverydayTotalStatus;
  if (day_lock == true) {
    courseEverydayTotalStatus = (
      <img className="course_star" src={icon_lock_gray} />
    );
  } else {
    if (icon_stars[media_lesson.rank]) {
      courseEverydayTotalStatus = (
        <img className="course_star" src={icon_stars[media_lesson.rank]} />
      );
    } else {
      courseEverydayTotalStatus = <div className="go_class">去上课</div>;
    }
  }
  return (
    <a
      href={day_lock == true ? "javascript:void(0)" : media_lesson.url}
      data-url={day_lock == true ? "" : media_lesson.url}
    >
      <div className="calendar_course">
        {courseEverydayTotalStatus}
        <div className="knowledge_content">
          <h2 style={{ color: day_lock == true && "#999" }}>
            {`${
              (is_parent_class ? "" : media_lesson.media_course_name + "：") +
              media_lesson.name
            }`}
          </h2>
          {media_lesson.objective && <p>{media_lesson.objective}</p>}
          <div className="course_subscrible_practice">
            <div className="course_time_difficulty">
              {lesson_time && <i>{lesson_time.time}</i>}
              {media_lesson.difficulty != null && (
                <span>{media_lesson.difficulty}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const CourseEverydayLock = ({
  media_lesson,
  day_study_start_time,
  now_date,
  icon_stars,
  lesson_time,
  is_parent_class,
}) => {
  let courseEverydayStatus;
  if (day_study_start_time <= now_date) {
    if (icon_stars[media_lesson.rank]) {
      courseEverydayStatus = (
        <img className="course_star" src={icon_stars[media_lesson.rank]} />
      );
    } else {
      courseEverydayStatus = (
        <div className="go_class" href="">
          去上课
        </div>
      );
    }
  } else {
    courseEverydayStatus = <img className="course_star" src={icon_lock_gray} />;
  }
  return (
    <a
      href={
        day_study_start_time < now_date
          ? media_lesson.url
          : "javascript:void(0)"
      }
      data-url={day_study_start_time < now_date ? media_lesson.url : ""}
    >
      <div className="calendar_course">
        {courseEverydayStatus}
        <div className="knowledge_content">
          <h2 style={{ color: day_study_start_time > now_date && "#999" }}>
            {`${
              (is_parent_class ? "" : media_lesson.media_course_name + "：") +
              media_lesson.name
            }`}
          </h2>
          {media_lesson.objective && <p>{media_lesson.objective}</p>}
          <div className="course_subscrible_practice">
            <div className="course_time_difficulty">
              {lesson_time && <i>{lesson_time.time}</i>}
              {media_lesson.difficulty != null && (
                <span>{media_lesson.difficulty}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const CalcalateTrainingCamps = ({
  calculate_training_camps,
  lock,
  day_lock,
  speedPracticeClick,
  day_study_start_time,
  now_date,
}) => {
  let handleClickSpeedPractice;
  let imgStar;
  // lock === false
  if (day_lock === false) {
    handleClickSpeedPractice = day_lock === true ? null : speedPracticeClick;
    imgStar = "";
  } else {
    handleClickSpeedPractice =
      day_study_start_time < now_date ? speedPracticeClick : null;
    if (day_study_start_time > now_date) {
      imgStar = <img className="course_star" src={icon_lock_gray} />;
    }
  }

  return calculate_training_camps.map((camp, position) => (
    <a
      key={position}
      href="javascript:void(0)"
      onClick={handleClickSpeedPractice}
    >
      <div className="calendar_examine">
        {imgStar}
        <div className="knowledge_content">
          <h2
            style={{
              color:
                day_lock == true && day_study_start_time > now_date && "#999",
            }}
          >
            {camp.training_camp_name}（第{camp.position}关）：
            {camp.calculate_training_name}
          </h2>
        </div>
      </div>
    </a>
  ));
};

const TodayQuizCourseForeverUnlock = ({
  today_quiz_url,
  day_lock,
  today_quiz_status,
  quizzes_count,
}) => {
  let todayQuizForeverStatus;
  if (day_lock == true) {
    todayQuizForeverStatus = (
      <img className="course_star" src={icon_lock_gray} />
    );
  } else {
    if (today_quiz_status === false) {
      todayQuizForeverStatus = <div className="go_practice">去练习</div>;
    } else {
      todayQuizForeverStatus = (
        <img className="course_star" src={icon_quiz_pass} />
      );
    }
  }
  return (
    <a href={day_lock == true ? "javascript:void(0)" : today_quiz_url}>
      <div className="calendar_examine">
        {todayQuizForeverStatus}
        <div className="knowledge_content">
          <h2 style={{ color: day_lock == true && "#999" }}>随堂测试</h2>
        </div>

        <div className="course_subscrible_practice">
          <div className="course_amount_time">
            <i>{quizzes_count}道题</i>
          </div>
        </div>
      </div>
    </a>
  );
};

const TodayQuizCourseEverydayUnlock = ({
  day_study_start_time,
  now_date,
  today_quiz_url,
  today_quiz_status,
  quizzes_count,
}) => {
  let todayQuizEverydayStatus;
  if (day_study_start_time > now_date) {
    todayQuizEverydayStatus = (
      <img className="course_star" src={icon_lock_gray} />
    );
  } else {
    if (today_quiz_status === false) {
      todayQuizEverydayStatus = <div className="go_practice">去练习</div>;
    } else {
      todayQuizEverydayStatus = (
        <img className="course_star" src={icon_quiz_pass} />
      );
    }
  }
  return (
    <a
      href={
        day_study_start_time < now_date ? today_quiz_url : "javascript:void(0)"
      }
    >
      <div className="calendar_examine">
        {todayQuizEverydayStatus}
        <div className="knowledge_content">
          <h2 style={{ color: day_study_start_time > now_date && "#999" }}>
            随堂测试
          </h2>
        </div>

        <div className="course_subscrible_practice">
          <div className="course_amount_time">
            <i>{quizzes_count}道题</i>
          </div>
        </div>
      </div>
    </a>
  );
};

const ExamineForeverUnlock = ({
  day_lock,
  exercise_quiz_url,
  exercise_quiz_status,
  exercise_quizzes_count,
}) => {
  let examineForeverStatus;
  if (day_lock == true) {
    examineForeverStatus = <img className="course_star" src={icon_lock_gray} />;
  } else {
    if (exercise_quiz_status === false) {
      examineForeverStatus = <div className="go_practice">去练习</div>;
    } else {
      examineForeverStatus = (
        <img className="course_star" src={icon_quiz_pass} />
      );
    }
  }
  return (
    <a href={day_lock == true ? "javascript:void(0)" : exercise_quiz_url}>
      <div className="calendar_examine">
        {examineForeverStatus}
        <div className="knowledge_content">
          <h2 style={{ color: day_lock == true && "#999" }}>
            课外练习（选做）
          </h2>
        </div>

        <div className="course_subscrible_practice">
          <div className="course_amount_time">
            <i>{exercise_quizzes_count}道题</i>
          </div>
        </div>
      </div>
    </a>
  );
};

const ExamineEverydayUnlock = ({
  day_study_start_time,
  now_date,
  exercise_quiz_url,
  exercise_quiz_status,
  exercise_quizzes_count,
}) => {
  let examineEverydayStatus;
  if (day_study_start_time > now_date) {
    examineEverydayStatus = (
      <img className="course_star" src={icon_lock_gray} />
    );
  } else {
    if (exercise_quiz_status === true) {
      examineEverydayStatus = (
        <img className="course_star" src={icon_quiz_pass} />
      );
    }
  }
  return (
    <a
      href={
        day_study_start_time < now_date
          ? exercise_quiz_url
          : "javascript:void(0)"
      }
    >
      <div className="calendar_examine">
        {examineEverydayStatus}
        <div className="knowledge_content">
          <h2 style={{ color: day_study_start_time > now_date && "#999" }}>
            课外练习（选做）
          </h2>
        </div>

        <div className="course_subscrible_practice">
          {day_study_start_time <= now_date &&
            exercise_quiz_status === false && (
              <div className="go_practice">去练习</div>
            )}
          <div className="course_amount_time">
            <i>{exercise_quizzes_count}道题</i>
          </div>
        </div>
      </div>
    </a>
  );
};

const UnitCourseEnd = ({
  content,
  another_content,
  is_experience,
  is_parent_class,
}) => {
  return (
    <div className="unit_course_end">
      {!is_parent_class && !is_experience && (
        <a className="fix_img">
          <span
            onClick={() => {
              window.location.replace(
                window.location.pathname +
                  window.location.search +
                  "#current_progress"
              );
              if (!document.getElementById("current_progress")) {
                window.location.reload(true);
              }
            }}
            id="href_current"
          >
            回到当前进度
          </span>
        </a>
      )}
      {!is_parent_class && (
        <div className="unit_divider">
          <div className="left_line"></div>
          <div className="more_course">
            <p>{content}</p>
            <span>{another_content}</span>
          </div>
          <div className="right_line"></div>
        </div>
      )}
    </div>
  );
};

export default class StudyPackage extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      scrollValue: 0,
      studyPackageSystemTime: this.props.study_package_system_time,
      lesson_times: [],
      isAlertQR: false,
      isSettingDialog: false,
      animatedBox: {},
    };
    // this.scrollToTop()
  }
  animatedLeftToDataEnter = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.setState(
          () => ({
            animatedToLeft: true,
            inVisible: true,
          }),
          resolve
        );
      });
    });
  };
  animatedLeftToDataLeave = () => {
    return new Promise((resolve, reject) => {
      this.setState({
        inVisible: false,
      });
      setTimeout(() => {
        this.setState(
          {
            animatedToLeft: null,
          },
          resolve
        );
      });
    });
  };

  getAudioResult(infourl, quiz_count_sec = 0) {
    return request({
      url: infourl,
      method: "get",
    })
      .then((resp) => {
        return new Promise((resolve, reject) => {
          const totalTime = parseInt(resp.data.format.duration);
          const showTime = formatAudioTime(totalTime + quiz_count_sec);
          const lesson_time = { url: infourl, time: showTime };
          var lesson_times = this.state.lesson_times;

          lesson_times.push(lesson_time);
          this.setState(
            {
              lesson_times: lesson_times,
            },
            resolve
          );
        });
      })
      .catch((err) => {
        console.log(err);
        return Promise.resolve();
      });
  }

  componentWillUnmount() {
    window.onload = null;
    window.onscroll = null;
  }

  componentDidMount() {
    // this.onContainerScroll()

    // this.scrollToTop()

    const share_url = this.props.share_url;
    const share_image_url = this.props.share_image_url;

    const share_title = this.props.share_title;
    const share_description = this.props.share_description;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    const nameEl = document.getElementById("name");
    const picker = new Picker({
      data: [date_hour_data],
      selectedIndex: [this.props.reminder_time_hour + 1],
      title: "",
    });

    const config_reminder_time_url = this.props.config_reminder_time_url;
    const reminder_time_hour = this.props.reminder_time_hour;
    picker.on("picker.select", function (selectedVal, selectedIndex) {
      nameEl.innerText = date_hour_data[selectedIndex[0]].text;
      // console.log("当前所选值：", nameEl.innerText)
      // console.log("选择值：", selectedIndex[0] - 1, "后台数据返回的值：", reminder_time_hour)

      if (selectedIndex[0] - 1 != reminder_time_hour) {
        let params = {
          reminder_hour: selectedIndex[0] - 1,
        };
        // console.log("params: ", params['reminder_hour'])
        var url = config_reminder_time_url;
        request({
          url: url,
          method: "POST",
          headers: csrfHeaders,
          data: params,
        })
          .then((resp) => {
            // const data = resp.data
            console.log("正确返回");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });

    if (this.props.reminder_time_hour >= -1) {
      const time_setup_click = document.getElementById("time_setup_click");
      if (time_setup_click) {
        time_setup_click.addEventListener("click", function () {
          picker.show();
        });
      }
    }
  }

  // 速度闯关练习类型的弹框提醒
  speedPracticeClick = () => {
    const buttons = [
      {
        label: "我知道了",
        onClick: () => this.confirmDialog.close(),
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        请使用手机版微信扫描练习卷上的二维码，完成训练
      </Dialog>
    );
  };

  //点击弹老师二维码
  onAlert = (e, isAlert) => {
    e.preventDefault();
    let experience_url = e.currentTarget.getAttribute("data-url");
    let experience_state = e.currentTarget.getAttribute("data-state");

    if (experience_state == 1) {
      if (UserAgent.isMiniProgram()) {
        onMiniProgram(e, experience_url, {});
      } else if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({ href: experience_url });
      } else {
        window.location.href = experience_url;
      }
    } else {
      this.setState({
        isAlertQR: isAlert,
      });
    }
  };
  // “更多”列表icon事件
  handleShowList = () => {
    this.setState({
      isSettingDialog: true,
    });
  };

  // 隐藏设置
  handleSetting = () => {
    this.setState({
      isSettingDialog: false,
    });
  };

  // componentDidUpdate() {
  // if(this.state.totalTopHeight===undefined) {
  // let arrayNewDate = []
  // let todayIndex = 0
  // let totalTopHeight = 0
  // let calendarCourse = document.getElementsByClassName("calendar_date_course")

  // this.state.day_studies.forEach(ele => {
  //   arrayNewDate.push({ "date": ele.date })
  // });

  // for (let index = 0; index < arrayNewDate.length; index++) {
  //   const elementDate = arrayNewDate[index].date;
  //   if (`"${elementDate}"` == `"${formatDate(new Date(this.props.study_package_system_time))}"`) {
  //     // console.log("====当前天数===: ", `"${elementDate}"` + ", " + "--当前索引: ", index)
  //     todayIndex = index
  //   }
  // }

  // [].forEach.call(calendarCourse, (calendar_course, index) => {
  //   if(todayIndex > index){
  //     totalTopHeight += calendar_course.offsetHeight
  //   }
  // })
  // console.log("总高度: ",totalTopHeight)
  // }
  // }

  dayStudies = (day_studies) => {
    console.log(day_studies);
    return new Promise((resolve, reject) => {
      const current_sysytem_date = formatDate(
        new Date(this.props.study_package_system_time)
      );
      this.setState({
        day_studies,
      });
      let arrayDayMediaLessons = [];
      if (day_studies) {
        for (let item of day_studies) {
          arrayDayMediaLessons.push({ media_lessons: item.media_lessons });
        }
      }
      if (arrayDayMediaLessons.length) {
        let mapped = arrayDayMediaLessons.map(
          (lessons) =>
            lessons.media_lessons &&
            lessons.media_lessons.map((lesson) =>
              this.getAudioResult.bind(
                this,
                lesson.audio_info_url,
                lesson.resource_quiz_count * 10 || 0
              )
            )
        );

        return Promise.all(
          mapped.map(
            (lessons) => lessons && lessons.map((lesson) => lesson && lesson())
          )
        )
          .then(resolve)
          .catch((err) => resolve());
      }
    });
  };

  render() {
    const {
      animatedBox,
      day_studies,
      studyPackageSystemTime,
      animatedToLeft,
      inVisible,
    } = this.state;
    const { is_experience, current_study_date, is_parent_class } = this.props;

    const icon_stars = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];
    var now_date = new Date(studyPackageSystemTime);

    const handleDayStudiesSlide = () => {
      let visible = "";
      if (inVisible) {
        visible = "visible";
      }
      if (inVisible === false) {
        visible = "hidden";
      }
      if (animatedToLeft === undefined) return `slide-normal ${visible}`;
      if (animatedToLeft === null) return `${visible}`;
      if (animatedToLeft === true) return `slide-in ${visible}`;
      if (animatedToLeft === false) return `slide-out ${visible}`;
    };

    return (
      <div className="comp-new-study-package" id="comp-new-study-package">
        <div
          style={animatedBox}
          id="user_info_slider"
          className="user_info_slider"
        >
          <UserInfo
            {...this.props.baby}
            is_ios_review={this.props.is_ios_review}
            share_url={this.props.share_url}
            media_lessons_count={this.props.media_lessons_count}
            total_studied_media_lessons_count={
              this.props.total_studied_media_lessons_count
            }
            sno={this.props.sno}
            bonus={this.props.bonus}
            is_experience={this.props.is_experience}
            handleShowList={this.handleShowList}
          />
          <StudyPackageUnitSlider
            study_package_units={this.props.study_package_units}
            study_package_end_time={this.props.study_package_end_time}
            study_package_start_time={this.props.study_package_start_time}
            study_package_system_time={this.props.study_package_system_time}
            get_unit_study_url={this.props.get_unit_study_url}
            is_experience={this.props.is_experience}
            dayStudies={this.dayStudies}
            dataEnterAnimation={this.animatedLeftToDataEnter}
            dataLeaveAnimation={this.animatedLeftToDataLeave}
            current_study_date={this.props.current_study_date}
            is_parent_class={is_parent_class === true}
          />
        </div>
        <div
          className="main_content_container"
          style={{
            maxHeight: is_parent_class ? "" : document.body.clientHeight - 141,
          }}
          id="main_content_container"
        >
          <div className="main_container">
            <div className={`calendar_plan ${handleDayStudiesSlide()}`}>
              {day_studies &&
                day_studies.map((day_study, index) => {
                  let mediaLessons =
                    day_study.media_lessons &&
                    day_study.media_lessons.length > 0;
                  let dayStudyExams =
                    day_study.day_study_exams &&
                    day_study.day_study_exams.length > 0;
                  let experiences =
                    day_study.experiences && day_study.experiences.length > 0;
                  let calculateTrainingCamps =
                    day_study.calculate_training_camps &&
                    day_study.calculate_training_camps.length > 0;
                  let today_date =
                    formatDate(this.props.study_package_system_time) ===
                    formatDate(day_study.date);

                  return (
                    <div
                      key={index}
                      className="calendar_date_course"
                      id={today_date ? "today" : null}
                    >
                      <div className="course_container">
                        <DayAndStudyReportEntrance
                          day={day_study.day}
                          todayQuizStatus={day_study.today_quiz_status}
                          studyReportUrl={day_study.study_report_url}
                          study_package_system_time={
                            this.props.study_package_system_time
                          }
                          day_study={day_study}
                          current_study_date={current_study_date}
                          isExperience={this.props.is_experience}
                          study_report={day_study.study_report}
                          is_parent_class={is_parent_class === true}
                        />
                        {day_study.day ? (
                          <div className="course_box">
                            {
                              // 经验主题周·无经验列表的时候, 显示 “今日休息...”
                              // 当天又是休息·又没有音频的时候(优先以休息日为主)
                              experiences ? (
                                ""
                              ) : mediaLessons ||
                                dayStudyExams ||
                                day_study.today_quiz_url ||
                                calculateTrainingCamps ||
                                day_study.exercise_quiz_url ? (
                                ""
                              ) : day_study.state === undefined &&
                                formatDate(
                                  this.props.study_package_system_time
                                ) === formatDate(day_study.date) ? (
                                <HolidayRestPrompt
                                  isExperience={is_experience === true}
                                />
                              ) : (
                                <NoneExperiencesPrompt
                                  isExperience={is_experience === true}
                                />
                              )
                            }

                            <div className="calendar_course_list">
                              {
                                // * day_study_exams 唤醒模块
                                // * AwakeCourseForeverUnlock 课程永久解锁
                                // * AwakeCourseEverydayUnlock 课程每日解锁

                                dayStudyExams &&
                                  day_study.day_study_exams.map(
                                    (day_study_exam) =>
                                      day_study_exam.quizzes_count > 0 &&
                                      (day_study.day_lock == false ? (
                                        <AwakeCourseForeverUnlock
                                          key={day_study_exam.id}
                                          day_study_exam={day_study_exam}
                                          day_lock={day_study.day_lock}
                                          day_study_start_time={
                                            new Date(
                                              day_study.day_study_start_time
                                            )
                                          }
                                          now_date={now_date}
                                          icon_stars={icon_stars}
                                        />
                                      ) : (
                                        <AwakeCourseEverydayUnlock
                                          key={day_study_exam.id}
                                          day_study_exam={day_study_exam}
                                          day_study_start_time={
                                            new Date(
                                              day_study.day_study_start_time
                                            )
                                          }
                                          now_date={now_date}
                                        />
                                      ))
                                  )
                              }

                              {this.state.isAlertQR && (
                                <div className="alert_qr_code">
                                  <div className="qr_phone_prompt">
                                    <div
                                      className="close_dialog"
                                      onClick={(e) => this.onAlert(e, false)}
                                    >
                                      &times;
                                    </div>
                                    <p>请联系老师进群听分享</p>
                                    {this.props.cc_qrcode_url && (
                                      <img
                                        data-src={this.props.cc_qrcode_url}
                                        className="lazyload"
                                      />
                                    )}
                                  </div>
                                </div>
                              )}

                              {
                                //  * media_lessons 课程学习模块
                                //  * CourseEverydayTotalUnlock 课程全部解锁
                                //  * CourseEverydayLock 课程每日都锁

                                // # 经验主题周 模块·有音频的时候
                                experiences ? (
                                  <ExperienceTopic
                                    experiences={day_study.experiences}
                                    onAlert={this.onAlert}
                                  />
                                ) : (
                                  mediaLessons &&
                                  day_study.media_lessons.map(
                                    (media_lesson) => {
                                      var lesson_time = this.state.lesson_times.find(
                                        (lesson_time) => {
                                          if (
                                            lesson_time.url ==
                                            media_lesson.audio_info_url
                                          ) {
                                            return lesson_time;
                                          }
                                        }
                                      );
                                      return (
                                        // lock == false时课程为：全部解锁状态
                                        // this.props.lock == false ?
                                        day_study.day_lock == false ? (
                                          <CourseEverydayTotalUnlock
                                            key={media_lesson.id}
                                            media_lesson={media_lesson}
                                            day_lock={day_study.day_lock}
                                            day_study_start_time={
                                              new Date(
                                                day_study.day_study_start_time
                                              )
                                            }
                                            now_date={now_date}
                                            icon_stars={icon_stars}
                                            lesson_time={lesson_time}
                                            is_parent_class={
                                              is_parent_class === true
                                            }
                                          />
                                        ) : (
                                          <CourseEverydayLock
                                            key={media_lesson.id}
                                            media_lesson={media_lesson}
                                            day_study_start_time={
                                              new Date(
                                                day_study.day_study_start_time
                                              )
                                            }
                                            now_date={now_date}
                                            icon_stars={icon_stars}
                                            lesson_time={lesson_time}
                                            is_parent_class={
                                              is_parent_class === true
                                            }
                                          />
                                        )
                                      );
                                    }
                                  )
                                )
                              }

                              {
                                // * 新增速度闯关练习类型
                                calculateTrainingCamps && (
                                  <CalcalateTrainingCamps
                                    calculate_training_camps={
                                      day_study.calculate_training_camps
                                    }
                                    lock={day_study.lock}
                                    day_lock={day_study.day_lock}
                                    speedPracticeClick={this.speedPracticeClick}
                                    day_study_start_time={
                                      new Date(day_study.day_study_start_time)
                                    }
                                    now_date={now_date}
                                  />
                                )
                              }

                              {
                                // * 随堂测试（原今日练习）模块
                                // * TodayQuizCourseForeverUnlock 课程永久解锁
                                // * TodayQuizCourseEverydayUnlock 课程每日解锁

                                day_study.today_quiz_url &&
                                  day_study.quizzes_count > 0 &&
                                  (day_study.day_lock == false ? (
                                    <TodayQuizCourseForeverUnlock
                                      today_quiz_url={day_study.today_quiz_url}
                                      day_lock={day_study.day_lock}
                                      today_quiz_status={
                                        day_study.today_quiz_status
                                      }
                                      quizzes_count={day_study.quizzes_count}
                                    />
                                  ) : (
                                    <TodayQuizCourseEverydayUnlock
                                      day_study_start_time={
                                        new Date(day_study.day_study_start_time)
                                      }
                                      now_date={now_date}
                                      today_quiz_url={day_study.today_quiz_url}
                                      today_quiz_status={
                                        day_study.today_quiz_status
                                      }
                                      quizzes_count={day_study.quizzes_count}
                                    />
                                  ))
                              }

                              {
                                // * 课外练习模块 (随堂测试)
                                // * ExamineForeverUnlock · 课程永久解锁
                                // * ExamineEverydayUnlock · 课程每日解锁

                                day_study.exercise_quiz_url &&
                                  day_study.exercise_quizzes_count > 0 &&
                                  (day_study.day_lock == false ? (
                                    <ExamineForeverUnlock
                                      day_lock={day_study.day_lock}
                                      exercise_quiz_url={
                                        day_study.exercise_quiz_url
                                      }
                                      exercise_quiz_status={
                                        day_study.exercise_quiz_status
                                      }
                                      exercise_quizzes_count={
                                        day_study.exercise_quizzes_count
                                      }
                                    />
                                  ) : (
                                    <ExamineEverydayUnlock
                                      day_study_start_time={
                                        new Date(day_study.day_study_start_time)
                                      }
                                      now_date={now_date}
                                      exercise_quiz_url={
                                        day_study.exercise_quiz_url
                                      }
                                      exercise_quiz_status={
                                        day_study.exercise_quiz_status
                                      }
                                      exercise_quizzes_count={
                                        day_study.exercise_quizzes_count
                                      }
                                    />
                                  ))
                              }
                            </div>
                          </div>
                        ) : (
                          <HolidayRestPrompt
                            isExperience={is_experience === true}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}

              {
                // 本单元结束 请切换另一单元查看
                day_studies && (
                  <UnitCourseEnd
                    content={"本单元结束"}
                    another_content={"请切换另一单元查看"}
                    is_experience={this.props.is_experience}
                    is_parent_class={is_parent_class === true}
                  />
                )
              }
            </div>
          </div>

          <span
            id="href_today"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.location.replace(
                window.location.pathname + window.location.search + "#today"
              );
              return false;
            }}
          ></span>
        </div>
        {
          // 设置
          <CourseActivityEntrance
            checkin={this.props.checkin}
            studyReportUrl={this.state.study_report_url}
            checkinDays={this.props.checkin_days}
            bonus={this.props.bonus}
            isIosReview={this.props.is_ios_review}
            shareUrl={this.props.invitation_rewards_url}
            reminderTimeHour={this.props.reminder_time_hour}
            commentState={this.props.comment_state}
            commentUrl={this.props.comment_url}
            handleHideDialog={this.handleSetting}
            isStyleDialog={this.state.isSettingDialog}
            isExperience={this.props.is_experience}
          />
        }
      </div>
    );
  }
}

StudyPackage.propTypes = Object.assign({}, SharePoster.propTypes, {
  media_lessons: PropTypes.array,
  day_study_exams: PropTypes.array,
  get_day_study_url: PropTypes.string,
});

StudyPackage.defaultProps = {
  free: "study_package",
};
