import React from "react";
import PropTypes from "prop-types";

import Picker from "better-picker";

import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import { Dialog, DialogManager } from "../utils/Dialog";
import { formatDate, formatAudioTime } from "../utils/formatAudioTime";
import {
  SharePosterProxy,
  SharePoster,
} from "../promotion_activities/SharePoster";
import CountDownTime from "../utils/CountDown";
import { reloadPage } from "../utils/helpers";
import { onMiniProgram } from "../utils/helpers";

import icon_star_0 from "study_package/icon_star_0.png";
import icon_star_1 from "study_package/icon_star_1.png";
import icon_star_2 from "study_package/icon_star_2.png";
import icon_star_3 from "study_package/icon_star_3.png";
import icon_lock_gray from "study_package/icon_lock_gray.png";
import icon_quiz_pass from "icon_quiz_pass.png";

import "./DayModeStudyPackage.scss";

import Calendar from "../utils/Calendar";
import UserAgent from "../utils/UserAgent";
import URI from "jsuri";
import QueryString from "query-string";

const date_hour_data = [
  { text: "不提醒", value: -1 },
  { text: "00点", value: 0 },
  { text: "01点", value: 1 },
  { text: "02点", value: 2 },
  { text: "03点", value: 3 },
  { text: "04点", value: 4 },
  { text: "05点", value: 5 },
  { text: "06点", value: 6 },
  { text: "07点", value: 7 },
  { text: "08点", value: 8 },
  { text: "09点", value: 9 },
  { text: "10点", value: 10 },
  { text: "11点", value: 11 },
  { text: "12点", value: 12 },
  { text: "13点", value: 13 },
  { text: "14点", value: 14 },
  { text: "15点", value: 15 },
  { text: "16点", value: 16 },
  { text: "17点", value: 17 },
  { text: "18点", value: 18 },
  { text: "19点", value: 19 },
  { text: "20点", value: 20 },
  { text: "21点", value: 21 },
  { text: "22点", value: 22 },
  { text: "23点", value: 23 },
];

const RefreshScheduleCountTime = ({ refreshSchedule, handleCountdownEnd }) => {
  return (
    <div className="refresh_schedule">
      <CountDownTime
        endTime={new Date(refreshSchedule)}
        overText="刷新网页"
        onEnd={handleCountdownEnd}
      >
        {({ d, h, m, s }) => {
          return <span></span>;
          // return (<span>距离第二天：{d}天 {h}:{m}:{s}</span>)
        }}
      </CountDownTime>
    </div>
  );
};

const CalendarTopText = ({ shareUrl }) => {
  return (
    <div className="calendar_check">
      <span>完成</span>
      {/* <span>补课</span> */}
      <span>未完成</span>
      {/* <span>
        <a href={shareUrl} onClick={this.shareClick}>邀请报名</a>
      </span> */}
    </div>
  );
};

const StudentId = ({ babyName, sno }) => {
  return (
    <div className="coupon_district">
      <div className="baby_name">
        <span>
          {babyName}(学号：{sno})
        </span>
      </div>
    </div>
  );
};

const NumberLine = () => {
  return <div className="student_number_line"></div>;
};

const CircleRing = ({
  studiedCourseDayCount,
  courseDayCount,
  scoreProgressLeft,
  scoreProgressRight,
  totalStudiedMediaLessonsCount,
  mediaLessonsCount,
  scoreProgressLeft2,
  scoreProgressRight2,
}) => {
  return (
    <div className="course_circle_box">
      {/* left 已学天数*/}
      <div className="interview_score">
        <div className="circleProgress_wrapper">
          <div className="circleProgress_wrapper_inside"></div>
          <span className="correct_score">
            <span className="">{studiedCourseDayCount}</span>
            <span>/{courseDayCount}</span>
          </span>
          <div className="wrapper left">
            <div
              className="circleProgress leftcircle"
              style={scoreProgressLeft}
            ></div>
          </div>
          <div className="wrapper right">
            <div
              className="circleProgress rightcircle"
              style={scoreProgressRight}
            ></div>
          </div>
        </div>
      </div>

      {/* right  已学课时*/}
      <div className="interview_score">
        <div className="circleProgress_wrapper">
          <div className="circleProgress_wrapper_inside"></div>
          <span className="correct_score">
            <span className="">{totalStudiedMediaLessonsCount}</span>
            <span>/{mediaLessonsCount}</span>
          </span>
          <div className="wrapper left">
            <div
              className="circleProgress leftcircle"
              style={scoreProgressLeft2}
            ></div>
          </div>
          <div className="wrapper right">
            <div
              className="circleProgress rightcircle"
              style={scoreProgressRight2}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

const KnowledgePrompt = ({ Day, Hour }) => {
  return (
    <div className="knowledge_time">
      <div className="knowledge_points">{Day}</div>
      <div className="knowledge_minutes">{Hour}</div>
    </div>
  );
};

const CourseActivityEntrance = ({
  checkin,
  studyReportUrl,
  checkinDays,
  bonus,
  isIosReview,
  shareUrl,
  reminderTimeHour,
}) => {
  return (
    <div className="course_activity_entrance">
      <ul>
        {checkin && (
          <li className="icon_card">
            <a href={studyReportUrl}>
              <p>打卡活动</p>
              <span>已打卡{checkinDays}天</span>
            </a>
          </li>
        )}
        {bonus != 0 && isIosReview === false && (
          <li className="icon_invite">
            <a href={shareUrl}>
              <p>邀请报名</p>
              <span>新用户报名奖{bonus}元</span>
            </a>
          </li>
        )}
        <li className="icon_remind" id="time_setup_click">
          <a href="javascript:void(0)">
            <p>提醒时间</p>
            {reminderTimeHour >= -1 ? (
              <div className="remind_time_set">
                当前：
                <span id="name">
                  {reminderTimeHour === -1 ? "不提醒" : `${reminderTimeHour}点`}
                </span>
              </div>
            ) : (
              <span>未购买,无法设置</span>
            )}
          </a>
        </li>
      </ul>
    </div>
  );
};

const StudyReportEntrance = ({
  todayQuizStatus,
  studyReportDisplay,
  studyReportUrl,
}) => {
  return (
    <div
      className="button_study_report"
      style={{ display: studyReportDisplay }}
    >
      <a href={studyReportUrl}>学习报告</a>
    </div>
  );
};

const DaySection = ({ day }) => {
  return (
    day && (
      <div className="correspond_course">
        <span>
          第&nbsp;<i>{day}</i>&nbsp;天
        </span>
      </div>
    )
  );
};

const AwakeCourseForeverUnlock = ({
  day_study_exam,
  day_lock,
  day_study_begin_time,
  now_date,
  icon_stars,
}) => {
  return (
    <div>
      {/* 唤醒(day_exam_type: 0) · 以练代学(day_exam_type: 2)· 课程永久解锁 */}
      <a href={day_lock == true ? "javascript:void(0)" : day_study_exam.url}>
        <div className="calendar_examine">
          <img
            className="course_star"
            src={
              day_lock == true
                ? icon_lock_gray
                : icon_stars[day_study_exam.rank]
            }
          />
          {day_lock == false &&
            day_study_exam.score >= 0 &&
            (day_study_exam.day_exam_type !== 2 ? (
              <div className="score">
                <i>{day_study_exam.score}</i>分
              </div>
            ) : (
              <img className="course_star" src={icon_quiz_pass} />
            ))}

          <div className="knowledge_content">
            <h2>{day_study_exam.name}</h2>
          </div>

          <div className="course_subscrible_practice">
            {day_lock == true ? (
              ""
            ) : day_study_exam.score >= 0 ? (
              ""
            ) : (
              <div className="go_practice">去练习</div>
            )}
            <div className="course_amount_time">
              {<i>{day_study_exam.quizzes_count}道题</i>}
              {day_study_exam.count_down_mins && (
                <span>限时：{day_study_exam.count_down_mins}分钟</span>
              )}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

const AwakeCourseEverydayUnlock = ({
  day_study_exam,
  day_study_begin_time,
  now_date,
}) => {
  return (
    <div>
      {/* 唤醒(day_exam_type: 0) · 以练代学(day_exam_type: 2) · 课程每日解锁 */}
      <a
        href={
          day_study_begin_time < now_date
            ? day_study_exam.url
            : "javascript:void(0)"
        }
      >
        <div className="calendar_examine">
          {day_study_begin_time < now_date ? (
            day_study_exam.score >= 0 &&
            (day_study_exam.day_exam_type !== 2 ? (
              <div className="score">
                <i>{day_study_exam.score}</i>分
              </div>
            ) : (
              <img className="course_star" src={icon_quiz_pass} />
            ))
          ) : (
            <img className="course_star" src={icon_lock_gray} />
          )}
          <div className="knowledge_content">
            <h2>{day_study_exam.name}</h2>
          </div>

          <div className="course_subscrible_practice">
            {day_study_begin_time > now_date ? (
              ""
            ) : day_study_exam.score >= 0 ? (
              ""
            ) : (
              <div className="go_practice">去练习</div>
            )}
            <div className="course_amount_time">
              {<i>{day_study_exam.quizzes_count}道题</i>}
              {day_study_exam.count_down_mins && (
                <span>限时：{day_study_exam.count_down_mins}分钟</span>
              )}
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

const HolidayRestPrompt = ({ isExperience }) => {
  return (
    <div
      className="holiday_rest"
      style={{ padding: isExperience ? "6px 0 20px" : null }}
    >
      {isExperience
        ? "今日休息，可自行安排收听其他的经验分享"
        : "今日休息，家长可自行安排复习前面的知识点"}
    </div>
  );
};

// 经验主题周
const ExperienceTopic = ({ experiences, onAlert }) => {
  let experienceStateText;
  return experiences.map((experience) => {
    if (experience.state == 0) {
      experienceStateText = "嘉宾分享";
    } else if (experience.state == 1) {
      experienceStateText = "去收听";
    } else {
      experienceStateText = "嘉宾分享";
    }

    return (
      <a
        key={experience.id}
        href="javascript:void(0)"
        data-url={experience.url}
        data-state={experience.state}
        onClick={(e) => onAlert(e, true)}
      >
        <div className="calendar_course" style={{ minHeight: "70px" }}>
          <div className="knowledge_content">
            <h2>{experience.name}</h2>
            <div>
              <div className="go_class" style={{ marginTop: "5px" }}>
                {experienceStateText}
              </div>
              <p>嘉宾：{experience.user_name}</p>
            </div>
          </div>
        </div>
      </a>
    );
  });
};

const CourseEverydayTotalUnlock = ({
  media_lesson,
  day_lock,
  day_study_begin_time,
  now_date,
  icon_stars,
  lesson_time,
}) => {
  return (
    <a
      key={media_lesson.id}
      href={day_lock == true ? "javascript:void(0)" : media_lesson.url}
      data-url={day_lock == true ? "" : media_lesson.url}
    >
      <div className="calendar_course">
        {day_lock == true ? (
          <img className="course_star" src={icon_lock_gray} />
        ) : (
          icon_stars[media_lesson.rank] && (
            <img className="course_star" src={icon_stars[media_lesson.rank]} />
          )
        )}
        <div className="knowledge_content">
          <h2>
            {media_lesson.media_course_name}：{media_lesson.name}
            {media_lesson.extension ? "【延伸课】" : ""}
          </h2>
          {media_lesson.objective && <p>{media_lesson.objective}</p>}
          <div className="course_subscrible_practice">
            {day_lock == true ? (
              ""
            ) : icon_stars[media_lesson.rank] ? (
              ""
            ) : (
              <div className="go_class">去上课</div>
            )}
            <div className="course_time_difficulty">
              {lesson_time && <i>{lesson_time.time}</i>}
              {media_lesson.difficulty != null && (
                <span>难度等级：{media_lesson.difficulty}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const CourseEverydayLock = ({
  media_lesson,
  day_study_begin_time,
  now_date,
  icon_stars,
  lesson_time,
}) => {
  return (
    <a
      href={
        day_study_begin_time < now_date
          ? media_lesson.url
          : "javascript:void(0)"
      }
      key={media_lesson.id}
      data-url={day_study_begin_time < now_date ? media_lesson.url : ""}
    >
      <div className="calendar_course">
        <img
          className="course_star"
          src={
            day_study_begin_time < now_date
              ? icon_stars[media_lesson.rank]
              : icon_lock_gray
          }
        />
        <div className="knowledge_content">
          <h2>
            {media_lesson.media_course_name}：{media_lesson.name}
            {media_lesson.extension ? "【延伸课】" : ""}
          </h2>
          {media_lesson.objective && <p>{media_lesson.objective}</p>}
          <div className="course_subscrible_practice">
            {day_study_begin_time > now_date ? (
              ""
            ) : icon_stars[media_lesson.rank] ? (
              ""
            ) : (
              <div className="go_class" href="">
                去上课
              </div>
            )}
            <div className="course_time_difficulty">
              {lesson_time && <i>{lesson_time.time}</i>}
              {media_lesson.difficulty != null && (
                <span>难度等级：{media_lesson.difficulty}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

const CalcalateTrainingCamps = ({
  calculate_training_camps,
  lock,
  day_lock,
  speedPracticeClick,
  day_study_begin_time,
  now_date,
}) => {
  let handleClickSpeedPractice;
  let imgStar;
  // lock === false
  if (day_lock === false) {
    handleClickSpeedPractice = day_lock === true ? null : speedPracticeClick;
    imgStar = "";
  } else {
    handleClickSpeedPractice =
      day_study_begin_time < now_date ? speedPracticeClick : null;
    if (day_study_begin_time > now_date) {
      imgStar = <img className="course_star" src={icon_lock_gray} />;
    }
  }

  return calculate_training_camps.map((camp, position) => (
    <a
      key={position}
      href="javascript:void(0)"
      onClick={handleClickSpeedPractice}
    >
      <div className="calendar_examine">
        {imgStar}
        <div className="knowledge_content">
          <h2>
            {camp.training_camp_name}（第{camp.position}关）：
            {camp.calculate_training_name}
          </h2>
        </div>
      </div>
    </a>
  ));
};

const TodayQuizCourseForeverUnlock = ({
  today_quiz_url,
  day_lock,
  today_quiz_status,
  quizzes_count,
}) => {
  return (
    <a href={day_lock == true ? "javascript:void(0)" : today_quiz_url}>
      <div className="calendar_examine">
        {day_lock == true ? (
          <img className="course_star" src={icon_lock_gray} />
        ) : today_quiz_status === false ? (
          ""
        ) : (
          <img className="course_star" src={icon_quiz_pass} />
        )}
        <div className="knowledge_content">
          <h2>随堂测试</h2>
        </div>

        <div className="course_subscrible_practice">
          {day_lock == true
            ? ""
            : today_quiz_status === false && (
                <div className="go_practice">去练习</div>
              )}
          <div className="course_amount_time">
            <i>{quizzes_count}道题</i>
          </div>
        </div>
      </div>
    </a>
  );
};

const TodayQuizCourseEverydayUnlock = ({
  day_study_begin_time,
  now_date,
  today_quiz_url,
  today_quiz_status,
  quizzes_count,
}) => {
  return (
    <a
      href={
        day_study_begin_time < now_date ? today_quiz_url : "javascript:void(0)"
      }
    >
      <div className="calendar_examine">
        {day_study_begin_time > now_date ? (
          <img className="course_star" src={icon_lock_gray} />
        ) : today_quiz_status === false ? (
          ""
        ) : (
          <img className="course_star" src={icon_quiz_pass} />
        )}

        <div className="knowledge_content">
          <h2>随堂测试</h2>
        </div>

        <div className="course_subscrible_practice">
          {day_study_begin_time <= now_date && today_quiz_status === false && (
            <div className="go_practice">去练习</div>
          )}
          <div className="course_amount_time">
            <i>{quizzes_count}道题</i>
          </div>
        </div>
      </div>
    </a>
  );
};

const ExamineForeverUnlock = ({
  day_lock,
  exercise_quiz_url,
  exercise_quiz_status,
  exercise_quizzes_count,
}) => {
  return (
    <a href={day_lock == true ? "javascript:void(0)" : exercise_quiz_url}>
      <div className="calendar_examine">
        {day_lock == true ? (
          <img className="course_star" src={icon_lock_gray} />
        ) : exercise_quiz_status === false ? (
          ""
        ) : (
          <img className="course_star" src={icon_quiz_pass} />
        )}
        <div className="knowledge_content">
          <h2>课外练习（选做）</h2>
        </div>

        <div className="course_subscrible_practice">
          {day_lock == true
            ? ""
            : exercise_quiz_status === false && (
                <div className="go_practice">去练习</div>
              )}
          <div className="course_amount_time">
            <i>{exercise_quizzes_count}道题</i>
          </div>
        </div>
      </div>
    </a>
  );
};

const ExamineEverydayUnlock = ({
  day_study_begin_time,
  now_date,
  exercise_quiz_url,
  exercise_quiz_status,
  exercise_quizzes_count,
}) => {
  return (
    <a
      href={
        day_study_begin_time < now_date
          ? exercise_quiz_url
          : "javascript:void(0)"
      }
    >
      <div className="calendar_examine">
        {day_study_begin_time > now_date ? (
          <img className="course_star" src={icon_lock_gray} />
        ) : exercise_quiz_status === false ? (
          ""
        ) : (
          <img className="course_star" src={icon_quiz_pass} />
        )}

        <div className="knowledge_content">
          <h2>课外练习（选做）</h2>
        </div>

        <div className="course_subscrible_practice">
          {day_study_begin_time <= now_date &&
            exercise_quiz_status === false && (
              <div className="go_practice">去练习</div>
            )}
          <div className="course_amount_time">
            <i>{exercise_quizzes_count}道题</i>
          </div>
        </div>
      </div>
    </a>
  );
};

export default class StudyPackage extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      media_lessons: this.props.media_lessons,
      experiences: this.props.experiences,
      day_study_exams: this.props.day_study_exams,
      calculate_training_camps: this.props.calculate_training_camps,
      day: this.props.day,
      day_study_start_time: this.props.day_study_start_time,
      study_package_system_time: this.props.study_package_system_time,
      today_quiz_url: this.props.today_quiz_url,
      today_quiz_status: this.props.today_quiz_status,
      exercise_quiz_status: this.props.exercise_quiz_status,
      quizzes_count: this.props.quizzes_count,
      exercise_quiz_url: this.props.exercise_quiz_url,
      exercise_quizzes_count: this.props.exercise_quizzes_count,
      lesson_times: [],
      study_report: this.props.study_report,
      study_report_url: this.props.study_report_url,
      study_package_start_time: this.props.study_package_start_time,
      study_package_end_time: this.props.study_package_end_time,
      StudyReportDisplay: "none",
      refresh_schedule: this.props.today_end_time,

      studied_course_day_count: this.props.studied_course_day_count,
      total_studied_media_lessons_count: this.props
        .total_studied_media_lessons_count,
      left_day_progress: 0,
      right_period_progress: 0,
      isAlertQR: false,
    };
    this.getAllLessonTimeInfo(this.props.media_lessons);
    this.selectOneday = this.selectOneday.bind(this);
    this.currentDayState = this.currentDayState.bind(this);
  }

  //点击当前日期
  selectOneday(current_day) {
    console.log("点击后的日期：" + current_day);

    var study_package_start_time = formatDate(
      this.props.study_package_start_time
    );
    var study_package_end_time = formatDate(this.props.study_package_end_time);
    var study_package_system_time = formatDate(
      this.props.study_package_system_time
    );

    if (
      formatDate(current_day) < study_package_start_time ||
      formatDate(current_day) > study_package_end_time
    ) {
      this.noLimitAccess();
    } else {
      this.ajaxRequest({ current_day: current_day });
    }
  }

  currentDayState(current_day) {
    let record = this.props.day_study_records.find((record) => {
      if (record.date == current_day) {
        return record;
      }
    });
    if (record) {
      return record.state;
    }
    return "";
  }

  ajaxRequest(params) {
    var url = this.props.get_day_study_url + "&date=" + params.current_day;
    this.toast = ToastManager.showLoading("拼命加载中...");
    request({
      url: url,
      method: "GET",
      headers: csrfHeaders,
    })
      .then((resp) => {
        const data = resp.data;
        console.log("正确返回");
        // console.log(data)
        this.toast.cancel();
        this.setState({
          media_lessons: data.media_lessons,
          experiences: data.experiences,
          day_study_exams: data.day_study_exams,
          calculate_training_camps: data.calculate_training_camps,
          day: data.day,
          day_study_start_time: data.day_study_start_time,
          today_quiz_url: data.today_quiz_url,
          quizzes_count: data.quizzes_count,
          today_quiz_status: data.today_quiz_status,
          exercise_quiz_status: data.exercise_quiz_status,
          exercise_quiz_url: data.exercise_quiz_url,
          exercise_quizzes_count: data.exercise_quizzes_count,
          study_report: data.study_report,
          study_report_url: data.study_report_url,
          difficulty: data.difficulty,
          day_lock: data.day_lock,
        });
        if (this.state.study_report) {
          this.setState({
            StudyReportDisplay: "block",
          });
        } else {
          this.setState({
            StudyReportDisplay: "none",
          });
        }

        this.getAllLessonTimeInfo(this.state.media_lessons);
      })
      .catch((err) => {
        this.toast.cancel();
        console.log("错误返回");
        console.log(err);
      });
  }

  noLimitAccess = (e) => {
    const buttons = [
      {
        label: "OK",
        onClick: () => this.confirmDialog.close(),
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>超出课程时间范围</Dialog>
    );
  };

  getAllLessonTimeInfo(media_lessons) {
    if (!media_lessons) {
      return;
    }
    for (var i = 0; i < media_lessons.length; i++) {
      let url = media_lessons[i].audio_info_url;
      let quiz_count_sec = media_lessons[i].resource_quiz_count * 10 || 0;
      this.getAudioResult(url, quiz_count_sec);
    }
  }

  getAudioResult(infourl, quiz_count_sec = 0) {
    request({
      url: infourl,
      method: "get",
    })
      .then((resp) => {
        const totalTime = parseInt(resp.data.format.duration);

        const showTime = formatAudioTime(totalTime + quiz_count_sec);
        const lesson_time = { url: infourl, time: showTime };
        var lesson_times = this.state.lesson_times;

        lesson_times.push(lesson_time);
        this.setState({
          lesson_times: lesson_times,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //判断设备
  // componentDidMount(){
  //   var container = document.getElementsByClassName('container')
  //   var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //   if (isMobile) {
  //     container[0].className = 'container'
  //   }else{
  //     container[0].className = 'container desktop'
  //   }
  // }

  componentDidMount() {
    if (this.state.study_report == true) {
      this.setState({
        StudyReportDisplay: "block",
      });
    }

    var study_package_start_time = formatDate(
      this.props.study_package_start_time
    );
    var study_package_end_time = formatDate(this.props.study_package_end_time);
    var study_package_system_time = formatDate(
      this.props.study_package_system_time
    );

    // this.selectOneday(study_package_end_time)

    if (study_package_system_time >= study_package_end_time) {
      this.cal.trigerActiveDate(formatDate(this.props.study_package_end_time));
      this.selectOneday(formatDate(this.props.study_package_end_time));
    } else if (
      study_package_system_time > study_package_start_time ||
      study_package_system_time < study_package_end_time
    ) {
      this.cal.trigerActiveDate(
        formatDate(this.props.study_package_system_time)
      );
      this.selectOneday(formatDate(this.props.study_package_system_time));
    }

    const share_url = this.props.share_url;
    const share_image_url = this.props.share_image_url;

    const share_title = this.props.share_title;
    const share_description = this.props.share_description;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    const nameEl = document.getElementById("name");
    const picker = new Picker({
      data: [date_hour_data],
      selectedIndex: [this.props.reminder_time_hour + 1],
      title: "",
    });

    const config_reminder_time_url = this.props.config_reminder_time_url;
    const reminder_time_hour = this.props.reminder_time_hour;
    picker.on("picker.select", function (selectedVal, selectedIndex) {
      nameEl.innerText = date_hour_data[selectedIndex[0]].text;
      console.log("当前所选值：", nameEl.innerText);
      console.log(
        "选择值：",
        selectedIndex[0] - 1,
        "后台数据返回的值：",
        reminder_time_hour
      );

      if (selectedIndex[0] - 1 != reminder_time_hour) {
        let params = {
          reminder_hour: selectedIndex[0] - 1,
        };
        console.log("params: ", params["reminder_hour"]);
        var url = config_reminder_time_url;
        request({
          url: url,
          method: "POST",
          headers: csrfHeaders,
          data: params,
        })
          .then((resp) => {
            // const data = resp.data
            console.log("正确返回");
          })
          .catch((err) => {
            console.log("错误返回");
            console.log(err);
          });
      }
    });

    if (this.props.reminder_time_hour >= -1) {
      const time_setup_click = document.getElementById("time_setup_click");
      time_setup_click.addEventListener("click", function () {
        picker.show();
        if (UserAgent.isNativeClient()) {
          XinYaNativeBridge.requestAuthorization({ authType: "notification" });
        }
      });
    }

    this.mounted = true;
  }

  inviteFriendRecommend = (e) => {
    this.recommendDilog.setState({
      isFillInDisplay: "block",
    });
  };

  handleCountdownEnd = (e) => {
    var isCorrectTime =
      new Date(this.state.refresh_schedule) -
        new Date(this.props.study_package_system_time) >
      0;
    if (isCorrectTime) {
      this.timeoutID = setTimeout(() => this.refreshPage(), 2000);
    }
  };

  refreshPage = (e) => {
    if (this.timeoutUpdater) {
      clearTimeout(this.timeoutID);
    }
    window.location.reload();
  };

  shareClick = (e) => {
    if (UserAgent.isMiniProgram() && e.target.tagName.toLowerCase() == "a") {
      let mini_program = e.target.href;
      onMiniProgram(e, mini_program, {});
    }
  };

  // 速度闯关练习类型的弹框提醒
  speedPracticeClick = () => {
    const buttons = [
      {
        label: "我知道了",
        onClick: () => this.confirmDialog.close(),
      },
    ];
    this.confirmDialog = DialogManager.open(
      <Dialog buttons={buttons}>
        请使用手机版微信扫描练习卷上的二维码，完成训练
      </Dialog>
    );
  };

  // activityTap = (e) => {
  //   var url = e.currentTarget.getAttribute("data-url")
  //   console.log(url)
  //   let searchParams = new URI(url).query()
  //   let pathname = new URI(url).path()
  //   let parsedQuery = QueryString.parse(searchParams)
  //   if ((UserAgent.isMiniProgram()) && url.includes('/media_lessons/') && this.props.is_miniprogram_native) {
  //     e.preventDefault()
  //     let id = pathname.match(/[0-9]*$/)[0]
  //     let params = Object.assign(parsedQuery, { id: id })
  //     let paramsString = JSON.stringify(params)
  //     const url = `/pages/recorder_demo/audioRecorder?params=${paramsString}`
  //     wx.miniProgram.navigateTo({url: url})
  //   }
  // }

  //点击弹老师二维码
  onAlert = (e, isAlert) => {
    e.preventDefault();
    let experience_url = e.currentTarget.getAttribute("data-url");
    let experience_state = e.currentTarget.getAttribute("data-state");

    if (experience_state == 1) {
      if (UserAgent.isMiniProgram()) {
        onMiniProgram(e, experience_url, {});
      } else if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({ href: experience_url });
      } else {
        window.location.href = experience_url;
      }
    } else {
      this.setState({
        isAlertQR: isAlert,
      });
    }
  };

  render() {
    const {
      media_lessons,
      experiences,
      day_study_exams,
      calculate_training_camps,
    } = this.state;

    const { is_experience, share_url } = this.props;

    const experiencesValue = experiences && experiences.length > 0;
    const mediaLessonsValue = media_lessons && media_lessons.length > 0;
    const dayStudyExamsValue = day_study_exams && day_study_exams.length > 0;

    const icon_stars = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];

    var now_date = new Date(this.state.study_package_system_time);
    var day_study_begin_time = new Date(this.state.day_study_start_time);
    var props_system_time = new Date(this.props.study_package_system_time);
    var user_upgrade_deadline = new Date("2018-07-16T00:00:00.000+08:00");

    let scoreProgress_left = {},
      scoreProgress_right = {};
    let scoreProgress_left_2 = {},
      scoreProgress_right_2 = {};

    let left_day_progress = Math.round(
      (this.props.studied_course_day_count / this.props.course_day_count) * 100
    );
    let right_period_progress = Math.round(
      (this.props.total_studied_media_lessons_count /
        this.props.media_lessons_count) *
        100
    );

    // console.log(left_day_progress)
    // console.log(right_period_progress)

    if (this.mounted) {
      if (left_day_progress >= 0 && left_day_progress <= 50) {
        const rotate = -135 + (18 / 5) * left_day_progress;
        scoreProgress_left = {
          transform: `rotate(${rotate}deg)`,
        };
        scoreProgress_right = {
          transform: `rotate(-135deg)`,
        };
      } else {
        const rotate = -135 + (18 / 5) * (left_day_progress - 50);
        scoreProgress_left = {
          transform: `rotate(45deg)`,
        };
        scoreProgress_right = {
          transform: `rotate(${rotate}deg)`,
        };
      }

      if (right_period_progress >= 0 && right_period_progress <= 50) {
        const rotate = -135 + (18 / 5) * right_period_progress;
        scoreProgress_left_2 = {
          transform: `rotate(${rotate}deg)`,
        };
        scoreProgress_right_2 = {
          transform: `rotate(-135deg)`,
        };
      } else {
        const rotate = -135 + (18 / 5) * (right_period_progress - 50);
        scoreProgress_left_2 = {
          transform: `rotate(45deg)`,
        };
        scoreProgress_right_2 = {
          transform: `rotate(${rotate}deg)`,
        };
      }
    }

    return (
      <div className="comp-study-package">
        <RefreshScheduleCountTime
          refreshSchedule={this.state.refresh_schedule}
          handleCountdownEnd={this.handleCountdownEnd}
        />
        <div className="calendar_plan">
          <CalendarTopText shareUrl={share_url} />

          <div className="calendar_date">
            <Calendar
              selectday={this.selectOneday}
              currentday={this.currentDayState}
              ref={(cal) => {
                this.cal = cal;
              }}
            />
          </div>

          <div className="calendar_date_course">
            {this.state.day ? (
              <div>
                {this.state.study_report && (
                  <StudyReportEntrance
                    todayQuizStatus={this.state.today_quiz_status}
                    studyReportDisplay={this.state.StudyReportDisplay}
                    studyReportUrl={this.state.study_report_url}
                  />
                )}
                <DaySection day={this.state.day} />

                {
                  // 经验主题周·无经验列表的时候, 显示 “今日休息...”
                  experiencesValue ? (
                    ""
                  ) : mediaLessonsValue ||
                    dayStudyExamsValue ||
                    this.state.today_quiz_url ||
                    calculate_training_camps ||
                    this.state.exercise_quiz_url ? (
                    ""
                  ) : (
                    <HolidayRestPrompt isExperience={is_experience === true} />
                  )
                }

                <div className="calendar_course_list">
                  {
                    /**
                     * AwakeCourseForeverUnlock 课程永久解锁
                     * AwakeCourseEverydayUnlock 课程每日解锁
                     * 唤醒(day_exam_type: 0)
                     * 单元测试(day_exam_type: 1)
                     * 以练代学(day_exam_type: 2)
                     */

                    dayStudyExamsValue &&
                      day_study_exams.map((day_study_exam) =>
                        this.state.day_lock == false ? (
                          <AwakeCourseForeverUnlock
                            key={day_study_exam.id}
                            day_study_exam={day_study_exam}
                            day_lock={this.state.day_lock}
                            day_study_begin_time={day_study_begin_time}
                            now_date={now_date}
                            icon_stars={icon_stars}
                          />
                        ) : (
                          <AwakeCourseEverydayUnlock
                            key={day_study_exam.id}
                            day_study_exam={day_study_exam}
                            day_study_begin_time={day_study_begin_time}
                            now_date={now_date}
                          />
                        )
                      )
                  }

                  {this.state.isAlertQR && (
                    <div className="alert_qr_code">
                      <div className="qr_phone_prompt">
                        <div
                          className="close_dialog"
                          onClick={(e) => this.onAlert(e, false)}
                        >
                          &times;
                        </div>
                        <p>请联系老师进群听分享</p>
                        {this.props.cc_qrcode_url && (
                          <img
                            data-src={this.props.cc_qrcode_url}
                            className="lazyload"
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {
                    //  * media_lessons 课程学习模块
                    //  * CourseEverydayTotalUnlock 课程全部解锁
                    //  * CourseEverydayLock 课程每日都锁

                    // # 经验主题周 模块
                    experiencesValue ? (
                      <ExperienceTopic
                        experiences={experiences}
                        onAlert={this.onAlert}
                      />
                    ) : (
                      mediaLessonsValue &&
                      media_lessons.map((media_lesson) => {
                        var lesson_time = this.state.lesson_times.find(
                          (lesson_time) => {
                            if (
                              lesson_time.url == media_lesson.audio_info_url
                            ) {
                              return lesson_time;
                            }
                          }
                        );
                        return (
                          // lock == false时课程为：全部解锁状态
                          this.state.day_lock == false ? (
                            <CourseEverydayTotalUnlock
                              key={media_lesson.id}
                              media_lesson={media_lesson}
                              day_lock={this.state.day_lock}
                              day_study_begin_time={day_study_begin_time}
                              now_date={now_date}
                              icon_stars={icon_stars}
                              lesson_time={lesson_time}
                            />
                          ) : (
                            <CourseEverydayLock
                              key={media_lesson.id}
                              media_lesson={media_lesson}
                              day_study_begin_time={day_study_begin_time}
                              now_date={now_date}
                              icon_stars={icon_stars}
                              lesson_time={lesson_time}
                            />
                          )
                        );
                      })
                    )
                  }

                  {
                    // * 新增速度闯关练习类型

                    !calculate_training_camps ? (
                      ""
                    ) : (
                      <CalcalateTrainingCamps
                        calculate_training_camps={calculate_training_camps}
                        lock={this.props.lock}
                        day_lock={this.state.day_lock}
                        speedPracticeClick={this.speedPracticeClick}
                        day_study_begin_time={day_study_begin_time}
                        now_date={now_date}
                      />
                    )
                  }

                  {
                    // * 随堂测试（原今日练习）模块
                    // * TodayQuizCourseForeverUnlock 课程永久解锁
                    // * TodayQuizCourseEverydayUnlock 课程每日解锁

                    this.state.day_lock == false
                      ? this.state.today_quiz_url && (
                          <TodayQuizCourseForeverUnlock
                            today_quiz_url={this.state.today_quiz_url}
                            day_lock={this.state.day_lock}
                            today_quiz_status={this.state.today_quiz_status}
                            quizzes_count={this.state.quizzes_count}
                          />
                        )
                      : this.state.today_quiz_url && (
                          <TodayQuizCourseEverydayUnlock
                            day_study_begin_time={day_study_begin_time}
                            now_date={now_date}
                            today_quiz_url={this.state.today_quiz_url}
                            today_quiz_status={this.state.today_quiz_status}
                            quizzes_count={this.state.quizzes_count}
                          />
                        )
                  }

                  {
                    // * 课外练习模块 (随堂测试)
                    // * ExamineForeverUnlock · 课程永久解锁
                    // * ExamineEverydayUnlock · 课程每日解锁

                    this.state.day_lock == false
                      ? this.state.exercise_quiz_url && (
                          <ExamineForeverUnlock
                            day_lock={this.state.day_lock}
                            exercise_quiz_url={this.state.exercise_quiz_url}
                            exercise_quiz_status={
                              this.state.exercise_quiz_status
                            }
                            exercise_quizzes_count={
                              this.state.exercise_quizzes_count
                            }
                          />
                        )
                      : this.state.exercise_quiz_url && (
                          <ExamineEverydayUnlock
                            day_study_begin_time={day_study_begin_time}
                            now_date={now_date}
                            exercise_quiz_url={this.state.exercise_quiz_url}
                            exercise_quiz_status={
                              this.state.exercise_quiz_status
                            }
                            exercise_quizzes_count={
                              this.state.exercise_quizzes_count
                            }
                          />
                        )
                  }
                </div>
              </div>
            ) : (
              <HolidayRestPrompt isExperience={is_experience === true} />
            )}
          </div>

          <div className="study_time_accumulate">
            {is_experience === false && [
              <StudentId
                key="studentId"
                babyName={this.props.baby_name}
                sno={this.props.sno}
              />,
              <NumberLine key="numberLine" />,
              <CircleRing
                key="circleRing"
                studiedCourseDayCount={this.state.studied_course_day_count}
                courseDayCount={this.props.course_day_count}
                scoreProgressLeft={scoreProgress_left}
                scoreProgressRight={scoreProgress_right}
                totalStudiedMediaLessonsCount={
                  this.state.total_studied_media_lessons_count
                }
                mediaLessonsCount={this.props.media_lessons_count}
                scoreProgressLeft2={scoreProgress_left_2}
                scoreProgressRight2={scoreProgress_right_2}
              />,
              <KnowledgePrompt
                key="knowledgePrompt"
                Day={"已学天数"}
                Hour={"已学课时"}
              />,
            ]}
            <CourseActivityEntrance
              checkin={this.props.checkin}
              studyReportUrl={this.state.study_report_url}
              checkinDays={this.props.checkin_days}
              bonus={this.props.bonus}
              isIosReview={this.props.is_ios_review}
              shareUrl={this.props.invitation_rewards_url}
              reminderTimeHour={this.props.reminder_time_hour}
            />
          </div>
        </div>
      </div>
    );
  }
}

StudyPackage.propTypes = Object.assign({}, SharePoster.propTypes, {
  media_lessons: PropTypes.array,
  day_study_exams: PropTypes.array,
  get_day_study_url: PropTypes.string,
});

StudyPackage.defaultProps = {
  free: "study_package",
};
