import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import AudioPLayer, {
  SmallAudioPlayer,
} from "../../utils/audio_player/AudioPlayer";
import { request, csrfHeaders } from "../../utils/request";
import { throttle } from "../../utils/helpers";
import { promisifiedLoadImage, detectedTypeOf } from "../../utils/helpers";
import DayExamSubject from "../../utils/study_report_info/DayExamSubject";
import { ToastManager } from "../../utils/Toast";
import "./PoetryLandingPage.scss";
import starWhite from "study_package/icon_star_white.png";
import starYellow from "study_package/icon_star_yellow.png";
import starBorder from "study_package/icon_star_border.png";
import qrCode from "qrcode_xinyaxuetang.jpg";

class Header extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { baby, studyPackage, day } = this.props;
    return (
      <div className="header">
        {baby}正在{studyPackage}的第{day}天
      </div>
    );
  }
}

class Footer extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="footer">
        <div className="left_text">
          <p>长按识别二维码关注</p>
          <p>心芽学堂</p>
          <p>每天10分钟，随时随地学</p>
        </div>
        <img className="right_qr_code lazyload" data-src={qrCode}></img>
      </div>
    );
  }
}

class LessonOneStared extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isStared: props.liked,
    };
  }
  staredClick = () => {
    if (!this.state.isStared && !this.props.liked) {
      request({
        url: this.props.likeUrl,
        method: "POST",
        headers: csrfHeaders,
      });
      this.setState({
        isStared: true,
        starNumed: parseInt(this.props.starNum) + 1,
      });
    }
  };
  render() {
    const { starNum } = this.props;
    const { isStared, starNumed } = this.state;
    return [
      <div className="encourage_text" key="encourage_text">
        {isStared ? "谢谢你的鼓励！" : "能不能上排行榜，就差你的鼓励 »"}
      </div>,
      <div className="stared" key="stared">
        <div
          className={
            isStared
              ? "button_wrapper_active_lesson_one"
              : "button_wrapper_lesson_one"
          }
          onClick={this.staredClick}
        >
          <img src={isStared ? starYellow : starWhite} alt="" />
          <div className="start_num">{starNumed || starNum}</div>
        </div>
      </div>,
    ];
  }
}

class LessonTwoStared extends PureComponent {
  constructor(props) {
    super(props);

    this.changingStar = [starYellow, starBorder];
    this.positionStar = ["imgLeftSrc", "imgRightSrc"];

    if (props.liked) {
      let initalLiked = {};
      initalLiked[
        this.positionStar.splice([props.likeOption - 1], 1)
      ] = this.changingStar[0];
      initalLiked[this.positionStar[0]] = this.changingStar[1];
      initalLiked["isStared"] = true;
      this.state = initalLiked;
    } else {
      this.state = {
        isStared: false,
        imgLeftSrc: starWhite,
        imgRightSrc: starWhite,
      };
    }
  }

  staredClick = (e) => {
    if (!this.state.isStared && !this.props.liked) {
      console.log(this.props.likeUrl);

      let temp = {};

      let img = e.currentTarget.getAttribute("data-position");
      let selectdOption = this.positionStar.indexOf(img);
      request({
        url: this.props.likeUrl,
        method: "POST",
        headers: csrfHeaders,
        data: {
          option: selectdOption + 1,
        },
      }).then((data) => {
        console.log(data);
      });
      this.positionStar.splice(selectdOption, 1);
      temp[`${img}`] = this.changingStar.shift();
      temp[this.positionStar[0]] = this.changingStar[0];
      temp["isStared"] = true;
      this.setState(temp);
      this.setState({
        starNumed: parseInt(this.props.starNum) + 1,
      });
      // e.currentTarget.getAttribute('data-position')
    }
  };
  render() {
    const { starNum } = this.props;
    const { isStared, imgLeftSrc, imgRightSrc, starNumed } = this.state;
    return [
      <div className="button_wrapper" key="button_wrapper">
        <div className="button_left_wrapper" key="button_left_wrapper">
          <div
            className={`button ${
              isStared ? "butto_common_active" : "butto_common"
            }`}
            onClick={this.staredClick}
            data-position="imgLeftSrc"
          >
            <img src={imgLeftSrc} alt="" />
            <div className="start_num">读的真棒</div>
          </div>
        </div>
        <div className="button_right_wrapper" key="button_right_wrapper">
          <div
            className={`button ${
              isStared ? "butto_common_active" : "butto_common"
            }`}
            onClick={this.staredClick}
            data-position="imgRightSrc"
          >
            <img src={imgRightSrc} alt="" />
            <div className="start_num">还需加油</div>
          </div>
        </div>
      </div>,
      <div className="star" key="star">
        <div className="start_num">{starNumed || starNum}</div>
      </div>,
    ];
  }
}

class QuizGeneration extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      quizContainerHeight: 0,
      currentQuiz: props.quizs[0],
      day_study_id: props.dayStudy.id,
      study_package_id: props.dayStudy.study_package_id,
      exam_type: "DAY_EXAM",
    };
    this.isSubmitting = false;
    this.answerCorrect = [];
    this.answers = [];
  }

  promisifiedPlayEffectAudio = (ElementId) => {
    return new Promise((resolve, reject) => {
      let audio = document.getElementById(ElementId);
      audio.play();
      setTimeout(() => {
        resolve();
      }, 500);
    });
  };

  // 一个quiz的image集合
  collectQuizImages(quiz) {
    return new Promise((resolve, reject) => {
      if (detectedTypeOf(quiz) !== "object") {
        reject(new Error("please pass a quiz object for collectQuizImage"));
      }
      let preLoadImage = [];
      if (quiz.question_photo_url) {
        preLoadImage.push(quiz.question_photo_url);
      }
      if (quiz.options) {
        quiz.options.map((option) => {
          if (option.photo) {
            preLoadImage.push(option.photo.url);
          }
        });
      }
      resolve(preLoadImage);
    });
  }

  toShowLoading(quiz) {
    if (quiz.question_photo_url) {
      promisifiedLoadImage([quiz.question_photo_url])
        .then(() => {
          this.toast.cancel();
        })
        .catch((err) => {
          console.log(err);
          this.toast.cancel();
        });
    }
  }

  // 取下一题
  nextQuiz() {
    this.isSubmitting = false;
    let quiz = this.state.currentQuiz;
    let index = this.props.quizs.findIndex(function (element) {
      return element.id == quiz.id;
    });
    let nextQuiz = this.props.quizs[index + 1];
    this.setState({
      currentQuiz: nextQuiz,
    });

    // 如果下一题是有图片 并且没有完成预加载就显示toast
    if (nextQuiz.question_photo_url && !nextQuiz.isImageLoaded) {
      this.toast = ToastManager.showLoading("加载中...");
      this.toShowLoading.call(this, nextQuiz);
    }

    // 对下下题图片预加载
    let nextSecondQuiz = this.props.quizs[index + 2];
    if (nextSecondQuiz) {
      this.collectQuizImages
        .call(this, nextSecondQuiz)
        .then((res) => promisifiedLoadImage(res))
        .then((loadedImages) => {
          if (nextSecondQuiz.question_photo_url) {
            this.props.quizs[index + 2].question_photo_url = loadedImages[0];
            this.props.quizs[index + 2]["isImageLoaded"] = true;
            loadedImages.shift();
          }
          if (nextSecondQuiz.options) {
            this.props.quizs[index + 2].options.map((option) => {
              if (option.photo) {
                option.photo.url = loadedImages[0];
                loadedImages.shift();
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  handleAfterUserSelection = (correctOption, selectedOption) => {
    let json = {};
    json = {
      media_lesson: {
        id: this.state.currentQuiz.current_lesson.id,
        tag: {
          id: this.state.currentQuiz.tag.id,
          quiz: {
            id: this.state.currentQuiz.id,
            input_correct: selectedOption.correct,
            choose_option: selectedOption,
            question_type: 1,
            correct_option: correctOption,
          },
        },
      },
    };
    this.answers.push(json);
    let lastQuiz = this.props.quizs[this.props.quizs.length - 1];
    console.log(lastQuiz);
    if (lastQuiz && lastQuiz.id == this.state.currentQuiz.id) {
      this.toast = ToastManager.showLoading("加载中...");
      setTimeout(this.submitAnswer.bind(this), 1000);
    } else {
      setTimeout(this.nextQuiz.bind(this), 1000);
    }
  };
  handleSelect = (e) => {
    if (!this.isSubmitting) {
      // 开始答题后，隐藏标题
      if (this.state.currentQuiz.id === this.props.quizs[0].id) {
        this.props.isHideTitle(true);
      }

      this.isSubmitting = true;
      let isCorrect = e.currentTarget.getAttribute("data-option");
      let selectedIndex = e.currentTarget.getAttribute("data-index");
      let selectedOption = this.state.currentQuiz.options[selectedIndex];
      console.log("selectedOption", selectedOption);
      let correctOption = this.state.currentQuiz.options.find((option) => {
        if (option.correct) {
          return option;
        }
      });
      console.log("correctOption", correctOption);
      e.currentTarget.className = isCorrect === "true" ? "correct" : "error";
      this.promisifiedPlayEffectAudio(
        isCorrect === "true" ? "effect_pass_sound" : "effect_fail_sound"
      )
        .then(() =>
          this.handleAfterUserSelection.call(
            this,
            correctOption,
            selectedOption
          )
        )
        .catch((err) => {
          console.log(err);
        });
      if (isCorrect === "true") {
        this.answerCorrect.push(this.state.currentQuiz.id);
      }
    }
  }; // handleSelect end

  submitAnswer() {
    console.log("提交答案");
    let answers = {
      answers: this.answers,
      id: this.state.day_study_id,
      study_package_activity_id: this.props.studyPackageActivityId,
      exam_type: this.state.exam_type,
      day_study_exam_id: this.props.dayExam.id,
    };
    console.log(answers);
    request({
      url: this.props.saveResultUrl,
      method: "POST",
      headers: csrfHeaders,
      data: answers,
    })
      .then((rsp) => {
        const data = rsp.data;
        console.log("正确返回");
        console.log(data);
        this.toast.cancel();
        this.props.onShowResultData(data);

        this.isSubmitting = false;
        let rate = this.answerCorrect.length / this.props.quizs.length;
        this.props.onChangeTitle(rate, this.answerCorrect.length);
        this.props.onStarNumed();
      })
      .catch((err) => {
        // 加载中
        this.toast.cancel();
        console.log("提交答案 错误返回");
        console.log(err);
      });
  }

  liStyle = (option) => {
    return {
      padding: option.photo ? "0" : "",
      // border: option.photo ?'none':'',
      boxShadow: option.photo ? "none" : "",
    };
  };
  render() {
    const { quizs } = this.props;
    const { currentQuiz } = this.state;
    const index = quizs.findIndex(function (element) {
      return element.id == currentQuiz.id;
    });

    return (
      <div className="quiz_generation">
        <div className="quiz_length">
          ({index + 1}/{quizs.length})&nbsp;{currentQuiz.question}
        </div>
        <div className="quiz_question_image">
          {currentQuiz.question_photo_url ? (
            <img
              src={currentQuiz.question_photo_url}
              key={currentQuiz.question_photo_url}
            />
          ) : (
            ""
          )}
        </div>
        <audio
          src="https://upload.fireflybaby.cn/failure1.mp3"
          id="effect_fail_sound"
          preload="auto"
        />
        <audio
          src="https://upload.fireflybaby.cn/pass.mp3"
          id="effect_pass_sound"
          preload="auto"
        />
        <div className="answer_area">
          <ul>
            {currentQuiz.options
              ? currentQuiz.options.map((option, index) => {
                  return (
                    <li
                      style={this.liStyle(option)}
                      key={option.id}
                      id={option.id}
                      data-index={index}
                      data-option={option.correct}
                      onClick={this.handleSelect}
                    >
                      {option.photo ? (
                        <img src={option.photo.url} key={option.photo.id} />
                      ) : (
                        option.content
                      )}
                    </li>
                  );
                })
              : ""}
          </ul>
        </div>
      </div>
    );
  }
}

// 打擂台
class PoetryGameWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      quizContainerHeight: 0,
      listForShowResult: props.mediaContents
        ? this.getListForShowResult(props.mediaContents)
        : null,
      mediaContents: props.mediaContents,
      isHideTitle: false,
    };
  }

  componentWillMount() {
    // 如果进入落地页已经答过题
    if (this.props.mediaContents && "correctCount" in this.props) {
      let quizesLength = 0;
      this.props.mediaContents.forEach((mediaContent) => {
        quizesLength = mediaContent.quizzes.length + quizesLength;
      });
      let rate = this.props.correctCount / quizesLength;
      this.onChangeTitle(rate, this.props.correctCount);
    }
  }

  onresize = () => {
    let titleHeight = document.getElementById("shared_title").offsetHeight;
    let quizContainerHeight =
      document.getElementById("content_container").offsetHeight -
      titleHeight -
      70;
    this.setState({
      quizContainerHeight: quizContainerHeight,
    });
  };

  titles = (num) => {
    return {
      level1: `答对${num}题，诗圣非你莫属！`,
      level2: `答对${num}题，真不错！`,
      level3: `答对${num}题，继续努力！`,
      level4: `答对${num}题，还要加油！`,
    };
  };

  onChangeTitle = (rate, num = 0) => {
    let titleLevel;
    if (rate == 1) {
      titleLevel = this.titles(num)["level1"];
    } else if (rate >= 0.75) {
      titleLevel = this.titles(num)["level2"];
    } else if (rate >= 0.5) {
      titleLevel = this.titles(num)["level3"];
    } else {
      titleLevel = this.titles(num)["level4"];
    }
    this.setState({
      title: titleLevel,
      stared: "打擂成功，谢谢！",
    });
    this.isHideTitle(false);
  };

  onStarNumed = () => {
    request({
      url: this.props.likeUrl,
      method: "POST",
      headers: csrfHeaders,
    });
    this.setState({
      starNumed: parseInt(this.props.starNum) + 1,
    });
  };

  onShowResultData = (data) => {
    this.setState({
      mediaContents: data.media_courses,
      listForShowResult: this.getListForShowResult(data.media_courses),
    });
  };

  getListForShowResult = (mediaContents) => {
    let objR = new Object();
    mediaContents.forEach(function (item) {
      objR[item.id] = true;
    });
    return objR;
  };

  isHideTitle = (bol) => {
    this.setState({
      isHideTitle: bol,
    });
  };

  componentDidMount() {
    this.onresize();
    window.onresize = throttle(this.onresize, 1000);
  }

  render() {
    const {
      correctNum,
      starNum,
      quizzes,
      studyPackageActivityId,
      saveResultUrl,
      dayExam,
      dayStudy,
    } = this.props;
    const {
      quizContainerHeight,
      title,
      stared,
      starNumed,
      mediaContents,
      listForShowResult,
      isHideTitle,
    } = this.state;

    let quizeContainerStyle = {
      height: quizContainerHeight + "px",
      borderTop: mediaContents ? "1px solid rgba(0,0,0,0.1)" : "",
      padding: "0 10px 10px 10px",
    };
    return [
      <div className="poetry_wrapper" key="poetry_wrapper">
        <div className="shared_title" id="shared_title">
          {!isHideTitle &&
            (title || `诗词小擂台，我答对了${correctNum}题，你呢？`)}
        </div>
        <div className="quiz_container" style={quizeContainerStyle}>
          {mediaContents ? (
            mediaContents.map((mediaContent, index) => (
              <DayExamSubject
                list={listForShowResult}
                index={index}
                mediaContent={mediaContent}
                key={index}
              />
            ))
          ) : (
            <QuizGeneration
              quizs={quizzes}
              onChangeTitle={this.onChangeTitle}
              studyPackageActivityId={studyPackageActivityId}
              saveResultUrl={saveResultUrl}
              dayExam={dayExam}
              dayStudy={dayStudy}
              onShowResultData={this.onShowResultData}
              onStarNumed={this.onStarNumed}
              isHideTitle={this.isHideTitle}
            />
          )}
        </div>
      </div>,
      <div className="poetry_stared" key="poetry_stared">
        <div className="button_wrapper game">
          {stared || "你答完题，我就可以获得一颗星星，加油"}
        </div>
        <div className="star">
          <div className="start_num">{starNumed || starNum}</div>
        </div>
      </div>,
    ];
  }
}

// 第一讲 第二讲
class PoetryLessonWrapper extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      quizContainerHeight: 0,
    };
  }
  onresize = () => {
    let totalHeight = document.getElementById("content_container").offsetHeight;
    let titleHeight = document.getElementById("shared_title").offsetHeight;
    let imgHeight = document.getElementById("shared_image_wrapper")
      .offsetHeight;
    let contentContainerHeight = totalHeight - titleHeight - imgHeight - 70;
    this.setState({
      contentContainerHeight: contentContainerHeight,
    });
  };
  componentDidMount() {
    this.onresize();
    window.onresize = throttle(this.onresize, 1000);
  }
  render() {
    const {
      poetryName,
      audioSrc,
      baby,
      starNum,
      checkinType,
      content,
      liked,
      likeUrl,
      likeOption,
      photoUrl,
      author,
      audioInfoUrl,
    } = this.props;
    const { contentContainerHeight } = this.state;
    let quizeContainerStyle = {
      height: contentContainerHeight + "px",
    };
    // 底部打星策略
    const lessonStaredStrategy = (starNum, liked, likeUrl, likeOption) => {
      return {
        1: (
          <LessonOneStared
            starNum={starNum}
            liked={liked}
            likeUrl={likeUrl}
            likeOption={likeOption}
          />
        ),
        2: (
          <LessonTwoStared
            starNum={starNum}
            liked={liked}
            likeUrl={likeUrl}
            likeOption={likeOption}
          />
        ),
      };
    };

    return [
      <div className="poetry_wrapper" key="poetry_wrapper" id="poetry_wrapper">
        <div className="shared_title" id="shared_title">
          {checkinType === 1
            ? `今天学的是《${poetryName}》，你猜对了吗？`
            : `这首《${poetryName}》我读的怎么样？`}
        </div>
        <div
          className="shared_image_wrapper lazyload"
          data-bgset={photoUrl}
          id="shared_image_wrapper"
        >
          {(checkinType === 1 || checkinType === 2) && (
            <SmallAudioPlayer
              audioSrc={audioSrc}
              poetryName={poetryName}
              baby={baby}
              audioInfoUrl={audioInfoUrl}
            />
          )}
        </div>
        <div className="poetry_content" style={quizeContainerStyle}>
          <div className="poetry_name">{poetryName}</div>
          <div className="author">{author}</div>
          <div className="content"> {content}</div>
        </div>
      </div>,
      <div className="poetry_stared" key="poetry_stared">
        {lessonStaredStrategy(starNum, liked, likeUrl, likeOption)[checkinType]}
      </div>,
    ];
  }
}

class LandingPageContent extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { render, checkinType } = this.props;
    return (
      <div className="content_container" id="content_container">
        {render(checkinType)}
      </div>
    );
  }
}

export default class PoetryLandingPage extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
  }

  render() {
    const {
      baby,
      content,
      day,
      checkin_type,
      quizzes,
      study_package_activity_id,
      save_result_url,
      day_exam,
      day_study,
      from_user_correct_count,
      rank,
      media_course,
      entire_record_url,
      media_contents,
      correct_count,
      liked,
      like_url,
      like_option,
      audio_info_url,
    } = this.props;
    console.log(entire_record_url);
    return (
      <div className="comp_landing_page">
        <Header
          baby={baby.nickname}
          studyPackage={`《${content.name}》`}
          day={day}
        />
        <LandingPageContent
          checkinType={checkin_type}
          render={(checkin_type) =>
            checkin_type === 3 ? (
              <PoetryGameWrapper
                correctNum={from_user_correct_count}
                starNum={rank}
                quizzes={quizzes}
                studyPackageActivityId={study_package_activity_id}
                saveResultUrl={save_result_url}
                dayExam={day_exam}
                dayStudy={day_study}
                mediaContents={media_contents}
                correctCount={correct_count}
                likeUrl={like_url}
              />
            ) : (
              <PoetryLessonWrapper
                poetryName={media_course.name}
                baby={baby.nickname}
                starNum={rank}
                checkinType={checkin_type}
                content={media_course.description}
                audioSrc={entire_record_url}
                liked={liked}
                likeUrl={like_url}
                likeOption={liked ? like_option : null}
                photoUrl={media_course.photo_url}
                author={media_course.author}
                audioInfoUrl={audio_info_url}
              />
            )
          }
        />
        <Footer />
      </div>
    );
  }
}
