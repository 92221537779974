import React, { Component } from "react";
import PropTypes from "prop-types";
import { GA } from "../utils/Env.js";
import ImageEdit from "./Certificate/ImageEdit";
import "./Certificate.scss";
import { min } from "date-fns";

class CertificateEditName extends Component {
  constructor(props) {
    super(props);
  }
  sendName = () => {
    let input = this.refs.name;
    if (input.value != "") {
      this.props.sendName(input.value);
    }
  };
  render() {
    return (
      <div id="certification-pop" className={this.props.className}>
        <div className="editname-form">
          <h3>孩子姓名</h3>
          <input type="text" ref="name" maxLength={8} />
          <button type="button" onClick={this.sendName.bind(this)}>
            确定
          </button>
        </div>
      </div>
    );
  }
}

class CertificateCanvas extends Component {
  constructor(props) {
    super(props);
  }
  // 名字合成
  handleDrawName = (name) => {
    const canvas = this.refs.canvas;
    let ctx = canvas.getContext("2d");
    ctx.font = "bolder 22px Arial";
    ctx.textAlign = "center";
    ctx.fillText(name, 105, 520);
  };
  // 照片裁切
  handleCropResetPhoto = (photo) => {
    let that = this;
    let canvas = document.createElement("canvas");
    canvas.width = 432;
    canvas.height = 292;
    let ctx = canvas.getContext("2d");

    let cropedImage = new Image();

    let { width, height, left, top } = photo;
    let ratio = 432 / 220;

    let xStart = left * ratio;
    let yStart = top * ratio;

    cropedImage.onload = () => {
      // cropedImage.width = width * ratio
      // cropedImage.height = height * ratio
      ctx.drawImage(cropedImage, xStart, yStart, width * ratio, height * ratio);
      that.handleDrawPhoto(canvas);
    };
    cropedImage.src = photo.src;
  };
  // TODO: clean
  handleCropPhoto = (photo) => {
    let that = this;
    let canvas = document.createElement("canvas");
    canvas.width = 432;
    canvas.height = 292;
    let ctx = canvas.getContext("2d");

    let cropedImage = new Image();

    let iRatio = photo.width / photo.height;
    let cRatio = 432 / 292;
    let finalWidth = 432,
      finalHeight = 292,
      xStart = 0,
      yStart = 0;

    if (iRatio > cRatio) {
      finalHeight = 292;
      finalWidth = photo.width * (finalHeight / photo.height);
      xStart = (432 - finalWidth) / 2;
      yStart = 0;
    }
    if (iRatio < cRatio) {
      finalWidth = 432;
      finalHeight = photo.height * (finalWidth / photo.width);
      xStart = 0;
      yStart = (292 - finalHeight) / 2;
    }

    cropedImage.onload = () => {
      ctx.drawImage(cropedImage, xStart, yStart, finalWidth, finalHeight);
      that.handleDrawPhoto(canvas);
    };
    cropedImage.src = photo.src;
  };
  // 照片合成海报
  handleDrawPhoto = (photoCanvas) => {
    let that = this;

    var newPhoto = new Image();
    newPhoto.src = photoCanvas.toDataURL("image/jpeg", 1.0);
    const canvas = this.refs.canvas;
    const canvasbox = this.refs.canvasbox;
    let ctx = canvas.getContext("2d");
    const p = new Image();
    p.onload = function () {
      ctx.drawImage(p, 60, 68, 432, 292); // orign 60 68 432 * 292
      that.roundRect(52, 64, 440, 300, 24);
      // 转为图片支持微信长按分享
      let newImg = that.canvasToImage();
      canvasbox.appendChild(newImg);
    };
    p.src = newPhoto.src;
  };
  // 圆角方法
  roundRect = (x, y, w, h, radius) => {
    var canvas = this.refs.canvas;
    var ctx = canvas.getContext("2d");
    var r = x + w;
    var b = y + h;
    ctx.beginPath();
    ctx.strokeStyle = "white";
    ctx.lineWidth = "16";
    ctx.moveTo(x + radius, y);
    ctx.lineTo(r - radius, y);
    ctx.quadraticCurveTo(r, y, r, y + radius);
    ctx.lineTo(r, y + h - radius);
    ctx.quadraticCurveTo(r, b, r - radius, b);
    ctx.lineTo(x + radius, b);
    ctx.quadraticCurveTo(x, b, x, b - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.stroke();
  };
  // Canvas转图片
  canvasToImage = () => {
    const canvas = this.refs.canvas;
    var image = new Image();
    image.src = canvas.toDataURL("image/jpeg", 1.0);
    return image;
  };

  componentDidMount() {
    let that = this;
    let { name, photo, resetPhoto, template } = this.props;
    console.log(this.props);
    // 初始化模板
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext("2d");

    const temp = new Image();
    temp.crossOrigin = "*";
    temp.onload = () => {
      ctx.drawImage(temp, 0, 0, 548, 976);
      that.handleDrawName(name);
      // that.handleCropPhoto(photo)
      that.handleCropResetPhoto(resetPhoto);
    };
    temp.src = template;
  }

  render() {
    return (
      <div id="certificate-canvas" ref="canvasbox">
        <h3>长按保存奖状，分享给朋友吧！</h3>
        <canvas ref="canvas" width="548" height="976"></canvas>
      </div>
    );
  }
}

class Certificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      template: this.props.template_photo_url,
      name: "",
      photo: null,
      resetPhoto: null,
      isPop: false,
      isShare: false,
    };
  }
  // 上传照片按钮触发
  uploadPhoto = (e) => {
    const fileload = this.refs.fileload;
    fileload.click();
    GA("Certificate", "create", this.state.id);
  };
  // 上传控件change
  handleChange = (e) => {
    let that = this;
    let files = e.target.files;
    if (files.length === 0) return;
    let file = files[0];
    if (file.type !== "" && !file.type.match("image.*")) return;
    window.URL = window.URL || window.webkitURL;
    const src = window.URL.createObjectURL(file);
    console.log("change", file);
    let img = new Image();
    img.onload = function () {
      let photo = {
        src,
        width: this.width,
        height: this.height,
      };
      that.setState({
        photo,
      });
    };
    img.src = src;
  };
  // 编辑后图片
  resetPhoto = (data) => {
    let resetPhoto = { ...data };
    this.setState({
      resetPhoto,
    });
  };
  // 保存名字
  setName = (name) => {
    this.setState({
      name,
    });
    this.closePop();
  };
  openShare = () => {
    let { name, photo } = this.state;
    if (!name || !photo) {
      alert("照片和名字不能为空");
      return;
    }
    this.setState({
      isShare: true,
    });
  };
  // 打开名字输入窗口
  openPop = () => {
    this.setState({
      isPop: true,
    });
  };
  closePop = () => {
    this.setState({
      isPop: false,
      isShare: false,
    });
  };

  componentDidMount() {
    // 初始化模板
    const wrap = this.refs.wrap;
    wrap.style.height = document.documentElement.scrollHeight + "px";
    // document.addEventListener('touchmove', function (event) {
    //     if(this.state.isShare){
    //         event.preventDefault();
    //     }
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    let { isShare, isPop } = this.state;
    if (isShare || isPop) {
      document.body.style.overflowY = "hidden";
    }
  }

  render() {
    console.log(this.state);
    let { isPop, isShare, template, name, photo, resetPhoto } = this.state;
    return (
      <div ref="wrap" id="certificate-wrap">
        {isPop && <CertificateEditName sendName={this.setName.bind(this)} />}
        {isShare && (
          <CertificateCanvas
            name={name}
            photo={photo}
            resetPhoto={resetPhoto}
            template={template}
          />
        )}

        {/* Mask */}
        <div
          ref="mask"
          onClick={this.closePop.bind(this)}
          className={isPop || isShare ? "certificate-mask" : ""}
        ></div>
        {/* Template */}
        <div id="certificate-canvas-box">
          <img src={template} width="100%" />
          <div
            className="certificate-photo"
            onClick={!photo ? this.uploadPhoto : undefined}
          >
            {photo && (
              <ImageEdit
                key={photo.src}
                photo={photo}
                edited={this.resetPhoto}
              />
            )}
          </div>
          <div className="certificate-name">{name}</div>
        </div>
        {/* Buttons */}
        <div id="certificate-edit">
          <div className="certificate-edit-item">
            <input
              type="file"
              name="fileload"
              accept="image/*"
              ref="fileload"
              className="hide"
              onChange={this.handleChange.bind(this)}
            />
            <button
              type="button"
              className="certificate-edit-btn"
              onClick={this.uploadPhoto.bind(this)}
            >
              上传孩子照片
            </button>
          </div>
          <div className="certificate-edit-item">
            <button
              type="button"
              className="certificate-edit-btn"
              onClick={this.openPop.bind(this)}
            >
              输入孩子姓名
            </button>
          </div>
        </div>
        <div id="certificate-generate">
          <div className="certificate-generate-item">
            <button
              type="button"
              className="certificate-generate-btn"
              onClick={this.openShare.bind(this)}
            >
              确定生成奖状
            </button>
          </div>
        </div>
      </div>
    );
  }
}

Certificate.propTypes = {};

export default Certificate;
