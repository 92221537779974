import React from "react";
import "./ReadingWord.scss";
import Word from "../components/Word";
import icon_null from "reading/icon_null.png";
import { ToastManager } from "../../utils/Toast";
export default class ReadingWord extends React.Component {
  constructor(props) {
    console.log(props, "----ReadingWord---");
    super(props);
    this.state = {
      data: null,
    };
  }
  componentDidMount() {
    const toast = ToastManager.showLoading();
    this.props.getData(this.props.getDataUrl).then((res) => {
      this.setState({ data: res.data });
      toast.cancel();
    });
  }
  render() {
    return this.state.data && this.state.data.sub_subjects.length > 0 ? (
      <div className="reading-index2">
        <div style={{ height: 50 }}></div>
        <div className="word-lists">
          {this.state.data &&
            this.state.data.sub_subjects.map(
              (material_item, material_index) => {
                return (
                  <div
                    className="word-list"
                    key={"material_item" + material_index}
                    style={{ marginTop: material_index == 0 ? 0 : "" }}
                  >
                    {material_item.material_type == 3 ? (
                      <Word
                        type={1}
                        name={material_item.name}
                        materials={material_item.materials}
                        material_index={material_index}
                      ></Word>
                    ) : (
                      <Word
                        type={0}
                        name={material_item.name}
                        materials={material_item.materials}
                        material_index={material_index}
                      ></Word>
                    )}
                  </div>
                );
              }
            )}
        </div>
      </div>
    ) : (
      <div className="icon_null_back">
        <img src={icon_null} className="icon_null"></img>
        <div>还没有积累任何好词好句哦</div>
        <div> 快去学习积累吧~</div>
      </div>
    );
  }
}
