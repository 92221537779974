import React, { useState, useEffect, useRef } from "react";
import CrystalWithStars from "point_rewards/crystal-with-stars.png";
import Crystal from "point_rewards/crystal.png";
import Arrow from "store/yellow-slim-right-arrow.png";
// import BScroll from '@better-scroll/core'
// import Pullup from '@better-scroll/pull-up'
// BScroll.use(Pullup)
import "../shared/tailwindcss.scss";
import "./Index.scss";
import { request, csrfHeaders } from "../../components/utils/request";
import { ToastManager } from "../utils/Toast";
import { setShouldSendCallback } from "raven-js";

function initializePager(url, callback) {
  let page = 2;
  let loading = false;
  let endOfList = false;
  const loadingRef = useRef();
  const handleScroll = () => {
    if (loading || endOfList) {
      return;
    }
    let loadingElement = loadingRef.current;
    if (loadingElement) {
      let top = loadingElement.offsetTop + loadingElement.parentElement.offsetTop;
      if (top <= window.scrollY + window.outerHeight) {
        loading = true;
        request({
          url,
          method: "GET",
          headers: csrfHeaders,
          params: { page },
        }).then(res => {
          loading = false;
          page += 1;
          if (res.data && res.data.commodities && res.data.commodities.length > 0) {
            callback(res.data.commodities);
          } else {
            endOfList = true;
            loadingElement.style.display = 'none';
          }
        }).catch(err => {
          console.log(err);
          loading = false;
          endOfList = true;
          loadingElement.style.display = 'none';
        });
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll) };
  }, []);
  return loadingRef;
}

const CommodityList = (props) => {
  console.log(props, "--");
  const [commodityItems, setCommodityItems] = useState(
    props.commodities
  );
  const [page, setPage] = useState(props.page || 0);
  const [shouldLoadMore, setshouldLoadMore] = useState(props.has_more);
  /* 处理上拉加载更多数据 */
  // let bscroll
  // let loadMore = props.has_more;
  // useEffect(() => {
  //   // initBscroll()
  // }, [])
  // useEffect(() => {
  //   console.log(loadMore);
  //   setshouldLoadMore(loadMore);
  // }, [loadMore]);
  const getDate = () => {
    if (shouldLoadMore) {
      fetchCommodities().then((res) => {
        setPage(res.data.page);
        setshouldLoadMore(res.data.has_more);
        setCommodityItems((pre) => [...pre, ...res.data.commodities]);
      });
    } else {
      const toast = ToastManager.showToastText("再也没有更多了...");
      setTimeout(() => {
        toast.cancel();
      }, 1000);
    }
  };
  const fetchCommodities = () => {
    const url = `${location.origin}${location.pathname}?page=${
      parseInt(page) + 1
    }`;
    return request({
      url: url,
      method: "get",
      headers: csrfHeaders,
    });
  };

  let url;
  if (props.url) {
    url = props.url;
  } else {
    url = `${location.origin}${location.pathname}`
  }
  let loadingRef = initializePager(url, commodities => {
    setCommodityItems((pre) => [...pre, ...commodities])
  });

  // const initBscroll = () => {
  //   bscroll = new BScroll('.container', {
  //     pullUpLoad: true,
  //     click: true,
  //   })
  //   bscroll.on('pullingUp', pullingUpHandler)
  // }
  // let page = props.page;
  // const pullingUpHandler = () => {
  //   if (!loadMore) {
  //     return
  //   }
  //   fetchCommodities().then((res) => {
  //     console.log(res)
  //     page = res.data.page
  //     loadMore = res.data.has_more
  //     if (res.data.commodities) {
  //       setCommodityItems([...commodityItems, ...res.data.commodities])
  //       bscroll.refresh()
  //     }
  //     if (loadMore) {
  //       bscroll.finishPullUp()
  //     } else {
  //       setshouldLoadMore(false)
  //       bscroll.closePullUp()
  //     }
  //   }).catch((err) => {
  //     console.log(err)
  //     bscroll.finishPullUp()
  //   })
  // }

  /* 处理上拉加载更多数据 */

  const Header = () => {
    const crystalStyle = {
      bottom: 0,
      right: 0,
      position: "absolute",
      width: "97px",
      height: "76px",
    };
    if (props.isInStudyPackage) {
      let { ...headerStyle } = props.headerStyle ? props.headerStyle : {};
      headerStyle.alignItems = 'flex-start';
      return (
        <div className="flex flex-row relative p-4 bg-white bg-yellow border-radius-15" style={headerStyle}>
          <div className="flex flex-col" style={{ flex: 1 }}>
            <div className="flex flex-row items-center">
              完成课程可获得约:
              <div
                className="font-bold"
                style={{ fontSize: "30px", color: '#ffc000', marginLeft: 5 }}
              >
                {props.total_reward_points}
              </div>
              <img
                src={Crystal}
                alt=""
                className="ml-3"
                style={{ width: "26px", height: "26px", marginLeft: 5 }}
              />
            </div>
            <div className="flex flex-row items-center">
              你当前累计已获得:
              <div
                className="font-bold"
                style={{ fontSize: "30px", color: '#ffc000', marginLeft: 5 }}
              >
                {props.user_point}
              </div>
              <img
                src={Crystal}
                alt=""
                className="ml-3"
                style={{ width: "26px", height: "26px", marginLeft: 5 }}
              />
            </div>
          </div>

          <div className="flex flex-row items-center" style={{ marginTop: 10 }}>
            <a href={props.point_change_records_path} className="text-yellow">
              规则与明细
            </a>
            <img
              src={Arrow}
              style={{ width: "12px", height: "12px" }}
              className="ml-1"
            />
          </div>
          <img src={CrystalWithStars} style={crystalStyle} />
        </div>
      );
    } else {
      return (
        <div className="flex flex-col relative p-4 bg-white bg-yellow border-radius-15" style={props.headerStyle}>
          <div className="flex flex-row justify-between items-center">
            <div className="text-gray">你当前有:</div>
            <div className="flex flex-row items-center">
              <a href={props.point_change_records_path} className="text-yellow">
                规则与明细
              </a>
              <img
                src={Arrow}
                style={{ width: "12px", height: "12px" }}
                className="ml-1"
              />
            </div>
          </div>
          <div className="flex flex-row mt-3 items-center">
            <div
              className="text-gray-dark font-bold"
              style={{ fontSize: "30px" }}
            >
              {props.user_point}
            </div>
            <img
              src={Crystal}
              alt=""
              className="ml-3"
              style={{ width: "26px", height: "26px" }}
            />
          </div>
          <img src={CrystalWithStars} style={crystalStyle} />
        </div>
      );
    }
  };

  const CommodityItem = (commodity) => {
    return (
      <a
        href={`/commodities/${commodity.id}`}
        className="flex flex-col border-radius-15 mt-7"
        key={commodity.id}
      >
        <img src={commodity.photo} className="border-radius-15" />
        <div className="flex flex-row justify-between items-center mt-px-15 text-base">
          <div className="text-gray-dark text-bold">{commodity.name}</div>
          <div className="flex flex-row items-center">
            <img src={Crystal} style={{ width: "22px", height: "22px" }} />
            <div className="text-yellow ml-1.5">{commodity.point_cost}</div>
          </div>
        </div>
        <div className="mt-px-5 flex flex-row justify-between text-gray-light text-sm">
          <div>{commodity.brief}</div>
          <div>
            仅剩<span className=" ml-1.5">{commodity.stock}</span>
          </div>
        </div>
      </a>
    );
  };

  return (
    <div className="p-px-15 commodity-list-container" style={props.containerStyle}>
      <Header />
      <div className="commodity-items">
        {commodityItems
          ? commodityItems.map((commodity) => {
              return CommodityItem(commodity);
            })
          : ""}
      </div>

      {/* <div                                                           */}
      {/*   className="text-gray-light mt-10 text-center"                */}
      {/*   onClick={() => {                                             */}
      {/*     getDate();                                                 */}
      {/*   }}                                                           */}
      {/* >                                                              */}
      {/*   {shouldLoadMore ? "点击加载更多" : "没有更多喽~"} */}
      {/* </div>                                                         */}

      {loadingRef && <div ref={loadingRef} className="text-gray-light" style={{ textAlign: 'center', padding: 10 }}>加载中...</div>}
    </div>
  );
};

export default CommodityList;
