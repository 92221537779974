import React from "react";
import PropTypes from "prop-types";
import icon_rank from "study_package/icon_star_rank.png";

import "./PoetryStarRank.scss";

// 排行榜子信息
const BabyRankInfo = ({ info, index, position }) => {
  return (
    <li
      style={{
        backgroundColor: position == index ? "#f4fff8" : null,
        margin: position == index ? "0 -20px" : null,
        padding: position == index ? "10px 20px 10px 20px" : null,
        backgroundPosition: position == index ? "20px center" : null,
      }}
    >
      <div className="rank_star">{info.rank}</div>
      <div className="name">
        <i>{index + 1 <= 3 ? "" : index + 1 + "."}&nbsp;</i>
        <span style={{ paddingLeft: index + 1 <= 3 ? "20px" : null }}>
          {info.baby_nick_name}
        </span>
      </div>
    </li>
  );
};

// 排行榜
const RankInfoLists = ({ rankInfos, currentBabyRank }) => {
  return (
    <div className="content">
      <img className="lazyload" data-src={icon_rank} alt="" />
      <div className="baby_info_list">
        <div className="baby_info">
          <ul
            style={{
              paddingBottom:
                currentBabyRank.position + 1 > rankInfos.length ? "45px" : null,
            }}
          >
            {rankInfos.map((info, index) => (
              <BabyRankInfo
                key={index}
                info={info}
                index={index}
                position={currentBabyRank.position}
              />
            ))}
          </ul>
          {currentBabyRank.position + 1 > rankInfos.length && (
            <div className="rank_bottom_fixed">
              <div className="rank_star">{currentBabyRank.rank}</div>
              <div className="name">
                <i style={{ paddingLeft: "0" }}>
                  {currentBabyRank.position + 1 + "."}&nbsp;
                </i>
                <span>{currentBabyRank.baby_nick_name}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default class PoetryStarRank extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  render() {
    return (
      <div className="comp_poetry_star_rank">
        <a className="collect_star_rule" href={this.props.rule_url}>
          集星规则
        </a>
        <div className="rank_info_lists">
          <RankInfoLists
            rankInfos={this.props.top_ranks}
            currentBabyRank={this.props.current_baby_rank}
          />
        </div>
        <div className="bottom_text">排行榜仅显示排名前10位的小学员</div>
      </div>
    );
  }
}

PoetryStarRank.propTypes = {
  top_ranks: PropTypes.array,
};
