/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback, memo } from "react";
import WithErrorBoundary from "../utils/WithErrorBoundary.jsx";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../utils/request";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import { ToastManager } from "../utils/Toast";
import { onNavigate } from "../utils/helpers";
import RightFloatIcons from "../study_package_activities/RightFloatIcons.jsx";
import icon_up from "wrong_book/icon_up.png";
import icon_down from "wrong_book/icon_down.png";
import { GA, GA_new } from "../utils/Env";

import "./CourseWrongs.scss";
import WrongBook from "./poetries/v2/components/WrongBook.jsx";

const NoneContent = memo((props) => {
  return (
    <div className="none_content">
      <div className="icon"></div>
      <div className="text">
        练习产生的错题会自动同步到错题本中
        <br />
        快快去练习吧~
      </div>
    </div>
  );
});
const SingleQuiz = ({ quiz, isRevisal }) => {
  const [isClose, setIsClose] = useState(true);
  const handleQuizAnalysisFold = useCallback((e) => {
    setIsClose((isClose) => !isClose);
  }, []);
  const subquestion = quiz.subquestions[0];
  return (
    <div className="quizzes_box">
      <div className="quiz_index">{quiz.quizIndex}</div>
      <div className="quiz_title">{quiz.question}</div>
      {quiz.question_photo_url && (
        <div className="quiz_img">
          <img src={quiz.question_photo_url} alt="" />
        </div>
      )}
      <div className="quiz_contents">
        <div className="quiz_content">
          {isRevisal ? (
            <div className="entry_time">
              <strong>订正时间：</strong>
              <span>{quiz.update_time}</span>
            </div>
          ) : (
            <div className="entry_time">
              <strong>录入时间：</strong>
              <span>{quiz.record_time}</span>
            </div>
          )}
        </div>
        {quiz?.media_lesson?.name ? (
          <div className="quiz_content">
            <div className="lesson_title">
              <strong>所属课时：</strong>
              <span>{quiz?.media_lesson?.name}</span>
            </div>

            {quiz?.media_lesson?.url && (
              <a className="video_explanation" href={quiz?.media_lesson?.url}>
                视频讲解
              </a>
            )}
          </div>
        ) : (
          ""
        )}
        {!quiz.offline && (
          <div
            className="quiz_content"
            onClick={(e) => handleQuizAnalysisFold(e)}
          >
            <strong>错题解析</strong>
            <img src={isClose ? icon_down : icon_up} alt="" />
          </div>
        )}
        {
          // 错题本展开
          !isClose && (
            <div className="quiz_analysis_fold">
              <div className="quiz_answer_box">
                <div className="correct_answer">
                  正确答案：
                  {subquestion?.correct_option?.photo ? (
                    <img src={subquestion?.correct_option?.photo?.url} alt="" />
                  ) : (
                    <span>
                      {subquestion?.correct_option
                        ? subquestion?.correct_option?.content
                        : subquestion?.correct_answer}
                    </span>
                  )}
                </div>
                <div className="person_answer">
                  我的回答：
                  {subquestion?.choose_option?.photo ? (
                    <img src={subquestion?.choose_option?.photo?.url} alt="" />
                  ) : (
                    <span>
                      {subquestion?.choose_option
                        ? subquestion?.choose_option?.content
                        : subquestion?.input_answer}
                    </span>
                  )}
                </div>
              </div>
              {quiz?.hint && (
                <div className="quiz_markdown_conclusion">
                  <ReactMarkdown source={quiz?.hint} />
                </div>
              )}
            </div>
          )
        }
      </div>
      <div
        className="fix_course_quiz"
        style={
          isRevisal ||
          quiz.offline ||
          !quiz.wrong_quiz_revisal_url ||
          quiz.set_type == 3
            ? { justifyContent: "center" }
            : {}
        }
        // style={{ justifyContent: "center" }}
      >
        {!isRevisal && quiz.wrong_quiz_revisal_url && !quiz.offline ? (
          <div
            className="quiz_correct"
            onClick={(e) => onNavigate(e, quiz.wrong_quiz_revisal_url)}
          >
            订正
          </div>
        ) : (
          ""
        )}
        {quiz.practise_url && quiz.set_type !== 3 && (
          <div
            className="relative_quiz_practise"
            onClick={(e) => onNavigate(e, quiz.practise_url)}
          >
            类似题练习
          </div>
        )}
      </div>
    </div>
  );
};

const WrongQuizzes = ({ wrongQuizzes }) => {
  console.log("wrongQuizzes: ", wrongQuizzes);
  const failQuizzes = [];
  wrongQuizzes.forEach((wrong_quiz, index) => {
    failQuizzes.push({
      ...wrong_quiz,
      quizIndex: index + 1,
    });
  });
  return (
    <div className="wrong_quizzes_container">
      {failQuizzes.reverse().map((wrong_quiz, index) => {
        return <SingleQuiz key={index} quiz={wrong_quiz} />;
      })}
    </div>
  );
};
const CorrectQuizzes = ({ correctQuizzes, isRevisal }) => {
  console.log("correctQuizzes: ", correctQuizzes);
  const correctQuizzesLists = [];
  correctQuizzes.forEach((correct_quiz, index) => {
    correctQuizzesLists.push({
      ...correct_quiz,
      quizIndex: index + 1,
    });
  });
  return (
    <div className="correct_quizzes_container">
      {correctQuizzesLists.reverse().map((correct_quiz, index) => {
        return (
          <SingleQuiz key={index} quiz={correct_quiz} isRevisal={isRevisal} />
        );
      })}
    </div>
  );
};

const CourseWrongs = ({ ...props }) => {
  console.log("CourseWrongs_props: ", props);
  const {
    get_wrong_quiz_sets_url,
    get_revisal_quiz_sets_url,
    // unitData,
    isShowFloat,
  } = props;
  const [quizzes, setQuizzes] = useState([]);
  const [comprehensiveUrl, setComprehensiveUrl] = useState("");
  const [isRevisal, setIsRevisal] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [quizWrong, setQuizWrong] = useState(0);
  const [mathQuizWrongs, setMathQuizWrongs] = useState(null);
  const [readingQuizWrongs, setReadingQuizWrongs] = useState(null);
  const [unitData, setUnitData] = useState(null);
  useEffect(() => {
    requestWrongQuizzes(get_wrong_quiz_sets_url);
    // request({
    //   url: props.get_wrong_quiz_sets_url,
    //   headers: csrfHeaders,
    //   method: "GET",
    // }).then((res) => {
    //   const data = res.data;
    //   if (data.comprehensive_wrong_quiz_practise_url) {
    //     setComprehensiveUrl(data.comprehensive_wrong_quiz_practise_url);
    //   }
    //   setQuizzes(data.quizzes);
    //   setUnitData(data);
    // });
  }, []);

  const onTabClick = (e, activeTabId) => {
    setActiveTabIndex(activeTabId);
    if (activeTabId == 1) {
      console.log("语文错题");
      requestWrongQuizzes(get_chinese_wrong_quiz_sets_url);
    } else if (activeTabId == 2) {
      console.log("已订正");
      setIsRevisal(true);
      requestWrongQuizzes(get_revisal_quiz_sets_url);
    } else {
      console.log("默认数学错题");
      requestWrongQuizzes(get_math_wrong_quiz_sets_url);
    }
  };
  const requestWrongQuizzes = useCallback((url) => {
    setQuizzes([]);
    const toast = ToastManager.showLoading("数据加载中...");
    return request({
      url: url,
      method: "GET",
      headers: csrfHeaders,
    })
      .then((res) => {
        console.log("错题data: ", res.data);
        const data = res.data;
        if (data.comprehensive_wrong_quiz_practise_url) {
          setComprehensiveUrl(data.comprehensive_wrong_quiz_practise_url);
        }
        let newQuizzes = [];
        let maths = [];
        let readings = [];
        data.quizzes.map((item) => {
          if (item.valid) {
            newQuizzes.push(item);
            if (item.set_type == 3) {
              readings.push(item);
            } else {
              maths.push(item);
            }
          }
        });
        setMathQuizWrongs(maths);
        setReadingQuizWrongs(readings);
        setQuizzes(newQuizzes);
        setUnitData(data);
        toast.cancel();
      })
      .catch((err) => {
        console.log(err);
        toast.cancel();
      });
  }, []);
  // useEffect(() => {
  //   setComprehensiveUrl(unitData.comprehensive_wrong_quiz_practise_url);
  //   setQuizzes(unitData.quizzes);
  // }, [unitData]);

  const handleBackToTop = () => {
    $("#_scroll_list").animate({ scrollTop: 0 }, 500);
  };
  // 返回课程
  const handleBackCourse = () => {
    GA("shortcut", "packageBack", props.unitData.tag_id);
    GA_new(
      "shortcut",
      "package_back",
      props.unitData.tag_id,
      props.unitData.target_type,
      props.unitData.target_id
    );
    window.location.href = props?.study_package_url;
  };
  return (
    <>
      <div className="comp_wrong_book">
        {unitData && (
          <WrongBook
            {...props}
            unitData={unitData}
            requestUrl={props.get_wrong_quiz_sets_url}
          ></WrongBook>
        )}
        {/* <Tabs
          defaultAction={onTabClick}
          styles="tabs"
          defaultActiveIndex={Number(activeTabIndex)}
        >
          <TabPane
            tab={
              <div>
                <div>数学错题</div>
                <div>{`（${mathQuizWrongs ? mathQuizWrongs.length : 0}）`}</div>
              </div>
            }
            styles="tab"
            activeStyle="active"
          >
            {quizzes.length > 0 ? (
              <WrongQuizzes wrongQuizzes={mathQuizWrongs} />
            ) : (
              <NoneContent />
            )}
          </TabPane>
          <TabPane
            tab={
              <div>
                <div>语文错题</div>
                <div>{`（${
                  readingQuizWrongs ? readingQuizWrongs.length : 0
                }）`}</div>
              </div>
            }
            styles="tab"
            activeStyle="active"
          >
            {quizzes.length > 0 ? (
              <WrongQuizzes wrongQuizzes={readingQuizWrongs} />
            ) : (
              <NoneContent />
            )}
          </TabPane>
          <TabPane
            tab={
              <div>
                <div>已订正</div>
                <div>{`（${
                  unitData ? unitData.revisal_quizzes_count : 0
                }）`}</div>
              </div>
            }
            styles="tab"
            activeStyle="active"
          >
            {quizzes.length > 0 ? (
              <CorrectQuizzes correctQuizzes={quizzes} isRevisal={isRevisal} />
            ) : (
              <NoneContent />
            )}
          </TabPane>
        </Tabs> */}
        {comprehensiveUrl &&
          unitData &&
          unitData.wrong_quizzes_count != 0 &&
          activeTabIndex != 1 && (
            <div className="comprehensive_url_box">
              <a href={comprehensiveUrl} className="comprehensive_url">
                生成综合错题卷
              </a>
            </div>
          )}
      </div>
      {isShowFloat && (
        <RightFloatIcons
          isShowBack
          onBackClick={handleBackCourse}
          isShowScrollTop
          onScrollTop={handleBackToTop}
          bottomPosition={90}
        />
      )}
    </>
  );
};

export default WithErrorBoundary(CourseWrongs);
