import React from "react";
import { TabPane, Tabs } from "../utils/Tabs/Tabs.js";
import "./CourseListStudy.scss";
import { request, csrfHeaders } from "../utils/request";
import CourseWrongs from "./CourseWrongs";
import CourseList from "./CourseList";
import { debounce, showDialog, onNavigate } from "../utils/helpers";
import icon_module_border from "c4/icon_module_border.png";
import icon_lock from "c4/icon_lock.png";
import RightFloatIcons from "../study_package_activities/RightFloatIcons.jsx";
import { ToastManager } from "../utils/Toast.jsx";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import { StudyPackageCommentModule } from "./UnitModeStudyPackage.jsx";

export default class CourseListStudy extends React.Component {
  constructor(props) {
    super(props);
    let units_title = null;
    if (
      props.study_package_activities &&
      props.study_package_activities.length > 0
    ) {
      units_title = props.study_package_activities.map((unit) => ({
        name: unit.name,
        id: unit.id,
        // handbook: unit.handbook,
        photoUrl: unit.photo_url,
        data: unit,
      }));
    }
    let activeTabId = window.location.href.includes("wrongBook=1") ? 1 : 0;
    this.state = {
      activeTabId: activeTabId || 0,
      units_title,
      tabs: [],
      createRecommendRequestUrl: props.create_recommend_request_url,
      // tabs: [
      //   { name: "name1", data: {} },
      //   { name: "name2", data: {} },
      // ],
    };
  }

  componentDidMount() {
    const { units_title } = this.state;
    const { get_wrong_quiz_sets_url, get_study_package_url } = this.props;
    const UNITS = [
      // parent_guide_url
      //   ? {
      //       name: "家长须知",
      //       Comp: ParentGuide,
      //       requestUrl: parent_guide_url,
      //     }
      //   : "",
      ...(units_title || []).map((unit) => ({
        id: unit.id,
        name: unit.name,
        // handbook: unit.handbook,
        Comp: () => {
          return <CourseList {...this.props}></CourseList>;
        },
        requestUrl: get_study_package_url || "",
      })),

      get_wrong_quiz_sets_url
        ? {
            // 增加错题本tab
            name: "错题本",
            Comp: () => {
              return <CourseWrongs {...this.props}></CourseWrongs>;
            },
            requestUrl:
              this.props.course_type == "chinese"
                ? this.props.get_chinese_wrong_quiz_sets_url
                : this.props.course_type == "math"
                ? this.props.get_math_wrong_quiz_sets_url
                : this.props.get_revisal_quiz_sets_url,
          }
        : "",
      // eslint-disable-next-line eqeqeq
    ].filter((item) => item != "");
    this.setState({
      tabs: [...UNITS],
    });
  }
  tabClick(e, index) {
    console.log(e, "handle", index);
    this.setState({
      activeTabId: Number(index),
    });
  }
  handleBackCourse = () => {
    const isRegularCourseIndex = this.props.study_package_activities.findIndex(
      (activity) => !activity.handbook
    );
    const head_menus = document.getElementsByClassName("head_menu_lis");
    if (isRegularCourseIndex >= 0 && head_menus?.length) {
      $(head_menus[isRegularCourseIndex]).trigger("click");
    }
  };
  onInvitationIconClick = () => {
    const {
      get_shopping_group_data_url: get_shopping_group_data_url,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      study_package_activity_id,
    } = this.props;
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: get_shopping_group_data_url,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(
        ({
          bonus,
          had_joined_groupon,
          remaining_shopping_group_count,
          avatar_location,
        }) => {
          new SharePosterProxy({
            // posterUrl: this.props.poster_url,
            // posterImgSrc: this.props.poster_img_src,
            avatar_location,
            posterTemplate,
            posterCreation,
            mode: "front_end",
            free:
              had_joined_groupon && remaining_shopping_group_count > 0
                ? "in_or_open_shopping_group"
                : "none_shopping_group",
            bonus: bonus,
            sale_mode: "shopping_group",
            remaining_shopping_group_count,
          })
            .open({
              isShowToast: false,
            })
            .then(() => {
              toast.cancel();
              // GA("shortcut", "packagePoster", study_package_activity_id);
            });
        }
      )
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });
  };
  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    this.setState({
      writeEvaluateDialogState: true,
    });
  };
  // 错题本
  handleWrongBookClick = () => {
    // console.log(
    //   "GA_new("shortcut", 26, this.props.study_package_activity_id, () => {",
    //   this.props
    // );
    // GA("shortcut", "report_wrong_quiz", this.props.study_package_activity_id);
    // GA_new(
    //   "shortcut",
    //   "report_wrong_quiz",
    //   this.props.study_package_activity_id,
    //   this.props.target_type,
    //   this.props.target_id
    // );
    const head_menus = document.getElementsByClassName("head_menu_lis");
    // if (isRegularCourseIndex >= 0 && head_menus?.length) {
    $(head_menus[head_menus?.length - 1]).trigger("click");
    // }
    // const { tabs } = this.state;
    // $(`.head_menu_lis [data-id=${String(tabs.length - 1)}]`).trigger("click");
    // window.location.href = this.props.get_wrong_quiz_sets_url;
  };
  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };

  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };
  render() {
    const { tabs, activeTabId } = this.state;
    const { createRecommendRequestUrl } = this.props;
    return (
      <div className="course-list-study-back">
        <div className="course-list-study-scroll">
          {/* <div className="course-list-study-tabs"> */}
          <Tabs
            defaultAction={this.tabClick.bind(this)}
            styles="course-list-study-tabs"
            defaultActiveIndex={Number(activeTabId)}
            animatedTabTitle={true}
          >
            {tabs.map((item, index) => {
              return (
                <TabPane
                  tab={
                    <div className="tab_wrapper">
                      {item.photoUrl && <img src={item.photoUrl} alt="" />}
                      <p>{item.name}</p>
                    </div>
                  }
                  styles={`head_menu_lis head_menu_li_${index}`}
                  activeStyle={`tab_light tab_light_${index}`}
                  key={index}
                >
                  <div>{item.Comp()}</div>
                </TabPane>
              );
            })}
          </Tabs>
          {/* </div> */}
        </div>
        <RightFloatIcons
          isShowMakeComment={
            this.props.comment_state === "uncommented" &&
            !!this.props.createRecommendRequestUrl
          }
          onMakeCommentClick={this.getWriteEvaluateStatus}
          isShowRedMoney={!!this.props.bonus}
          onRedMoneyClick={this.onInvitationIconClick}
          // isShowReport
          // onReportClick={this.onStudyReportIconClick}
          isShowWrongBook={
            !!this.props.get_wrong_quiz_sets_url && activeTabId == 0
          }
          onWongBookClick={this.handleWrongBookClick}
          // isShowSetting={this.props.trial_unlock_mode ? false : true}
          // onSetting={this.onSettingIconClick}
          bottomPosition={this.props.trial_unlock_mode ? 170 : 80}
          isShowBack={this.state.activeTabId == 1}
          onBackClick={this.handleBackCourse}
          // isShowScrollTop
          // onScrollTop={this.handleBackToTop}
        />
        {this.state.writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={this.state.createRecommendRequestUrl}
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment}
            bought_phase={this.props.bought_phase}
            promotion={this.props.promotion}
            rate={this.props.rate}
            name={this.props.study_package_name}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}
        {/* <div className=" back-fixed">
          <div className="no-pass-back">
            <div className="no-pass-tiitle">
              <div>{` ${"33333"}未通过，联系`}</div>
              <div>{`课程老师，更换/取消课程吧~`}</div>
            </div>
            <div className="no-pass-content">
              <img
                src={icon_module_border}
                className="icon_module_border"
              ></img>
              <div className="no-pass-content-small"></div>
              <div className="no-pass-content-big">
                <img src={icon_module_border} className="code-img"></img>
                <div className="module-button">
                  <img src={icon_lock} className="photo-img"></img>
                  <div className="module-line">心芽-桃子老师</div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="pass-back back-fixed"></div> */}
      </div>
    );
  }
}
