import React from "react";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../utils/request";
import {
  debounce,
  print,
  tracking,
  onNavigate,
  promptPageBack,
} from "../utils/helpers";
import styled from "styled-components";

import { clipboard } from "../../components/utils/helpers.js";
import course_title from "practice_courses/course_title.png";
import exam_go_icon from "practice_courses/exam_go_icon.png";
import exam_pass from "practice_courses/exam_pass.png";
import fail_star from "practice_courses/fail_star.png";
import go_right_course from "practice_courses/go_right_course.png";
import group_back_img1 from "practice_courses/group_back_img1.png";
import group_back_img2 from "practice_courses/group_back_img2.png";
import group_back_img3 from "practice_courses/group_back_img3.png";
import group_back_img4 from "practice_courses/group_back_img4.png";
import group_item_icon from "practice_courses/group_item_icon.png";
import go_study_back from "practice_courses/go_study_back.png";
import sucess_star from "practice_courses/sucess_star.png";
import icon_download from "practice_courses/icon_download.png";
import icon_know from "c4/icon_know.png";
import icon_teacher from "c4/icon_teacher.png";
import btn_close from "study_package/btn_close.png";
import UserAgent from "../utils/UserAgent";
import "../utils/ReactMarkDown.scss";
import "./PracticeCourses.scss";
// import VConsole from "vconsole";
// var vConsole = new VConsole();
const unitBack = [
  {
    back_img: group_back_img1,
    w: "152px",
    h: "124px",
    r: "-15px",
    t: "-71px",
  },
  { back_img: group_back_img2, w: "", h: "266px", r: "-15px", t: "-186px" },
  { back_img: group_back_img3, w: "", h: "227px", r: "-15px", t: "-90px" },
  {
    back_img: group_back_img4,
    w: "201px",
    h: "111px",
    r: "-15px",
    t: "-44px",
  },
];

const idexUper = [
  "一",
  "二",
  "三",
  "四",
  "五",
  "六",
  "七",
  "八",
  "九",
  "十",
  "十一",
  "十二",
  "十三",
  "十四",
  "十五",
  "十六",
  "十七",
  "十八",
  "十九",
  "二十",
  "二十一",
  "二十二",
  "二十三",
  "二十四",
  "二十五",
  "二十六",
  "二十七",
  "二十八",
  "二十九",
  "三十",
  "三十一",
  "三十二",
  "三十三",
  "三十四",
  "三十五",
  "三十六",
  "三十七",
  "三十八",
  "三十九",
  "四十",
  "四十一",
  "四十二",
  "四十三",
  "四十四",
  "四十五",
  "四十六",
  "四十七",
  "四十八",
  "四十九",
  "五十",
];

const StudyUnitMoudle = styled.div`
  position: relative;

  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    width: ${({ idx }) => (idx ? unitBack[idx % 4].w : unitBack[0].w)};
    height: ${({ idx }) => (idx ? unitBack[idx % 4].h : unitBack[0].h)};
    right: ${({ idx }) => (idx ? unitBack[idx % 4].r : unitBack[0].r)};
    top: ${({ idx }) => (idx ? unitBack[idx % 4].t : unitBack[0].t)};
    background: url(${({ idx }) =>
        idx ? unitBack[idx % 4].back_img : unitBack[0].back_img})
      no-repeat;
    background-size: cover;
    background-position: left;
  }

  margin-top: ${({ idx }) => (idx === 0 ? "50px" : "60px")};
`;
const settings = [
  { title: "文章打印", icon: icon_download, url: false },
  {
    title: "联系老师",
    icon: icon_teacher,
    url: "https://xinya.me/course_consultants?scenario=45",
  },
];

export const PdfPracticeNoteCopy = ({
  // media_lessons,
  handlePdfPractice,
  copyUrl,
  unitPdfUrl,
  unitName,
  is_android_os,
  // activityCode,
  // isPoetry,
}) => {
  // const CopyPdfUrlList = ({ title }) => {
  //   return (
  //     <div className="pdf_quiz_lists">
  //       <div className="left_title">{title}</div>
  //       {media_lessons.map((media_lesson, index) => {
  //         const {
  //           media_course_name,
  //           name,
  //           media_lesson_pdf_url,
  //         } = media_lesson;
  //         return !media_lesson.media_lesson_pdf_url ? (
  //           ""
  //         ) : (
  //           <div className="quiz_list" key={index}>
  //             <div className="left_section">
  //               <div className="practice_title">
  //                 {isPoetry
  //                   ? media_course_name
  //                   : !!media_course_name
  //                   ? `${activityCode}-${media_course_name}-${name}`
  //                   : `${activityCode}-${name}`}
  //               </div>
  //               {/* <div className="practice_url">{media_lesson_pdf_url}</div> */}
  //             </div>
  //             <div
  //               className="right_section"
  //               onClick={copyUrl}
  //               data-index={index}
  //             >
  //               复制网址
  //             </div>
  //           </div>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  const UnitPdfUrlList = ({ title }) => {
    return (
      <div className="pdf_quiz_lists">
        <div className="left_title">{title}</div>
        <div className="quiz_list">
          <div className="left_section">
            <div className="practice_title">
              {unitName ? unitName : "课件"}
              {/* {isPoetry
                ? `${unitName}单元全部讲义`
                : `${activityCode}-${unitName}单元全部练习题`} */}
            </div>
            {/* <div className="practice_url">{unitPdfUrl}</div> */}
          </div>
          <div
            className="pdf-down-bottom"
            style={{ justifyContent: is_android_os ? "center" : "" }}
          >
            {is_android_os ? (
              ""
            ) : (
              <div
                className="pdf-down-bottom-look"
                onClick={() => {
                  // window.open(unitPdfUrl);
                  window.location.href = unitPdfUrl;
                }}
              >
                预览
              </div>
            )}
            <div className="right_section" onClick={copyUrl} data-index={`-1`}>
              复制网址下载
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="pdf_practice_copy">
      <div className="overlay" />
      <div className="dialog_container">
        <div className="title">建议使用电脑浏览器访问以下网址打印</div>
        <div
          className="close_button_right"
          onClick={handlePdfPractice.bind(this, false)}
        >
          &times;
        </div>
        <div className="pdf_quiz_lists_container">
          {/* <CopyPdfUrlList title={`附件`} /> */}
          {unitPdfUrl && <UnitPdfUrlList title={`附件`} />}
        </div>
      </div>
    </div>
  );
};

class PracticeCourses extends React.Component {
  constructor(props) {
    super(props);
    console.log("PracticeCourses_props", props);
    this.state = {
      study_package_modules: [],
      showUpper: true,
    };
  }

  componentDidMount() {
    let { study_package_units } = this.props;
    request({
      url: study_package_units[0].get_unit_study_url,
      method: "GET",
      headers: csrfHeaders,
      data: {
        study_package_unit_id: study_package_units[0].id,
        study_package_activity_id: this.props.study_package_activity_id,
      },
    }).then((res) => {
      console.log(res, "------------------res");
      // let { study_package_modules } = res;
      this.setState(
        {
          study_package_modules: [...res.data.study_package_modules],
        },
        () => {
          this.scrollToDay(this.props.current_study_day);
        }
      );
    });
  }

  getStar(n) {
    if (n === 0) {
      return (
        <div className="star_icons">
          <img src={fail_star}></img>
          <img src={fail_star}></img>
          <img src={fail_star}></img>
        </div>
      );
    } else if (n === 1) {
      return (
        <div className="star_icons">
          <img src={sucess_star}></img>
          <img src={fail_star}></img>
          <img src={fail_star}></img>
        </div>
      );
    } else if (n === 2) {
      return (
        <div className="star_icons">
          <img src={sucess_star}></img>
          <img src={sucess_star}></img>
          <img src={fail_star}></img>
        </div>
      );
    } else if (n === 3) {
      return (
        <div className="star_icons">
          <img src={sucess_star}></img>
          <img src={sucess_star}></img>
          <img src={sucess_star}></img>
        </div>
      );
    } else {
      return (
        <div className="star_icons">
          <div className="go_icons">
            <img src={go_right_course}></img>
          </div>
        </div>
      );
    }
  }

  goExam(flag) {
    return flag ? (
      <div className="star_icons">
        <div className="go_exam_icons ">
          <img src={exam_pass}></img>
        </div>
      </div>
    ) : (
      <div className="star_icons">
        <div className="next_quize">
          {/* <div className="next_quize1"></div>
          <div className="next_quize2"></div> */}
          去测试
        </div>
      </div>
    );
  }

  scrollToDay = (id) => {
    const anchorCurrentProgress = document.getElementById(id);
    if (anchorCurrentProgress) {
      let progressMarkTop = anchorCurrentProgress.getBoundingClientRect().top;
      // console.log("progressMarkTop: ", progressMarkTop);

      progressMarkTop -= 100;
      console.log(String(id).indexOf("grounp"), String(id), "             ");
      if (
        String(id).indexOf("grounp") === -1 &&
        this.props.current_study_day == 1
      ) {
        progressMarkTop = 0; // 默认清零
      }
      $("#_scroll_list").animate({ scrollTop: progressMarkTop }, 500);
      // this.scrolledAnchor = true;
    }
  };

  handlePdfPractice(boolState) {
    this.setState({ showDownLoad: boolState });
  }
  handleCopyUrl(e) {
    const parentIndex = e.currentTarget.getAttribute("data-index");
    // console.log('parentIndex: ', parentIndex);

    let currentClickUrl = this.props.article_to_print_url;
    // trial_period_notice?.document?.download_url || "zhantie";
    // parentIndex == -1
    //   ? this.props.unit_pdf_url
    //   : this.props.media_lessons[parentIndex].media_lesson_pdf_url;

    // console.log("parentIndex: ", parentIndex);
    // console.log("currentClickUrl: ", currentClickUrl);

    clipboard(window, document, navigator).copy(currentClickUrl, true);
  }
  render() {
    return (
      <>
        <div className="new-course clear_scroll_bar" id="_scroll_list">
          {/* 头部 */}
          <div className="course-list-header course-practice-list-header">
            <div className="course-list-header-title">
              <img src={icon_know}></img>
              练习须知
              <img src={icon_know}></img>
            </div>
            <div className="course-list-header-body">
              {settings.map((item, index) => {
                return (
                  <div
                    key={item.icon}
                    className="header-body-item"
                    onClick={() => {
                      if (item.url) {
                        window.location.href = item.url;
                      } else {
                        // console.log("pppppppppppp");
                        this.setState({
                          showDownLoad: true,
                        });
                      }
                    }}
                  >
                    <div className="item-style">
                      <img src={item.icon}></img>
                      <div>{item.title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="new-course-title">
            <img className="course_title" src={course_title}></img>
            <div className="new-course-title-border">
              <div className="new-course-title-group">
                {this.state.study_package_modules &&
                  this.state.study_package_modules.map((item, indx) => {
                    return (
                      <div
                        className="new-course-group-item"
                        key={item.id + "id"}
                        onClick={() => {
                          if (item.pass) {
                            return;
                          }
                          this.setState({ selcedId: item.id, showDig: true });
                        }}
                        style={{
                          marginRight: (indx + 1) % 4 === 0 ? 0 : "",
                        }}
                      >
                        <div className="new-course-group-item-border">
                          {item.pass && item.url ? (
                            <img src={item.url}></img>
                          ) : (
                            <img src={group_item_icon}></img>
                          )}
                          <div className="add-border"></div>
                          {/* <img src={group_item_icon}></img> */}
                          {!(item.pass && item.url) && (
                            <div className="group-item-title">
                              {"单元" + idexUper[indx]}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* 尾部说明 */}
              <div className="group-items-bottom">
                <div className="group-items-dot"></div>
                <div className="group-items-intro">
                  完成单元练习，就可以收集精美卡牌啦！
                </div>
              </div>
              {/* 尾部说明 */}
            </div>
          </div>
          {/* 头部 */}
          {/* 课程列表 */}
          <div className="new-courses">
            {/* 单元 */}
            {this.state.study_package_modules &&
              this.state.study_package_modules.map((item, index) => {
                return item.day_studies && item.day_studies.length ? (
                  <StudyUnitMoudle
                    className="new-courses-group"
                    idx={index}
                    key={item.id + "StudyUnitMoudle"}
                    id={item.id + "grounp"}
                  >
                    <div className="new-courses-group-title">
                      <div className="group-title-name">
                        {"单元" + idexUper[index]}
                      </div>
                      {item.name && (
                        <div className="group-title-dot">{item.name}</div>
                      )}
                    </div>
                    {/* 单元简介-----单元课时 */}
                    <div className="new-courses-group-items">
                      {item.day_studies &&
                        item.day_studies.length &&
                        item.day_studies.map((im, ix) => {
                          return im.study_type == 2 ? (
                            // {/* 单元课时----------------单元测试 */}
                            <div
                              key={ix + "ix"}
                              id={im.day}
                              className="group-exam-back"
                              onClick={() => {
                                if (im.pass) {
                                  window.location.href = im.get_learn_info_url;
                                } else {
                                  window.location.href =
                                    im.unit_practice_poetry_articles_url;
                                }
                              }}
                            >
                              <div className="group-exam">
                                <div className="group-exam-conect">
                                  <div className="group-exam-img">
                                    <img
                                      src={
                                        im.photo_url
                                          ? im.photo_url
                                          : exam_go_icon
                                      }
                                    ></img>
                                  </div>
                                  <div className="group-exam-left">
                                    <div className="group-exam-left-main">
                                      <div className="group-exam-name">
                                        {im.day_study_title}
                                      </div>
                                      <div className="group-exam-decs">
                                        {im.kb_lesson_content}
                                      </div>
                                    </div>
                                    <div className="group-exam-go">
                                      {this.goExam(im.pass)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="new-courses-group-item"
                              id={im.day}
                              key={ix + "ix"}
                              onClick={() => {
                                if (im.get_learn_info_url) {
                                  window.location.href = im.get_learn_info_url;
                                }
                              }}
                            >
                              <div className="group-item-img">
                                {im.photo_url && <img src={im.photo_url}></img>}
                              </div>
                              <div className="group-item-left">
                                <div className="group-item-name-flex">
                                  <div>{ix + 1 + "."}</div>
                                  <div className="group-item-name">
                                    {im.day_study_title}
                                    {/* 这是一个句子非常长这是一个句子非常长这是一个句子非常长这是一个句子非常长这是一个句子非常长这是一个句子非常长这是一个句子非常长 */}
                                  </div>
                                </div>
                                <div className="group-item-status">
                                  {!im.pass
                                    ? this.getStar(null)
                                    : this.getStar(im.rank)}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </StudyUnitMoudle>
                ) : (
                  ""
                );
              })}

            {/* 单元 */}
          </div>

          {/*  课程列表 */}
        </div>
        {/* 弹窗 */}
        {this.state.showDig && (
          <div className="show-dig">
            <div
              className="show-dig-center"
              onClick={() => {
                this.setState({ showDig: false }, () => {
                  this.scrollToDay(this.state.selcedId + "grounp");
                });
              }}
            >
              <div className="show-dig-img">
                <img src={go_study_back}></img>
              </div>
              <div className="show-dig-tips">
                <div>本单元掌握如何？</div>
                <div> 赶快测一测吧！</div>
              </div>
              <div className="show-dig-bottom">
                <div className="next_quize">
                  <div className="next_quize1"></div>
                  <div className="next_quize2"></div>
                  去测试
                </div>
              </div>
            </div>
            <div
              className="go_study_cancle"
              onClick={() => {
                this.setState({ showDig: false });
              }}
            >
              <img src={btn_close}></img>
            </div>
          </div>
        )}
        {/* {this.state.showDig&&} */}
        {/* 弹窗 */}
        {console.log(
          this.state.showUpper,
          "------------",
          this.props.upgrade_app_url,
          "=================="
        )}
        {this.state.showUpper && this.props.upgrade_app_url && (
          <div className="upper-icon">
            <div className="upper-icon-back-img">
              {/* 更新内容 */}
              <div className="upper-icon-back-text">
                <div style={{ color: "#66d167" }}>新版APP中新增：语音题型</div>
                <div style={{ color: "#66d167" }}>
                  请升级APP，否则将无法进行练习哦~
                </div>
              </div>
              {/* 更新按钮 ============ */}
              <div
                className="upper-icon-back-text-item-button"
                onClick={() => {
                  if (UserAgent.isAndroid()) {
                    XinYaNativeBridge.navigate({
                      href: this.props.upgrade_app_url,
                      type: "update",
                    });
                  } else {
                    XinYaNativeBridge.navigate({
                      href: this.props.upgrade_app_url
                    });
                  }
                }}
              >
                立即升级
              </div>
              {/* 更新按钮 ============*/}
            </div>
            {/* 取消按钮 */}
            <div
              className="upper-icon-back-text-item-cancle"
              onClick={() => {
                console.log("..........cancle");
                this.setState({
                  showUpper: false,
                });
              }}
            ></div>
            {/* 取消按钮 ===============*/}
            {/* 更新提示====================== */}
          </div>
        )}
        {this.state.showDownLoad && (
          <PdfPracticeNoteCopy
            // media_lessons={media_lessons}
            handlePdfPractice={this.handlePdfPractice.bind(this)}
            copyUrl={this.handleCopyUrl.bind(this)}
            unitPdfUrl={
              this.props.article_to_print_url
                ? this.props.article_to_print_url
                : "暂时没有课件，尽快联系老师吧！"
              // "这是粘贴内容"
              // trial_period_notice?.document?.download_url || "这是粘贴内容"
            }
            unitName={"《阅读理解课课练·一下》拓展文章"}
            is_android_os={this.props.is_android_os}
            // activityCode={study_package_activity_code}
          />
        )}
      </>
    );
  }
}

export default PracticeCourses;
